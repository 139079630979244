import { useCallback, useState } from 'react'

export default function useResourceLibraryTabs() {
  const [tab, setTab] = useState<number>(0)

  const onTabChange = useCallback((evt: React.SyntheticEvent, tab: number) => {
    setTab(tab)
  }, [])

  return { tab, onTabChange, setTab }
}
