import { EntrySkeletonType, FieldsType } from 'contentful'
import apiClient from './apiClient'
import { SimpleEntry } from './types'

// Note: the locale should be included in the query. Consider using useGetAllEntries instead.
export default async function getAllEntries<T extends FieldsType>(contentType: string, query: any) {
  let done = false
  let skip = 0

  const all: SimpleEntry<T>[] = []

  while (!done) {
    const entries = await apiClient.getEntries<EntrySkeletonType<T>>({
      ...query,
      content_type: contentType,
      skip,
    })
    all.push(...entries.items)
    if (entries.total > 0 && entries.total >= entries.limit) {
      skip = entries.skip + entries.total
    } else {
      done = true
    }
  }

  return all
}
