import { Typography, useTheme } from '@mui/material'

interface ISimpleCardHeaderProps {
  text: string
}

export default function SimpleCardHeader(props: ISimpleCardHeaderProps): JSX.Element {
  const { text } = props

  const { typography } = useTheme()

  return (
    <Typography variant="h6" sx={{ ...typography.h6_onSurface.light }} gutterBottom>
      {text}
    </Typography>
  )
}
