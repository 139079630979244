import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { StoragePermissionPage } from '../pages/StoragePermissionPage'
import useMst from '../../models/useMst'

export default observer(function StoragePermissionWall({ children }: React.PropsWithChildren<{}>): JSX.Element {
  const { persistentStorage } = useMst()
  const { hasAcknowledged } = persistentStorage

  const body = useMemo(() => {
    // If the user has previously aknowledged persistent storage message, show the children.
    // Otherwise, show the permission page
    if (hasAcknowledged) {
      return children
    } else {
      return <StoragePermissionPage />
    }
  }, [children, hasAcknowledged])

  // Since we are doing checks on the first render as to the browser and permissions, we must prevent a mismatch of server and client side rendering
  return <>{body}</>
})
