import { useMutation } from '@tanstack/react-query'

interface IMutateVars<T, S> {
  data: T
  onSuccess?: (data: T, saved: boolean, remoteData: S | undefined) => void
  onError?: (error: unknown, vars: IMutateVars<T, S>) => void
}

interface IMutationResult<LocalData, RemoteData> {
  localData: LocalData
  remoteData: RemoteData | undefined
  saved: boolean
}

interface IUseOfflineMutationProps<LocalData, RemoteData> {
  addLocal: (data: LocalData) => void
  removeLocal: (data: LocalData) => void
  saveRemote: (data: LocalData) => Promise<RemoteData>
  onSuccess?: (data: LocalData, saved: boolean, remoteData: RemoteData | undefined) => void
  onError?: (error: unknown, vars: IMutateVars<LocalData, RemoteData>) => void
}

export default function useOfflineMutation<LocalData, RemoteData>(
  props: IUseOfflineMutationProps<LocalData, RemoteData>
) {
  const { addLocal, onError, onSuccess, removeLocal, saveRemote } = props

  return useMutation({
    mutationFn: async (vars: IMutateVars<LocalData, RemoteData>) => {
      addLocal(vars.data)
      let remoteData: RemoteData | undefined
      let saved = false
      if (navigator.onLine) {
        remoteData = await saveRemote(vars.data)
        saved = true
      } else {
        // it's offline, so we'll just wait for the next online event
      }
      return { localData: vars.data, remoteData, saved }
    },
    networkMode: 'offlineFirst', // important to make sure onMutate is called even when offline
    onSuccess: (data: IMutationResult<LocalData, RemoteData>, vars: IMutateVars<LocalData, RemoteData>) => {
      if (data.saved) {
        // remote mutation succeeded, so we can remove the local data
        removeLocal(data.localData)
      }

      onSuccess?.(data.localData, data.saved, data.remoteData)
      vars.onSuccess?.(data.localData, data.saved, data.remoteData)
    },
    onError: (error: unknown, vars: IMutateVars<LocalData, RemoteData>) => {
      onError?.(error, vars)
      vars.onError?.(error, vars)
    },
  })
}
