import { useUser } from '@auth0/nextjs-auth0/client'
import debounce from 'lodash/debounce'
import pick from 'lodash/pick'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'next-i18next'
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { FieldValues, FormProvider, SubmitErrorHandler, useForm } from 'react-hook-form'
import { ContentTypes } from '../../../../api/contentTypeIds'
import { IUserReport } from '../../../../api/types'
import { getErrorMessage } from '../../../../hooks/useErrorMessage'
import useMst from '../../../../models/useMst'
import serialiseObject from '../../../../utils/serialiseObject'
import substituteStrings from '../../../../utils/substituteStrings'
import useShowMessage from '../../../Common/useShowMessage'
import useMutateUserReport from '../hooks/useMutateUserReport'
import {
  IPlainReport,
  IPlainReportField,
  IPlainReportFormGroup,
  IPlainReportInputTable,
  IPlainReportInputTableRow,
  IPlainReportValidation,
  Variables,
} from '../types'
import compileExp from '../utils/compileExp'
import getFormFieldError from '../utils/getFormFieldError'
import initUserReport from '../utils/initUserReport'
import resolveDefaultValue from '../utils/resolveDefaultValue'
import walkFormGroupFields from '../utils/walkFormGroupFields'

interface IUserReportContextValue {
  didSubmitReport: boolean
  goBackOneStep: () => void
  groupErrors: Record<string, string[]>
  hasNext: boolean
  hasPrevious: boolean
  isReadonly: boolean
  isSaving: boolean
  report: IPlainReport
  reportErrors: string[]
  saveError: unknown | undefined
  step: IPlainReportFormGroup | undefined
  submit: () => Promise<void>
  tableErrors: Record<string, string[]>
  userReport: IUserReport
  variables: Variables
}

export const UserReportContext = createContext<IUserReportContextValue>({
  didSubmitReport: false,
  goBackOneStep: () => {},
  groupErrors: {},
  hasNext: false,
  hasPrevious: false,
  isReadonly: false,
  isSaving: false,
  report: {
    id: '',
    countries: [],
  },
  saveError: undefined,
  reportErrors: [],
  step: undefined,
  submit: async () => {},
  tableErrors: {},
  userReport: {
    created_at: '',
    Reporting_Period__c: '',
    submission_id: '',
    updated_at: '',
    user_id: '',
    Year_of_Report__c: '',
  },
  variables: {},
})

type UserReportContextProviderProps = React.PropsWithChildren<{
  report: IPlainReport
  userReport: IUserReport | undefined
}>

const appendString = (strArr: string[], value: string | string[] | undefined | false) => {
  if (value) {
    if (Array.isArray(value)) {
      strArr.push(...value)
    } else {
      strArr.push(value)
    }
  }
}

export const UserReportContextProvider = observer((props: UserReportContextProviderProps) => {
  const { children, report, userReport: userReportProp } = props
  const { user } = useUser()
  const { messages, reports } = useMst()
  const { t } = useTranslation('reports')
  const { messageDialog, showMessage } = useShowMessage()
  const [didSubmitReport, setDidSubmitReport] = useState(false)

  const {
    mutate,
    isLoading: isSaving,
    error: saveError,
  } = useMutateUserReport({
    onError: (error) => {
      showMessage({
        buttons: [
          {
            text: t('common:okay') ?? 'Okay',
            isCancel: true,
          },
        ],
        title: t('errorSavingReport') ?? 'Error saving report',
        message: getErrorMessage(error),
      })
    },
    onSuccess: (_, saved) => {
      if (saved) {
        messages.showMessage(t('reportSubmittedSuccessfully'), 'success')
        setDidSubmitReport(true)
      }
    },
  })

  // if the provided userReport has an id, it is submitted and user can only view it
  // if user has deleted the report, it is readonly
  // if user is saving the report, it is readonly
  const isReadonly = Boolean(userReportProp?.id || userReportProp?.isDeleted || isSaving)

  // construct map of variables
  const constants: Variables = useMemo(() => {
    return {
      country: user?.meta?.country ?? '',
      district: user?.meta?.district ?? '',
      school: user?.meta?.school ?? '',
      today: new Date().toISOString().split('T')[0],
      user_id: user?.sub ?? '',
      user_name: user?.name ?? '',
      year: new Date().getFullYear().toString(),
    }
  }, [user?.meta?.country, user?.meta?.district, user?.meta?.school, user?.name, user?.sub])

  // derive default values
  const allFields: IPlainReportField[] = useMemo(() => {
    const fields: IPlainReportField[] = []
    report?.steps?.forEach((step) => {
      walkFormGroupFields(step, (field) => {
        fields.push(field)
      })
    })
    return fields
  }, [report?.steps])

  const defaultValues = useMemo(() => {
    const defaultValues: Record<string, any> = {}
    allFields.forEach((field) => {
      if (field.defaultValue) {
        const defaultValue = resolveDefaultValue(field, constants)
        if (defaultValue !== undefined) {
          defaultValues[field.name] = defaultValue
        }
      }
    })
    return defaultValues
  }, [allFields, constants])

  // use provided user report or create a new one
  const [userReport, setUserReport] = useState(userReportProp ?? initUserReport(user, defaultValues))

  const mergeValues = useCallback(
    (values: Partial<IUserReport>) => {
      return new Promise<any>((resolve) => {
        setUserReport((prev) => {
          if (isReadonly) {
            resolve(prev)
            return prev
          } else {
            const merged = serialiseObject({ ...prev, ...values })
            reports.enqueueForUpdate(merged) // save to local storage
            resolve(merged)
            return merged
          }
        })
      })
    },
    [isReadonly, reports]
  )

  // debounced mergeValues
  const debouncedMergeValues = useMemo(() => debounce(mergeValues, 500), [mergeValues])

  // handle form
  const form = useForm()
  const values = form.watch()

  // handle variables
  const visibilityPredicates = useMemo(() => {
    return report?.steps?.map((step) => compileExp(step.isVisible)) ?? []
  }, [report?.steps])

  const reportDerivedValueExps = useMemo(() => {
    return (report?.derivedValues ?? []).map((field) => [field.key, compileExp(field.expression)] as const)
  }, [report?.derivedValues])

  const hiddenFieldsExps = useMemo(() => {
    return allFields
      .filter((f) => f.dataType === 'Hidden')
      .map((f) => [f.name, compileExp(f.defaultValue)])
      .filter((e): e is [string, (vars: any) => any] => typeof e[1] === 'function')
  }, [allFields])

  const variables = useMemo(() => {
    const ret: Variables = { true: true, false: false, ...constants, ...userReport, ...values }

    // evaluate calculated fields
    hiddenFieldsExps.forEach(([name, exp]) => {
      ret[name] = exp(ret)
    })

    // evaluate derived values
    for (const [key, exp] of reportDerivedValueExps) {
      ret[key] = exp?.(ret)
    }

    return ret
  }, [constants, hiddenFieldsExps, reportDerivedValueExps, userReport, values])
  const variablesRef = useRef(variables)
  variablesRef.current = variables

  // watch values change
  useEffect(() => {
    // IMPORTANT: this triggers a re-render which is needed to update the visibilities
    const { unsubscribe } = form.watch((values) => {
      // save to local storage, but debounce it to avoid too many saves
      // this greatly improves performance when typing
      debouncedMergeValues(values)
    })
    return unsubscribe
  }, [debouncedMergeValues, form, form.watch])

  // handle step visibility
  const visibilities = useMemo(() => {
    return visibilityPredicates?.map((p) => (p ? Boolean(p(variables)) : true))
  }, [visibilityPredicates, variables])

  const visibleSteps = useMemo(() => {
    return report?.steps?.filter((step, index) => visibilities?.[index]) ?? []
  }, [report?.steps, visibilities])

  // store current step
  const [step, setStep] = useState<IPlainReportFormGroup | undefined>(visibleSteps[0])

  // make sure current step is visible
  useEffect(() => {
    const currentStepIsVisible = !!visibleSteps.find((s) => s.id === step?.id)
    if (!currentStepIsVisible) {
      const originalStepIndex = report?.steps?.findIndex((s) => s.id === step?.id) ?? -1
      // try to find a visible step before or after the current one
      for (let i = originalStepIndex - 1; i >= 0; i--) {
        const s = report?.steps?.[i]
        if (s && visibilities?.[i]) {
          setStep(s)
          return
        }
      }
      for (let i = originalStepIndex + 1; i < (report?.steps?.length ?? 0); i++) {
        const s = report?.steps?.[i]
        if (s && visibilities?.[i]) {
          setStep(s)
          return
        }
      }
      // no visible step found, reset to first visible step
      const s = visibleSteps[0]
      if (s) {
        setStep(s)
      }
      // no visible step found
      setStep(undefined)
    }
  }, [report?.steps, step?.id, visibilities, visibleSteps])

  // handle step navigation
  const hasNext = useMemo(() => {
    const index = visibleSteps.findIndex((s) => s.id === step?.id)
    return index >= 0 && index < visibleSteps.length - 1
  }, [step?.id, visibleSteps])

  const hasPrevious = useMemo(() => {
    const index = visibleSteps.findIndex((s) => s.id === step?.id)
    return index > 0
  }, [step?.id, visibleSteps])

  const goBackOneStep = useCallback(() => {
    const index = visibleSteps.findIndex((s) => s.id === step?.id)
    if (index > 0) {
      setStep(visibleSteps[index - 1])
    }
  }, [step?.id, visibleSteps])

  const [tableErrors, setTableErrors] = useState<Record<string, string[]>>({})
  const [groupErrors, setGroupErrors] = useState<Record<string, string[]>>({})
  const [reportErrors, setReportErrors] = useState<string[]>([])

  const validateForm = useCallback(
    (vars: Variables, currentStep: IPlainReportFormGroup | undefined, fullReport: boolean) => {
      const errors: string[] = []

      const check = (isVisible: string | undefined, validations: IPlainReportValidation[] | undefined) => {
        if (isVisible) {
          const result = compileExp(isVisible)?.(vars)
          if (result !== undefined && !result) {
            return undefined // element is hidden
          }
        }
        if (validations?.length) {
          for (const { condition, message } of validations) {
            try {
              const test = compileExp(condition)
              const result = test?.(vars)
              if (result) {
                const msg = substituteStrings(message || '', vars)
                errors.push(msg)
                return msg
              }
            } catch (e) {
              console.log('Error evaluating expression', condition, e)
            }
          }
        }
        return false // element is valid
      }

      const stepTableErrors: Record<string, string[]> = {}
      const checkRow = (table: IPlainReportInputTable, row: IPlainReportInputTableRow) => {
        const msg = check(row.isVisible, row.validations)
        if (msg) {
          stepTableErrors[table.id] = stepTableErrors[table.id] ?? []
          appendString(stepTableErrors[table.id], msg)
        }
      }

      const checkTable = (table: IPlainReportInputTable) => {
        const msg = check(table.isVisible, table.validations)
        if (msg) {
          stepTableErrors[table.id] = stepTableErrors[table.id] ?? []
          appendString(stepTableErrors[table.id], msg)
        }
        if (msg !== undefined) {
          table.headerRows?.forEach((headerRow) => {
            checkRow(table, headerRow)
          })
          table.rows?.forEach((row) => {
            checkRow(table, row)
          })
        }
      }

      const groupErrors: Record<string, string[]> = {}
      const checkGroup = (group: IPlainReportFormGroup) => {
        const msg = check(group.isVisible, group.validations)
        if (msg) {
          groupErrors[group.id] = groupErrors[group.id] ?? []
          appendString(groupErrors[group.id], msg)
        }
        group.elements?.forEach((element) => {
          if (element.contentType === ContentTypes.reportInputTable) {
            checkTable(element)
          } else if (element.contentType === ContentTypes.reportFormGroup) {
            checkGroup(element)
          }
        })
      }

      // loop through all steps, tables and table rows and validate them
      report?.steps?.forEach((step) => {
        if (fullReport || currentStep?.id === step.id) {
          checkGroup(step)
        }
      })
      setTableErrors(stepTableErrors)
      setGroupErrors(groupErrors)

      // execute validation rules at the report level when it is ready to be submitted
      let reportErrors: string[] = []
      if (fullReport) {
        appendString(reportErrors, check(undefined, report?.validations))
      }
      setReportErrors(reportErrors)

      return errors.length ? errors : undefined
    },
    [report?.steps, report?.validations]
  )

  // handle form submission
  const submit = useCallback(async () => {
    if (!form) return

    const index = visibleSteps.findIndex((s) => s.id === step?.id)
    const isFinalStep = index === visibleSteps.length - 1

    const onValid = async (data: any) => {
      // merge values to our user report object
      const mergedValues = await mergeValues(data)

      const submitNames = [
        ...allFields.filter((f) => f.shouldSubmitValue).map((f) => f.name),
        'submission_id',
        'user_id',
      ]
      const submitValues = pick(mergedValues, submitNames)

      mutate({
        data: {
          report: submitValues as IUserReport,
        },
      })
    }

    const showErrors = (errorMessages: string[]) => {
      showMessage({
        buttons: [
          {
            text: t('common:goBackAndFix') ?? 'Go back and fix',
          },
        ],
        title: t('common:formValidationError') ?? 'Form validation error',
        content: (
          <div className="mx-6 min-w-[240px] sm:min-w-[360px] md:min-w-[480px] whitespace-pre-wrap">
            {errorMessages.join('\n\n')}
          </div>
        ),
      })
    }

    const onInvalid: SubmitErrorHandler<FieldValues> = (errors) => {
      const values = form.getValues()

      const allFields: Record<string, IPlainReportField> = {}
      walkFormGroupFields(step, (f) => {
        if (f.contentType === ContentTypes.reportField) {
          allFields[f.name] = f
        }
      })

      const errorMessages: string[] = Object.entries(errors)
        .map(([key, error]) => {
          if (error) {
            // set field as touched; we only show errors for touched fields
            form.setValue(key, values[key], { shouldTouch: true })
            return getFormFieldError(key, allFields[key], error, t) ?? ''
          }
          return ''
        })
        .filter(Boolean)

      showErrors(errorMessages)
    }

    // manually trigger form validation and handle errors
    // so that we can show errors before actually invoking form.handleSubmit
    if (!isReadonly) {
      const valid = await form.trigger()
      if (!valid) {
        onInvalid(form.formState.errors)
        return
      }
      const errors = validateForm({ ...variablesRef.current, ...form.getValues() }, step, isFinalStep)
      if (errors) {
        showErrors(errors)
        return
      }
    }

    // check if there is a next step
    if (!isFinalStep) {
      // go to next step
      setStep(visibleSteps[index + 1])
    } else if (!isReadonly) {
      if (!navigator.onLine) {
        // if no internet connection, show error
        showMessage({
          buttons: [
            {
              text: t('common:ok') ?? 'OK',
            },
          ],
          title: t('common:noInternet') ?? 'No internet',
          message:
            t('common:offlineTryAgain') ?? 'You are currently offline. Please connect to the internet and try again.',
        })
      } else {
        // ask for final confirmation
        showMessage({
          buttons: [
            {
              text: t('common:cancel') ?? 'Cancel',
              isCancel: true,
            },
            {
              text: t('common:confirm') ?? 'Confirm',
              onClick: (dismiss) => {
                // submit report
                form?.handleSubmit(onValid, onInvalid)()
                dismiss()
              },
            },
          ],
          message:
            report.confirmationMessage ??
            t('areYouSureToSubmitReport') ??
            'Are you sure you want to submit this report?',
          title: t('submitReport?') ?? 'Submit report?',
        })
      }
    }
  }, [
    allFields,
    form,
    isReadonly,
    mergeValues,
    mutate,
    report.confirmationMessage,
    showMessage,
    step,
    t,
    validateForm,
    visibleSteps,
  ])

  const value: IUserReportContextValue = useMemo(
    () => ({
      didSubmitReport,
      goBackOneStep,
      groupErrors,
      hasNext,
      hasPrevious,
      isReadonly,
      isSaving,
      report,
      reportErrors,
      saveError,
      step,
      submit,
      tableErrors,
      userReport,
      variables,
    }),
    [
      didSubmitReport,
      goBackOneStep,
      groupErrors,
      hasNext,
      hasPrevious,
      isReadonly,
      isSaving,
      report,
      reportErrors,
      saveError,
      step,
      submit,
      tableErrors,
      userReport,
      variables,
    ]
  )

  return (
    <UserReportContext.Provider value={value}>
      <FormProvider {...form}>
        {children}
        {messageDialog}
      </FormProvider>
    </UserReportContext.Provider>
  )
})

export function useUserReportContext() {
  return useContext(UserReportContext)
}
