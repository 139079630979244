import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import AppBarBackButton from '../../AppBarBackButton'
import AuthLayout from '../../AuthLayout'
import CamfedAppBar from '../../CamfedAppBar'
import { IScreenProps } from '../../Navigation/types'
import RequestLoadingHandler from '../../RequestLoadingHandler'
import useGetMyGuides from '../MyGuides/OverviewPage/hooks/useGetMyGuides'
import ActiveTimeCard from './components/ActiveTimeCard'
import RemoteInsightsCharts from './components/RemoteInsightsCharts'

const CoreTrainerInsightsPage = (props: IScreenProps) => {
  const { navigator } = props
  const { data, isLoading, isSuccess, isError, error, fetchStatus } = useGetMyGuides()
  const { t: tInsights } = useTranslation('insights')

  const ids = useMemo(() => {
    return data?.map((v) => v.auth0_id) ?? []
  }, [data])

  return (
    <AuthLayout
      appBar={
        <CamfedAppBar centralText={tInsights('insights')} leftButton={<AppBarBackButton onClick={navigator.pop} />} />
      }
    >
      <div className="flex-1 flex flex-col justify-center items-center">
        <div className="flex-1 h-full w-full flex flex-col p-[16px]">
          <RequestLoadingHandler
            error={error}
            fetchStatus={fetchStatus}
            isError={isError}
            isLoading={isLoading}
            isSuccess={isSuccess}
            loadingClassName="py-16"
          >
            {ids.length ? (
              <>
                <ActiveTimeCard className="mb-4" userIds={ids} />
                <RemoteInsightsCharts shouldDisplayMBWGNum={true} shouldHideCardButtons={true} learnerGuideIds={ids} />
              </>
            ) : (
              <p>No associated insights found.</p>
            )}
          </RequestLoadingHandler>
        </div>
      </div>
    </AuthLayout>
  )
}

export default CoreTrainerInsightsPage
