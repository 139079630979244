import { types } from 'mobx-state-tree'
import { ISocialSupportCaseLocalData } from '../api/types'

const SocialSupportCases = types
  .model({
    queue: types.array(types.frozen<ISocialSupportCaseLocalData>()),
  })
  .actions((self) => ({
    findIndexById(id: number | undefined) {
      if (typeof id !== 'number') return -1
      return self.queue.findIndex((c) => c.id === id)
    },
    findIndexBySubmissionId(submissionId: string | undefined) {
      if (typeof submissionId !== 'string') return -1
      return self.queue.findIndex((c) => c.submission_id === submissionId)
    },
    replaceAtIndex(index: number, c: ISocialSupportCaseLocalData) {
      if (index > -1) {
        self.queue.splice(index, 1, { ...c, isLocal: true })
      } else {
        self.queue.push({ ...c, isLocal: true })
      }
    },
    removeAtIndex(index: number) {
      if (index > -1) {
        self.queue.splice(index, 1)
      }
    },
  }))
  .actions((self) => ({
    findIndex: (c: ISocialSupportCaseLocalData) => {
      if (c.id) {
        return self.findIndexById(c.id)
      } else if (c.submission_id) {
        return self.findIndexBySubmissionId(c.submission_id)
      } else {
        return -1
      }
    },
  }))
  .actions((self) => ({
    enqueueForUpdate(c: ISocialSupportCaseLocalData) {
      self.replaceAtIndex(self.findIndex(c), c)
    },
    removeCase(c: ISocialSupportCaseLocalData) {
      self.removeAtIndex(self.findIndex(c))
    },
    didAddCase(submissionId: string) {
      self.removeAtIndex(self.findIndexBySubmissionId(submissionId))
    },
    didUpdateCase(caseId: number) {
      self.removeAtIndex(self.findIndexById(caseId))
    },
  }))

export default SocialSupportCases
