import { Typography, useTheme } from '@mui/material'
import { ReactNode } from 'react'

export interface IPlaceholderMessageProps {
  bodyText?: string
  header: ReactNode
  image: JSX.Element
  className?: string
  alignBodyText?: 'left' | 'center'
}

// Responsible for formatting the placeholder message that can be seen for instance on
// the home feed when all messages have been dismissed

export default function PlaceholderMessage(props: IPlaceholderMessageProps): JSX.Element {
  const { alignBodyText = 'left', bodyText, className = '', header, image } = props

  const { typography } = useTheme()

  return (
    <div className={`max-w-xl h-full text-center flex flex-col max-h-[600px] ${className}`}>
      <div className="flex-1  flex overflow-hidden justify-center">
        {/* Disable eslint on next line */}
        {/* eslint-disable-next-line @next/next/no-img-element */}
        {image}
      </div>
      <Typography variant="h5" sx={{ ...typography.h5_onSurface.light, mt: 2, marginBottom: '14px' }}>
        {header}
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{ ...typography.subtitle2.onSurface.light.lowEmphasis, extAlign: alignBodyText }}
      >
        {bodyText}
      </Typography>
    </div>
  )
}
