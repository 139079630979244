import { Button } from '@mui/material'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import useOnlineEffect from '../../hooks/offline/useOnlineEffect'
import useMst from '../../models/useMst'

const SHOW_DEBUG_PANEL = false // show debug info panel
const LOG_INTERVAL = 10_1000 // log every 10 seconds to mobx store
const ACTIVITY_TIMEOUT = 30_000 // 30 seconds of inactivity will trigger idle state
const SUBMIT_INTERVAL = 60_000 // submit/commit activity every 60 seconds
const MAX_TIMEOUT = 2147483647 // max timeout for setTimeout

const DebugPanel = (props: {
  getRemainingTime: () => number
  getElapsedTime: () => number
  getActiveTime: () => number
  getIdleTime: () => number
}) => {
  const { getRemainingTime, getElapsedTime, getActiveTime, getIdleTime } = props
  const { engagement } = useMst()

  // output debug info
  const [, setRenderCount] = useState(0)
  useEffect(() => {
    const cb = () => setRenderCount((c) => c + 1)
    const timer = setInterval(cb, 1000)
    return () => clearInterval(timer)
  }, [])

  const storedActiveTime = engagement.activity
    .filter((a) => !a.submitted && !a.committed)
    .reduce((acc, cur) => {
      return acc + cur.activeTime
    }, 0)
  const sealedActiveTime = engagement.activity
    .filter((a) => a.sealed && !a.committed)
    .reduce((acc, cur) => {
      return acc + cur.activeTime
    }, 0)
  const submittedActiveTime = engagement.activity
    .filter((a) => a.submitted && !a.committed)
    .reduce((acc, cur) => {
      return acc + cur.activeTime
    }, 0)
  const committedActiveTime = engagement.activity
    .filter((a) => a.committed)
    .reduce((acc, cur) => {
      return acc + cur.activeTime
    }, 0)

  return (
    <div className="absolute z-[99999] bottom-4 right-4 p-4 bg-gray-100 rounded-md opacity-50">
      <div>Time elapsed: {(getElapsedTime() / 1000).toFixed(0)}s</div>
      <div>Idle count down: {(getRemainingTime() / 1000).toFixed(0)}s</div>
      <div>Idle time: {(getIdleTime() / 1000).toFixed(0)}s</div>
      <div>Active time: {(getActiveTime() / 1000).toFixed(0)}s</div>
      <div>Stored active time: {(storedActiveTime / 1000).toFixed(0)}s</div>
      <div>Sealed active time: {(sealedActiveTime / 1000).toFixed(0)}s</div>
      <div>Submitted active time: {(submittedActiveTime / 1000).toFixed(0)}s</div>
      <div>Committed active time: {(committedActiveTime / 1000).toFixed(0)}s</div>
      <Button className="mt-2" fullWidth onClick={() => engagement.resetStats()} variant="outlined">
        Reset Stats
      </Button>
      <Button className="mt-2" fullWidth onClick={() => engagement.seal()} variant="outlined">
        Seal
      </Button>
      <Button className="mt-2" fullWidth onClick={() => engagement.submitActivities()} variant="outlined">
        Submit
      </Button>
      <Button className="mt-2" fullWidth onClick={() => engagement.commitActivities()} variant="outlined">
        Commit
      </Button>
    </div>
  )
}

function useSubmitActivity() {
  const {
    engagement: { submitActivities, commitActivities },
  } = useMst()
  // submit regularly
  useEffect(() => {
    const cb = () => {
      // submit only if online
      if (navigator.onLine) {
        submitActivities().finally(() => {
          commitActivities()
        })
      }
    }
    const timer = setInterval(cb, SUBMIT_INTERVAL)
    return () => clearInterval(timer)
  }, [commitActivities, submitActivities])

  // submit when going online from offline
  useOnlineEffect(
    debounce(() => {
      submitActivities().finally(() => {
        commitActivities()
      })
    })
  )
}

export default function ActivityTracker() {
  const { engagement } = useMst()
  const logTimeRef = useRef(() => {})

  const onIdle = useCallback(() => {
    // log the time when idle
    logTimeRef.current()
  }, [])

  const { getRemainingTime, getElapsedTime, getActiveTime, getIdleTime, isIdle } = useIdleTimer({
    onIdle,
    timeout: ACTIVITY_TIMEOUT,
    throttle: 500,
    startOnMount: true,
    crossTab: false,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange', // trigger active state when tab is visible
      'focus',
    ],
    immediateEvents: [
      'visibilitychange', // trigger idle state when tab is hidden
      'blur',
      'unload',
    ],
  })

  // add the active time to our store
  const lastLogTimeRef = useRef(new Date())
  const lastLogActiveTimeRef = useRef(getActiveTime())
  const logTime = useCallback(() => {
    // get the current active time, subtract the last active time
    const currentActiveTime = getActiveTime()
    const activeTime = currentActiveTime - lastLogActiveTimeRef.current
    lastLogActiveTimeRef.current = currentActiveTime

    // get the current time to make a key for the month
    const startTime = lastLogTimeRef.current
    lastLogTimeRef.current = new Date()
    const yearMonth = startTime.toISOString().slice(0, 7) // format in YYYY-MM

    // add the active time to the store
    engagement.addActiveTime(yearMonth, activeTime)
  }, [engagement, getActiveTime])

  logTimeRef.current = logTime

  // set an interval to log time regularly
  useEffect(() => {
    const cb = () => {
      if (!isIdle()) {
        logTimeRef.current()
      }
    }
    const timer = setInterval(cb, LOG_INTERVAL)
    return () => clearInterval(timer)
  }, [isIdle])

  // set a timer to log time when month changes
  const [monthChangeTrigger, setMonthChangeTrigger] = useState(0)
  useEffect(() => {
    const cb = () => {
      logTimeRef.current()
      setMonthChangeTrigger((t) => t + 1) // trigger another month change timer
    }
    // set a timer for the next month
    const now = new Date()
    const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1)
    const timeout = Math.max(nextMonth.getTime() - now.getTime(), MAX_TIMEOUT) // max timeout is 2**31-1 ms, which is about 24 days
    const timer = setTimeout(cb, timeout)
    return () => clearTimeout(timer)
  }, [engagement, monthChangeTrigger])

  // submit activity to server
  useSubmitActivity()

  if (SHOW_DEBUG_PANEL) {
    return (
      <DebugPanel
        getRemainingTime={getRemainingTime}
        getElapsedTime={getElapsedTime}
        getActiveTime={getActiveTime}
        getIdleTime={getIdleTime}
      />
    )
  }
  return null
}
