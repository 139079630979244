import transform from 'lodash/transform'

export default function omitDeepWith<T extends object, TResult extends object>(
  obj: T,
  shouldOmit: (value: any, key: string | number | symbol) => any
): TResult {
  return transform(obj, (result: any, value, key) => {
    if (shouldOmit(value, key)) {
      return
    } else if (Array.isArray(value)) {
      result[key] = (value as Array<unknown>)
        .filter((item, idx) => !shouldOmit(item, idx))
        .map((item) => {
          if (item && typeof item === 'object') {
            return omitDeepWith(item, shouldOmit)
          } else {
            return item
          }
        })
    } else if (value && typeof value === 'object') {
      result[key] = omitDeepWith(value, shouldOmit)
    } else {
      result[key] = value
    }
  })
}
