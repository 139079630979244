import { types } from 'mobx-state-tree'

const InitialLoading = types
  .model({
    // Whether the onboarding request is done
    onboardingDone: types.optional(types.boolean, false),
    // Whether the home feed cards are done
    homeFeedCardsDone: types.optional(types.boolean, false),
  })
  .views((self) => ({
    // If all requests are done
    get isDone() {
      return self.onboardingDone && self.homeFeedCardsDone
    },
  }))
  .actions((self) => ({
    // Set onboardingDone
    setOnboardingDone(isDone: boolean) {
      self.onboardingDone = isDone
    },
    // Set homeFeedCardsDone
    setHomeFeedCardsDone(isDone: boolean) {
      self.homeFeedCardsDone = isDone
    },
  }))

export default InitialLoading
