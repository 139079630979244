import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import { Locales, defaultLocale } from './constants'
import useCurrentLanguage from './useCurrentLanguage'

/**
 * This component patches the path in the browser history to keep it in sync with the current language.
 *
 * Problem: if the user changes the language on the settings page, and then clicks the back button, the history state
 * will be popped, restoring the previous path, which will be in the previous language. NextJS will sometimes render
 * the page in the new language, and sometimes in the old language, causing the language to be out-of-sync with
 * the path or rendering the page in the wrong language.
 *
 * Solution: when the user pops the history state, we take the language from i18next and patch the path in the history.
 */
export default function LocalePathPatcher() {
  const lang = useCurrentLanguage()
  const router = useRouter()
  const routerRef = useRef(router)

  useEffect(() => {
    const handlePopState = () => {
      const currentPath = window.location.pathname
      const currentLocale = lang?.code ?? defaultLocale

      // remove locale prefix from path
      let pathWithoutLocalePrefix = currentPath
      for (const locale of Locales) {
        if (currentPath.startsWith(`/${locale.code}`)) {
          pathWithoutLocalePrefix = currentPath.replace(`/${locale.code}`, '')
          break
        }
      }

      // based on the language returned from i18next, check if the path is correct
      const correctPathPrefix = currentLocale === defaultLocale ? '/' : `/${currentLocale}/`
      const correctPath = `${correctPathPrefix}${pathWithoutLocalePrefix}`
        .replace(/\/+/g, '/') // replace multiple slashes with a single slash
        .replace(/\/$/, '') // remove trailing slash

      if (window.location.pathname !== correctPath) {
        // patch the path in the history
        const { pathname, query } = routerRef.current
        router.replace({ pathname, query }, undefined, { locale: currentLocale })
      }
    }
    window.addEventListener('popstate', handlePopState)
    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [lang, router])

  return null
}
