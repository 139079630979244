import { CSSProperties } from 'react'
import { JsonObject, JsonValue } from 'type-fest'
import {
  IReportFieldFields,
  IReportFields,
  IReportFormGroupFields,
  IReportInputTableCellFields,
  IReportInputTableFields,
  IReportInputTableRowFields,
  IReportNoteFields,
  IReportPicklistFields,
  IReportPicklistOptionFields,
} from '../../../../@types/generated/contentful'
import { ContentTypes } from '../../../../api/contentTypeIds'
import { MaybeEntry, ResolvedFields } from '../../../../api/types'
import {
  IPlainReport,
  IPlainReportDerivedValue,
  IPlainReportField,
  IPlainReportFormGroup,
  IPlainReportInputTable,
  IPlainReportInputTableCell,
  IPlainReportInputTableRow,
  IPlainReportNote,
  IPlainReportPicklist,
  IPlainReportPicklistOption,
  IPlainReportValidation,
} from '../types'

function toPlainReportPicklistOption(
  option: MaybeEntry<IReportPicklistOptionFields>
): IPlainReportPicklistOption | undefined {
  if (option && 'fields' in option) {
    const { fields } = option

    return {
      label: fields.label,
      value: fields.value,
      isExclusive: fields.isExclusive,
    }
  }
}

function toPlainReportPicklist(picklist: MaybeEntry<IReportPicklistFields>): IPlainReportPicklist | undefined {
  if (picklist && 'fields' in picklist) {
    const { fields } = picklist

    return {
      title: fields.title,
      options:
        fields.options?.map(toPlainReportPicklistOption).filter((o): o is IPlainReportPicklistOption => Boolean(o)) ??
        [],
    }
  }
}

function toPlainReportInputTableCell(
  cell: MaybeEntry<IReportInputTableCellFields>
): IPlainReportInputTableCell | undefined {
  if (cell && 'fields' in cell) {
    const { fields } = cell

    return {
      colSpan: fields.colSpan,
      description: fields.description,
      id: cell.sys.id,
      inputs:
        fields.inputs
          ?.map((f) => toPlainReportField(f))
          .filter((field): field is IPlainReportField => Boolean(field)) ?? [],
      name: fields.name,
      rowSpan: fields.rowSpan,
      style: fields.style as CSSProperties,
    }
  }
}

function toPlainReportValidation(keyValuePair: JsonValue): IPlainReportValidation {
  return {
    id: (keyValuePair as JsonObject).id as string,
    condition: (keyValuePair as JsonObject).key as string,
    message: (keyValuePair as JsonObject).value as string,
  }
}

function toPlainReportDerivedValue(keyValuePair: JsonValue): IPlainReportDerivedValue {
  return {
    id: (keyValuePair as JsonObject).id as string,
    key: (keyValuePair as JsonObject).key as string,
    expression: (keyValuePair as JsonObject).value as string,
  }
}

function toPlainReportInputTableRow(
  row: MaybeEntry<IReportInputTableRowFields>
): IPlainReportInputTableRow | undefined {
  if (row && 'fields' in row) {
    const { fields } = row

    return {
      cells:
        fields.cells
          ?.map(toPlainReportInputTableCell)
          .filter((cell): cell is IPlainReportInputTableCell => Boolean(cell)) ?? [],
      id: row.sys.id,
      name: fields.name,
      isVisible: fields.isVisible,
      validations: fields.validations?.map(toPlainReportValidation),
    }
  }
}

function toPlainReportField(
  field: MaybeEntry<IReportFieldFields | IReportNoteFields | IReportInputTableFields | IReportFormGroupFields>
): IPlainReportField | IPlainReportNote | IPlainReportInputTable | IPlainReportFormGroup | undefined {
  if (field && 'fields' in field) {
    const { fields } = field

    if (field.sys.contentType.sys.id === ContentTypes.reportField) {
      const f: ResolvedFields<IReportFieldFields> = fields as ResolvedFields<IReportFieldFields>

      return {
        contentType: ContentTypes.reportField,
        dataType: f.dataType,
        defaultValue: f.defaultValue,
        id: field.sys.id,
        isMultiline: f.isMultiline,
        isMultiple: f.isMultiple,
        isReadonly: f.isReadonly,
        isRequired: f.isRequired,
        label: f.label,
        maxLength: f.maxLength,
        maxRows: f.maxRows,
        maxValue: f.maxValue,
        minLength: f.minLength,
        minRows: f.minRows,
        minValue: f.minValue,
        name: f.name,
        picklist: toPlainReportPicklist(f.picklist),
        isVisible: f.isVisible,
        shouldSubmitValue: f.shouldSubmitValue,
        style: f.style as CSSProperties,
        validations: f.validations?.map(toPlainReportValidation),
      }
    } else if (field.sys.contentType.sys.id === ContentTypes.reportInputTable) {
      const f: ResolvedFields<IReportInputTableFields> = fields as ResolvedFields<IReportInputTableFields>

      return {
        contentType: ContentTypes.reportInputTable,
        helperText: f.helperText,
        id: field.sys.id,
        name: f.name,
        description: f.description,
        headerRows: f.headerRows
          ?.map(toPlainReportInputTableRow)
          .filter((row): row is IPlainReportInputTableRow => Boolean(row)),
        isVisible: f.isVisible,
        rows: f.rows?.map(toPlainReportInputTableRow).filter((row): row is IPlainReportInputTableRow => Boolean(row)),
        title: f.title,
        validations: f.validations?.map(toPlainReportValidation),
      }
    } else if (field.sys.contentType.sys.id === ContentTypes.reportNote) {
      const f: ResolvedFields<IReportNoteFields> = fields as ResolvedFields<IReportNoteFields>
      return {
        contentType: ContentTypes.reportNote,
        id: field.sys.id,
        name: f.name,
        isVisible: f.isVisible,
        style: f.style as CSSProperties,
        textExpression: f.textExpression,
        variant: f.variant,
      }
    } else if (field.sys.contentType.sys.id === ContentTypes.reportFormGroup) {
      return toPlainReportFormGroup(field)
    }
  }
}

function toPlainReportFormGroup(group: MaybeEntry<IReportFormGroupFields>): IPlainReportFormGroup | undefined {
  if (group && 'fields' in group) {
    const { fields } = group

    return {
      contentType: ContentTypes.reportFormGroup,
      elements: fields.elements
        ?.map(toPlainReportField)
        .filter(
          (field): field is IPlainReportField | IPlainReportNote | IPlainReportInputTable | IPlainReportFormGroup =>
            Boolean(field)
        ),
      id: group.sys.id,
      name: fields.name,
      isVisible: fields.isVisible,
      validations: fields.validations?.map(toPlainReportValidation),
    }
  }
}

export default function toPlainReport(report: MaybeEntry<IReportFields>): IPlainReport | undefined {
  if (report && 'fields' in report) {
    const { confirmationMessage, countries, derivedValues, title, description, steps, validations } = report.fields

    return {
      confirmationMessage,
      countries,
      derivedValues: derivedValues?.map(toPlainReportDerivedValue),
      description,
      id: report.sys.id,
      steps: steps
        ?.map((step) => (step && 'fields' in step ? toPlainReportFormGroup(step) : null))
        .filter((step): step is IPlainReportFormGroup => Boolean(step)),
      title,
      validations: validations?.map(toPlainReportValidation),
    }
  }
}
