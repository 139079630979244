export const accountProfileRouteName = 'accountProfile'
export const accountSettingsRouteName = 'accountSettings'
export const addMyBetterWorldGroupRouteName = 'addMyBetterWorldGroup'
export const curriculumRouteName = 'curriculum'
export const curriculumStepRouteName = 'curriculumStep'
export const findYourAccountRouteName = 'findYourAccount'
export const homeFeedRouteName = 'homeFeed'
export const insightsCoreTrainerRouteName = 'insightsCoreTrainer' // display aggregated stats of associated learner guides
export const insightsLearnerGuideRouteName = 'insightsLearnerGuide' // display stats of an individual learner guide with remote data
export const insightsLocalSelfRouteName = 'insightsLocalSelf' // display self stats with local data
export const loginRouteName = 'login'
export const myBetterWorldGroupRouteName = 'myBetterWorldGroup'
export const myBetterWorldGroupsRouteName = 'myBetterWorldGroups'
export const myGuideDetailsRouteName = 'myGuideDetails'
export const myGuidesOverviewRouteName = 'myGuides'
export const onboardingRouteName = 'onboarding'
export const pageRouteName = 'page'
export const reportsRouteName = 'reports'
export const resourceLibraryRouteName = 'resourceLibrary'
export const resourceRouteName = 'resource'
export const socialSupportCaseActionEditRouteName = 'socialSupportCaseActionEdit'
export const socialSupportCaseCreateName = 'socialSupportCaseCreate'
export const socialSupportCaseOverviewRouteName = 'socialSupportCaseOverview'
export const socialSupportCaseRouteName = 'socialSupportCase'
export const userReportRouteName = 'userReport'
