import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { IPageFields } from '../../../@types/generated/contentful'
import { SimpleEntry } from '../../../api/types'
import { curriculumStepRoute } from '../../../constants'
import AppBarBackButton from '../../AppBarBackButton'
import AuthLayout from '../../AuthLayout'
import BodyPadding from '../../BodyPadding'
import CamfedAppBar from '../../CamfedAppBar'
import ListSectionTitle from '../../Common/ListSectionTitle'
import { IScreenProps } from '../../Navigation/types'
import RelatedResources from '../../RelatedResources'
import RequestLoadingHandler from '../../RequestLoadingHandler'
import RichText from '../../RichText'
import Steps from './Steps/Steps'
import useGetCurriculum from './hooks/useGetCurriculum'

interface ICurriculumPageProps extends IScreenProps {}

export default function CurriculumPage(props: ICurriculumPageProps): JSX.Element {
  const { id, navigator } = props

  const { t } = useTranslation('curriculum')

  const { data, error, fetchStatus, isError, isLoading, isSuccess } = useGetCurriculum(id)

  const handleStepClick = useCallback(
    (step: SimpleEntry<IPageFields>) => {
      if (data) {
        navigator.push(curriculumStepRoute(step.sys.id, data, step))
      }
    },
    [data, navigator]
  )

  return (
    <AuthLayout
      appBar={
        <CamfedAppBar
          centralText={data?.fields?.title || ''}
          leftButton={<AppBarBackButton onClick={navigator.pop} />}
          prominent={true}
        />
      }
    >
      <BodyPadding>
        <RequestLoadingHandler
          error={error}
          fetchStatus={fetchStatus}
          isError={isError}
          isLoading={isLoading}
          isSuccess={isSuccess}
        >
          <RichText
            document={
              data?.fields?.rootPage && 'fields' in data.fields.rootPage ? data.fields.rootPage.fields?.body : undefined
            }
          />
          <RelatedResources className="mt-4" relatedResources={data?.fields?.relatedResources} />
          <ListSectionTitle className="mt-4 mb-2">{t('steps')}:</ListSectionTitle>
          <Steps className="-mx-4" onStepClick={handleStepClick} steps={data?.fields?.steps} />
        </RequestLoadingHandler>
      </BodyPadding>
    </AuthLayout>
  )
}
