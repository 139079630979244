import { Components } from '@mui/material'
import getRootElement from '../getRootElement'

const MuiPopover: Components['MuiPopover'] = {
  defaultProps: {
    container: getRootElement(),
  },
}

export default MuiPopover
