import { useEffect, useRef, useState } from 'react'

type BoxSize = {
  width: number
  height: number
  borderBoxWidth: number
  borderBoxHeight: number
}

export default function useResizeObserver(element: HTMLElement | null | undefined) {
  const [size, setSize] = useState<BoxSize>()
  const sizeRef = useRef(size)
  sizeRef.current = size

  // create a size observer
  const [observer] = useState(() => {
    if (typeof ResizeObserver !== 'undefined') {
      return new ResizeObserver((entries) => {
        const entry = entries[0]
        if (entry) {
          const element = entry.target as HTMLElement
          if (entry.contentBoxSize) {
            const contentBoxSize: ResizeObserverSize = Array.isArray(entry.contentBoxSize)
              ? entry.contentBoxSize[0]
              : entry.contentBoxSize

            const borderBoxSize: ResizeObserverSize = Array.isArray(entry.borderBoxSize)
              ? entry.borderBoxSize[0]
              : entry.borderBoxSize

            setSize({
              width: contentBoxSize.inlineSize,
              height: contentBoxSize.blockSize,
              borderBoxWidth: borderBoxSize.inlineSize,
              borderBoxHeight: borderBoxSize.blockSize,
            })
          } else {
            setSize({
              width: entry.contentRect.width,
              height: entry.contentRect.height,
              borderBoxWidth: element.offsetWidth,
              borderBoxHeight: element.offsetHeight,
            })
          }
        }
      })
    }
  })
  const observerRef = useRef(observer)

  useEffect(() => {
    const observer = observerRef.current
    if (element && observer) {
      observer.observe(element)
      return () => {
        observer.unobserve(element)
      }
    }
  }, [element])

  return {
    size,
    sizeRef,
  }
}
