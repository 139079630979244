import { observer } from 'mobx-react-lite'
import RequestLoadingHandler from '../../../RequestLoadingHandler'
import useGetLearnerGuidesInsights from '../../MyBetterWorldGroups/hooks/useGetLearnerGuidesInsights'
import { useDateRangeSelector } from './DateRangeSelector'
import InsightsCharts from './InsightsCharts'

interface RemoteInsightsChartsProps {
  learnerGuideIds: string[] | undefined
  shouldHideCardButtons?: boolean
  shouldDisplayMBWGNum?: boolean
}

const RemoteInsightsCharts = observer((props: RemoteInsightsChartsProps) => {
  const { learnerGuideIds, shouldHideCardButtons, shouldDisplayMBWGNum } = props

  const { dateRangeSelector, startDate, endDate } = useDateRangeSelector({
    className: 'mb-4',
  })

  const { data, isLoading, isSuccess, isError, error, fetchStatus } = useGetLearnerGuidesInsights({
    learnerGuideIds,
    startDate,
    endDate,
  })

  return learnerGuideIds?.length ? (
    <RequestLoadingHandler
      error={error}
      fetchStatus={fetchStatus}
      isError={isError}
      isLoading={isLoading}
      isSuccess={isSuccess}
      loadingClassName="py-16"
    >
      {dateRangeSelector}
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
        <InsightsCharts
          shouldDisplayMBWGNum={shouldDisplayMBWGNum}
          data={data?.data}
          shouldHideCardButtons={shouldHideCardButtons}
        />
      </div>
    </RequestLoadingHandler>
  ) : null
})

export default RemoteInsightsCharts
