import { defaultLocale } from '../components/Languages/constants'

export const rootPage = (locale?: string | null | undefined) => {
  if (!locale || locale === defaultLocale) {
    return '/'
  } else {
    return `/${encodeURIComponent(locale)}/`
  }
}

export const loginPage = (method?: string) => '/api/auth/login' + (method ? `/${encodeURIComponent(method)}` : '')
export const logoutPage = '/auth/logout'
export const logoutServerPage = '/api/auth/logout'
