import { useUser } from '@auth0/nextjs-auth0/client'
import { AxiosError } from 'axios'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import useMst from '../../../../models/useMst'
import generateSubmissionId from '../../../../utils/generateSubmissionId'
import AppBarBackButton from '../../../AppBarBackButton'
import AuthLayout from '../../../AuthLayout'
import BodyPadding from '../../../BodyPadding'
import CamfedAppBar from '../../../CamfedAppBar'
import { IScreenProps } from '../../../Navigation/types'
import SocialSupportCaseForm from '../SocialSupportCaseForm'
import useCreateSocialSupportCase from '../useCreateSocialSupportCase'
import { OnSubmitSocialSupportCaseForm } from '../useSocialSupportCaseForm'

interface ISocialSupportCaseCreatePageProps extends IScreenProps {}

export default function SocialSupportCaseCreatePage(props: ISocialSupportCaseCreatePageProps) {
  // Get the navigator from the props
  const { navigator } = props
  const { pop } = navigator

  const { user } = useUser()
  const userId = user?.sub

  const { t } = useTranslation('social-support-cases-form')
  const { messages } = useMst()
  const { showMessage } = messages

  const { mutate } = useCreateSocialSupportCase({
    onSuccess: (data) => {
      showMessage(t('addCaseSuccess'), 'success')
      pop()
    },
    onError: (error) => {
      const err = error as AxiosError
      showMessage(t('addCaseError', { message: err.message }), 'error')
    },
  })

  // Memoize the handleSubmit function
  const onSubmit: OnSubmitSocialSupportCaseForm = useCallback(
    (data) => {
      if (userId) {
        mutate({
          data: {
            actions: null,
            categories: data.selectedCategory,
            closed_date: null,
            closed: false,
            created_at: new Date().toISOString(),
            notes: data.caseNotes,
            number_of_boys: data.number_of_boys,
            number_of_girls: data.number_of_girls,
            submission_id: generateSubmissionId(),
            title: data.caseTitle,
            trainer_id: userId,
            updated_at: new Date().toISOString(),
            year: data.year,
          },
        })
        pop()
      }
    },
    [mutate, pop, userId]
  )

  return (
    <AuthLayout
      appBar={<CamfedAppBar centralText={t('title')} leftButton={<AppBarBackButton onClick={pop} />} prominent />}
    >
      <BodyPadding>
        <SocialSupportCaseForm onSubmit={onSubmit} />
      </BodyPadding>
    </AuthLayout>
  )
}
