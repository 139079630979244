export enum ContentTypes {
  appPage = 'appPage',
  buttonLink = 'buttonLink',
  card = 'card',
  curriculum = 'curriculum',
  navigation = 'navigation',
  onboarding = 'onboarding',
  page = 'page',
  report = 'report',
  reportDistrict = 'reportDistrict',
  reportField = 'reportField',
  reportFormGroup = 'reportFormGroup',
  reportInputTable = 'reportInputTable',
  reportNote = 'reportNote',
  reportPicklist = 'reportPicklist',
  resource = 'resource',
  role = 'role',
  socialSupportCategory = 'socialSupportCategory',
}
