import { ContentTypes } from '../api/contentTypeIds'
import { IRoute } from '../models/Router'

const getLinkedResourceRoute = ({ id, type }: { id: string; type?: string }): IRoute | undefined => {
  if (type === ContentTypes.page) {
    return { name: ContentTypes.page, state: { id, visible: true, prominent: true } }
  } else if (type === ContentTypes.resource) {
    return { name: ContentTypes.resource, state: { id, visible: true, prominent: true }, transitionStyle: 'slideUp' }
  }
}

export default getLinkedResourceRoute
