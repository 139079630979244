import { ButtonBase, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { useCallback, useMemo } from 'react'
import { ICurriculumFields, IPageFields, IResourceFields } from '../../../../@types/generated/contentful'
import { MaybeEntry, SimpleEntry } from '../../../../api/types'
import { curriculumStepRoute } from '../../../../constants'
import AppBarBackButton from '../../../AppBarBackButton'
import ArticleCard from '../../../ArticleCard'
import AuthLayout from '../../../AuthLayout'
import BodyPadding from '../../../BodyPadding'
import CamfedAppBar from '../../../CamfedAppBar'
import { IScreenProps } from '../../../Navigation/types'
import RelatedResources from '../../../RelatedResources'
import RichText from '../../../RichText'
import SimpleCardBody from '../../../SimpleCardBody'
import SimpleCardHeader from '../../../SimpleCardHeader'
import stepTitle from '../helpers/stepTitle'

interface ICurriculumStepPageData {
  curriculum: SimpleEntry<ICurriculumFields>
  step: SimpleEntry<IPageFields>
}

interface ICurriculumStepPageProps extends IScreenProps<ICurriculumStepPageData> {}

type StepLinkProps = {
  className?: string
  onClick: (step: SimpleEntry<IPageFields>) => void
  step: MaybeEntry<IPageFields>
  stepTitle: string
  title: string
}

const StepLink = ({ className = '', onClick, step, stepTitle, title }: StepLinkProps) => {
  const { typography } = useTheme()

  if (!step || !('fields' in step)) {
    return null
  }

  return (
    <div className={className}>
      <Typography className="mb-2" sx={{ ...typography.subtitle2.onSurface.light.mediumEmphasis }}>
        {title}:
      </Typography>
      <ButtonBase className="w-full" sx={{ textAlign: 'start' }} onClick={() => onClick(step)}>
        <ArticleCard className="w-full">
          <SimpleCardHeader text={stepTitle} />
          <SimpleCardBody>{step.fields?.excerpt}</SimpleCardBody>
        </ArticleCard>
      </ButtonBase>
    </div>
  )
}

export default function CurriculumStepPage(props: ICurriculumStepPageProps): JSX.Element {
  const { navigator } = props
  const { curriculum, step } = props.data ?? {}

  const { t } = useTranslation('curriculum')

  const relatedResources: SimpleEntry<IResourceFields>[] | undefined = step?.fields?.relatedResources as
    | SimpleEntry<IResourceFields>[]
    | undefined

  const [stepIndex, previousStep, nextStep] = useMemo(() => {
    let previousStep: MaybeEntry<IPageFields>
    const stepIndex = curriculum?.fields?.steps?.findIndex((s) => s.sys.id === step?.sys.id) ?? -1
    if (stepIndex > 0) {
      previousStep = curriculum?.fields?.steps[stepIndex - 1]
    }

    let nextStep: MaybeEntry<IPageFields>
    if (stepIndex >= 0 && curriculum?.fields?.steps && stepIndex < curriculum.fields.steps.length) {
      nextStep = curriculum?.fields.steps[stepIndex + 1]
    }
    return [stepIndex, previousStep, nextStep]
  }, [curriculum?.fields?.steps, step?.sys.id])

  const handleStepClick = useCallback(
    (step: SimpleEntry<IPageFields>) => {
      if (curriculum) {
        navigator.push(curriculumStepRoute(step.sys.id, curriculum, step))
      }
    },
    [curriculum, navigator]
  )

  return (
    <AuthLayout
      appBar={
        <CamfedAppBar
          centralText={stepTitle(t, stepIndex, step?.fields?.title, ': ')}
          leftButton={<AppBarBackButton onClick={navigator.pop} />}
          prominent={true}
        />
      }
    >
      <BodyPadding>
        <RichText document={step?.fields?.body} />
        <RelatedResources className="mt-4" relatedResources={relatedResources} />
        <StepLink
          className="mt-4"
          title={t('previousStep')}
          step={previousStep}
          stepTitle={stepTitle(
            t,
            stepIndex - 1,
            previousStep && 'fields' in previousStep ? previousStep?.fields?.title : '',
            ' - '
          )}
          onClick={handleStepClick}
        />
        <StepLink
          className="mt-4"
          title={t('nextStep')}
          step={nextStep}
          stepTitle={stepTitle(
            t,
            stepIndex + 1,
            nextStep && 'fields' in nextStep ? nextStep?.fields?.title : '',
            ' - '
          )}
          onClick={handleStepClick}
        />
      </BodyPadding>
    </AuthLayout>
  )
}
