import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { messageDialogZIndex } from '../../constants'
import useDialogue from '../../hooks/useDialogue'
import TargetBlankLink from './TargetBlankLink'

export type DialogButton<Data> = {
  /**
   * The color of the button
   * @default 'primary'
   * @optional
   */
  color?: ButtonProps['color']
  /**
   * Button content, if supplied, text is ignored
   * @optional
   */
  content?: React.ReactNode
  /**
   * Instead of a click handler, a href can be supplied to navigate to a new
   * page, launch an email client or make a phone call.
   */
  href?: string
  /**
   * If true, the click handler will be called when the dialog is dismissed
   * by clicking the backdrop (if `cancelOnBackdropClick` is true) or pressing
   * escape (if `cancelOnEscapeKeyDown` is true).
   * @default false
   * @optional
   */
  isCancel?: boolean // invoked on backdrop click or escape key down

  /**
   * The click handler. A dismiss function is passed in to allow the onClick
   * handler to determine when to dismiss the dialog. If not supplied, the
   * dialog is dismissed automatically.
   * @optional
   * @param dismiss A function that dismisses the dialog
   */
  onClick?: (dismiss: () => void, data: Data | undefined) => void
  /**
   * The text of the button
   * @optional
   */
  text?: string
}

export type ShowMessageArgs<Data = any> = {
  /**
   * The buttons to display
   * @optional
   * @default []
   * @type DialogButton[]
   * @see DialogButton
   */
  buttons: DialogButton<Data>[]
  /**
   * If true, the cancel action is called if specified when the dialog is
   * dismissed by clicking the backdrop.
   * @default false
   * @optional
   * @type boolean
   */
  cancelOnBackdropClick?: boolean
  /**
   * If true, the cancel action is called if specified when the dialog is
   * dismissed by pressing escape.
   * @default false
   * @optional
   * @type boolean
   */
  cancelOnEscapeKeyDown?: boolean
  /**
   * The content of the dialog. If supplied, message is ignored.
   * @optional
   * @type React.ReactNode
   */
  content?: React.ReactNode | ((data: Data | undefined, dismiss: () => void) => React.ReactNode)
  /**
   * The props to pass to the dialog for further customization.
   */
  dialogProps?: Omit<DialogProps, 'open' | 'onClose'>
  /**
   * The message to display. Ignored if content is supplied.
   * @optional
   * @type string
   * @default ''
   */
  message?: string
  /**
   * The title of the dialog
   * @optional
   * @type string
   * @default ''
   */
  title?: string
}

function useShowMessage<Data>(data?: Data) {
  const [visible, dismiss, show] = useDialogue()
  const [args, setArgs] = useState<ShowMessageArgs<Data> | null>(null)

  const showMessage = useCallback(
    (args: ShowMessageArgs<Data>) => {
      setArgs(args)
      show()
    },
    [show]
  )

  const handleButtonClick = useCallback(
    (button: DialogButton<Data>) => {
      if (button.onClick) {
        button.onClick(dismiss, data)
      } else {
        dismiss()
      }
    },
    [data, dismiss]
  )

  const handleDialogClose = useCallback(
    (event: unknown, reason: 'escapeKeyDown' | 'backdropClick') => {
      const cancelButton = args?.buttons.find((b) => b.isCancel)
      if (cancelButton) {
        if (
          (reason === 'escapeKeyDown' && args?.cancelOnEscapeKeyDown) ||
          (reason === 'backdropClick' && args?.cancelOnBackdropClick)
        ) {
          if (cancelButton.onClick) {
            cancelButton.onClick?.(dismiss, data)
          } else {
            dismiss()
          }
        }
      }
    },
    [args?.buttons, args?.cancelOnBackdropClick, args?.cancelOnEscapeKeyDown, data, dismiss]
  )

  const messageDialog = useMemo(() => {
    let content

    if (args?.content) {
      if (typeof args.content === 'function') {
        content = args.content(data, dismiss)
      } else {
        content = args.content
      }
    } else if (args?.message) {
      content = (
        <DialogContent>
          <DialogContentText>{args.message}</DialogContentText>
        </DialogContent>
      )
    } else {
      content = null
    }

    const hasActions = Boolean(args?.buttons?.length)
    return (
      <Dialog {...args?.dialogProps} onClose={handleDialogClose} open={visible} sx={{ zIndex: messageDialogZIndex }}>
        {args?.title ? <DialogTitle>{args?.title}</DialogTitle> : null}
        {content}
        {hasActions ? (
          <DialogActions>
            {args?.buttons.map((b, i) => {
              return (
                <Button
                  href={b.href}
                  LinkComponent={TargetBlankLink}
                  key={i}
                  onClick={() => handleButtonClick(b)}
                  color={b.color}
                >
                  {b.content ?? b.text}
                </Button>
              )
            })}
          </DialogActions>
        ) : null}
      </Dialog>
    )
  }, [args, data, dismiss, handleButtonClick, handleDialogClose, visible])

  return { dismissMessage: dismiss, showMessage, messageDialog }
}

export default useShowMessage
