import AssetButton from '../AssetButton/AssetButton'
import AuthLayout from '../AuthLayout'
import BodyPadding from '../BodyPadding'
import ResourceAppBar from '../CamfedAppBar/ResourceAppbar'
import { IScreenProps } from '../Navigation/types'
import RequestLoadingHandler from '../RequestLoadingHandler'
import useResource from '../ResourceLibrary/Resource/useResource'
import useRichTextOptions from '../RichText/useRichTextOptions'
import RichTextParser from '../RichTextParser'

interface IResourcePageProps extends IScreenProps {}

export default function ResourcePage(props: IResourcePageProps): JSX.Element {
  const { id, navigator } = props

  const { data: res, isError, error, isLoading, isSuccess, fetchStatus } = useResource(id)
  const options = useRichTextOptions()

  return (
    <AuthLayout
      appBar={<ResourceAppBar centralText={res?.fields?.title || ''} onClose={navigator.pop} prominent={true} />}
    >
      <BodyPadding>
        <RequestLoadingHandler
          error={error}
          fetchStatus={fetchStatus}
          isError={isError}
          isLoading={isLoading}
          isSuccess={isSuccess}
        >
          <RichTextParser document={res?.fields?.body} options={options} />
          {res?.fields?.resources?.map((asset, i) => {
            if (!('fields' in asset)) return null
            return <AssetButton key={i} asset={asset} />
          })}
        </RequestLoadingHandler>
      </BodyPadding>
    </AuthLayout>
  )
}
