import { Box, useTheme } from '@mui/material'
import { FC, PropsWithChildren } from 'react'

type ResponsiveFrameProps = PropsWithChildren<{}>

const ResponsiveFrame: FC<ResponsiveFrameProps> = ({ children }) => {
  const theme = useTheme()
  return (
    <Box className="flex flex-row min-h-[100vh]">
      <Box className="flex-0 lg:flex-1" sx={{ background: theme.palette.primary.color500 }}></Box>
      <Box className="flex-1">
        <div className="px-8 py-8 sm:py-36 max-w-sm mx-auto h-full relative">{children}</div>
      </Box>
    </Box>
  )
}

export default ResponsiveFrame
