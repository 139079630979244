import { Box, Button, TextField, useTheme } from '@mui/material'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { useTranslation } from 'next-i18next'
import { ISocialSupportCaseActionLocalData } from '../../../api/types'
import formatDate from '../../../utils/dates/formatDate'
import BodyText from '../Account/Common/BodyText'
import Subtitle from '../Account/Common/Subtitle'
import useSocialSupportActionForm, { OnSubmitAction } from './useSocialSupportActionForm'

interface ISocialSupportActionFormProps {
  initialAction: ISocialSupportCaseActionLocalData | undefined
  onSubmit: OnSubmitAction
  readOnly?: boolean
}

export default function SocialSupportActionForm(props: ISocialSupportActionFormProps): JSX.Element {
  // Get the t function from the translation
  const { t } = useTranslation('social-support-case-action-form')

  const { initialAction, onSubmit, readOnly } = props

  const {
    handleSubmit,
    actionTitle,
    handleActionTitleChange,
    dateValue,
    handleDateChange,
    actionNotes,
    handleActionNotesChange,
  } = useSocialSupportActionForm({ initialAction, onSubmit })

  // Get typography from theme
  const { typography } = useTheme()

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ mb: 2 }}>
        {readOnly ? (
          <div>
            <Subtitle>{t('actionTitleLabel')}</Subtitle>
            <BodyText>{actionTitle}</BodyText>
          </div>
        ) : (
          <TextField
            fullWidth
            id="actionTitle"
            label={t('actionTitleLabel')}
            onChange={handleActionTitleChange}
            required
            value={actionTitle}
            variant="outlined"
          />
        )}
      </Box>
      <Box sx={{ mb: 2, zIndex: 1400 }}>
        {readOnly ? (
          <div>
            <Subtitle>{t('dateLabel')}</Subtitle>
            <BodyText>{formatDate(dateValue?.toJSDate())}</BodyText>
          </div>
        ) : (
          <MobileDatePicker
            slotProps={{ dialog: { style: { zIndex: 5000 } }, textField: { fullWidth: true } }}
            format="dd/MM/yyyy"
            label={t('dateLabel')}
            onChange={handleDateChange}
            value={dateValue}
          />
        )}
      </Box>
      <Box sx={{ mb: 2 }}>
        {readOnly ? (
          <div>
            <Subtitle>{t('notesLabel')}</Subtitle>
            <BodyText className="whitespace-pre-line">{actionNotes}</BodyText>
          </div>
        ) : (
          <TextField
            fullWidth
            id="actionNotes"
            label={t('notesLabel')}
            multiline
            onChange={handleActionNotesChange}
            rows={4}
            value={actionNotes}
            variant="outlined"
          />
        )}
      </Box>
      {readOnly ? null : (
        <Button
          variant="contained"
          sx={{ ...typography.button.primary.dark.enabled, color: '#fff', padding: '10px' }}
          type="submit"
        >
          {t('submitText')}
        </Button>
      )}
    </form>
  )
}
