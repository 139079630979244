import { useEffect } from 'react'
import useMst from '../../models/useMst'

export default function useNavigationHistory() {
  const { router } = useMst()

  useEffect(() => {
    router.pushHistory()

    function handler(event: PopStateEvent) {
      event.preventDefault()
      router.applySnapshot(event.state)
    }

    window.addEventListener('popstate', handler)

    return () => {
      window.removeEventListener('popstate', handler)
    }
  }, [router])
}
