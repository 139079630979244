import { useQuery } from '@tanstack/react-query'
import { Entry, EntrySkeletonType, FieldsType } from 'contentful'
import { useMemo } from 'react'
import apiClient from '../../api/apiClient'
import { entryQueryKey } from '../../api/constants'
import useApiLocaleCode from '../../components/Languages/useApiLocaleCode'

export default function useGetEntry<EntryFields extends FieldsType = FieldsType>(
  contentType: string,
  entryId: string | undefined,
  query?: unknown
) {
  const locale = useApiLocaleCode()

  const queryWithLocale = useMemo(() => {
    return {
      ...(typeof query === 'object' ? query : {}),
      locale,
    }
  }, [locale, query])

  // Generate the query key
  const queryKey = useMemo(
    () => entryQueryKey(contentType, entryId, queryWithLocale),
    [contentType, entryId, queryWithLocale]
  )

  return useQuery<Entry<EntrySkeletonType<EntryFields>, undefined>, Error>({
    queryKey,
    queryFn: () =>
      apiClient.getEntry<EntrySkeletonType<EntryFields>>(entryId!, {
        ...queryWithLocale,
        content_type: contentType, // this is needed for our proxy to work
      } as any),
    enabled: Boolean(entryId),
  })
}
