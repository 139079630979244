import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { userReportsQueryKey } from '../../../../api/constants'
import { userReportsEndpoint } from '../../../../api/endpoints'
import { IUserReport } from '../../../../api/types'

export type TGetUserReportData = Record<string, string | string[] | number | boolean | null | undefined>
export interface IGetUserReport {
  created_at: string
  id: number
  report_data: TGetUserReportData
  report_id: string
  reporting_period: string
  updated_at: string
  user_id: string
  year: number
}

export default function useGetUserReports(userId: string | null | undefined) {
  return useQuery({
    queryKey: userReportsQueryKey(userId),
    queryFn: async (): Promise<IUserReport[] | undefined> => {
      if (!userId) {
        return undefined
      }
      const resp = await axios.get<{ data: IGetUserReport[] }>(userReportsEndpoint(userId))

      // Transform the response data to match the shape of the IUserReport type
      const transformedData: IUserReport[] = resp.data.data.map((d: IGetUserReport) => {
        const { year, reporting_period, report_id, report_data, ...rest } = d
        return {
          Reporting_Period__c: reporting_period,
          submission_id: report_id,
          Year_of_Report__c: year.toString(),
          ...report_data,
          ...rest,
        }
      })

      return transformedData
    },
    enabled: !!userId,
  })
}
