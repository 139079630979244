import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Button, Card, CardContent } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { ReactNode, useMemo } from 'react'
import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts'
import { ILearnerGuideInsightsDto } from '../../../../api/types'
import { myBetterWorldGroupsRoute, socialSupportCaseOverviewRoute } from '../../../../constants'
import { palette } from '../../../../theme/palette'
import useNavigator from '../../../Navigation/useNavigator'

interface InsightsChartsProps {
  data: ILearnerGuideInsightsDto | undefined
  shouldHideCardButtons?: boolean
  shouldDisplayMBWGNum?: boolean
}

const COLORS = [palette.primary.color500, palette.secondary.color500]

interface ChartCell {
  name: string
  value: number
}

const PieChartCard = ({ cells, children, title }: { cells: ChartCell[]; children?: ReactNode; title: string }) => {
  return (
    <Card className="w-full">
      <CardContent>
        <p>{title}</p>
        <PieChart className="mx-auto" width={300} height={300}>
          <Legend
            layout="horizontal"
            verticalAlign="top"
            align="center" // @ts-ignore - Recharts doesn't have the correct types for the 'entry' property
            formatter={(value, entry) => `${value} (${entry.payload?.payload?.value ?? 0})`}
          />
          <Tooltip />
          <Pie data={cells} dataKey="value">
            {cells.map((_, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
        {children}
      </CardContent>
    </Card>
  )
}

const PieChartCardFor2 = ({
  children,
  title,
  value1,
  value2,
  label1,
  label2,
}: {
  children?: ReactNode
  title: string
  value1: number
  value2: number
  label1: string
  label2: string
}) => {
  const data = useMemo(
    () => [
      {
        name: label1,
        value: value1,
      },
      {
        name: label2,
        value: value2,
      },
    ],
    [label1, label2, value1, value2]
  )
  return (
    <PieChartCard cells={data} title={title}>
      {children}
    </PieChartCard>
  )
}

const InsightsCharts = (props: InsightsChartsProps) => {
  const { data, shouldDisplayMBWGNum, shouldHideCardButtons } = props
  const navigator = useNavigator()

  const { t: tInsights } = useTranslation('insights')

  const gotoGroupsButton = useMemo(
    () =>
      shouldHideCardButtons ? null : (
        <Button color="primary" fullWidth onClick={() => navigator.push(myBetterWorldGroupsRoute)} variant="outlined">
          {tInsights('my-better-world-groups:title')}
          <ArrowForwardIcon className="ml-2" />
        </Button>
      ),
    [navigator, shouldHideCardButtons, tInsights]
  )

  const gotoCasesButton = useMemo(
    () =>
      shouldHideCardButtons ? null : (
        <Button
          color="primary"
          fullWidth
          onClick={() => navigator.push(socialSupportCaseOverviewRoute)}
          variant="outlined"
        >
          {tInsights('social-support-cases:socialSupportCases')}
          <ArrowForwardIcon className="ml-2" />
        </Button>
      ),
    [navigator, shouldHideCardButtons, tInsights]
  )

  if (!data) {
    return null
  }

  return (
    <>
      {shouldDisplayMBWGNum ? (
        <Card className="w-full">
          <CardContent className="h-full flex flex-col">
            <p>{tInsights('betterWorldGuidesComplete')}</p>
            <p className="text-5xl font-bold flex-1 flex justify-center items-center">
              {data.better_world_group_steps_completed}
            </p>
          </CardContent>
        </Card>
      ) : (
        <PieChartCardFor2
          label1={tInsights('pending')}
          label2={tInsights('completed')}
          title={tInsights('betterWorldGuides')}
          value1={data.better_world_group_steps_pending}
          value2={data.better_world_group_steps_completed}
        >
          {gotoGroupsButton}
        </PieChartCardFor2>
      )}

      <PieChartCardFor2
        label1={tInsights('pending')}
        label2={tInsights('completed')}
        title={tInsights('supportingChildren')}
        value1={data.children_supporting}
        value2={data.children_supported_completion}
      >
        {gotoCasesButton}
      </PieChartCardFor2>
      <PieChartCardFor2
        label1={tInsights('pending')}
        label2={tInsights('completed')}
        title={tInsights('actionsCompleted')}
        value1={data.social_support_case_actions_pending}
        value2={data.social_support_case_actions_completed}
      >
        {gotoCasesButton}
      </PieChartCardFor2>
      <PieChartCardFor2
        label1={tInsights('pending')}
        label2={tInsights('completed')}
        title={tInsights('betterWorldGroups')}
        value1={data.better_world_group_pending}
        value2={data.better_world_group_completed}
      >
        {gotoGroupsButton}
      </PieChartCardFor2>
      <PieChartCardFor2
        label1={tInsights('open')}
        label2={tInsights('closed')}
        title={tInsights('supportCases')}
        value1={data.social_support_cases_open}
        value2={data.social_support_cases_closed}
      >
        {gotoCasesButton}
      </PieChartCardFor2>
    </>
  )
}

export default InsightsCharts
