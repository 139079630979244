import { useQueryClient } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { userReportsQueryKey } from '../../../../api/constants'
import { userReportsEndpoint } from '../../../../api/endpoints'
import { IUserReport } from '../../../../api/types'
import useOfflineMutation from '../../../../hooks/offline/useOfflineMutation'
import useMst from '../../../../models/useMst'
import { IPostUserReportSuccessDto, IUserReportSuccessResponse } from '../../../../pages/api/reports/[userId]'

interface IMutateArgs {
  report: IUserReport
}

interface UseMutateUserReportProps {
  onSuccess?: (data: IMutateArgs, saved: boolean) => void
  onError?: (error: unknown) => void
}

export async function postUserReport(userReport: IUserReport): Promise<IPostUserReportSuccessDto> {
  if (!userReport.user_id) {
    throw new Error('User id is required') // this should never happen so we don't need to translate it
  }

  const res: AxiosResponse<IUserReportSuccessResponse> = await axios.post(userReportsEndpoint(userReport.user_id), {
    attributes: userReport,
  })
  return res.data.data
}

export default function useMutateUserReport(props: UseMutateUserReportProps) {
  const { reports } = useMst()

  const queryClient = useQueryClient()

  return useOfflineMutation<IMutateArgs, unknown>({
    addLocal: (data) => {
      reports.enqueueForUpdate(data.report)
    },
    removeLocal: (data) => {
      reports.didSubmitReport(data.report.submission_id)
    },
    saveRemote: async (data) => {
      await postUserReport(data.report)
    },
    onError: props.onError,
    onSuccess: (data, saved) => {
      // Always invalidate the reports query cache
      queryClient.invalidateQueries(userReportsQueryKey(data.report.user_id))
      props.onSuccess?.(data, saved)
    },
  })
}
