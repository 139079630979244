import { Palette } from '@mui/material'
import React from 'react'

interface INotesAnnotationsTypography {
  subhead: React.CSSProperties
  subheadBlack: React.CSSProperties
  caption: React.CSSProperties
  captionBlack: React.CSSProperties
  captionMediumWhite: React.CSSProperties
  captionMediumBlack: React.CSSProperties
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    notesAnnotations: INotesAnnotationsTypography
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    notesAnnotations: INotesAnnotationsTypography
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    notesAnnotations: true
  }
}

interface INotesAnnotationsTheme {
  notesAnnotations: INotesAnnotationsTypography
}

export const notesAndAnnotationsTypography = (palette: Palette): INotesAnnotationsTheme => ({
  notesAnnotations: {
    subhead: {
      fontFamily: 'droid-serif',
      fontWeight: 400,
      fontSize: '18px',
      letterSpacing: 0.14,
      lineHeight: 1.333,
      color: palette.onSurface.dark_60,
    },
    subheadBlack: {
      fontFamily: 'droid-serif',
      fontWeight: 400,
      fontSize: '18px',
      letterSpacing: 0.14,
      lineHeight: 1.333,
      color: palette.onSurface.light_60,
    },
    caption: {
      fontFamily: 'droid-serif',
      fontWeight: 400,
      fontSize: '14px',
      letterSpacing: 0.25,
      lineHeight: 1.429,
      color: palette.onSurface.dark_60,
    },
    captionBlack: {
      fontFamily: 'droid-serif',
      fontWeight: 400,
      fontSize: '14px',
      letterSpacing: 0.25,
      lineHeight: 1.429,
      color: palette.onSurface.light_60,
    },
    captionMediumWhite: {
      fontFamily: 'droid-serif',
      fontWeight: 400,
      fontSize: '14px',
      letterSpacing: 0.4,
      lineHeight: 1.429,
      color: palette.onSurface.dark_87,
    },
    captionMediumBlack: {
      fontFamily: 'droid-serif',
      fontWeight: 400,
      fontSize: '14px',
      letterSpacing: 0.4,
      lineHeight: 1.429,
      color: palette.onSurface.light_87,
    },
  },
})
