import { types } from 'mobx-state-tree'
import { IMyBetterWorldGroupLocalData } from '../api/types'

const MyBetterWorldGroups = types
  .model({
    queue: types.array(types.frozen<IMyBetterWorldGroupLocalData>()),
  })
  .actions((self) => ({
    findIndexById(id: number | undefined) {
      if (typeof id !== 'number') return -1
      return self.queue.findIndex((group) => group.id === id)
    },
    findIndexBySubmissionId(submissionId: string | undefined) {
      if (typeof submissionId !== 'string') return -1
      return self.queue.findIndex((group) => group.submission_id === submissionId)
    },
    replaceAtIndex(index: number, group: IMyBetterWorldGroupLocalData) {
      if (index > -1) {
        self.queue.splice(index, 1, { ...group, isLocal: true })
      } else {
        self.queue.push({ ...group, isLocal: true })
      }
    },
    removeAtIndex(index: number) {
      if (index > -1) {
        self.queue.splice(index, 1)
      }
    },
  }))
  .actions((self) => ({
    findIndex: (group: IMyBetterWorldGroupLocalData) => {
      if (group.id) {
        return self.findIndexById(group.id)
      } else if (group.submission_id) {
        return self.findIndexBySubmissionId(group.submission_id)
      } else {
        return -1
      }
    },
  }))
  .actions((self) => ({
    enqueueForUpdate(group: IMyBetterWorldGroupLocalData) {
      self.replaceAtIndex(self.findIndex(group), group)
    },
    removeGroup(group: IMyBetterWorldGroupLocalData) {
      self.removeAtIndex(self.findIndex(group))
    },
    didAddGroup(submissionId: string) {
      self.removeAtIndex(self.findIndexBySubmissionId(submissionId))
    },
    didUpdateGroup(groupId: number) {
      self.removeAtIndex(self.findIndexById(groupId))
    },
  }))

export default MyBetterWorldGroups
