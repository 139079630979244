import { Button, CircularProgress, MenuItem } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { useCallback, useEffect } from 'react'
import { IUserReport } from '../../../api/types'
import useMst from '../../../models/useMst'
import AppBarBackButton from '../../AppBarBackButton'
import AuthLayout from '../../AuthLayout'
import CamfedAppBar from '../../CamfedAppBar'
import useMoreMenu from '../../Common/useMoreMenu'
import useShowMessage from '../../Common/useShowMessage'
import { IScreenProps } from '../../Navigation/types'
import useNavigator from '../../Navigation/useNavigator'
import ReportFormGroup from './components/ReportFormGroup'
import { UserReportContextProvider, useUserReportContext } from './components/UserReportContext'
import { IPlainReport } from './types'
import userReportTitle from './utils/userReportTitle'

interface UserReportPageData {
  year: string
  period: string
  report: IPlainReport
  userReport: IUserReport | undefined
}

interface UserReportPageProps extends IScreenProps<UserReportPageData> {}

function UserReportView() {
  const navigator = useNavigator()
  const { messages, reports } = useMst()
  const { t } = useTranslation('reports')
  const { didSubmitReport, goBackOneStep, hasNext, hasPrevious, isReadonly, isSaving, step, submit, userReport } =
    useUserReportContext()

  // pop when report is submitted
  useEffect(() => {
    if (didSubmitReport) {
      navigator.pop()
    }
  }, [didSubmitReport, navigator])

  const { messageDialog, showMessage } = useShowMessage()

  const onBack = useCallback(() => {
    if (isReadonly) {
      navigator.pop()
    } else {
      showMessage({
        buttons: [
          {
            text: t('common:leave') ?? 'Leave',
            onClick: () => {
              navigator.pop()
            },
          },
          {
            text: t('common:returnToForm') ?? 'Return to Form',
            isCancel: true,
          },
        ],
        title: t('areYouSureToLeaveForm') ?? 'Are you sure you want to leave the form?',
        message:
          t('youCanComeBackLater') ?? 'Changes will be saved locally. You can come back later to submit the report.',
      })
    }
  }, [isReadonly, navigator, showMessage, t])

  const handleDelete = useCallback(() => {
    if (isReadonly) return
    showMessage({
      title: t('deleteReport?') ?? 'Delete Report?',
      message: t('areYouSureToDeleteReport') ?? 'Are you sure you want to delete this report?',
      buttons: [
        {
          text: t('common:cancel') ?? '',
          isCancel: true,
        },
        {
          text: t('common:delete') ?? '',
          onClick: () => {
            reports.removeReport(userReport)
            messages.showMessage(t('reportDeletedSuccessfully'), 'success')
            navigator.pop()
          },
        },
      ],
    })
  }, [isReadonly, messages, navigator, reports, showMessage, t, userReport])

  const { menu } = useMoreMenu((closeMenu: () => void) => {
    return [
      <MenuItem
        key="deleteReport"
        onClick={() => {
          closeMenu()
          handleDelete()
        }}
      >
        {t('deleteReport')}
      </MenuItem>,
    ]
  })

  const hasMenu = !isReadonly

  return (
    <AuthLayout
      appBar={
        <CamfedAppBar
          centralText={
            userReport.Year_of_Report__c && userReport.Reporting_Period__c
              ? userReportTitle(userReport)
              : t('submitReport')
          }
          leftButton={<AppBarBackButton onClick={onBack} />}
          prominent
          rightItems={hasMenu ? menu : null}
        />
      }
    >
      <div className="m-4 md:mx-auto md:w-[736px]">
        <form onSubmit={submit}>{step ? <ReportFormGroup group={step} isReadonly={isReadonly} /> : null}</form>

        <div className="my-4 flex flex-row items-center">
          {hasPrevious ? (
            <Button className="mr-2" disabled={isSaving} onClick={goBackOneStep} variant="contained">
              {t('common:previous')}
            </Button>
          ) : null}
          {hasNext ? (
            <Button className="mr-2" disabled={isSaving} onClick={submit} variant="contained">
              {t('common:next')}
            </Button>
          ) : !userReport.id ? (
            <Button className="mr-2" disabled={isSaving} onClick={submit} variant="contained">
              {t('common:submit')}
            </Button>
          ) : null}
          {isSaving ? (
            <div className="mr-2">
              <CircularProgress size={24} />
            </div>
          ) : null}
        </div>
      </div>
      {messageDialog}
    </AuthLayout>
  )
}

export default function UserReportPage(props: UserReportPageProps) {
  const { data } = props
  const report = data?.report

  return report ? (
    <UserReportContextProvider report={report} userReport={data?.userReport}>
      <UserReportView />
    </UserReportContextProvider>
  ) : null
}
