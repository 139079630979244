/**
 * Hook to search entries for content type of curruiculum
 *
 */

import { ICurriculumFields } from '../../../../@types/generated/contentful'
import { ContentTypes } from '../../../../api/contentTypeIds'
import useGetAllEntries from '../../../../hooks/requests/useGetAllEntries'

export default function useGetCurriculums() {
  // Search the entries
  return useGetAllEntries<ICurriculumFields>(ContentTypes.curriculum)
}
