import { useUser } from '@auth0/nextjs-auth0/client'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { USER_ROLES } from '../../../hooks/useRoles'
import AppBarBackButton from '../../AppBarBackButton'
import AuthLayout from '../../AuthLayout'
import CamfedAppBar from '../../CamfedAppBar'
import { IScreenProps } from '../../Navigation/types'
import ActiveTimeCard from './components/ActiveTimeCard'
import RemoteInsightsCharts from './components/RemoteInsightsCharts'

interface LearnerGuideInsightsPageProps extends IScreenProps<{ ids: string[]; showAuthBar: boolean }> {}

const LearnerGuideInsightsPage = (props: LearnerGuideInsightsPageProps) => {
  const { navigator } = props
  const { t: tInsights } = useTranslation('insights')

  const { user } = useUser()
  const selfId = user?.sub

  const learnerGuideIds = useMemo(() => {
    if (props.data?.ids) {
      // if ids are provided, use them
      return props.data.ids
    }
    if (selfId) {
      // if no ids are provided, use selfId
      return [selfId]
    }
    // this should never happen or just be momentary as the user should be logged in
    return []
  }, [props.data?.ids, selfId])

  const isCoreTrainer = !!user?.meta?.roles?.find((role) => role.name === USER_ROLES.coreTrainer)
  const shouldHideCardButtons = Boolean(selfId !== learnerGuideIds[0] || isCoreTrainer)

  const shouldDisplayMBWGNum = shouldHideCardButtons

  return (
    <AuthLayout
      appBar={
        <CamfedAppBar centralText={tInsights('insights')} leftButton={<AppBarBackButton onClick={navigator.pop} />} />
      }
    >
      <div className="flex-1 flex flex-col justify-center items-center">
        <div className="flex-1 h-full w-full flex flex-col p-[16px]">
          <ActiveTimeCard className="mb-4" userIds={learnerGuideIds} />
          <RemoteInsightsCharts
            shouldDisplayMBWGNum={shouldDisplayMBWGNum}
            shouldHideCardButtons={shouldHideCardButtons}
            learnerGuideIds={learnerGuideIds}
          />
        </div>
      </div>
    </AuthLayout>
  )
}

export default LearnerGuideInsightsPage
