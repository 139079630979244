// helper function to get the title of a step
export default function stepTitle(
  t: (key: string, params: Record<string, any>) => string, // the translation function
  stepIndex: number,
  stepTitle: string | undefined,
  glue: string
): string {
  const stepN = stepIndex > -1 ? t('stepN', { n: stepIndex + 1 }) : ''
  return [stepN, stepTitle].filter((s) => s).join(glue)
}
