import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import AppBarBackButton from '../../AppBarBackButton'
import AuthLayout from '../../AuthLayout'
import CamfedAppBar from '../../CamfedAppBar'
import { INavigator } from '../../Navigation/types'
import useNavigator from '../../Navigation/useNavigator'
import { ResourceLibraryContextProvider } from '../../ResourceLibrary/Context/ResourceLibraryContext'
import FilterView from '../../ResourceLibrary/FilterView/FilterView'
import ResourceList from '../../ResourceLibrary/ResourceList/ResourceList'
import TabBar from '../../ResourceLibrary/TabBar/TabBar'
import useResourceLibraryTabs from '../../ResourceLibrary/TabBar/useResourceLibraryTabs'

interface IResourceListPageProps {
  id?: string
  navigator?: INavigator
}

function ResourceLibraryListing() {
  const navigator: INavigator = useNavigator()
  const { tab, onTabChange, setTab } = useResourceLibraryTabs()

  // on apply, switch to listing tab
  const onApplyClick = useCallback(() => {
    setTab(0)
  }, [setTab])

  const onFiltersClick = useCallback(() => {
    // Set the tab to 1
    setTab(1)
  }, [setTab])

  return (
    <div className="flex flex-col absolute inset-0">
      <TabBar tab={tab} onTabChange={onTabChange} />
      <div className="relative flex-1">
        {tab === 0 ? <ResourceList onFiltersClick={onFiltersClick} navigator={navigator} /> : null}
        {tab === 1 ? <FilterView onApplyClick={onApplyClick} /> : null}
      </div>
    </div>
  )
}

/*
 * Renders the resource list/library page layout
 */
export default function ResourceListPage({}: IResourceListPageProps) {
  const navigator: INavigator = useNavigator()
  const { t } = useTranslation('resource-library')
  const title = t('resourceLibrary')

  return (
    <AuthLayout appBar={<CamfedAppBar centralText={title} leftButton={<AppBarBackButton onClick={navigator.pop} />} />}>
      <ResourceLibraryContextProvider>
        <ResourceLibraryListing />
      </ResourceLibraryContextProvider>
    </AuthLayout>
  )
}
