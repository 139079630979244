import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer'
import { Document } from '@contentful/rich-text-types'

interface IRichTextParserProps {
  document: Document | undefined
  options: Options
}

export default function RichTextParser(props: IRichTextParserProps): JSX.Element {
  const { document, options } = props

  return <>{document ? documentToReactComponents(document, options) : null}</>
}
