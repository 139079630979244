import { useUser } from '@auth0/nextjs-auth0/client'
import { QueryClient, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { toJS } from 'mobx'
import { useTranslation } from 'next-i18next'
import { myProfileQueryKey } from '../../../../../api/constants'
import { myProfileEndpoint } from '../../../../../api/endpoints'
import { IUserProfile, IUserProfileLocalData } from '../../../../../api/types'
import { accountProfileRouteName } from '../../../../../constants'
import useOfflineMutation from '../../../../../hooks/offline/useOfflineMutation'
import useMst from '../../../../../models/useMst'
import { trackEmailUpdated, trackPhoneUpdated } from '../../../../Analytics/trackers'
import { logError } from '../../../../Common/loggers'

export const updateProfile = async (attributes: Partial<IUserProfileLocalData>): Promise<IUserProfile> => {
  const res = await axios.put<{ data: IUserProfile }>(myProfileEndpoint, { attributes })
  return res.data.data
}

function optimisticUpdateMyProfile(queryClient: QueryClient, data: IUserProfileLocalData) {
  if (data) {
    const key = myProfileQueryKey(data.user_id)

    // optimistic update
    queryClient.setQueryData<IUserProfile>(key, (profile: IUserProfile | undefined) => {
      if (profile) {
        const { user_id, ...attributes } = data
        return {
          ...profile,
          ...attributes,
        }
      }
    })

    // invalidate the query to trigger a refetch
    queryClient.invalidateQueries(key)
  }
}

export function useSyncProfile() {
  const { profile } = useMst()
  const queryClient = useQueryClient()
  const { user } = useUser()

  return async () => {
    try {
      const data = toJS(profile.localProfile)

      if (data) {
        // submit to backend
        const { user_id, ...attributes } = data
        await updateProfile(attributes)

        // clear local data
        profile.didUpdateProfile()

        // optimistic update
        optimisticUpdateMyProfile(queryClient, data)

        if (user) {
          if (attributes.contact_phone_number !== undefined) {
            trackPhoneUpdated(user, accountProfileRouteName)
          }
          if (attributes.contact_email !== undefined) {
            trackEmailUpdated(user, accountProfileRouteName)
          }
        }
      }
    } catch (e) {
      logError(e)
    }
  }
}

export default function useMutateProfile() {
  const { messages, profile } = useMst()
  const queryClient = useQueryClient()
  const { t } = useTranslation('account-profile')

  return useOfflineMutation({
    addLocal: (data: IUserProfileLocalData) => {
      profile.enqueueForUpdate(data)
    },
    removeLocal: () => {
      profile.didUpdateProfile()
    },
    saveRemote: async (data: IUserProfileLocalData) => {
      const { user_id, ...attributes } = data
      return await updateProfile(attributes)
    },
    onSuccess: (data: IUserProfileLocalData, saved) => {
      if (saved) {
        messages.showMessage(t('updatedSuccessfully'), 'success')
        optimisticUpdateMyProfile(queryClient, data)
      }
    },
    onError: (e) => {
      const err = e as AxiosError
      messages.showMessage(t('errorUpdating', { error: err.message }), 'error')
    },
  })
}
