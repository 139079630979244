import { types } from 'mobx-state-tree'

const Insights = types
  .model({
    startDate: types.optional(types.string, ''),
    endDate: types.optional(types.string, ''),
  })

  .actions((self) => ({
    setDateRange(startDate: Date | null, endDate: Date | null) {
      self.startDate = startDate?.toISOString() ?? ''
      self.endDate = endDate?.toISOString() ?? ''
    },
  }))

export default Insights
