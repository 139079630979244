import { Document } from '@contentful/rich-text-types'
import useRichTextOptions from './useRichTextOptions'
import RichTextParser from '../RichTextParser'

interface IRichTextProps {
  document?: Document
}

export default function RichText({ document }: IRichTextProps): JSX.Element {
  //   Setup the rich text parser options
  const options = useRichTextOptions()

  // Parse the rich text
  if (document) {
    return <RichTextParser document={document} options={options} />
  } else {
    return <></>
  }
}
