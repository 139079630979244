import { Card, CardActions, CardContent } from '@mui/material'
import { PropsWithChildren } from 'react'

export interface IArticleCardProps {
  actions?: JSX.Element[] | JSX.Element
  className?: string
  onClick?: () => void
}

export default function ArticleCard(props: PropsWithChildren<IArticleCardProps>): JSX.Element {
  const { actions, children, className, onClick } = props

  return (
    <Card className={className} onClick={onClick}>
      <CardContent>{children}</CardContent>
      <CardActions>{actions}</CardActions>
    </Card>
  )
}
