import { Home } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import useMst from '../../../models/useMst'
import { palette } from '../../../theme/palette'

export default function HomeButton(): JSX.Element {
  const { router } = useMst()
  const { resetRoutes } = router

  const { t } = useTranslation('home-feed')

  const onClick = useCallback(() => {
    resetRoutes()
  }, [resetRoutes])

  return (
    <IconButton
      aria-label={t('myHomeFeedPage') ?? ''}
      aria-haspopup="false"
      sx={{ color: palette.onPrimary.light_74 }}
      onClick={onClick}
    >
      <Home />
    </IconButton>
  )
}
