import { useUser } from '@auth0/nextjs-auth0/client'
import { MenuItem } from '@mui/material'
import { DateTime } from 'luxon'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import {
  ISocialSupportCaseActionLocalData,
  ISocialSupportCaseDto,
  ISocialSupportCaseLocalData,
} from '../../../../api/types'
import generateSubmissionId from '../../../../utils/generateSubmissionId'
import AppBarBackButton from '../../../AppBarBackButton'
import AuthLayout from '../../../AuthLayout'
import BodyPadding from '../../../BodyPadding'
import CamfedAppBar from '../../../CamfedAppBar'
import useMoreMenu from '../../../Common/useMoreMenu'
import useShowMessage from '../../../Common/useShowMessage'
import { IScreenProps } from '../../../Navigation/types'
import SocialSupportActionForm from '../SocialSupportActionForm'
import useMutateSocialSupportCase from '../hooks/useMutateSocialSupportCase'
import { OnSubmitAction } from '../useSocialSupportActionForm'

interface ActionPageData {
  // onConfirm: (action: ISocialSupportCaseActionLocalData) => void
  action?: ISocialSupportCaseActionLocalData | undefined
  supportCase: ISocialSupportCaseDto | ISocialSupportCaseLocalData
}

interface IEditActionPageProps extends IScreenProps<ActionPageData> {}

export default function EditActionPage(props: IEditActionPageProps) {
  const { navigator, data } = props
  const action = data?.action
  const supportCase = data?.supportCase

  const { user, isLoading: isLoadingUser } = useUser()
  const userId = user?.sub
  const editable = !isLoadingUser && userId && userId === supportCase?.trainer_id

  const { mutate } = useMutateSocialSupportCase()

  const onConfirm = useCallback(
    (newAction: ISocialSupportCaseActionLocalData) => {
      if (supportCase && editable) {
        if (action) {
          mutate({
            data: {
              supportCase: {
                ...supportCase,
                actions: (supportCase.actions ?? []).map((a) =>
                  a.submission_id === action.submission_id ? newAction : a
                ),
              },
              change: {
                type: 'upsertAction',
                submissionId: newAction.submission_id,
              },
            },
          })
        } else {
          mutate({
            data: {
              supportCase: {
                ...supportCase,
                actions: [...(supportCase.actions ?? []), newAction],
              },
              change: {
                type: 'upsertAction',
                submissionId: newAction.submission_id,
              },
            },
          })
        }
      }
    },
    [action, editable, mutate, supportCase]
  )

  // Get the t function from the translation
  const { t } = useTranslation('social-support-case-action-form')

  // Memoize the handleBack function
  const handleBack = useCallback(() => {
    navigator.pop()
  }, [navigator])

  // use message dialog
  const { showMessage, messageDialog } = useShowMessage()

  // Memoize the handleSubmit function
  const handleSubmit: OnSubmitAction = useCallback(
    (title: string, dateValue: DateTime | null, notes: string) => {
      if (!editable) return
      const date = dateValue?.toISODate() ?? ''
      const newAction: ISocialSupportCaseActionLocalData = action
        ? {
            ...action,
            date,
            notes,
            title,
          }
        : {
            completed: 0,
            completed_date: null,
            created_at: new Date().toISOString(),
            date,
            notes,
            submission_id: generateSubmissionId(),
            title,
            updated_at: new Date().toISOString(),
          }
      onConfirm(newAction)
      navigator.pop()
    },
    [action, editable, navigator, onConfirm]
  )

  const handleDelete = useCallback(() => {
    if (!editable) return
    showMessage({
      title: t('deleteAction') ?? '',
      message: t('deleteActionConfirmationMessage') ?? '',
      buttons: [
        {
          text: t('common:cancel') ?? '',
          isCancel: true,
        },
        {
          text: t('common:delete') ?? '',
          onClick: () => {
            if (action) {
              onConfirm?.({
                ...action,
                isDeleted: true,
              })
            } else {
              // the action is not yet saved, so just discard it
            }
            navigator.pop()
          },
        },
      ],
    })
  }, [action, editable, navigator, onConfirm, showMessage, t])

  const { menu } = useMoreMenu((closeMenu: () => void) => {
    return [
      <MenuItem
        key="deleteAction"
        onClick={() => {
          closeMenu()
          handleDelete()
        }}
      >
        {t('deleteAction')}
      </MenuItem>,
    ]
  })

  return (
    <AuthLayout
      appBar={
        <CamfedAppBar
          centralText={!editable ? t('social-support-cases:action') : action ? t('editAction') : t('addNewAction')}
          leftButton={<AppBarBackButton onClick={handleBack} />}
          prominent
          rightItems={action && editable ? menu : null}
        />
      }
    >
      <BodyPadding>
        <SocialSupportActionForm initialAction={action} onSubmit={handleSubmit} readOnly={!editable} />
      </BodyPadding>
      {messageDialog}
    </AuthLayout>
  )
}
