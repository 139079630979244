import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { socialSupportCasesQueryKey } from '../../../../api/constants'
import { myGuideSocialSupportCasesEndpoint, socialSupportCasesEndpoint } from '../../../../api/endpoints'
import { ISocialSupportCaseDto } from '../../../../api/types'

export default function useGetSocialSupportCases(userId: string, isSelf: boolean) {
  return useQuery({
    queryKey: socialSupportCasesQueryKey(userId),
    queryFn: async (): Promise<ISocialSupportCaseDto[] | undefined> => {
      if (!userId) {
        return undefined
      }
      const resp = await axios.get<{ data: ISocialSupportCaseDto[] }>(
        isSelf ? socialSupportCasesEndpoint : myGuideSocialSupportCasesEndpoint(userId)
      )
      return resp.data.data
    },
  })
}
