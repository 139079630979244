import { DateTime } from 'luxon'
import { useCallback, useState } from 'react'
import { ISocialSupportCaseActionLocalData } from '../../../api/types'
import parseDate from '../../../utils/dates/parseDate'

export type OnSubmitAction = (actionTitle: string, dateValue: DateTime | null, actionNotes: string) => void

interface IUserSocialSupportActionFormProps {
  initialAction: ISocialSupportCaseActionLocalData | undefined
  onSubmit: OnSubmitAction
}

export default function useSocialSupportActionForm(props: IUserSocialSupportActionFormProps) {
  const { initialAction, onSubmit } = props

  //   Create the state for the action title
  const [actionTitle, setActionTitle] = useState(initialAction?.title ?? '')
  // Memoized function for handling the change of the action title text input
  const handleActionTitleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setActionTitle(event.target.value)
  }, [])

  // Create the state for the date
  const [dateValue, setDateValue] = useState<DateTime | null>(() => {
    const d = parseDate(initialAction?.date)
    return d ? DateTime.fromJSDate(d) : DateTime.now()
  })

  // Memoized function for handling the change of the date
  const handleDateChange = useCallback((newValue: DateTime | null) => {
    setDateValue(newValue)
  }, [])

  // Create the state for the action notes
  const [actionNotes, setActionNotes] = useState(initialAction?.notes ?? '')
  // Memoized function for handling the change of the action notes text input
  const handleActionNotesChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setActionNotes(event.target.value)
  }, [])

  // Todo: Implement the handleSubmit function with BE logic
  // Memoize the handleSubmit function for the HTML form element
  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      onSubmit(actionTitle, dateValue, actionNotes)
    },
    [actionNotes, actionTitle, dateValue, onSubmit]
  )

  return {
    handleSubmit,
    actionTitle,
    handleActionTitleChange,
    dateValue,
    handleDateChange,
    actionNotes,
    handleActionNotesChange,
  }
}
