import { useEffect, useRef } from 'react'
import usePrevious from './usePrevious'

export default function useOnValueChange<T>(value: T, cb: (value: T, oldValue: T | undefined) => void) {
  const oldValue = usePrevious(value)
  const cbRef = useRef(cb)
  cbRef.current = cb

  useEffect(() => {
    if (value !== oldValue) {
      cbRef.current(value, oldValue)
    }
  }, [oldValue, value])
}
