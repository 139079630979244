import { SxProps, Theme, Typography } from '@mui/material'
import { TypographyVariants as ITypography } from '@mui/material/styles'
import { ElementType, PropsWithChildren } from 'react'

interface IRichTextSubtitleProps {
  component: ElementType<any>
  typography: ITypography
  sx?: SxProps<Theme>
}

// heading from a RTE in the design looks like this
export default function RichTextSubtitle({
  children,
  component,
  typography,
  sx,
}: PropsWithChildren<IRichTextSubtitleProps>): JSX.Element {
  return (
    <Typography component={component} gutterBottom sx={{ ...typography.subtitle1.onSurface.light.highEmphasis, ...sx }}>
      {children}
    </Typography>
  )
}
