import { compileExpression, useDotAccessOperatorAndOptionalChaining } from 'filtrex'
import boolValue from '../../../../utils/boolValue'
import parseDate from '../../../../utils/dates/parseDate'
import numValue from '../../../../utils/numValue'

const extraFunctions = {
  bool: (value: any) => boolValue(value),
  num: (value: any) => numValue(value),
  date: (value: any) => parseDate(value),
  obj: (...keyValues: any[]) => {
    const obj: any = {}
    for (let i = 0; i < keyValues.length; i += 2) {
      obj[keyValues[i]] = keyValues[i + 1]
    }
    return obj
  },
  str: (...value: any[]) => String(value.map((a) => String(a ?? '')).join('')), // convert and concatenate all arguments to a string
}

export default function compileExp(exp: string | undefined): ((data: any) => any) | undefined {
  if (!exp || !exp.trim()) return undefined
  try {
    return compileExpression(exp, {
      customProp: useDotAccessOperatorAndOptionalChaining, // allow access to non-existing properties
      extraFunctions,
    })
  } catch (e) {
    console.warn('Error compiling expression', exp, e)
  }
}
