import { useCallback, useState } from 'react'

export function useToggleValues<T = string>(initialValues?: T[] | null | undefined) {
  const [values, setValues] = useState(new Set<T>(initialValues))

  const toggleValue = useCallback(
    (value: T) => {
      setValues((values) => {
        const newValues = new Set(values)
        if (newValues.has(value)) {
          newValues.delete(value)
        } else {
          newValues.add(value)
        }
        return newValues
      })
    },
    [setValues]
  )

  const clearAll = useCallback(() => {
    setValues(new Set())
  }, [setValues])

  return { clearAll, values, toggleValue }
}
