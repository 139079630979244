import { ListItem, ListItemButton, ListItemText, Typography, useTheme } from '@mui/material'
import { TFunction } from 'next-i18next'
import { useCallback, useMemo } from 'react'
import { ISocialSupportCaseDto, ISocialSupportCaseLocalData } from '../../../../api/types'
import { socialSupportCaseRoute } from '../../../../constants'
import useRelativeDateFormatter from '../../../../hooks/useRelativeDateFormatter'
import { INavigator } from '../../../Navigation/types'

const SocialSupportCaseListItem = (props: {
  navigator: INavigator
  supportCase: ISocialSupportCaseLocalData | ISocialSupportCaseDto
  t: TFunction
}) => {
  const { navigator, supportCase, t } = props
  const title = `${supportCase.year} - ${supportCase.title}${supportCase.isLocal ? '*' : ''}`

  const { typography } = useTheme()

  // Onclick handler to push the route to the router
  const onClick = useCallback(() => {
    navigator.push(socialSupportCaseRoute(supportCase))
  }, [supportCase, navigator])

  // get the number of actions that are not completed
  const numCompletedActions =
    (supportCase.actions as { completed: 0 | 1; isDeleted: boolean }[])?.reduce((count, action): number => {
      return count + (action.completed && !action.isDeleted ? 1 : 0)
    }, 0) ?? 0

  const numActions =
    (supportCase.actions as { isDeleted: boolean }[])?.reduce((count, action): number => {
      return count + (action.isDeleted ? 0 : 1)
    }, 0) ?? 0

  // Get the last updated action from the array of actions
  const lastUpdatedAction = useMemo(
    () =>
      (supportCase.actions ?? []).slice().sort((a, b) => {
        // If updated_at is a key in the action object, use that to sort
        if ('updated_at' in a && 'updated_at' in b) {
          if (a.updated_at > b.updated_at) {
            return -1
          } else if (a.updated_at < b.updated_at) {
            return 1
          } else {
            return 0
          }
        } else {
          // If the updated key is preset in a but not in b, then a is greater than b
          if ('updated_at' in a) {
            return -1
          } else if ('updated_at' in b) {
            // If the updated key is preset in b but not in a, then b is greater than a
            return 1
          } else {
            // If neither a nor b have the updated key, then they are equal
            return 0
          }
        }
      })[0],
    [supportCase.actions]
  )

  const formatRelativeDate = useRelativeDateFormatter()

  // If updated_at is a key in lastUpdatedAction, use that to format the date
  const lastUpdatedDate = useMemo(() => {
    if (lastUpdatedAction && 'updated_at' in lastUpdatedAction) {
      return formatRelativeDate(lastUpdatedAction.updated_at)
    } else {
      return ''
    }
  }, [lastUpdatedAction, formatRelativeDate])

  return (
    <ListItem disableGutters disablePadding className="pr-1.5">
      <ListItemButton onClick={onClick} sx={{ display: 'block' }}>
        <ListItemText classes={{ primary: 'w-full flex flex-row items-start justify-between' }}>
          <Typography variant="subtitle1" sx={typography.subtitle1.onSurface.light.highEmphasis}>
            {title}
          </Typography>
          <Typography
            className="uppercase"
            variant="subtitle1"
            sx={typography.subtitle1.onSurface.light.mediumEmphasis}
          >
            {t('numActions', { completedCount: numCompletedActions, totalCount: numActions })}
          </Typography>
        </ListItemText>
        <ListItemText classes={{ primary: 'w-full flex flex-row items-start justify-between' }}>
          <Typography component="div" sx={{ ...typography.body1.onSurface.light.mediumEmphasis }}>
            {lastUpdatedAction?.title}
          </Typography>
          <Typography className="ml-2" component="div" sx={{ ...typography.caption.onSurface.light.highEmphasis }}>
            {lastUpdatedDate}
          </Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  )
}

export default SocialSupportCaseListItem
