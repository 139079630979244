import { Box, Button, TextField, useTheme } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import ControlledSelect from '../../../ControlledSelect'
import useMyBetterWorldGroupForm from '../hooks/useMyBetterWorldGroupForm'

// Props extends IScreenProps
interface IMyBetterWorldGroupFormProps {
  // onSubmit prop is a function that accepts a string for the year, a string for the group and a string for the curriculum
  onSubmit: (year: string, group: string, curriculum: string) => void
}

const MyBetterWorldGroupForm = (props: IMyBetterWorldGroupFormProps) => {
  const { onSubmit } = props

  // Get the labels from the translations
  const { t } = useTranslation('my-better-world-group-form')

  const {
    years,
    selectedYear,
    handleYearChange,
    group,
    handleGroupChange,
    curriculum,
    handleCurriculumChange,
    handleSubmit,

    curriculumOptions,
  } = useMyBetterWorldGroupForm({ onSubmit })

  // Get the typography from the theme
  const { typography } = useTheme()

  const onFormSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      handleSubmit()
    },
    [handleSubmit]
  )

  return (
    <form onSubmit={onFormSubmit}>
      <Box sx={{ mb: 2 }}>
        <ControlledSelect
          label={t('yearLabel') ?? ''}
          options={years}
          value={selectedYear}
          onChange={handleYearChange}
          fullWidth
          required
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <TextField
          required
          value={group}
          onChange={handleGroupChange}
          id="group"
          label={t('groupLabel')}
          variant="outlined"
          fullWidth
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <ControlledSelect
          required
          label={t('curriculumLabel') ?? ''}
          options={curriculumOptions}
          value={curriculum}
          onChange={handleCurriculumChange}
        />
      </Box>
      <Button
        variant="contained"
        sx={{ ...typography.button.primary.dark.enabled, color: '#fff', padding: '10px' }}
        type="submit"
      >
        Submit
      </Button>
    </form>
  )
}

export default MyBetterWorldGroupForm
