import { ComponentType } from 'react'
import { ICurriculumFields, IPageFields } from '../@types/generated/contentful'
import {
  IAssociatedGuide,
  IMyBetterWorldGroupDto,
  IMyBetterWorldGroupLocalData,
  ISocialSupportCaseActionLocalData,
  ISocialSupportCaseDto,
  ISocialSupportCaseLocalData,
  IUserReport,
  SimpleEntry,
} from '../api/types'
import FindYourAccountPage from '../components/Auth/FindYourAccountPage'
import { IScreenProps } from '../components/Navigation/types'
import ResourcePage from '../components/ResourcePage'
import AccountProfilePage from '../components/pages/Account/Profile/AccountProfilePage'
import AccountSettingsPage from '../components/pages/Account/Settings/AccountSettingsPage'
import CurriculumPage from '../components/pages/CurriculumPage'
import CurriculumStepPage from '../components/pages/CurriculumPage/Steps/CurriculumStepPage'
import CoreTrainerInsightsPage from '../components/pages/Insights/CoreTrainerInsightsPage'
import LearnerGuideInsightsPage from '../components/pages/Insights/LearnerGuideInsightsPage'
import SelfInsightsPage from '../components/pages/Insights/SelfInsightsPage'
import AddMyBetterWorldGroup from '../components/pages/MyBetterWorldGroups/AddMyBetterWorldGroupPage'
import MyBetterWorldGroupPage from '../components/pages/MyBetterWorldGroups/MyBetterWorldGroupPage'
import MyBetterWorldGroupsPage from '../components/pages/MyBetterWorldGroups/MyBetterWorldGroupsPage'
import MyGuideDetailsPage from '../components/pages/MyGuides/DetailsPage/MyGuideDetailsPage'
import MyGuidesOverviewPage from '../components/pages/MyGuides/OverviewPage/MyGuidesOverviewPage'
import Page from '../components/pages/Page'
import ReportsOverviewPage from '../components/pages/Reports/ReportsOverviewPage'
import UserReportPage from '../components/pages/Reports/UserReportPage'
import { IPlainReport } from '../components/pages/Reports/types'
import ResourceListPage from '../components/pages/ResourceListPage'
import EditActionPage from '../components/pages/SocialSupportCases/EditActionPage'
import SocialSupportCaseOverviewPage from '../components/pages/SocialSupportCases/OverviewPage/SocialSupportCaseOverviewPage'
import SocialSupportCaseCreatePage from '../components/pages/SocialSupportCases/SocialSupportCaseCreatePage'
import SocialSupportCasePage from '../components/pages/SocialSupportCases/SocialSupportCasePage'
import { IRoute, TransitionStyle } from '../models/Router'
import {
  accountProfileRouteName,
  accountSettingsRouteName,
  addMyBetterWorldGroupRouteName,
  curriculumRouteName,
  curriculumStepRouteName,
  findYourAccountRouteName,
  homeFeedRouteName,
  insightsCoreTrainerRouteName,
  insightsLearnerGuideRouteName,
  insightsLocalSelfRouteName,
  loginRouteName,
  myBetterWorldGroupRouteName,
  myBetterWorldGroupsRouteName,
  myGuideDetailsRouteName,
  myGuidesOverviewRouteName,
  pageRouteName,
  reportsRouteName,
  resourceLibraryRouteName,
  resourceRouteName,
  socialSupportCaseActionEditRouteName,
  socialSupportCaseCreateName,
  socialSupportCaseOverviewRouteName,
  socialSupportCaseRouteName,
  userReportRouteName,
} from './routeNames'
export * from './pageUrls'
export * from './routeNames'

export const ScreenTransitionDuration = 300 // define the duration so that all components can share a common value

export const publicRoutes = new Set([loginRouteName, findYourAccountRouteName])

export const stackScreenBaseZIndex = 1299
let dialogBaseIndex = 10000
export const assetDownloadDialogZIndex = dialogBaseIndex++
export const messageDialogZIndex = dialogBaseIndex++
export const onboardingDialogZIndex = dialogBaseIndex++
export const menuZIndex = dialogBaseIndex++
export const datePickerZIndex = dialogBaseIndex++

// Route names and corresponding page components
/*
 * Responsible for linking key value pairs of route name and page component
 */

export const addMyBetterWorldGroupRoute: IRoute = {
  name: addMyBetterWorldGroupRouteName,
  state: {
    id: addMyBetterWorldGroupRouteName,
    visible: true,
    prominent: true,
  },
}

export const homeFeedRoute: IRoute = {
  name: homeFeedRouteName,
  state: {
    id: homeFeedRouteName,
    visible: true,
    prominent: true,
  },
}

export const loginRoute: IRoute = {
  name: loginRouteName,
  state: {
    id: loginRouteName,
    visible: true,
    prominent: true,
  },
}

export const myBetterWorldGroupsRoute: IRoute = {
  name: myBetterWorldGroupsRouteName,
  state: {
    id: myBetterWorldGroupsRouteName,
    visible: true,
    prominent: true,
  },
}

export const myBetterWorldGroupRoute = (group: IMyBetterWorldGroupDto | IMyBetterWorldGroupLocalData): IRoute => ({
  name: myBetterWorldGroupRouteName,
  state: { id: myBetterWorldGroupRouteName, visible: true, prominent: true, data: { group } },
})

export const resourceLibraryRoute: IRoute = {
  name: resourceLibraryRouteName,
  state: {
    id: resourceLibraryRouteName,
    visible: true,
    prominent: true,
  },
}

export const findYourAccountRoute: IRoute = {
  name: findYourAccountRouteName,
  state: {
    id: findYourAccountRouteName,
    visible: true,
    prominent: true,
  },
}

export const accountProfileRoute: IRoute = {
  name: accountProfileRouteName,
  state: { id: accountProfileRouteName, visible: true, prominent: false },
}

export const accountSettingsRoute: IRoute = {
  name: accountSettingsRouteName,
  state: { id: accountSettingsRouteName, visible: true, prominent: false },
}

export const pageRoute = (id: string): IRoute => ({
  name: pageRouteName,
  state: { id, visible: true, prominent: false },
})

export const curriculumRoute = (id: string): IRoute => ({
  name: curriculumRouteName,
  state: { id, visible: true, prominent: false },
})

// josh
export const curriculumStepRoute = (
  id: string,
  curriculum: SimpleEntry<ICurriculumFields>,
  step: SimpleEntry<IPageFields>
): IRoute => {
  return {
    name: curriculumStepRouteName,
    state: { id, visible: true, prominent: false, data: { curriculum, step } },
  }
}

export const myGuidesOverviewRoute: IRoute = {
  name: myGuidesOverviewRouteName,
  state: { id: myGuidesOverviewRouteName, visible: true, prominent: false },
}

export const myGuideDetailsRoute = (guide: IAssociatedGuide): IRoute => ({
  name: myGuideDetailsRouteName,
  state: { id: String(guide.data.id), visible: true, prominent: false, data: { guide } },
  transitionStyle: TransitionStyle.slideUp,
})

export const socialSupportCaseOverviewRoute: IRoute = {
  name: socialSupportCaseOverviewRouteName,
  state: { id: socialSupportCaseOverviewRouteName, visible: true, prominent: false },
}

export const socialSupportCaseRoute = (supportCase: ISocialSupportCaseDto | ISocialSupportCaseLocalData): IRoute => ({
  name: socialSupportCaseRouteName,
  state: { id: socialSupportCaseRouteName, visible: true, prominent: false, data: { supportCase } },
})

export const socialSupportCaseCreateRoute: IRoute = {
  name: socialSupportCaseCreateName,
  state: { id: socialSupportCaseCreateName, visible: true, prominent: false },
}

export const socialSupportCaseActionEditRoute = (
  action: ISocialSupportCaseActionLocalData | null,
  supportCase: ISocialSupportCaseDto | ISocialSupportCaseLocalData
): IRoute => ({
  name: socialSupportCaseActionEditRouteName,
  state: { id: socialSupportCaseActionEditRouteName, visible: true, prominent: false, data: { action, supportCase } },
})

export const insightsLearnerGuideRoute = (ids: Array<string>, showAuthBar: boolean): IRoute => ({
  name: insightsLearnerGuideRouteName,
  state: { id: insightsLearnerGuideRouteName, visible: true, prominent: false, data: { ids, showAuthBar } },
})

export const userReportRoute = (report: IPlainReport, userReport?: IUserReport | undefined): IRoute => ({
  name: userReportRouteName,
  state: { id: userReportRouteName, visible: true, prominent: false, data: { report, userReport } },
})

export const Screens: { [key: string]: ComponentType<IScreenProps> } = {
  [addMyBetterWorldGroupRouteName]: AddMyBetterWorldGroup,
  [accountProfileRouteName]: AccountProfilePage,
  [accountSettingsRouteName]: AccountSettingsPage,
  [curriculumRouteName]: CurriculumPage,
  [curriculumStepRouteName]: CurriculumStepPage,
  [findYourAccountRouteName]: FindYourAccountPage,
  [myBetterWorldGroupsRouteName]: MyBetterWorldGroupsPage,
  [myBetterWorldGroupRouteName]: MyBetterWorldGroupPage,
  [myGuideDetailsRouteName]: MyGuideDetailsPage,
  [myGuidesOverviewRouteName]: MyGuidesOverviewPage,
  [pageRouteName]: Page,
  [resourceLibraryRouteName]: ResourceListPage,
  [insightsCoreTrainerRouteName]: CoreTrainerInsightsPage,
  [insightsLearnerGuideRouteName]: LearnerGuideInsightsPage,
  [insightsLocalSelfRouteName]: SelfInsightsPage,
  [reportsRouteName]: ReportsOverviewPage,
  [resourceRouteName]: ResourcePage,
  [socialSupportCaseActionEditRouteName]: EditActionPage,
  [socialSupportCaseCreateName]: SocialSupportCaseCreatePage,
  [socialSupportCaseOverviewRouteName]: SocialSupportCaseOverviewPage,
  [socialSupportCaseRouteName]: SocialSupportCasePage,
  [userReportRouteName]: UserReportPage,
}
