import { Checkbox, List, ListItem, ListItemButton, ListItemText, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { IPageFields } from '../../../../@types/generated/contentful'
import { MaybeEntries, SimpleEntry } from '../../../../api/types'
import stepTitle from '../helpers/stepTitle'

type StepsProps = {
  checkboxes?: boolean
  checkedSteps?: string[] | undefined
  className?: string
  disabled?: boolean
  listItemClassName?: string
  onStepClick?: (step: SimpleEntry<IPageFields>) => void
  onStepToggle?: (step: SimpleEntry<IPageFields>) => void
  steps: MaybeEntries<IPageFields>
}

const Steps = ({
  checkboxes = false,
  checkedSteps,
  className = '',
  disabled = false,
  listItemClassName = '',
  onStepClick,
  onStepToggle,
  steps,
}: StepsProps) => {
  const hasSteps = steps && steps.length > 0
  const { typography } = useTheme()
  const { t } = useTranslation('curriculum')

  const checkedStepSet = useMemo(() => new Set(checkedSteps), [checkedSteps])

  return hasSteps ? (
    <List className={`m-0 p-0 ${className}`}>
      {steps.map((step, i) => {
        const isChecked = checkedStepSet.has(step.sys.id)
        const labelId = `step-checkbox-label-${step.sys.id}`
        const checkbox = checkboxes ? (
          <Checkbox
            className="mr-2"
            disabled={disabled}
            onChange={() => 'fields' in step && onStepToggle?.(step)}
            checked={isChecked}
            inputProps={{ 'aria-labelledby': labelId }}
          />
        ) : null

        return (
          <ListItem
            className={listItemClassName}
            key={step.sys.id}
            secondaryAction={checkbox}
            disableGutters
            disablePadding
          >
            <ListItemButton disabled={disabled} onClick={() => 'fields' in step && onStepClick?.(step)}>
              <ListItemText id={labelId}>
                <Typography sx={{ ...typography.subtitle1.onSurface.light.highEmphasis }}>
                  {stepTitle(t, i, 'fields' in step ? step.fields?.title : '', ' - ')}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        )
      })}
    </List>
  ) : null
}

export default Steps
