import { Duration } from 'luxon'

export default function formatDuration(seconds: number | null | undefined): string | null | undefined {
  if (seconds === null || seconds === undefined) {
    return seconds
  }
  let d = Duration.fromMillis(seconds * 1000)

  if (seconds < 3600) {
    d = d.shiftTo('minutes', 'seconds')
  } else if (seconds < 86400) {
    d = d.shiftTo('hours', 'minutes')
  } else {
    d = d.shiftTo('days', 'hours')
  }

  return d
    .toHuman({ listStyle: 'narrow', unitDisplay: 'narrow', notation: 'compact' })
    .replace(/,\s0\s?s$/, '') // remove trailing `, 0s`
    .replace(/,\s0\s?m$/, '') // remove trailing `, 0m`
    .replace(/,\s0\s?h$/, '') // remove trailing `, 0h`
    .replace(/,/g, '') // remove ,
}
