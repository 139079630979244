import { Button, Typography, useTheme } from '@mui/material'
import { Theme } from '@mui/system/createTheme/createTheme'
import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx'

import RichTextSubtitle from '../RichTextParser/RichTextSubtitle'

interface ISimpleArticleButtonProps {
  excerpt: string
  onClick?: () => void
  sx?: SxProps<Theme>
  title: string
}

export default function SimpleArticleButton(props: ISimpleArticleButtonProps): JSX.Element {
  const { excerpt, onClick, sx, title } = props

  const { typography } = useTheme()

  return (
    <Button onClick={onClick} sx={{ display: 'block', textAlign: 'left', textTransform: 'none', ...sx }}>
      <RichTextSubtitle sx={{ marginBottom: 0 }} component={'h6'} typography={typography}>
        {title}
      </RichTextSubtitle>
      <Typography component={'p'} sx={{ ...typography.notesAnnotations.captionBlack }}>
        {excerpt}
      </Typography>
    </Button>
  )
}
