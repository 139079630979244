import { Box, useTheme } from '@mui/material'
import { PropsWithChildren } from 'react'

export default function SimpleCardBody(props: PropsWithChildren): JSX.Element {
  const { children } = props

  const { typography } = useTheme()

  return <Box sx={{ ...typography.body2.onSurface.body2.light }}>{children}</Box>
}
