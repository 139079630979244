import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'
import { useCallback, useMemo, useRef, useState } from 'react'
import { UseControllerProps, useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { datePickerZIndex } from '../../../../constants'
import boolValue from '../../../../utils/boolValue'
import parseDate from '../../../../utils/dates/parseDate'
import simpleRandomString from '../../../../utils/simpleRandomString'
import substituteStrings from '../../../../utils/substituteStrings'
import { IPlainReportField } from '../types'
import compileExp from '../utils/compileExp'
import getFormFieldError from '../utils/getFormFieldError'
import { useUserReportContext } from './UserReportContext'

interface ReportFieldProps {
  className?: string
  field: IPlainReportField
  initialValue: string | string[] | number | Date | undefined
  isReadonly?: boolean
}

export default function ReportField(props: ReportFieldProps) {
  const { className, field, initialValue, isReadonly = false } = props
  const { control, formState, getFieldState } = useFormContext()
  const { error, isTouched } = getFieldState(field.name, formState)
  const { t } = useTranslation()
  const errorMessage = useMemo(() => {
    return isTouched ? getFormFieldError(field?.name, field, error, t, true) : ''
  }, [error, field, isTouched, t])
  const showError = Boolean(isTouched && error)

  const { variables } = useUserReportContext()
  const variablesRef = useRef(variables)
  variablesRef.current = variables

  const rules: UseControllerProps['rules'] = useMemo(() => {
    const rules: UseControllerProps['rules'] = {}
    if (field.isRequired) {
      rules.required = true
    }
    if (field.dataType === 'Text' && typeof field.maxLength === 'number') {
      rules.maxLength = field.maxLength
    }
    if (field.dataType === 'Number' || (field.dataType === 'Integer' && typeof field.maxValue === 'number')) {
      rules.max = field.maxValue
    }
    if (field.dataType === 'Number' || (field.dataType === 'Integer' && typeof field.minValue === 'number')) {
      rules.min = field.minValue
    }
    if (field.validations?.length) {
      // custom validation expressions
      const validations = field.validations
      const tests = validations.map((v) => compileExp(v.condition))
      rules.validate = (value) => {
        const vars = { ...variablesRef.current, value }
        for (let i = 0; i < validations.length; i++) {
          const { message } = validations[i]
          const test = tests[i]
          const result = test?.(vars)
          if (result) {
            // field is invalid, compute the error message by replacing the variables in format ${var}
            return substituteStrings(message || '', vars)
          }
        }
      }
    }
    return rules
  }, [field.dataType, field.isRequired, field.maxLength, field.maxValue, field.minValue, field.validations])

  const [id] = useState(() => simpleRandomString())

  const defaultValue = useMemo(() => {
    switch (field.dataType) {
      case 'Text':
      case 'Hidden': {
        return String(initialValue ?? '')
      }
      case 'Picklist': {
        if (field.isMultiple) {
          if (Array.isArray(initialValue)) {
            return initialValue
          }
          return String(initialValue ?? '')
            .split(/\s+[,;]\s+/)
            .filter(Boolean)
        } else {
          return initialValue || null
        }
      }
      case 'Number':
      case 'Integer': {
        if (initialValue !== undefined && initialValue !== null) {
          const n = Number(initialValue)
          return isNaN(n) ? '' : n
        } else {
          return ''
        }
      }
      case 'Tickbox':
        return boolValue(initialValue)
      case 'Date': {
        if (initialValue) {
          const jsDate =
            typeof initialValue === 'number'
              ? new Date(initialValue * 1000)
              : Array.isArray(initialValue)
              ? null
              : parseDate(initialValue)
          return jsDate ? DateTime.fromJSDate(jsDate) : null
        } else {
          return null
        }
      }
    }
    return null
  }, [field.dataType, field.isMultiple, initialValue])

  const {
    field: { onChange, onBlur, value },
  } = useController({
    name: field.name,
    control,
    rules,
    defaultValue,
  })

  const handlePicklistCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const checkboxValue = event.target.value
      const selectedValues = Array.isArray(value) ? value : []
      const wasSelected = selectedValues.includes(checkboxValue)
      if (wasSelected) {
        onChange(selectedValues.filter((item) => item !== checkboxValue))
      } else {
        const exclusiveOptions = new Set(
          field.picklist?.options.filter((option) => option.isExclusive).map((o) => o.value)
        )
        if (exclusiveOptions.has(checkboxValue)) {
          onChange([checkboxValue])
        } else {
          onChange([...selectedValues, checkboxValue].filter((v) => !exclusiveOptions.has(v)))
        }
      }
    },
    [field.picklist?.options, onChange, value]
  )

  // if we disable a field, the default style is to make it greyed out, which is not very good for viewing
  // so we instead provide a undefined onChange handler, which effectively makes the field readonly
  // however, we must make sure the value is not undefined, otherwise the field will be uncontrolled
  const disabled = false
  const noChange = isReadonly || field.isReadonly

  switch (field.dataType) {
    case 'Text':
      return (
        <div className={className} style={field.style}>
          <TextField
            disabled={disabled}
            error={showError}
            fullWidth
            helperText={errorMessage}
            InputProps={{ readOnly: noChange }}
            label={field.label}
            maxRows={field.isMultiline && field.maxRows ? field.maxRows : undefined}
            minRows={field.isMultiline && field.minRows ? field.minRows : undefined}
            multiline={Boolean(field.isMultiline)}
            name={field.name}
            onBlur={onBlur}
            onChange={noChange ? undefined : onChange}
            required={field.isRequired}
            value={value ?? ''}
          />
        </div>
      )
    case 'Number':
    case 'Integer':
      return (
        <div className={className} style={field.style}>
          <TextField
            disabled={disabled}
            error={showError}
            fullWidth
            helperText={errorMessage}
            InputProps={{ readOnly: noChange }}
            inputProps={{ step: field.dataType === 'Integer' ? 1 : 0.1 }}
            label={field.label}
            name={field.name}
            onBlur={onBlur}
            onChange={noChange ? undefined : onChange}
            required={field.isRequired}
            type="number"
            value={value ?? ''}
          />
        </div>
      )
    case 'Date':
      return (
        <div className={className} style={field.style}>
          <DatePicker
            disabled={disabled || noChange}
            label={field.label}
            onChange={noChange ? undefined : onChange}
            value={value ?? null}
            slotProps={{
              textField: { error: showError, fullWidth: true, helperText: errorMessage },
              popper: { style: { zIndex: datePickerZIndex } },
            }}
          />
        </div>
      )
    case 'Picklist':
      if (field.isMultiple) {
        return (
          <div className={className} style={field.style}>
            <FormControl
              component="fieldset"
              disabled={disabled}
              error={showError}
              fullWidth
              required={field.isRequired}
            >
              <FormLabel component="legend">{field.label}</FormLabel>
              <FormGroup>
                {field.picklist?.options?.map((option) => {
                  const checked = Boolean(Array.isArray(value) && value.indexOf(option.value) > -1)
                  return (
                    <FormControlLabel
                      key={option.value}
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={noChange ? undefined : handlePicklistCheckboxChange}
                          value={option.value}
                        />
                      }
                      label={option.label}
                    />
                  )
                })}
              </FormGroup>
              {errorMessage ? <FormHelperText>{errorMessage}</FormHelperText> : null}
            </FormControl>
          </div>
        )
      } else {
        return (
          <div className={className} style={field.style}>
            <FormControl disabled={disabled} error={showError} fullWidth required={field.isRequired}>
              <FormLabel id={id}>{field.label}</FormLabel>
              <RadioGroup aria-labelledby={id} value={value ?? []} onChange={noChange ? undefined : onChange}>
                {field.picklist?.options?.map((option) => {
                  return (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={<Radio />}
                      label={option.label}
                    />
                  )
                })}
              </RadioGroup>
              {errorMessage ? <FormHelperText>{errorMessage}</FormHelperText> : null}
            </FormControl>
          </div>
        )
      }
    case 'Tickbox': {
      return (
        <div className={className} style={field.style}>
          <FormControl error={showError} fullWidth>
            {field.label ? (
              <FormControlLabel
                disabled={disabled}
                required={field.isRequired}
                control={<Checkbox checked={Boolean(value)} onChange={noChange ? undefined : onChange} />}
                label={field.label}
              />
            ) : (
              <Checkbox
                checked={Boolean(value)}
                disabled={disabled}
                onChange={noChange ? undefined : onChange}
                required={field.isRequired}
              />
            )}
            {errorMessage ? <FormHelperText error={true}>{errorMessage}</FormHelperText> : null}
          </FormControl>
        </div>
      )
    }
    case 'Hidden': {
      // no need to render hidden fields; the value is passed to the controller
      break
    }
  }
  return null
}
