import { Palette } from '@mui/material'
import React from 'react'

interface IOverlineTypography {
  light: {
    highEmphasis: React.CSSProperties
    mediumEmphasis: React.CSSProperties
  }
  dark: {
    highEmphasis: React.CSSProperties
    mediumEmphasis: React.CSSProperties
    disabled: React.CSSProperties
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    overline: IOverlineTypography
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    overline: IOverlineTypography
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    overline: true
  }
}

interface IOverlineTheme {
  overline: IOverlineTypography
}

export const overlineTypography = (palette: Palette): IOverlineTheme => ({
  overline: {
    light: {
      highEmphasis: {
        fontFamily: 'droid-sans',
        fontWeight: 400,
        fontSize: '10px',
        letterSpacing: 1.5,
        lineHeight: 1.6,
        textTransform: 'uppercase',
        color: palette.onSurface.light_87,
      },
      mediumEmphasis: {
        fontFamily: 'droid-sans',
        fontWeight: 400,
        fontSize: '10px',
        letterSpacing: 1.5,
        lineHeight: 1.6,
        textTransform: 'uppercase',
        color: palette.onSurface.light_60,
      },
    },
    dark: {
      highEmphasis: {
        fontFamily: 'droid-sans',
        fontWeight: 400,
        fontSize: '10px',
        letterSpacing: 1.5,
        lineHeight: 1.6,
        textTransform: 'uppercase',
        color: palette.onSurface.dark_87,
      },
      mediumEmphasis: {
        fontFamily: 'droid-sans',
        fontWeight: 400,
        fontSize: '10px',
        letterSpacing: 1.5,
        lineHeight: 1.6,
        textTransform: 'uppercase',
        color: palette.onSurface.dark_60,
      },
      disabled: {
        fontFamily: 'droid-sans',
        fontWeight: 400,
        fontSize: '10px',
        letterSpacing: 1.5,
        lineHeight: 1.6,
        textTransform: 'uppercase',
        color: palette.onSurface.dark_38,
      },
    },
  },
})
