import { useUser } from '@auth0/nextjs-auth0/client'
import { useMemo } from 'react'

export enum USER_ROLES {
  coreTrainer = 'Core Trainer',
  learnerGuide = 'Learner Guide',
  learnerMentor = 'Learner Mentor',
}

export default function useRoles(): IUserRole[] {
  const { user } = useUser()
  console.log("USER DIRECTLY FROM AUTH0: ", user)
  return useMemo(() => user?.meta?.roles ?? [], [user?.meta?.roles])
}
