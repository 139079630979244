import { useMemo } from 'react'
import { ContentTypes } from '../../../../api/contentTypeIds'
import DynamicText from '../components/DynamicText'
import { RenderGuard } from '../components/RenderGuard'
import ReportField from '../components/ReportField'
import ReportFormGroup from '../components/ReportFormGroup'
import ReportInputTable from '../components/ReportInputTable'
import { useUserReportContext } from '../components/UserReportContext'
import { IPlainReportField, IPlainReportFormGroup, IPlainReportInputTable, IPlainReportNote } from '../types'
import walkFormGroupFields from '../utils/walkFormGroupFields'
import walkTableFields from '../utils/walkTableFields'
import useUnregisterField from './useUnregisterField'

export default function useFormElements(
  elements: (IPlainReportField | IPlainReportInputTable | IPlainReportNote | IPlainReportFormGroup)[] | undefined,
  isReadonly: boolean
) {
  const { userReport, variables } = useUserReportContext()
  const unregisterField = useUnregisterField()

  return useMemo(
    () =>
      elements?.map((elem) => {
        if (elem.contentType === ContentTypes.reportInputTable) {
          return (
            <RenderGuard
              condition={elem.isVisible}
              key={elem.id}
              onInvisible={() => {
                walkTableFields(elem, unregisterField) // clear values when table becomes invisible
              }}
            >
              <ReportInputTable className="w-full mb-4" isReadonly={isReadonly} table={elem} />
            </RenderGuard>
          )
        } else if (elem.contentType === ContentTypes.reportField) {
          const initialValue = userReport[elem.name]
          return (
            <RenderGuard
              condition={elem.isVisible}
              key={elem.id}
              onInvisible={() => {
                unregisterField(elem) // clear value when field becomes invisible
              }}
            >
              <ReportField className="mb-4" field={elem} initialValue={initialValue} isReadonly={isReadonly} />
            </RenderGuard>
          )
        } else if (elem.contentType === ContentTypes.reportNote) {
          return (
            <RenderGuard condition={elem.isVisible} key={elem.id}>
              <DynamicText
                className="mb-4"
                expression={elem.textExpression}
                style={elem.style}
                variables={variables}
                variant={elem.variant}
              />
            </RenderGuard>
          )
        } else if (elem.contentType === ContentTypes.reportFormGroup)
          return (
            <RenderGuard
              condition={elem.isVisible}
              key={elem.id}
              onInvisible={() => {
                walkFormGroupFields(elem, unregisterField) // clear values when table becomes invisible
              }}
            >
              <ReportFormGroup className="mb-4" group={elem} isReadonly={isReadonly} />
            </RenderGuard>
          )
        else {
          return null
        }
      }),
    [elements, isReadonly, unregisterField, userReport, variables]
  )
}
