import { Components } from '@mui/material'
import getRootElement from '../getRootElement'

const MuiPopper: Components['MuiPopper'] = {
  defaultProps: {
    container: getRootElement(),
  },
}

export default MuiPopper
