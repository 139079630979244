import { Check } from '@mui/icons-material'
import { List, ListItemButton, ListItemIcon } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { Locales } from './constants'
import useChangeLanguage from './useChangeLanguage'

export default function LanguageMenu({ didSelect }: { didSelect?: (newLanguage: string) => void }): JSX.Element {
  const { language, changeLanguage } = useChangeLanguage()

  const handleSelect = useCallback(
    (newLanguage: string) => {
      changeLanguage(newLanguage)
      didSelect?.(newLanguage)
    },
    [changeLanguage, didSelect]
  )

  const { t } = useTranslation('common')

  return (
    <List sx={{ pt: 0 }}>
      {Locales.map((locale) => {
        const selected = language === locale.code
        const name = t(locale.name)
        return (
          <ListItemButton dense={false} key={locale.code} onClick={() => handleSelect(locale.code)} selected={selected}>
            <ListItemIcon>{selected ? <Check /> : null}</ListItemIcon>
            {name}
          </ListItemButton>
        )
      })}
    </List>
  )
}
