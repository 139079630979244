import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { betterWorldGroupsByTrainerIdQueryKey } from '../../../../api/constants'
import { myBetterWorldGroupsEndpoint } from '../../../../api/endpoints'
import { IMyBetterWorldGroupDto } from '../../../../api/types'

interface IUseGetBetterWorldGroupsByTrainerIdProps {
  trainer_id: string
}

export default function useGetBetterWorldGroupsByTrainerId(props: IUseGetBetterWorldGroupsByTrainerIdProps) {
  return useQuery({
    queryKey: betterWorldGroupsByTrainerIdQueryKey(props.trainer_id),
    queryFn: async (): Promise<IMyBetterWorldGroupDto[] | undefined> => {
      const resp = await axios.get<{ data: IMyBetterWorldGroupDto[] }>(myBetterWorldGroupsEndpoint, {
        params: {
          trainer_id: props.trainer_id,
        },
      })
      return resp.data.data
    },
  })
}
