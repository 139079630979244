import FlagIcon from '@mui/icons-material/Flag'
import GpsFixedIcon from '@mui/icons-material/GpsFixed'
import MailIcon from '@mui/icons-material/Mail'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import SchoolIcon from '@mui/icons-material/School'
import { Button } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { IAssociatedGuide } from '../../../../api/types'
import { insightsLearnerGuideRoute } from '../../../../constants'
import useGetProfile from '../../../../hooks/requests/useGetProfile'
import useRelativeDateFormatter from '../../../../hooks/useRelativeDateFormatter'
import AuthLayout from '../../../AuthLayout'
import PopupAppBar from '../../../CamfedAppBar/PopupAppbar'
import Divider from '../../../Common/Divider'
import ListSectionTitle from '../../../Common/ListSectionTitle'
import useShowMessage from '../../../Common/useShowMessage'
import { INavigator, IScreenProps } from '../../../Navigation/types'
import useNavigator from '../../../Navigation/useNavigator'
import SimpleActionButton from '../../../SimpleActionButton'
import IconTextRow from '../../Account/Common/IconTextRow'
import useGetBetterWorldGroupsByTrainerId from '../../MyBetterWorldGroups/hooks/useGetBetterWorldGroupsByTrainerId'
import SocialSupportCaseListItem from '../../SocialSupportCases/OverviewPage/SocialSupportCaseListItem'
import useGetSocialSupportCases from '../../SocialSupportCases/hooks/useGetSocialSupportCases'
import GroupsSection from './GroupsSection'

interface MyGuideDetailsPageProps extends IScreenProps<{ guide: IAssociatedGuide }> {}

const MyGuideDetailsPageInner = ({ guide }: { guide: IAssociatedGuide }) => {
  const navigator: INavigator = useNavigator()
  const { t } = useTranslation('my-guides')
  const { t: tCommon } = useTranslation('common')
  const { t: tProfile } = useTranslation('account-profile')
  const { t: tSocialSupportCases } = useTranslation('social-support-cases')

  const { data: betterWorldGroups } = useGetBetterWorldGroupsByTrainerId({ trainer_id: guide.auth0_id })

  const { data: socialSupportCases } = useGetSocialSupportCases(guide.auth0_id ?? '', false)

  const { data: profileData, isLoading: isLoadingProfileData } = useGetProfile({ userId: guide.auth0_id })
  const formatRelativeDate = useRelativeDateFormatter()

  const formattedLoginDate = formatRelativeDate(profileData?.last_login)
  const lastLoginDate = isLoadingProfileData
    ? 'Loading...'
    : Boolean(formattedLoginDate)
    ? formattedLoginDate
    : 'User has not logged in yet'

  const { messageDialog, showMessage } = useShowMessage()

  const handleEmailClick = useCallback(() => {
    if (guide.data.email) {
      showMessage({
        buttons: [
          {
            text: tCommon('send') ?? '',
            href: `mailto:${guide.data.email}`,
          },
          {
            text: tCommon('cancel') ?? '',
            isCancel: true,
          },
        ],
        message: t('emailGuideMessage', { name: guide.data.fullName, email: guide.data.email }) ?? '',
        title: t('emailGuide') ?? '',
      })
    }
  }, [guide.data.email, guide.data.fullName, showMessage, t, tCommon])

  const handlePhoneClick = useCallback(() => {
    if (guide.data.phoneNumber) {
      showMessage({
        buttons: [
          {
            text: tCommon('call') ?? '',
            href: `tel:${guide.data.phoneNumber.replace(/\s/g, '')}`,
          },
          {
            text: tCommon('cancel') ?? '',
            isCancel: true,
          },
        ],
        message: t('callGuideMessage', { name: guide.data.fullName, phone: guide.data.phoneNumber }) ?? '',
        title: t('callGuide') ?? '',
      })
    }
  }, [guide.data.fullName, guide.data.phoneNumber, showMessage, t, tCommon])

  const handleInsightsClick = useCallback(() => {
    navigator.push(insightsLearnerGuideRoute([guide.auth0_id], true))
  }, [navigator, guide])

  return (
    <AuthLayout
      appBar={<PopupAppBar centralText={guide.data.fullName ?? ''} onClose={navigator.pop} prominent={true} />}
    >
      <div className="flex flex-col py-4">
        <div>
          <ListSectionTitle className="mx-4 mb-1">{t('guideActivity')}</ListSectionTitle>
          <IconTextRow label={t('lastActive')} detailText={lastLoginDate} icon={<PhoneIphoneIcon />} />
        </div>
        <Divider className="my-4" />
        <div>
          <ListSectionTitle className="mx-4 mb-1">{tProfile('contactDetails')}</ListSectionTitle>
          <IconTextRow
            label={tProfile('phoneNumber')}
            detailText={guide.data.phoneNumber}
            icon={<PhoneIphoneIcon />}
            onClick={handlePhoneClick}
          />
          <IconTextRow
            label={tProfile('emailAddress')}
            detailText={guide.data.email}
            icon={<MailIcon />}
            onClick={handleEmailClick}
          />
        </div>
        <Divider className="my-4" />
        <div>
          <ListSectionTitle className="mx-4 mb-1">{tProfile('locationDetails')}</ListSectionTitle>
          <IconTextRow label={tProfile('country')} detailText={guide.data.country} icon={<FlagIcon />} />
          <IconTextRow label={tProfile('district')} detailText={guide.data.district} icon={<GpsFixedIcon />} />
          <IconTextRow label={tProfile('school')} detailText={guide.data.school} icon={<SchoolIcon />} />
        </div>
        <GroupsSection groups={betterWorldGroups} />

        <Divider className="my-4" />
        <div>
          <ListSectionTitle className="mx-4 mb-1">{tSocialSupportCases('socialSupportCases')}</ListSectionTitle>

          {socialSupportCases?.map((supportCase) => (
            <SocialSupportCaseListItem
              key={supportCase.id}
              navigator={navigator}
              supportCase={supportCase}
              t={tSocialSupportCases}
            />
          ))}
        </div>

        <Divider className="my-4" />
        <div className="mx-2">
          <SimpleActionButton onClick={handleInsightsClick}>{tProfile('viewInsights')}</SimpleActionButton>
        </div>
        <Divider className="my-4" />
      </div>
      {messageDialog}
    </AuthLayout>
  )
}

const MyGuideDetailsPage = ({ data, navigator }: MyGuideDetailsPageProps) => {
  const guide = data?.guide
  const { t } = useTranslation('common')
  return guide ? (
    <MyGuideDetailsPageInner guide={guide} />
  ) : (
    <div>
      <h1>{t('oopsThereIsAnError')}</h1>
      <div>
        <Button onClick={navigator.pop} variant="contained">
          {t('back')}
        </Button>
      </div>
    </div>
  )
}

export default MyGuideDetailsPage
