import { ButtonBase, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { IResourceFields } from '../../@types/generated/contentful'
import { MaybeEntries, SimpleEntry } from '../../api/types'
import useMst from '../../models/useMst'
import getLinkedResourceRoute from '../../utils/getLinkedResourceRoute'
import ArticleCard from '../ArticleCard'
import SimpleCardBody from '../SimpleCardBody'
import SimpleCardHeader from '../SimpleCardHeader'

interface IRelatedResourcesProps {
  className?: string
  relatedResources: MaybeEntries<IResourceFields>
}

export default function RelatedResources(props: IRelatedResourcesProps): JSX.Element {
  const { className = '', relatedResources } = props
  const { typography } = useTheme()
  const { router } = useMst()
  const { appendRoute } = router
  const { t } = useTranslation('resource-library')

  const resources = useMemo(
    () =>
      relatedResources
        ?.filter((v): v is SimpleEntry<IResourceFields> => v.hasOwnProperty('fields'))
        .map((r, i) => {
          const onClick = () => {
            const type = r.sys.contentType.sys.id
            const id = r.sys.id
            const route = getLinkedResourceRoute({ id, type })
            if (route) {
              appendRoute(route)
            }
          }
          return (
            <ButtonBase className="mr-4 mb-4" sx={{ textAlign: 'start' }} key={i} onClick={onClick}>
              <ArticleCard className="w-full h-full">
                <SimpleCardHeader text={r.fields!.title} />
                <SimpleCardBody>{r.fields!.excerpt}</SimpleCardBody>
              </ArticleCard>
            </ButtonBase>
          )
        }),
    [appendRoute, relatedResources]
  )

  return (
    <div className={className}>
      {relatedResources && relatedResources.length > 0 ? (
        <Typography sx={{ ...typography.subtitle2.onSurface.light.mediumEmphasis, marginBottom: 1 }}>
          {t('relatedResources')}:
        </Typography>
      ) : null}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 items-stretch place-items-stretch -mr-4">
        {resources}
      </div>
    </div>
  )
}
