import { cast, flow, getSnapshot, Instance, SnapshotIn, types } from 'mobx-state-tree'
import { logError } from '../components/Common/loggers'
import { ScreenTransitionDuration } from '../constants'
import simpleRandomString from '../utils/simpleRandomString'
import sleep from '../utils/sleep'

export enum TransitionStyle {
  slideUp = 'slideUp',
  slideToLeft = 'slideToLeft', // this is the default
}

const RouteState = types.model({
  id: types.string,
  visible: types.optional(types.boolean, true),
  prominent: types.boolean,
  data: types.maybe(types.frozen<unknown>()),
})

const PersistedRouteState = types.snapshotProcessor(RouteState, {
  postProcessor(sn: SnapshotIn<typeof RouteState>) {
    const ret = {
      ...sn,
      visible: true, // always set visible to true when restoring from snapshot
    }
    return ret
  },
})

export interface IRouteState extends SnapshotIn<typeof RouteState> {}

export const Route = types.model({
  key: types.maybe(types.string),
  name: types.string,
  state: PersistedRouteState,
  transitionStyle: types.maybe(types.string),
})

export interface IRoute extends SnapshotIn<typeof Route> {}

type RoutePredicate = (r: IRoute) => boolean
type RouteSpecifier = number | string | IRoute | RoutePredicate

const Router = types
  .model({
    root: Route,
    routes: types.array(Route),
  })
  .views((self) => ({
    get topRoute() {
      return self.routes[self.routes.length - 1]
    },
  }))
  .actions((self) => ({
    pushHistory() {
      window.history.pushState(getSnapshot(self), document.title, window.location.href)
    },
    replaceHistory() {
      if (history.state === null) {
        window.history.pushState(getSnapshot(self), document.title, window.location.href)
      } else {
        window.history.replaceState(getSnapshot(self), document.title, window.location.href)
      }
    },
  }))
  .actions((self) => {
    let poppingHistory = false
    return {
      applySnapshot: (snapshot: IRouter) => {
        try {
          if (!snapshot || typeof snapshot !== 'object' || !('routes' in snapshot)) {
            // if the snapshot is invalid, push a new history state
            // the state will be invalid if user is trying to navigate away from the app
            // this effectively prevents user from navigating away
            self.pushHistory()
          } else if (poppingHistory) {
            // ignore the snapshot if it's the result of popHistory()
            poppingHistory = false
          } else {
            // apply the snapshot
            self.root = cast(snapshot.root)
            self.routes = cast(snapshot.routes)
          }
        } catch (ex) {
          logError('Failed to apply snapshot to router', snapshot, ex)
          self.pushHistory()
        }
      },
      popHistory() {
        poppingHistory = true
        history.back() // pop history, the popped state should be the same as the current snapshot
        setTimeout(() => {
          if (!history.state || !('routes' in history.state)) {
            // make sure we don't reach the bottom of the history stack
            window.history.pushState(getSnapshot(self), document.title, window.location.href)
          }
        }, 1)
      },
    }
  })
  .actions((self) => ({
    _popRoute() {
      const removed = self.routes.slice(0, self.routes.length - 1)
      self.routes = cast(removed)
    },
    _findRouteIndex(route: RouteSpecifier) {
      let index = -1
      if (typeof route === 'number') {
        if (route >= 0 && route < self.routes.length) {
          index = route
        }
      } else if (typeof route === 'string') {
        index = self.routes.findIndex((r) => r.name === route)
      } else if (typeof route === 'function') {
        index = self.routes.findIndex(route)
      } else {
        index = self.routes.findIndex((r) => r.name === route.name)
      }
      return index
    },
  }))
  .actions((self) => ({
    appendRoute: flow(function* (route: IRoute) {
      self.routes.push({ ...route, key: simpleRandomString() })
      self.pushHistory()
      yield sleep(ScreenTransitionDuration)
    }),
    popRoute: flow(function* () {
      // pop the top most route and animate it out
      const copy = [...self.routes]
      if (copy.length > 0) {
        copy[copy.length - 1].state.visible = false
      }
      self.routes = cast(copy)
      yield sleep(ScreenTransitionDuration)
      self._popRoute()
      self.popHistory()
    }),
    removeRoute(route: RouteSpecifier) {
      // remove a route without animating it out
      const idx = self._findRouteIndex(route)
      if (idx > -1) {
        const copy = [...self.routes]
        copy.splice(idx, 1)
        self.routes = cast(copy)
        self.replaceHistory()
      }
    },
    removeRouteRange(start: number, count: number) {
      // sliently remove routes without animating them out
      if (start < 0 || start >= self.routes.length) return
      const copy = [...self.routes]
      copy.splice(start, count)
      self.routes = cast(copy)
      self.replaceHistory()
    },
    bringToTop: flow(function* (route: RouteSpecifier) {
      let idx = self._findRouteIndex(route)
      if (idx < 0 || idx >= self.routes.length - 1) return // out of range or already at top

      const copy = [...self.routes]
      const [target] = copy.splice(idx, 1)
      copy.push(target)
      self.routes = cast(copy)
      self.replaceHistory()
    }),
    findRoute(route: RouteSpecifier) {
      return self.routes[self._findRouteIndex(route)]
    },

    replaceRoot(route: IRoute) {
      self.root = cast({ ...route, key: simpleRandomString() })
      self.replaceHistory()
    },
  }))
  .actions((self) => ({
    popToRoute: flow(function* (route: RouteSpecifier) {
      let idx = self._findRouteIndex(route)
      if (idx < 0 || idx >= self.routes.length - 1) return // out of range

      if (idx < self.routes.length - 2) {
        // remove the routes between the top most route and the target route
        self.removeRouteRange(idx + 1, self.routes.length - idx - 2)
      }

      // pop the top most route and animate it out
      yield self.popRoute()
    }),
    pushRouteOrBringToTop: flow(function* (route: IRoute) {
      let idx = self._findRouteIndex((r) => r.name === route.name)
      if (idx < 0) {
        // route not found, append it
        yield self.appendRoute(route)
      } else {
        // route found, bring it to top
        yield self.bringToTop(idx)
      }
    }),
    replaceTopRoute: flow(function* (route: IRoute) {
      // top route to be replaced
      const topRoute = self.topRoute
      // push the new route and animate it in
      yield self.appendRoute(route)
      // remove the previous top route (without animation)
      self.routes = cast([...self.routes].filter((r) => r !== topRoute))
      self.replaceHistory() // appendRoute() already pushed history, so we need to replace it
    }),
    resetRoutes: flow(function* () {
      const copy = self.routes.map((r) => ({ ...r, state: { ...r.state, visible: false } }))
      self.routes = cast(copy)
      yield sleep(ScreenTransitionDuration)
      self.routes = cast([])
      self.pushHistory()
    }),
  }))

export interface IRouter extends Instance<typeof Router> {}

export default Router
