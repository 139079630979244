import { Palette } from '@mui/material'

interface ICaptionTypography {
  onSurface: {
    light: {
      highEmphasis: React.CSSProperties
      mediumEmphasis: React.CSSProperties
    }
  }
  white: {
    highEmphasis: React.CSSProperties
    mediumEmphasis: React.CSSProperties
    disabled: React.CSSProperties
  }
  onPrimary: {
    dark: {
      highEmphasis: React.CSSProperties
      mediumEmphasis: React.CSSProperties
      disabled: React.CSSProperties
    }
  }
  primary: {
    light: {
      highEmphasis: React.CSSProperties
    }
    dark: {
      highEmphasis: React.CSSProperties
      mediumEmphasis: React.CSSProperties
      disabled: React.CSSProperties
    }
  }
  error: {
    light: React.CSSProperties
    dark: React.CSSProperties
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    caption: ICaptionTypography
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    caption: ICaptionTypography
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    caption: true
  }
}

interface ICaptionTheme {
  caption: ICaptionTypography
}

export const captionTypography = (palette: Palette): ICaptionTheme => ({
  caption: {
    onSurface: {
      light: {
        highEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 400,
          fontSize: '12px',
          letterSpacing: 0.4,
          lineHeight: 1.333,
          color: palette.onSurface.light_87,
        },
        mediumEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 400,
          fontSize: '12px',
          letterSpacing: 0.4,
          lineHeight: 1.333,
          color: palette.onSurface.light_60,
        },
      },
    },

    white: {
      highEmphasis: {
        fontFamily: 'droid-sans',
        fontWeight: 400,
        fontSize: '12px',
        letterSpacing: 0.4,
        lineHeight: 1.333,
        color: 'rgba(255, 255, 255, 0.87)',
      },
      mediumEmphasis: {
        fontFamily: 'droid-sans',
        fontWeight: 400,
        fontSize: '12px',
        letterSpacing: 0.4,
        lineHeight: 1.333,
        color: 'rgba(255, 255, 255, 0.6)',
      },
      disabled: {
        fontFamily: 'droid-sans',
        fontWeight: 400,
        fontSize: '12px',
        letterSpacing: 0.4,
        lineHeight: 1.333,
        color: 'rgba(255, 255, 255, 0.38)',
      },
    },
    onPrimary: {
      dark: {
        highEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 400,
          fontSize: '12px',
          letterSpacing: 0.4,
          lineHeight: 1.333,
          color: palette.onPrimary.dark_87,
        },
        mediumEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 400,
          fontSize: '12px',
          letterSpacing: 0.4,
          lineHeight: 1.333,
          color: palette.onPrimary.dark_74,
        },
        disabled: {
          fontFamily: 'droid-sans',
          fontWeight: 400,
          fontSize: '12px',
          letterSpacing: 0.4,
          lineHeight: 1.333,
          color: palette.onPrimary.dark_38,
        },
      },
    },
    primary: {
      light: {
        highEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 400,
          fontSize: '12px',
          letterSpacing: 0.4,
          lineHeight: 1.333,
          color: palette.primary.color500,
        },
      },
      dark: {
        highEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 400,
          fontSize: '12px',
          letterSpacing: 0.4,
          lineHeight: 1.333,
          color: palette.primary.color200,
        },
        mediumEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 400,
          fontSize: '12px',
          letterSpacing: 0.4,
          lineHeight: 1.333,
          color: palette.primary.color200_60,
        },
        disabled: {
          fontFamily: 'droid-sans',
          fontWeight: 400,
          fontSize: '12px',
          letterSpacing: 0.4,
          lineHeight: 1.333,
          color: palette.primary.color200_38,
        },
      },
    },
    error: {
      light: {
        fontFamily: 'droid-sans',
        fontWeight: 400,
        fontSize: '12px',
        letterSpacing: 0.4,
        lineHeight: 1.333,
        color: palette.error.light,
      },
      dark: {
        fontFamily: 'droid-sans',
        fontWeight: 400,
        fontSize: '12px',
        letterSpacing: 0.4,
        lineHeight: 1.333,
        color: palette.error.dark,
      },
    },
  },
})
