import { Box, ButtonBase, Typography, useTheme } from '@mui/material'
import { ReactNode } from 'react'

const IconTextRow = ({
  actionText,
  caption,
  className = '',
  detailText,
  icon,
  label,
  onClick,
}: {
  actionText?: ReactNode
  caption?: ReactNode
  className?: string
  detailText: ReactNode
  icon?: ReactNode
  label: ReactNode
  onClick?: () => void
}) => {
  const { palette, typography } = useTheme()

  const content = (
    <>
      {icon ? (
        <Box className="w-14" sx={{ color: palette.primary.color500 }}>
          {icon}
        </Box>
      ) : null}
      <div className="flex-1 flex flex-col pr-1.5">
        <div className="flex justify-between items-center">
          <Typography
            className="flex-1"
            variant="subtitle1"
            sx={{ ...typography.subtitle1.onSurface.light.highEmphasis }}
          >
            {label}
          </Typography>
          <div className="flex flex-row items-center">
            {caption ? (
              <Typography className="ml-2" component="div" sx={{ ...typography.caption.onSurface.light.highEmphasis }}>
                {caption}
              </Typography>
            ) : null}
            {actionText ? (
              <Typography className="ml-2" component="div" sx={{ ...typography.body2.primary.light }}>
                {actionText}
              </Typography>
            ) : null}
          </div>
        </div>
        <Typography component="div" sx={{ ...typography.body1.onSurface.light.mediumEmphasis }}>
          {detailText}
        </Typography>
      </div>
    </>
  )

  const rootClass = `w-full flex flex-row text-start items-start px-4 py-2 ${className}`

  if (onClick) {
    return (
      <ButtonBase className={rootClass} onClick={onClick}>
        {content}
      </ButtonBase>
    )
  } else {
    return <div className={rootClass}>{content}</div>
  }
}

export default IconTextRow
