import { useMemo } from 'react'
import { IReportFields } from '../../../../@types/generated/contentful'
import { ContentTypes } from '../../../../api/contentTypeIds'
import useGetAllEntries from '../../../../hooks/requests/useGetAllEntries'
import { IPlainReport } from '../types'
import toPlainReport from '../utils/toPlainReport'

export default function useGetReports(country: string | undefined) {
  // Search the entries
  const { data, error, fetchStatus, isError, isLoading, isSuccess } = useGetAllEntries<IReportFields>(
    ContentTypes.report,
    {
      'fields.countries': country,
      include: 5, // so that nested tables and fields are included in one request
    },
    Boolean(country)
  )

  return useMemo(
    () => ({
      data: data?.map(toPlainReport).filter((r): r is IPlainReport => Boolean(r)),
      error,
      fetchStatus,
      isError,
      isLoading,
      isSuccess,
    }),
    [data, error, fetchStatus, isError, isLoading, isSuccess]
  )
}
