import React, { useEffect, useState } from 'react'

export default function NoSSR({ children }: React.PropsWithChildren<{}>): JSX.Element | null {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])

  if (mounted) {
    return <>{children}</>
  } else return null
}
