import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton, Menu, useTheme } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { menuZIndex } from '../../constants'
import simpleRandomString from '../../utils/simpleRandomString'

export default function useMoreMenu(renderMenuContent: (close: () => void) => React.ReactNode) {
  const { palette } = useTheme()

  const [menuId] = useState(simpleRandomString())
  const [buttonId] = useState(simpleRandomString())
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const visible = Boolean(anchorEl)

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const button = (
    <IconButton
      aria-controls={visible ? menuId : undefined}
      aria-expanded={visible ? 'true' : undefined}
      aria-haspopup="true"
      id={buttonId}
      onClick={handleClick}
      sx={{ color: palette.onPrimary.light_74 }}
    >
      <MoreVertIcon />
    </IconButton>
  )

  const menu = (
    <>
      {button}
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={visible}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': buttonId,
        }}
        sx={{ zIndex: menuZIndex }}
      >
        {renderMenuContent(handleClose)}
      </Menu>
    </>
  )

  return { menu }
}
