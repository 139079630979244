import { FormHelperText } from '@mui/material'

interface ErrorListProps {
  className?: string
  errors?: string[] | undefined
}

export default function ErrorList(props: ErrorListProps) {
  const { className = '', errors } = props

  if (!errors?.length) {
    return null
  }

  return errors.length == 1 ? (
    <FormHelperText className={className} error>
      {errors[0]}
    </FormHelperText>
  ) : (
    <ul className={`mt-0 pl-6 ${className}`}>
      {errors.map((error, index) => (
        <li key={index}>
          <FormHelperText error>{error}</FormHelperText>
        </li>
      ))}
    </ul>
  )
}
