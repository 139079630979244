import { useUser } from '@auth0/nextjs-auth0/client'
import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { IResourceFields } from '../../../@types/generated/contentful'
import { SimpleEntry } from '../../../api/types'
import useGetProfile from '../../../hooks/requests/useGetProfile'
import useDebouncedValue from '../../../hooks/useDebouncedValue'
import { useAnalytics } from '../../Analytics/AnalyticsContext'
import { useToggleValues } from '../FilterView/useToggleValues'
import useResourceLibrary from '../ResourceList/useResourceLibrary'

export type ResourceLibraryContextType = {
  clearSelectedLanguages: () => void
  clearSelectedTypeTags: () => void
  keywords: string
  onKeywordsChange: (value: string) => void
  resources: SimpleEntry<IResourceFields>[]
  selectedLanguages: Set<string>
  selectedTypeTags: Set<string>
  setDebouncedKeywords: (value: string) => void
  toggleLanguage: (code: string) => void
  toggleTypeTag: (id: string) => void
}

const keywordsMatcher = (keywords: string | null | undefined) => {
  const terms = (keywords?.split(/\s+/) ?? []).map((t) => t.toLowerCase())

  return (resource: SimpleEntry<IResourceFields>) => {
    const { fields } = resource
    const { title, excerpt, body } = fields ?? {}

    const search = `${title} ${excerpt} ${body}`.toLowerCase()

    return terms.every((t) => search.includes(t))
  }
}

export const ResourceLibraryContext = createContext<ResourceLibraryContextType>({
  clearSelectedLanguages: () => { },
  clearSelectedTypeTags: () => { },
  keywords: '',
  onKeywordsChange: () => { },
  resources: [],
  selectedLanguages: new Set(),
  selectedTypeTags: new Set(),
  setDebouncedKeywords: () => { },
  toggleLanguage: () => { },
  toggleTypeTag: () => { },
})

export const ResourceLibraryContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [keywords, setKeywords] = useState<string>('')

  const onKeywordsChange = useCallback((value: string) => {
    setKeywords(value)
  }, [])

  // delay keyword search to save some CPU power
  const { debouncedValue: debouncedKeywords, setValue: setDebouncedKeywords } = useDebouncedValue(keywords, 500)

  // for tracked keywords, we want to wait a bit longer
  const { debouncedValue: trackedKeywords } = useDebouncedValue(keywords, 3000)

  // track keyword search
  const { trackSearchResource } = useAnalytics()
  useEffect(() => {
    if (trackedKeywords) {
      trackSearchResource(trackedKeywords)
    }
  }, [trackedKeywords, trackSearchResource])

  const { clearAll: clearSelectedTypeTags, values: selectedTypeTags, toggleValue: toggleTypeTag } = useToggleValues()
  const { clearAll: clearSelectedLanguages, values: selectedLanguages, toggleValue: toggleLanguage } = useToggleValues()
  const { data } = useResourceLibrary()
  const { data: profileData } = useGetProfile()
  const u = useUser()
  const country = u.user?.meta?.country

  const filteredResources = useMemo(() => {
    const matchKeywords = keywordsMatcher(debouncedKeywords)
    const filtered = (data ?? []).filter((resource) => {
      const resTags = resource.metadata.tags

      const matchesLangTag =
        resTags.length === 0
          ? true
          : resTags.some((t) => {
            const v = t.sys.id
            // If there are no country tags, return true
            if (!v.includes('country')) {
              return true
              // If there is a country tag, return true if it matches the country
            } else {
              return v === `country${country}`
            }
          })
      // Filter out resources that don't match the country tag
      if (country && !matchesLangTag) {
        return false
      } else {
        const matchingType = !selectedTypeTags.size || resTags.some((tag) => selectedTypeTags.has(tag.sys.id))
        const matchingLanguage = !selectedLanguages.size || resTags.some((tag) => selectedLanguages.has(tag.sys.id))
        const matchingKeywords = matchKeywords(resource)

        return matchingType && matchingLanguage && matchingKeywords
      }
    })
    return filtered
  }, [debouncedKeywords, data, selectedTypeTags, selectedLanguages, country])

  const value: ResourceLibraryContextType = useMemo(
    () => ({
      clearSelectedLanguages,
      clearSelectedTypeTags,
      keywords: keywords,
      onKeywordsChange,
      resources: filteredResources,
      selectedLanguages,
      selectedTypeTags,
      setDebouncedKeywords,
      toggleLanguage,
      toggleTypeTag,
    }),
    [
      clearSelectedLanguages,
      clearSelectedTypeTags,
      keywords,
      onKeywordsChange,
      filteredResources,
      selectedLanguages,
      selectedTypeTags,
      setDebouncedKeywords,
      toggleLanguage,
      toggleTypeTag,
    ]
  )

  return <ResourceLibraryContext.Provider value={value}>{children}</ResourceLibraryContext.Provider>
}

export const useResourceLibraryContext = () => {
  return useContext(ResourceLibraryContext)
}
