import { FormHelperText, FormLabel } from '@mui/material'
import { useMemo } from 'react'
import PageTitle from '../../../Common/PageTitle'
import { Variables } from '../types'
import compileExp from '../utils/compileExp'

interface DynamicHelperTextProps {
  className?: string
  expression?: string
  style?: React.CSSProperties
  variables: Variables
  variant?: 'Normal' | 'Title' | 'Label' | 'Hint' | 'Error'
}

function UnderlinableText(props: { children: string }) {
  const { children: text } = props

  // split text into an array of normal text and bold text which is surrounded by __
  const textArray = text.split(/(_.*?_)/g)

  // render underline text and normal text
  const textNodes = textArray.map((text, index) => {
    if (text.startsWith('_') && text.endsWith('_')) {
      return (
        <span className="underline" key={index}>
          {text.slice(1, -1)}
        </span>
      )
    }
    return <span key={index}>{text}</span>
  })
  return <>{textNodes}</>
}

function BoldableText(props: { children: string }) {
  const { children: text } = props

  // split text into an array of normal text and bold text which is surrounded by **
  const textArray = text.split(/(\*\*.*?\*\*)/g)

  // render bold text as <strong> and normal text as <span>
  const textNodes = textArray.map((text, index) => {
    if (text.startsWith('**') && text.endsWith('**')) {
      return (
        <strong key={index}>
          <UnderlinableText>{text.slice(2, -2)}</UnderlinableText>
        </strong>
      )
    }
    return (
      <span key={index}>
        <UnderlinableText>{text}</UnderlinableText>
      </span>
    )
  })
  return <>{textNodes}</>
}

export default function DynamicText(props: DynamicHelperTextProps) {
  const { className = '', expression, style, variables, variant = 'Normal' } = props

  const exp = useMemo(() => compileExp(expression), [expression])

  const result = useMemo(() => {
    try {
      return exp?.(variables)
    } catch (e) {
      console.warn('Error evaluating expression', e)
    }
  }, [exp, variables])

  if (typeof result !== 'string') return null

  switch (variant) {
    case 'Normal':
      return (
        <div className={className} style={style}>
          <BoldableText>{result}</BoldableText>
        </div>
      )
    case 'Title':
      return (
        <PageTitle className={className}>
          <BoldableText>{result}</BoldableText>
        </PageTitle>
      )
    case 'Label':
      return (
        <FormLabel className={`block ${className}`} style={style}>
          <BoldableText>{result}</BoldableText>
        </FormLabel>
      )
    case 'Hint':
      return (
        <FormHelperText className={className} style={style}>
          <BoldableText>{result}</BoldableText>
        </FormHelperText>
      )
    case 'Error':
      return (
        <FormHelperText className={className} error style={style}>
          <BoldableText>{result}</BoldableText>
        </FormHelperText>
      )
  }
  return null
}
