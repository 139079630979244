import { DateTime } from 'luxon'
import parseDate from './parseDate'

const defaultDateFormat = 'dd/MM/yyyy'

function formatDate(date: null, pattern?: string | null | undefined): null
function formatDate(date: undefined, pattern?: string | null | undefined): undefined
function formatDate(date: string, pattern?: string | null | undefined): string | undefined
function formatDate(date: string | null, pattern?: string | null | undefined): string | null | undefined
function formatDate(date: string | undefined, pattern?: string | null | undefined): string | undefined
function formatDate(date: string | null | undefined, pattern?: string | null | undefined): string | undefined
function formatDate(date: Date, pattern?: string | null | undefined): string | undefined
function formatDate(date: Date | null, pattern?: string | null | undefined): string | null | undefined
function formatDate(date: Date | undefined, pattern?: string | null | undefined): string | undefined
function formatDate(date: Date | null | undefined, pattern?: string | null | undefined): string | undefined
function formatDate(date: Date | string, pattern?: string | null | undefined): string | undefined
function formatDate(date: Date | string | null, pattern?: string | null | undefined): string | null | undefined
function formatDate(date: Date | string | undefined, pattern?: string | null | undefined): string | undefined
function formatDate(date: Date | string | null | undefined, pattern?: string | null | undefined): string | undefined
function formatDate(
  date: Date | string | null | undefined,
  pattern?: string | null | undefined
): string | null | undefined {
  if (date === null || date === undefined) {
    return date
  } else if (date instanceof Date) {
    return DateTime.fromJSDate(date).toFormat(pattern || defaultDateFormat)
  } else if (typeof date === 'string') {
    const dateValue = parseDate(date)
    if (dateValue) {
      return DateTime.fromJSDate(dateValue).toFormat(pattern || defaultDateFormat)
    }
  }
}

export default formatDate
