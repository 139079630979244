import { IPlainReportField, IPlainReportInputTable } from '../types'
import walkTableRowFields from './walkTableRowFields'

export default function walkTableFields(
  table: IPlainReportInputTable | undefined,
  cb: (field: IPlainReportField) => void | false
): void | false {
  if (table?.headerRows?.length) {
    for (let row of table.headerRows) {
      if (walkTableRowFields(row, cb) === false) {
        return false // early exit
      }
    }
  }
  if (table?.rows?.length) {
    for (let row of table.rows) {
      if (walkTableRowFields(row, cb) === false) {
        return false // early exit
      }
    }
  }
}
