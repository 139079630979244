import { FetchStatus } from '@tanstack/react-query'
import { useMemo } from 'react'
import { ICurriculumFields } from '../../../../@types/generated/contentful'
import { ILearnerGuideInsightsDto, SimpleEntry } from '../../../../api/types'
import parseDate from '../../../../utils/dates/parseDate'
import useGetCurriculums from '../../CurriculumPage/hooks/useGetCurriculums'
import useMyBetterWorldGroups from '../../MyBetterWorldGroups/hooks/useMyBetterWorldGroups'
import useSocialSupportCases from '../../SocialSupportCases/hooks/useSocialSupportCases'

const filterByDate = (value: Date | null | undefined, startDate: Date | null, endDate: Date | null) => {
  if (!startDate && !endDate) {
    // always true if no date range is specified
    return true
  }
  if (startDate && !endDate) {
    // only check start date
    return Boolean(value && value >= startDate)
  }
  if (!startDate && endDate) {
    // only check end date
    return Boolean(value && value <= endDate)
  }
  if (startDate && endDate) {
    return Boolean(value && value >= startDate && value <= endDate)
  }
  return false
}

export default function useLocalInsightsStats(props: { startDate: Date | null; endDate: Date | null }) {
  const { startDate, endDate } = props

  // get all the curriculums
  const {
    data: curriculums,
    error: curriculumsError,
    isLoading: isLoadingCurriculums,
    isError: isErrorCurriculums,
    isSuccess: isSuccessCurriculums,
  } = useGetCurriculums()
  const curriculumsById = useMemo(() => {
    return (
      curriculums?.reduce((acc, curr) => {
        acc[curr.sys.id] = curr
        return acc
      }, {} as Record<string, SimpleEntry<ICurriculumFields>>) ?? {}
    )
  }, [curriculums])

  // get all the groups
  const {
    groups,
    error: myBetterWorldGroupsError,
    isLoading: isLoadingMyBetterWorldGroups,
    isError: isErrorMyBetterWorldGroups,
    isSuccess: isSuccessMyBetterWorldGroups,
  } = useMyBetterWorldGroups()

  // get all the support cases
  const {
    cases,
    error: socialSupportCasesError,
    isLoading: isLoadingSocialSupportCases,
    isError: isErrorSocialSupportCases,
    isSuccess: isSuccessSocialSupportCases,
  } = useSocialSupportCases()

  const data: ILearnerGuideInsightsDto = useMemo(() => {
    const filteredGroups = groups.filter(
      (g) => !g.isDeleted && filterByDate(parseDate(g.created_at), startDate, endDate)
    )

    // number of pending/completed group steps
    const [better_world_group_steps_pending, better_world_group_steps_completed] = filteredGroups.reduce(
      (acc, group) => {
        const curriculum = curriculumsById[group.curriculum_id]
        if (curriculum) {
          const completedStepIds = new Set(group.steps?.map((step) => step.step_id))
          curriculum.fields?.steps.forEach((step) => {
            if (completedStepIds.has(step.sys.id)) {
              acc[1]++
            } else {
              acc[0]++
            }
          })
        }
        return acc
      },
      [0, 0]
    )

    const better_world_group_pending = filteredGroups.filter((g) => !g.completed).length
    const better_world_group_completed = filteredGroups.filter((g) => g.completed).length

    const filteredCases = cases.filter((c) => !c.isDeleted && filterByDate(parseDate(c.created_at), startDate, endDate)) // apply date filter
    const social_support_cases_closed = filteredCases.filter((c) => c.closed).length
    const social_support_cases_open = filteredCases.length - social_support_cases_closed

    // number of children in open cases
    const children_supporting = filteredCases
      .filter((c) => !c.closed)
      .reduce((acc, curr) => {
        return acc + (curr.number_of_boys || 0) + (curr.number_of_girls || 0)
      }, 0)

    // number of children in closed cases
    const children_supported_completion = filteredCases
      .filter((c) => c.closed)
      .reduce((acc, curr) => {
        return acc + (curr.number_of_boys || 0) + (curr.number_of_girls || 0)
      }, 0)

    // number of pending/completed actions
    const [social_support_case_actions_pending, social_support_case_actions_completed] = filteredCases.reduce(
      (acc, curr) => {
        let pendingActions = 0
        let completedActions = 0
        curr.actions?.forEach((a) => {
          // TODO apply date filter?
          if (a.completed) {
            completedActions++
          } else {
            pendingActions++
          }
        })
        acc[0] += pendingActions
        acc[1] += completedActions
        return acc
      },
      [0, 0]
    )

    return {
      better_world_group_steps_pending,
      better_world_group_steps_completed,
      better_world_group_pending,
      better_world_group_completed,
      social_support_cases_open,
      social_support_cases_closed,
      children_supporting,
      children_supported_completion,
      social_support_case_actions_pending,
      social_support_case_actions_completed,
    }
  }, [cases, curriculumsById, endDate, groups, startDate])

  const fetchStatus: FetchStatus = useMemo(() => {
    if (isLoadingCurriculums || isLoadingMyBetterWorldGroups || isLoadingSocialSupportCases) {
      return 'fetching'
    }
    return 'idle'
  }, [isLoadingCurriculums, isLoadingMyBetterWorldGroups, isLoadingSocialSupportCases])

  return {
    data,
    error: curriculumsError || myBetterWorldGroupsError || socialSupportCasesError,
    fetchStatus,
    isLoading: isLoadingCurriculums || isLoadingMyBetterWorldGroups || isLoadingSocialSupportCases,
    isError: isErrorCurriculums || isErrorMyBetterWorldGroups || isErrorSocialSupportCases,
    isSuccess: isSuccessCurriculums && isSuccessMyBetterWorldGroups && isSuccessSocialSupportCases,
  }
}
