import { UserProfile } from '@auth0/nextjs-auth0/client'
import { IUserReport } from '../../../../api/types'
import generateSubmissionId from '../../../../utils/generateSubmissionId'
import { Variables } from '../types'

export default function initUserReport(user: UserProfile | undefined, defaultValues: Variables): IUserReport {
  const ret: IUserReport = {
    created_at: new Date().toISOString(),
    Reporting_Period__c: '',
    submission_id: generateSubmissionId(),
    updated_at: new Date().toISOString(),
    user_id: user?.sub ?? '',
    Year_of_Report__c: '',
  }

  Object.entries(defaultValues).forEach(([key, value]) => {
    if (typeof value === 'string') {
      ret[key] = value
    } else if (value instanceof Date) {
      ret[key] = value.toISOString()
    } else if (typeof value === 'number') {
      ret[key] = value.toString()
    }
  })

  return ret
}
