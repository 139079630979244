import { useUser } from '@auth0/nextjs-auth0/client'
import CategoryIcon from '@mui/icons-material/Category'
import ManIcon from '@mui/icons-material/Man'
import NotesIcon from '@mui/icons-material/Notes'
import WomanIcon from '@mui/icons-material/Woman'
import { Checkbox, List, ListItem, ListItemButton, ListItemText, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { useCallback, useMemo } from 'react'
import {
  ISocialSupportActionDto,
  ISocialSupportCaseActionLocalData,
  ISocialSupportCaseDto,
  ISocialSupportCaseLocalData,
} from '../../../../api/types'
import { socialSupportCaseActionEditRoute } from '../../../../constants'
import formatDate from '../../../../utils/dates/formatDate'
import AppBarBackButton from '../../../AppBarBackButton'
import AuthLayout from '../../../AuthLayout'
import CamfedAppBar from '../../../CamfedAppBar'
import AddListItemButton from '../../../Common/AddListItemButton'
import Divider from '../../../Common/Divider'
import ListSectionTitle from '../../../Common/ListSectionTitle'
import usePrompt from '../../../Common/usePrompt'
import { IScreenProps } from '../../../Navigation/types'
import IconTextRow from '../../Account/Common/IconTextRow'
import isCategoryEqual from '../helpers/isCategoryEqual'
import socialSupportCaseTitle from '../helpers/socialSupportCaseTitle'
import useGetSocialSupportCategories from '../hooks/useGetSocialSupportCategories'
import useMutateSocialSupportCase from '../hooks/useMutateSocialSupportCase'
import useSocialSupportCase from '../hooks/useSocialSupportCase'
import useSocialSupportCaseMenu from './useSocialSupportCaseMenu'

interface IActionItemProps {
  action: ISocialSupportActionDto | ISocialSupportCaseActionLocalData
  onEdit: (action: ISocialSupportActionDto | ISocialSupportCaseActionLocalData) => void
  onToggle: (action: ISocialSupportActionDto | ISocialSupportCaseActionLocalData) => void
  readOnly: boolean
}

const ActionItem = ({ action, onEdit, onToggle, readOnly }: IActionItemProps) => {
  const { typography } = useTheme()

  const handleEdit = useCallback(() => {
    onEdit(action)
  }, [action, onEdit])

  const handleToggle = useCallback(() => {
    if (!readOnly) {
      onToggle(action)
    }
  }, [action, onToggle, readOnly])

  const labelId = `action-label-${action.submission_id}`

  return (
    <ListItem
      secondaryAction={
        <Checkbox
          checked={Boolean(action.completed)}
          className="mr-2"
          disableRipple={readOnly}
          inputProps={{ 'aria-labelledby': labelId }}
          onChange={handleToggle}
          readOnly={readOnly}
        />
      }
      disableGutters
      disablePadding
    >
      <ListItemButton disabled={false} onClick={handleEdit}>
        <ListItemText id={labelId}>
          <Typography sx={{ ...typography.subtitle1.onSurface.light.highEmphasis }}>
            {action.title} - {formatDate(action.date)}
          </Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  )
}

interface ISocialSupportCasePageProps
  extends IScreenProps<{ supportCase: ISocialSupportCaseDto | ISocialSupportCaseLocalData }> {}

export default function SocialSupportCasePage(props: ISocialSupportCasePageProps) {
  // Get the navigator from the props
  const { navigator } = props

  const listItem = props.data?.supportCase
  const { resolvedCase: supportCase } = useSocialSupportCase(listItem)
  const nonDeletedActions = supportCase.actions?.filter((action) => !action.isDeleted)

  const { user, isLoading: isLoadingUser } = useUser()
  const userId = user?.sub
  const editable = !isLoadingUser && userId && userId === listItem?.trainer_id

  const { mutate } = useMutateSocialSupportCase()

  // Memoize the handleBack function
  const handleBack = useCallback(() => {
    navigator.pop()
  }, [navigator])

  // Get the categories from the API
  const { data: categories } = useGetSocialSupportCategories()

  // Get memoized display text for categories
  const categoryDisplayText = useMemo(() => {
    return (
      supportCase.categories
        ?.map((cat) => categories?.find((c) => isCategoryEqual(cat, c.fields))?.fields?.title ?? '')
        ?.filter(Boolean)
        ?.join(', ') ?? ''
    )
  }, [categories, supportCase.categories])

  // Get the t function from the translation
  const { t } = useTranslation('social-support-cases-form')
  const { t: tCommon } = useTranslation('common')

  const { showPrompt: handleEditNotes, promptDialog: editNotesDialogue } = usePrompt({
    initialValue: supportCase.notes,
    inputLabel: t('notesFieldLabel'),
    multiLine: true,
    rows: 10,
    onConfirm: useCallback(
      (notes: string) => {
        mutate({
          data: {
            supportCase: {
              ...supportCase,
              notes,
            },
            change: {
              type: 'updateProps',
            },
          },
        })
      },
      [mutate, supportCase]
    ),
    title: t('editNotesTitle') ?? '',
  })

  // Get the typography from the theme
  const { typography } = useTheme()

  // Memoize the function for adding a new action
  const handleAddNewAction = useCallback(() => {
    navigator.push(socialSupportCaseActionEditRoute(null, supportCase))
  }, [navigator, supportCase])

  const handleEditAction = useCallback(
    (action: ISocialSupportActionDto | ISocialSupportCaseActionLocalData) => {
      navigator.push(socialSupportCaseActionEditRoute(action, supportCase))
    },
    [navigator, supportCase]
  )

  const handleToggleAction = useCallback(
    (action: ISocialSupportActionDto | ISocialSupportCaseActionLocalData) => {
      mutate({
        data: {
          supportCase: {
            ...supportCase,
            actions: (supportCase.actions ?? []).map((a) =>
              a.submission_id === action.submission_id ? { ...a, completed: !a.completed ? 1 : 0 } : a
            ),
          },
          change: {
            type: 'upsertAction',
            submissionId: action.submission_id,
          },
        },
      })
    },
    [mutate, supportCase]
  )

  const { menu, messageDialog, renameDialog } = useSocialSupportCaseMenu({ mutate, navigator, supportCase })

  return (
    <AuthLayout
      appBar={
        <CamfedAppBar
          centralText={socialSupportCaseTitle(supportCase.title, supportCase.year)}
          leftButton={<AppBarBackButton onClick={handleBack} />}
          prominent
          rightItems={editable ? menu : null}
        />
      }
    >
      <ListSectionTitle className="mx-4 mt-4 mb-2">{t('caseDetails')}</ListSectionTitle>
      <IconTextRow label={t('categoryListTitle')} detailText={categoryDisplayText} icon={<CategoryIcon />} />
      <IconTextRow label={t('girlsListTitle')} detailText={supportCase.number_of_girls} icon={<WomanIcon />} />
      <IconTextRow label={t('boysListTitle')} detailText={supportCase.number_of_boys} icon={<ManIcon />} />
      <IconTextRow
        actionText={editable ? tCommon('edit') : undefined}
        detailText={<div className="whitespace-pre-line">{supportCase.notes}</div>}
        icon={<NotesIcon />}
        label={t('notesFieldLabel')}
        onClick={editable ? handleEditNotes : undefined}
      />
      <Divider className="my-4" />
      <List className={`m-0 p-0 `}>
        {nonDeletedActions?.map((action) => (
          <ActionItem
            action={action}
            key={action.submission_id}
            onEdit={handleEditAction}
            onToggle={handleToggleAction}
            readOnly={!editable}
          />
        ))}
      </List>
      {editable ? (
        <AddListItemButton className="px-4 py-2" onClick={handleAddNewAction}>
          {t('addActionText')}
        </AddListItemButton>
      ) : null}

      {renameDialog}
      {messageDialog}
      {editNotesDialogue}
    </AuthLayout>
  )
}
