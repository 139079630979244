// Simple random string generator, good enough for React element keys.
// Do not use this for crypto purposes.
export default function simpleRandomString(length: number = 8): string {
  let result = ''
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const n = alphabet.length
  for (var i = 0; i < length; i++) {
    result += alphabet.charAt(Math.floor(Math.random() * n))
  }
  return result
}
