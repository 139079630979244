import { AccountCircle } from '@mui/icons-material'
import { IconButton, useTheme } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { accountProfileRoute } from '../../../constants'
import useMst from '../../../models/useMst'

export default observer(function AccountButton(): JSX.Element {
  const { palette } = useTheme()
  const { router } = useMst()
  const { pushRouteOrBringToTop, topRoute } = router

  const { t } = useTranslation('account-profile')

  const isActive = topRoute?.name === accountProfileRoute.name && topRoute.state.visible

  const onClick = useCallback(async () => {
    await pushRouteOrBringToTop(accountProfileRoute)
  }, [pushRouteOrBringToTop])

  return (
    <>
      <IconButton aria-label={t('profilePage') ?? ''} aria-haspopup="true" onClick={onClick}>
        <AccountCircle
          sx={{
            // Work around for unfilled icon
            borderRadius: '50%',
            fill: palette.primary.main,
            background: isActive ? palette.onPrimary.light : palette.onPrimary.light_74,
          }}
        />
      </IconButton>
    </>
  )
})
