import { types } from 'mobx-state-tree'

const Message = types.model({
  message: types.string,
  id: types.number,
  type: types.enumeration('MessageType', ['error', 'success']),
})

let seq = 0

export type MessageType = 'error' | 'success'

const Messages = types
  .model({
    messageQueue: types.optional(types.array(Message), []),
  })
  .volatile((self) => ({
    _callbacks: {} as Callbacks,
  }))
  .actions((self) => ({
    _enqueueMessage(message: string, messageType: MessageType, callback?: () => void) {
      const id = ++seq
      self.messageQueue.push(Message.create({ message, id, type: messageType }))

      if (callback) self._callbacks[id] = callback
    },
  }))
  .actions((self) => ({
    showMessage(message: string, messageType: MessageType, callback?: () => void) {
      self._enqueueMessage(message, messageType, callback)
    },

    showMessageNow(message: string, messageType: MessageType, callback?: () => void) {
      self.messageQueue.clear()
      self._enqueueMessage(message, messageType, callback)
    },

    onDismissMessage() {
      const id = self.messageQueue[0]?.id
      self.messageQueue.shift()

      if (id) {
        self._callbacks[id]?.()
        delete self._callbacks[id]
      }
    },
  }))

export default Messages

type Callbacks = Record<number, () => void>
