import { useTranslation } from 'next-i18next'
import { IMyBetterWorldGroupDto } from '../../../../api/types'
import useRelativeDateFormatter from '../../../../hooks/useRelativeDateFormatter'
import Divider from '../../../Common/Divider'
import ListSectionTitle from '../../../Common/ListSectionTitle'
import IconTextRow from '../../Account/Common/IconTextRow'
import stepTitle from '../../CurriculumPage/helpers/stepTitle'
import useGetCurriculums from '../../CurriculumPage/hooks/useGetCurriculums'

interface GroupsSummaryProps {
  groups: IMyBetterWorldGroupDto[] | null | undefined
}

const GroupsSection = ({ groups }: GroupsSummaryProps) => {
  const { t: tGroups } = useTranslation('my-better-world-groups')
  const { t: tCurriculum } = useTranslation('curriculum')
  const formatRelativeDate = useRelativeDateFormatter()
  const { data: curriculums } = useGetCurriculums()

  return groups ? (
    <>
      <Divider className="my-4" />
      <div>
        <ListSectionTitle className="mx-4 mb-1">{tGroups('title')}</ListSectionTitle>
        {groups.map((group) => {
          // sort steps most recent first
          const sortedSteps = (group.steps ?? []).slice().sort((a, b) => {
            if (a.updated_at > b.updated_at) {
              return -1
            } else if (a.updated_at < b.updated_at) {
              return 1
            } else {
              return 0
            }
          })

          const recentStep = sortedSteps[0]
          const curriculum = curriculums?.find((c) => c.sys.id === group.curriculum_id)
          const stepIndex = curriculum?.fields?.steps?.findIndex((s) => s.sys.id === recentStep?.step_id) ?? -1
          const stepDetails = curriculum?.fields?.steps?.[stepIndex]

          return (
            <IconTextRow
              caption={formatRelativeDate(recentStep?.updated_at)}
              key={group.id}
              label={group.title}
              detailText={stepTitle(
                tCurriculum,
                stepIndex,
                stepDetails && 'fields' in stepDetails ? stepDetails.fields?.title ?? '' : '',
                ' - '
              )}
            />
          )
        })}
      </div>
    </>
  ) : null
}

export default GroupsSection
