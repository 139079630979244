import { Settings } from '@mui/icons-material'
import { IconButton, useTheme } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { accountSettingsRoute } from '../../../constants'
import useMst from '../../../models/useMst'

export default observer(function SettingsButton(): JSX.Element {
  const { palette } = useTheme()
  const { router } = useMst()
  const { pushRouteOrBringToTop, topRoute } = router

  const { t } = useTranslation('account-settings')

  const isActive = topRoute?.name === accountSettingsRoute.name && topRoute.state.visible

  const onClick = useCallback(async () => {
    await pushRouteOrBringToTop(accountSettingsRoute)
  }, [pushRouteOrBringToTop])

  return (
    <IconButton
      aria-label={t('accountSettingsPage') ?? ''}
      aria-haspopup="true"
      sx={{ color: isActive ? palette.onPrimary.light : palette.onPrimary.light_74 }}
      onClick={onClick}
    >
      <Settings />
    </IconButton>
  )
})
