import { createClient } from 'contentful'
import axios, { AxiosRequestConfig } from 'axios'

const { protocol, host, pathname } = ((url: string) => {
  try {
    return new URL(url)
  } catch (ex) {
    return { protocol: undefined, host: undefined, pathname: undefined }
  }
})(process.env.NEXT_PUBLIC_CONTENT_API_BASE_URL || '')

const apiClient = createClient({
  space: 'camfed',
  accessToken: 'secret',
  environment: 'master',
  host,
  basePath: pathname,
  insecure: protocol === 'http:',
  adapter: (config: AxiosRequestConfig) => {
    const headers = {
      ...config.headers,
    }

    const newConfig = {
      ...config,
      adapter: undefined,
      paramsSerializer: undefined,
      headers,
    }
    return axios(newConfig)
  },
})

export default apiClient
