import { Components } from '@mui/material'
import getRootElement from '../getRootElement'

const MuiDrawer: Components['MuiDrawer'] = {
  defaultProps: {
    container: getRootElement(),
  },
}

export default MuiDrawer
