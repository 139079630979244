import { Palette } from '@mui/material'

interface IHeadingTypography {
  light: React.CSSProperties
  dark: React.CSSProperties
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    h1_onSurface: IHeadingTypography
    h2_onSurface: IHeadingTypography
    h3_onSurface: IHeadingTypography
    h4_onSurface: IHeadingTypography
    h5_onSurface: IHeadingTypography
    h6_onSurface: IHeadingTypography
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h1_onSurface: IHeadingTypography
    h2_onSurface: IHeadingTypography
    h3_onSurface: IHeadingTypography
    h4_onSurface: IHeadingTypography
    h5_onSurface: IHeadingTypography
    h6_onSurface: IHeadingTypography
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1_onSurface: true
    h2_onSurface: true
    h3_onSurface: true
    h4_onSurface: true
    h5_onSurface: true
    h6_onSurface: true
  }
}

const h1_os_base = {
  fontFamily: 'droid-sans',
  fontWeight: 400,
  fontSize: '96px',
  letterSpacing: '-1.5',
  lineHeight: 1.167,
}

const h2_os_base = {
  fontFamily: 'droid-sans',
  fontWeight: 400,
  fontSize: '60px',
  letterSpacing: '-0.5',
  lineHeight: 1.2,
}

const h3_os_base = {
  fontFamily: 'droid-sans',
  fontWeight: 400,
  fontSize: '48px',
  lineHeight: 1.167,
}

const h4_os_base = {
  fontFamily: 'droid-sans',
  fontWeight: 700,
  fontSize: '34px',
  lineHeight: 1.059,
}

const h5_os_base = {
  fontFamily: 'droid-sans',
  fontWeight: 700,
  fontSize: '24px',
  letterSpacing: 0.18,
  lineHeight: 1,
}

const h6_os_base = {
  fontFamily: 'droid-sans',
  fontWeight: 400,
  fontSize: '20px',
  letterSpacing: 0.15,
  lineHeight: 1.2,
}

export const headingsTypography = (palette: Palette) => ({
  h1_onSurface: {
    light: {
      ...h1_os_base,
      color: palette.onSurface.light_87,
    },
    dark: {
      ...h1_os_base,
      color: palette.onSurface.dark_87,
    },
  },
  h2_onSurface: {
    light: {
      ...h2_os_base,
      color: palette.onSurface.light_87,
    },
    dark: {
      ...h2_os_base,
      color: palette.onSurface.dark_87,
    },
  },
  h3_onSurface: {
    light: {
      ...h3_os_base,
      color: palette.onSurface.light_87,
    },
    dark: {
      ...h3_os_base,
      color: palette.onSurface.dark_87,
    },
  },
  h4_onSurface: {
    light: {
      ...h4_os_base,
      color: palette.onSurface.light_87,
    },
    dark: {
      ...h4_os_base,
      color: palette.onSurface.dark_87,
    },
  },
  h5_onSurface: {
    light: {
      ...h5_os_base,
      color: palette.onSurface.light_87,
    },
    dark: {
      ...h5_os_base,
      color: palette.onSurface.dark_87,
    },
  },
  h6_onSurface: {
    light: {
      ...h6_os_base,
      fontWeight: 700,
      color: palette.onSurface.light_87,
    },
    dark: {
      ...h6_os_base,
      fontWeight: 400,
      color: palette.onSurface.dark_87,
    },
  },
})
