import { Typography, useTheme } from '@mui/material'
import { PropsWithChildren } from 'react'

const BodyText = ({ children, className = '' }: PropsWithChildren<{ className?: string }>) => {
  const { typography } = useTheme()
  return (
    <Typography className={className} variant="body2" sx={{ ...typography.body2.onSurface.body2.light }}>
      {children}
    </Typography>
  )
}

export default BodyText
