import { useUser } from '@auth0/nextjs-auth0/client'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { myProfileQueryKey } from '../../api/constants'
import { myProfileEndpoint } from '../../api/endpoints'
import { IUserProfile } from '../../api/types'

export default function useMyProfile() {
  const { user } = useUser()

  return useQuery({
    queryKey: myProfileQueryKey(user?.sub!),
    queryFn: async () => {
      const resp = await axios.get<{ data: IUserProfile }>(myProfileEndpoint)
      return resp.data.data
    },

    enabled: !!user?.sub,
  })
}
