import CancelIcon from '@mui/icons-material/Cancel'
import SearchIcon from '@mui/icons-material/Search'
import { IconButton, TextField } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { FC, useCallback, useMemo } from 'react'

type SearchBoxProps = {
  className?: string
  inputRef?: React.Ref<HTMLInputElement | null> | undefined
  onChange: (v: string) => void
  value: string
}

const SearchBox: FC<SearchBoxProps> = ({ className, inputRef, onChange, value }) => {
  const { t } = useTranslation('common')

  const handleChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      onChange(evt.target.value)
    },
    [onChange]
  )
  const handleClear = useCallback(() => {
    onChange('')
  }, [onChange])

  const hasValue = !!value

  const inputProps = useMemo(() => ({ 'aria-label': t('search') }), [t])

  // The label is not visible if we use OutlinedInput directly (MUI bug?). It seems to be okay if we use TextField with InputProps.
  const InputProps = useMemo(
    () => ({
      endAdornment: hasValue ? (
        <IconButton className="-mr-2" onClick={handleClear}>
          <CancelIcon />
        </IconButton>
      ) : (
        <SearchIcon />
      ),
    }),
    [handleClear, hasValue]
  )

  return (
    <div className={className}>
      <TextField
        inputRef={inputRef}
        fullWidth
        inputProps={inputProps}
        InputProps={InputProps}
        label={t('searchTerm')}
        onChange={handleChange}
        value={value}
        variant="outlined"
      />
    </div>
  )
}

export default SearchBox
