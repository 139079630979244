import { CircularProgress, DialogContent } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { logoutPage } from '../../constants'
import useMst from '../../models/useMst'
import { useAnalytics } from '../Analytics/AnalyticsContext'
import useShowMessage from '../Common/useShowMessage'

export default function useLogout() {
  const { auth } = useMst()
  const { t: tCommon } = useTranslation('common')
  const { t } = useTranslation('auth')
  const { trackLogout } = useAnalytics()

  const logout = useCallback(() => {
    trackLogout()
    // redirect to a logout page before clearing cache and local storage
    window.location.href = logoutPage
  }, [trackLogout])

  const { showMessage, messageDialog } = useShowMessage()

  const logoutWithConfirmation = useCallback(() => {
    showMessage({
      buttons: [
        {
          text: tCommon('cancel') ?? '',
          isCancel: true,
        },
        {
          text: tCommon('confirm') ?? '',
          onClick: () => {
            auth.confirmLogout()
            showMessage({
              buttons: [],
              title: t('logout') ?? '',
              content: (
                <DialogContent className="text-center py-4">
                  <CircularProgress />
                </DialogContent>
              ),
            })
            logout()
          },
        },
      ],
      cancelOnBackdropClick: true,
      cancelOnEscapeKeyDown: true,
      message: t('logoutConfirmation') ?? '',
      title: t('logoutConfirmationTitle') ?? '',
    })
  }, [auth, logout, showMessage, t, tCommon])

  return { logout, logoutWithConfirmation, logoutDialog: messageDialog }
}
