import { Button, Theme } from '@mui/material'
import { useCallback } from 'react'
import { useAnalytics } from '../../Analytics/AnalyticsContext'
import TargetBlankLink from '../../Common/TargetBlankLink'

interface IButtonProps {
  href: string
  labelText: string
  theme: Theme
}

export default function ButtonLink(props: IButtonProps) {
  const { href, labelText, theme } = props
  const { palette } = theme
  const { trackButtonClick } = useAnalytics()

  const handleClick = useCallback(() => {
    trackButtonClick(labelText, href)
  }, [labelText, href, trackButtonClick])

  return (
    <Button
      color="success"
      href={href}
      LinkComponent={TargetBlankLink}
      onClick={handleClick}
      sx={{ background: 'blue', backgroundColor: `${palette.primary.color500} !important` }}
      variant="contained"
    >
      {labelText}
    </Button>
  )
}
