// Front end server endpoints
export const activeTimeEndpoint = '/api/stats/active-time'
export const cmsPagesImportEndpoint = '/api/cms/pages/data-import'
export const commitActiveTimeEndpoint = '/api/stats/active-time/commit'
export const learnerGuidesInsightsEndpoint = '/api/learner-guides-insights'
export const meEndpoint = '/api/me'
export const myBetterWorldGroupsEndpoint = '/api/my-better-world-groups'
export const myGuidesEndpoint = '/api/my-guides'
export const myGuideEndpoint = (guideId: string) => `/api/my-guides/${encodeURIComponent(guideId)}`
export const myGuideSocialSupportCasesEndpoint = (guideId: string) =>
  `/api/my-guides/${encodeURIComponent(guideId)}/social-support-cases`
export const myBetterWorldGroupEndpoint = (groupId: number) =>
  `/api/my-better-world-groups/${encodeURIComponent(groupId)}`
export const myProfileEndpoint = '/api/profile'
export const socialSupportCaseEndpoint = (caseId: number) => `/api/social-support-cases/${encodeURIComponent(caseId)}`
export const socialSupportCasesEndpoint = '/api/social-support-cases'
export const socialSupportCaseActionEndpoint = (caseId: number, actionId: number) =>
  `/api/social-support-cases/${encodeURIComponent(caseId)}/actions/${encodeURIComponent(actionId)}`
export const socialSupportCaseActionsEndpoint = (caseId: number) =>
  `/api/social-support-cases/${encodeURIComponent(caseId)}/actions`
export const userReportsEndpoint = (userId: string) => `/api/reports/${encodeURIComponent(userId)}`
