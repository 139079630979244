import { UserConfig } from 'next-i18next'
import { ILocale } from './types'

export const Locales: ILocale[] = [
  {
    name: 'English',
    code: 'en',
    apiCode: 'en-GB',
    isDefault: true,
  },
  {
    name: 'Swahili',
    code: 'sw',
    apiCode: 'sw-TZ',
  },
]

export const locales = Locales.map((l) => l.code)
export const defaultLocale = Locales.find((l) => l.isDefault)?.code || Locales[0].code

const isProduction = process.env.NODE_ENV === 'production'

export const translationConfig: UserConfig = {
  i18n: {
    defaultLocale,
    locales,
  },
  reloadOnPrerender: !isProduction,
}
