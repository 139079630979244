import { Palette } from '@mui/material'
import React from 'react'

interface IBody1Typography {
  onSurface: {
    light: {
      highEmphasis: React.CSSProperties
      highEmphasisLink: React.CSSProperties
      mediumEmphasis: React.CSSProperties
      disabled: React.CSSProperties
    }
    dark: {
      highEmphasis: React.CSSProperties
      mediumEmphasis: React.CSSProperties
      disabled: React.CSSProperties
    }
  }
  onPrimary: {
    dark: {
      highEmphasis: React.CSSProperties
    }
  }
}

interface IBody2Typography {
  onSurface: {
    body2: {
      light: React.CSSProperties
      lightAlert: React.CSSProperties
      lightLink: React.CSSProperties
    }
  }
  onPrimary: {
    light: {
      highEmphasis: React.CSSProperties
      mediumEmphasis: React.CSSProperties
      disabled: React.CSSProperties
    }
    dark: {
      highEmphasis: React.CSSProperties
      mediumEmphasis: React.CSSProperties
      disabled: React.CSSProperties
    }
  }
  primary: {
    light: React.CSSProperties
    dark: React.CSSProperties
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body1: IBody1Typography
    body2: IBody2Typography
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body1: IBody1Typography
    body2: IBody2Typography
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body1: true
    body2: true
  }
}

export const bodyTypography = (palette: Palette) => ({
  body1: {
    onSurface: {
      light: {
        highEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 700,
          fontSize: '16px',
          letterSpacing: 0.5,
          lineHeight: 1.5,
          color: palette.onSurface.light_87,
        },
        highEmphasisLink: {
          fontFamily: 'droid-sans',
          fontWeight: 700,
          fontSize: '16px',
          letterSpacing: 0.5,
          lineHeight: 1.5,
          color: palette.primary.color700, // palette.onSurface.light_87,
        },
        mediumEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 400,
          fontSize: '16px',
          letterSpacing: 0.5,
          lineHeight: 1.5,
          color: palette.onSurface.light_87,
        },
        disabled: {
          fontFamily: 'droid-sans',
          fontWeight: 400,
          fontSize: '16px',
          letterSpacing: 0.5,
          lineHeight: 1.5,
          color: palette.onSurface.light_38,
        },
      },
      dark: {
        highEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 400,
          fontSize: '16px',
          letterSpacing: 0.5,
          lineHeight: 1.5,
          color: palette.onSurface.dark_87,
        },
        mediumEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 400,
          fontSize: '16px',
          letterSpacing: 0.5,
          lineHeight: 1.5,
          color: palette.onSurface.dark_60,
        },
        disabled: {
          fontFamily: 'droid-sans',
          fontWeight: 400,
          fontSize: '16px',
          letterSpacing: 0.5,
          lineHeight: 1.5,
          color: palette.onSurface.dark_38,
        },
      },
    },
    onPrimary: {
      dark: {
        highEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 400,
          fontSize: '16px',
          letterSpacing: 0.5,
          lineHeight: 1.5,
          color: palette.onPrimary.dark,
        },
      },
    },
  },
  body2: {
    onSurface: {
      body2: {
        light: {
          fontFamily: 'droid-sans',
          fontWeight: 400,
          fontSize: '14px',
          letterSpacing: 0.25,
          lineHeight: 1.429,
          color: palette.surface.dark,
        },
        lightAlert: {
          fontFamily: 'droid-sans',
          fontWeight: 400,
          fontSize: '14px',
          letterSpacing: 0.25,
          lineHeight: 1.429,
          color: palette.error.light,
        },
        lightLink: {
          fontFamily: 'droid-sans',
          fontWeight: 400,
          fontSize: '14px',
          letterSpacing: 0.25,
          lineHeight: 1.429,
          color: palette.primary.color700,
        },
      },
    },
    onPrimary: {
      light: {
        highEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 400,
          fontSize: '14px',
          letterSpacing: 0.25,
          lineHeight: 1.429,
          color: palette.onPrimary.light_87,
        },
        mediumEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 400,
          fontSize: '14px',
          letterSpacing: 0.25,
          lineHeight: 1.429,
          color: palette.onPrimary.light_60,
        },
        disabled: {
          fontFamily: 'droid-sans',
          fontWeight: 400,
          fontSize: '14px',
          letterSpacing: 0.25,
          lineHeight: 1.429,
          color: palette.onPrimary.light_38,
        },
      },
      dark: {
        highEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 400,
          fontSize: '14px',
          letterSpacing: 0.25,
          lineHeight: 1.429,
          color: palette.onPrimary.dark,
        },
        mediumEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 400,
          fontSize: '14px',
          letterSpacing: 0.25,
          lineHeight: 1.429,
          color: palette.onPrimary.dark_74,
        },
        disabled: {
          fontFamily: 'droid-sans',
          fontWeight: 400,
          fontSize: '14px',
          letterSpacing: 0.25,
          lineHeight: 1.429,
          color: palette.onPrimary.dark_38,
        },
      },
    },
    primary: {
      light: {
        fontFamily: 'droid-sans',
        fontWeight: 400,
        fontSize: '14px',
        letterSpacing: 0.25,
        lineHeight: 1.429,
        color: palette.primary.color500,
      },
      dark: {
        fontFamily: 'droid-sans',
        fontWeight: 400,
        fontSize: '14px',
        letterSpacing: 0.25,
        lineHeight: 1.429,
        color: palette.primary.color200,
      },
    },
  },
})
