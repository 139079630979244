import { Typography, useTheme } from '@mui/material'
import { PropsWithChildren } from 'react'

const SectionHeading = ({ children, className = '' }: PropsWithChildren<{ className?: string }>) => {
  const { typography } = useTheme()
  return (
    <Typography className={className} variant="h1" sx={{ ...typography.subtitle1.onSurface.light.highEmphasis }}>
      {children}
    </Typography>
  )
}

export default SectionHeading
