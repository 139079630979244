import useFormElements from '../hooks/useFormElements'
import { IPlainReportFormGroup } from '../types'
import ErrorList from './ErrorList'
import { useUserReportContext } from './UserReportContext'

interface ReportFormGroupProps {
  className?: string
  group: IPlainReportFormGroup
  isReadonly?: boolean
}

export default function ReportFormGroup(props: ReportFormGroupProps) {
  const { className, group, isReadonly = false } = props
  const { groupErrors } = useUserReportContext()
  const errors = groupErrors[group.id]

  const elements = useFormElements(group.elements, isReadonly)

  return (
    <div className={className} style={group.style}>
      {elements}
      <ErrorList className="mb-4" errors={errors} />
    </div>
  )
}
