import { List } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { IUserReport } from '../../../../api/types'
import { userReportRoute } from '../../../../constants'
import AddListItemButton from '../../../Common/AddListItemButton'
import PageTitle from '../../../Common/PageTitle'
import useNavigator from '../../../Navigation/useNavigator'
import RequestLoadingHandler from '../../../RequestLoadingHandler'
import RichText from '../../../RichText'
import useUserReports from '../hooks/useUserReports'
import { IPlainReport } from '../types'
import UserReportListItem from './UserReportListItem'

interface ReportViewProps {
  report: IPlainReport
}

export default function ReportOverview(props: ReportViewProps) {
  const { report } = props

  const navigator = useNavigator()

  const { t } = useTranslation('reports')

  const { data, isLoading, isError, error, isSuccess, fetchStatus } = useUserReports()

  const handleAdd = useCallback(() => {
    navigator.push(userReportRoute(report))
  }, [navigator, report])

  const handleItemClick = useCallback(
    (userReport: IUserReport) => {
      navigator.push(userReportRoute(report, userReport))
    },
    [navigator, report]
  )

  return (
    <>
      <div className="mt-4 mx-4">
        {report.title ? <PageTitle className="mb-4">{report.title}</PageTitle> : null}
        <RichText document={report.description} />
      </div>
      <AddListItemButton className="px-4 py-2" onClick={handleAdd}>
        {t('addNewReport')}
      </AddListItemButton>
      <RequestLoadingHandler
        error={error}
        errorClassName="p-4"
        fetchStatus={fetchStatus}
        isError={isError}
        isLoading={isLoading}
        isSuccess={isSuccess}
        loadingClassName="p-4"
      >
        {data?.length ? (
          <List>
            {data.map((r) => (
              <UserReportListItem data={r} key={r.submission_id} onClick={handleItemClick} />
            ))}
          </List>
        ) : null}
      </RequestLoadingHandler>
    </>
  )
}
