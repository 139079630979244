let platform: { ios: boolean; android: boolean } | null = null // only initialise when being used and navigator is available

export default function platformSelect<T>(selector: { ios?: T; android?: T; default: T }): T {
  if (typeof navigator !== 'undefined') {
    // Detect platform from navigator.userAgent, there are currently no better ways to do this

    if (platform === null) {
      platform = {
        android: navigator.userAgent.toLowerCase().indexOf('android') > -1,
        ios:
          (/iPad|iPhone|iPod/.test(navigator.userAgent) && !('MSStream' in window)) || // general iOS
          Boolean(/Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1), // iPadOS
      }
    }

    if (platform.android) {
      return selector.android || selector.default
    }

    if (platform.ios) {
      return selector.ios || selector.default
    }
  }
  return selector.default
}
