import RefreshIcon from '@mui/icons-material/Refresh'
import { ButtonBase, useTheme } from '@mui/material'
import { useCallback } from 'react'
import { IResourceFields } from '../../../@types/generated/contentful'
import { SimpleEntry } from '../../../api/types'
import { IRoute } from '../../../models/Router'
import getLinkedResourceRoute from '../../../utils/getLinkedResourceRoute'
import ArticleCard from '../../ArticleCard'
import FlatList from '../../FlatList/FlatList'
import { INavigator } from '../../Navigation/types'
import SearchBox from '../../SearchBox/SearchBox'
import SimpleCardBody from '../../SimpleCardBody'
import SimpleCardHeader from '../../SimpleCardHeader'
import { useResourceLibraryContext } from '../Context/ResourceLibraryContext'

interface IResourceCardProps {
  index: number
  item: SimpleEntry<IResourceFields>
  navigator: INavigator
}

const ResourceCard = ({ item, navigator }: IResourceCardProps): JSX.Element => {
  const className = `px-4 pt-0 pb-4`

  const handleClick = useCallback(() => {
    let route: IRoute | undefined
    const type = item.sys.contentType.sys.id
    const id = item.sys.id

    if (type && id) {
      route = getLinkedResourceRoute({ id, type })
    }
    if (!route) {
      return
    }
    navigator.push(route)
  }, [item.sys.contentType.sys.id, item.sys.id, navigator])

  return (
    <div className={className}>
      <ArticleCard className="cursor-pointer" onClick={handleClick}>
        <SimpleCardHeader text={item.fields?.title ?? ''} />
        <SimpleCardBody>{item.fields?.excerpt}</SimpleCardBody>
      </ArticleCard>
    </div>
  )
}

interface IResourceListProps {
  navigator: INavigator
  onFiltersClick: () => void
}

const ResourceList = (props: IResourceListProps): JSX.Element => {
  const { navigator, onFiltersClick } = props

  const {
    clearSelectedLanguages,
    clearSelectedTypeTags,
    keywords,
    onKeywordsChange,
    resources: items,
    selectedLanguages,
    selectedTypeTags,
    setDebouncedKeywords,
  } = useResourceLibraryContext()

  const onResetClick = useCallback(() => {
    // Clear the selected languages and selected type tags
    clearSelectedLanguages()
    clearSelectedTypeTags()

    // Clear the keywords
    onKeywordsChange('') // clear keywords input
    setDebouncedKeywords('') // clear debounced value to apply immediately
  }, [clearSelectedLanguages, clearSelectedTypeTags, onKeywordsChange, setDebouncedKeywords])

  const itemKey = useCallback((r: SimpleEntry<IResourceFields>) => r.sys.id, [])

  const renderItem = useCallback(
    (item: SimpleEntry<IResourceFields>, index: number) => {
      return <ResourceCard index={index} item={item} navigator={navigator} />
    },
    [navigator]
  )

  const totalFilters = selectedLanguages.size + selectedTypeTags.size
  const { typography } = useTheme()

  return (
    <FlatList
      itemKey={itemKey}
      items={items}
      header={
        <>
          <SearchBox className="p-4" onChange={onKeywordsChange} value={keywords} />

          <div className="flex justify-between pl-4 pr-4 pb-4">
            <ButtonBase onClick={onFiltersClick} sx={{ ...typography.body2.primary.light }}>
              +{totalFilters} {totalFilters > 0 ? 'more' : ''} filters
            </ButtonBase>
            {keywords || totalFilters > 0 ? (
              <ButtonBase onClick={onResetClick} sx={{ ...typography.body2.primary.light }}>
                <RefreshIcon />
                Reset
              </ButtonBase>
            ) : null}
          </div>
        </>
      }
    >
      {renderItem}
    </FlatList>
  )
}

export default ResourceList
