import isEqual from 'lodash/isEqual'
import { autorun } from 'mobx'
import { useEffect, useRef, useState } from 'react'

export default function useStableMobxSnapshot<T>(getValue: () => T): T {
  const [value, setValue] = useState(getValue())

  const valueRef = useRef(value)
  valueRef.current = value

  const getterRef = useRef(getValue)
  getterRef.current = getValue

  useEffect(() => {
    return autorun(() => {
      // something changed in mst
      const currentValue = valueRef.current
      const latestValue = getterRef.current()

      // only update if not deeply equal
      if (!isEqual(currentValue, latestValue)) {
        setValue(latestValue)
      }
    })
  }, [])

  return value
}
