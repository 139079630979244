import { Divider, List, useTheme } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { socialSupportCaseCreateRoute } from '../../../../constants'
import AppBarBackButton from '../../../AppBarBackButton'
import AuthLayout from '../../../AuthLayout'
import CamfedAppBar from '../../../CamfedAppBar'
import AddListItemButton from '../../../Common/AddListItemButton'
import ListSectionTitle from '../../../Common/ListSectionTitle'
import UnsyncedFootnote from '../../../Common/UnsyncedFootnote'
import { IScreenProps } from '../../../Navigation/types'
import RequestLoadingHandler from '../../../RequestLoadingHandler'
import useSocialSupportCases from '../hooks/useSocialSupportCases'
import SocialSupportCaseListItem from './SocialSupportCaseListItem'

interface SocialSupportCaseOverviewPageProps extends IScreenProps {}

const SocialSupportCaseOverviewPage = observer((props: SocialSupportCaseOverviewPageProps) => {
  const { navigator } = props
  const { t } = useTranslation('social-support-cases')

  const { closedCases, error, fetchStatus, isError, isLoading, isSuccess, openCases } = useSocialSupportCases()
  const hasModifiedClosedCases = closedCases.some((supportCase) => supportCase.isLocal)
  const hasModifiedOpenCases = openCases.some((supportCase) => supportCase.isLocal)

  // Handler to add a new case
  const handleAddNewCase = useCallback(() => {
    navigator.push(socialSupportCaseCreateRoute)
  }, [navigator])

  const { typography } = useTheme()

  return (
    <AuthLayout
      appBar={
        <CamfedAppBar
          centralText={t('Social Support Cases')}
          leftButton={<AppBarBackButton onClick={navigator.pop} />}
          prominent
        />
      }
    >
      <RequestLoadingHandler
        error={error}
        errorClassName="p-4"
        fetchStatus={fetchStatus}
        isError={isError}
        isLoading={isLoading}
        isSuccess={isSuccess}
        loadingClassName="p-4"
      >
        <ListSectionTitle className="mt-4 mx-4">{t('currentCases')}</ListSectionTitle>
        <List subheader={null}>
          {openCases.map((supportCase, idx) => (
            <SocialSupportCaseListItem
              key={supportCase.submission_id || idx}
              supportCase={supportCase}
              navigator={navigator}
              t={t}
            />
          ))}
          {hasModifiedOpenCases ? <UnsyncedFootnote /> : null}
        </List>

        <AddListItemButton className="px-4 py-2" onClick={handleAddNewCase}>
          {t('addNewCase')}
        </AddListItemButton>

        {closedCases.length ? (
          <>
            <Divider className="my-4" />
            <ListSectionTitle className="mx-4">{t('closedCases')}</ListSectionTitle>
            <List>
              {closedCases.map((supportCase, idx) => (
                <SocialSupportCaseListItem
                  key={supportCase.submission_id || idx}
                  supportCase={supportCase}
                  navigator={navigator}
                  t={t}
                />
              ))}
              {hasModifiedClosedCases ? <UnsyncedFootnote /> : null}
            </List>
          </>
        ) : null}
      </RequestLoadingHandler>
    </AuthLayout>
  )
})

export default SocialSupportCaseOverviewPage
