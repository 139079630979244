import { useCallback, useState } from 'react'
import downloadBlob from '../utils/downloadBlob'
import saveBlob from '../utils/saveBlob'

export default function useDownload({
  filename,
  href,
  onError,
  openInNewTab,
}: {
  filename?: string | null | undefined
  href: string | null | undefined
  onError?: (error: Error) => void
  openInNewTab?: boolean // only works if href is publicly accessible
}) {
  const [isLoading, setLoading] = useState(false)

  const download = useCallback(
    async (evt: React.MouseEvent) => {
      evt.preventDefault()
      evt.stopPropagation()
      if (!href) {
        return
      } else if (openInNewTab) {
        window.open(href, '_blank', 'noopener noreferrer')
      } else {
        try {
          setLoading(true)
          const blob = await downloadBlob({ url: href })
          if (blob) {
            saveBlob(blob, filename)
          }
        } catch (ex) {
          onError?.(ex as Error)
        } finally {
          setLoading(false)
        }
      }
    },
    [openInNewTab, href, filename, onError]
  )

  return { download, isLoading }
}
