import { createTheme } from '@mui/material'
import MuiDialog from './components/MuiDialog'
import MuiDrawer from './components/MuiDrawer'
import MuiPopover from './components/MuiPopover'
import MuiPopper from './components/MuiPopper'
import MuiTab from './components/MuiTab'
import MuiTabs from './components/MuiTabs'
import { palette } from './palette'
import { bodyTypography } from './typography/body'
import { buttonTypography } from './typography/button'
import { captionTypography } from './typography/caption'
import { headingsTypography } from './typography/headings'
import { notesAndAnnotationsTypography } from './typography/notesAndAnnotations'
import { overlineTypography } from './typography/overline'
import { subtitlesTypography } from './typography/subtitles'
// When using TypeScript 4.x and above
import type {} from '@mui/x-date-pickers/themeAugmentation'

// Create a theme instance.
const theme = createTheme({
  palette,
  components: {
    MuiDialog,
    MuiDrawer,
    MuiPopover,
    MuiPopper,
    MuiTab,
    MuiTabs,
  },
  typography(palette) {
    return {
      ...headingsTypography(palette),
      ...subtitlesTypography(palette),
      ...bodyTypography(palette),
      ...buttonTypography(palette),
      ...captionTypography(palette),
      ...overlineTypography(palette),
      ...notesAndAnnotationsTypography(palette),
    }
  },
})

export default theme
