import { useQuery } from '@tanstack/react-query'
import { FieldsType } from 'contentful'
import { useMemo } from 'react'
import { entriesQueryKey } from '../../api/constants'
import getAllEntries from '../../api/getAllEntries'
import useApiLocaleCode from '../../components/Languages/useApiLocaleCode'

export default function useGetAllEntries<EntryType extends FieldsType>(
  contentType: string,
  query?: any,
  enabled: boolean = true
) {
  const locale = useApiLocaleCode()

  const queryWithLocale = useMemo(() => {
    return {
      ...query,
      locale,
    }
  }, [locale, query])

  // Generate the query key
  const queryKey = useMemo(() => entriesQueryKey(contentType, queryWithLocale), [contentType, queryWithLocale])

  return useQuery({
    queryKey,
    queryFn: () => getAllEntries<EntryType>(contentType, queryWithLocale),
    enabled,
  })
}
