import { useTranslation } from 'next-i18next'
import { Locales } from './constants'

export default function useApiLocaleCode(): string {
  const { i18n } = useTranslation()
  const lang = i18n.language

  // Get the backend locale code based on Next's locale
  let ret = Locales.find((l) => l.code === lang)?.apiCode

  // If we still don't have a locale, use the default one
  if (!ret) {
    ret = Locales.find((l) => l.isDefault)?.apiCode
  }

  // If we still don't have a locale, use the first one
  if (!ret) {
    ret = Locales[0]?.apiCode
  }

  if (!ret) {
    throw new Error('No locale found')
  }

  return ret
}
