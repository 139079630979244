import { useInfiniteQuery } from '@tanstack/react-query'
import { Tag, TagCollection } from 'contentful'
import { useMemo } from 'react'
import apiClient from '../../../api/apiClient'

export default function useTags() {
  const { data, error, fetchNextPage, hasNextPage, isError, isFetchingNextPage, isLoading, isSuccess } =
    useInfiniteQuery({
      queryKey: ['tags'],
      queryFn: async (args) => {
        const tags: TagCollection = await apiClient.getTags({
          skip: args.pageParam,
        })
        return tags
      },
      getNextPageParam: (page) => {
        if (page.total > 0 && page.total >= page.limit) {
          return page.skip + page.limit
        }
      },
    })

  if (hasNextPage && !isFetchingNextPage && !isLoading) {
    fetchNextPage()
  }

  const tags: Tag[] = useMemo(() => {
    const all: Tag[] = []
    data?.pages?.forEach((page) => {
      if (Array.isArray(page.items)) {
        all.push(...(page.items || []))
      }
    })
    return all
  }, [data?.pages])

  return { tags, error, isError, isLoading, isSuccess }
}
