import { useCallback, useMemo, useState } from 'react'
import useResizeObserver from './useResizeObserver'

type ConstrainedImageProps = {
  expandImage?: boolean
  maxWidth?: number
  maxHeight?: number
}

export default function useConstrainedImage({ expandImage = true, maxWidth, maxHeight }: ConstrainedImageProps) {
  const [naturalWidth, setNaturalWidth] = useState(0)
  const [naturalHeight, setNaturalHeight] = useState(0)

  const [element, setElement] = useState<HTMLElement | null>(null)
  const { size } = useResizeObserver(element)
  const containerWidth = size?.width
  const containerHeight = size?.height

  const onLoadingComplete = useCallback((img: HTMLImageElement) => {
    const width = img.naturalWidth
    const height = img.naturalHeight
    setNaturalWidth(width)
    setNaturalHeight(height)
  }, [])

  const [width, height] = useMemo(() => {
    let drawWidth = naturalWidth ?? 0
    let drawHeight = naturalHeight ?? 0

    if (naturalWidth && naturalHeight && containerWidth && containerHeight) {
      const actualMaxWidth = Math.min(
        expandImage ? Math.max(containerWidth, naturalWidth) : naturalWidth,
        containerWidth,
        maxWidth ?? containerWidth
      )
      const actualMaxHeight = Math.min(
        expandImage ? Math.max(containerHeight, naturalHeight) : naturalHeight,
        containerHeight,
        maxHeight ?? containerHeight
      )
      const containerRatio = actualMaxWidth / actualMaxHeight
      const imageRatio = naturalWidth / naturalHeight

      // determine which side to constrain
      if (containerRatio > imageRatio) {
        // container is wider than image
        drawHeight = actualMaxHeight
        drawWidth = drawHeight * imageRatio
      } else {
        // image is wider than container
        drawWidth = actualMaxWidth
        drawHeight = drawWidth / imageRatio
      }
    }

    return [drawWidth, drawHeight]
  }, [containerHeight, containerWidth, expandImage, maxHeight, maxWidth, naturalHeight, naturalWidth])

  return { setParentElement: setElement, width, height, onLoadingComplete }
}
