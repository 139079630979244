import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { myProfileQueryKey } from '../../api/constants'
import { myProfileEndpoint } from '../../api/endpoints'
import { IUserProfile } from '../../api/types'

export interface IUseGetProfileProps {
  userId?: string
}

export default function useGetProfile(vars?: IUseGetProfileProps) {
  return useQuery({
    queryKey: myProfileQueryKey(vars?.userId),
    queryFn: async () => {
      const resp = await axios.get<{ data: IUserProfile }>(myProfileEndpoint, { params: { userId: vars?.userId } })
      return resp.data.data
    },

    enabled: !!vars?.userId,
  })
}
