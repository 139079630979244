import { Palette } from '@mui/material'
import React from 'react'

interface IS1Typography {
  onSurface: {
    light: {
      highEmphasis: React.CSSProperties
      mediumEmphasis: React.CSSProperties
      disabled: React.CSSProperties
    }
    dark: {
      highEmphasis: React.CSSProperties
      mediumEmphasis: React.CSSProperties
      disabled: React.CSSProperties
    }
  }
  primary: {
    dark: {
      highEmphasis: React.CSSProperties
    }
  }
}

interface IS2Typography {
  onSurface: {
    light: {
      highEmphasis: React.CSSProperties
      mediumEmphasis: React.CSSProperties
      lowEmphasis: React.CSSProperties
      lowEmphasisCentred: React.CSSProperties
    }
    dark: {
      highEmphasis: React.CSSProperties
      mediumEmphasis: React.CSSProperties
      disabled: React.CSSProperties
    }
  }
  primary: {
    light: React.CSSProperties
    dark: React.CSSProperties
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    subtitle1: IS1Typography
    subtitle2: IS2Typography
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    subtitle1: IS1Typography
    subtitle2: IS2Typography
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subtitle1: true
    subtitle2: true
  }
}

interface ISubtitleTheme {
  subtitle1: IS1Typography
  subtitle2: IS2Typography
}

export const subtitlesTypography = (palette: Palette): ISubtitleTheme => ({
  subtitle1: {
    onSurface: {
      light: {
        highEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 700,
          fontSize: '16px',
          letterSpacing: 0.15,
          lineHeight: 1.5,
          color: palette.onSurface.light_87,
        },
        mediumEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 400,
          fontSize: '16px',
          letterSpacing: 0.15,
          lineHeight: 1.5,
          color: palette.onSurface.light_87,
        },
        disabled: {
          fontFamily: 'droid-sans',
          fontWeight: 700,
          fontSize: '16px',
          letterSpacing: 0.15,
          lineHeight: 1.5,
          color: palette.onSurface.light_38,
        },
      },
      dark: {
        highEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 700,
          fontSize: '16px',
          letterSpacing: 0.15,
          lineHeight: 1.5,
          color: palette.onSurface.dark_87,
        },
        mediumEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 700,
          fontSize: '16px',
          letterSpacing: 0.15,
          lineHeight: 1.5,
          color: palette.onSurface.dark_60,
        },
        disabled: {
          fontFamily: 'droid-sans',
          fontWeight: 700,
          fontSize: '16px',
          letterSpacing: 0.15,
          lineHeight: 1.5,
          color: palette.onSurface.dark_38,
        },
      },
    },
    primary: {
      dark: {
        highEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 700,
          fontSize: '16px',
          letterSpacing: 0.15,
          lineHeight: 1.5,
          color: palette.primary.color200,
        },
      },
    },
  },
  subtitle2: {
    onSurface: {
      light: {
        highEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 700,
          fontSize: '14px',
          letterSpacing: 0.1,
          lineHeight: 1.714,
          color: palette.onSurface.light_87,
        },
        mediumEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 700,
          fontSize: '14px',
          letterSpacing: 0.1,
          lineHeight: 1.714,
          color: palette.onSurface.light_60,
        },
        lowEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 400,
          fontSize: '14px',
          letterSpacing: 0.1,
          lineHeight: 1.714,
          color: palette.onSurface.light_60,
        },
        lowEmphasisCentred: {
          fontFamily: 'droid-sans',
          fontWeight: 400,
          fontSize: '14px',
          letterSpacing: 0.1,
          lineHeight: 1.714,
          color: palette.onSurface.light_60,
          textAlign: 'center',
        },
      },
      dark: {
        highEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 700,
          fontSize: '14px',
          letterSpacing: 0.1,
          lineHeight: 1.714,
          color: palette.onSurface.dark_87,
        },
        mediumEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 700,
          fontSize: '14px',
          letterSpacing: 0.1,
          lineHeight: 1.714,
          color: palette.onSurface.dark_60,
        },
        disabled: {
          fontFamily: 'droid-sans',
          fontWeight: 700,
          fontSize: '14px',
          letterSpacing: 0.1,
          lineHeight: 1.714,
          color: palette.onSurface.dark_38,
        },
      },
    },
    primary: {
      light: {
        fontFamily: 'droid-sans',
        fontWeight: 700,
        fontSize: '14px',
        letterSpacing: 0.1,
        lineHeight: 1.714,
        color: palette.primary.color500,
      },
      dark: {
        fontFamily: 'droid-sans',
        fontWeight: 700,
        fontSize: '14px',
        letterSpacing: 0.1,
        lineHeight: 1.714,
        color: palette.primary.color200,
      },
    },
  },
})
