import { SelectChangeEvent } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import generateYearOptions from '../../../../utils/generateYearOptions'
import useGetCurriculums from '../../CurriculumPage/hooks/useGetCurriculums'

// Props include onSubmit
interface IUseMyBetterWorldGroupFormProps {
  onSubmit: (year: string, group: string, curriculum: string) => void
}

export default function useMyBetterWorldGroupForm(props: IUseMyBetterWorldGroupFormProps) {
  const { onSubmit } = props

  // Memoized array of year options
  // Sort the most recent year first
  const years = useMemo(() => generateYearOptions(2020).sort((a, b) => Number(b.value) - Number(a.value)), [])

  // Create state to hold the selected year
  const [selectedYear, setSelectedYear] = useState<string>('')

  // Memoized callback to handle the change of the year select
  const handleYearChange = useCallback((event: SelectChangeEvent<string>) => {
    setSelectedYear(event.target.value)
  }, [])

  // State to hold the value of the group input
  const [group, setGroup] = useState<string>('')
  // Memoized callback to handle the change of the group input
  const handleGroupChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setGroup(event.target.value)
  }, [])

  const { data: curriculumItems } = useGetCurriculums()

  // Get an array of curriculum options from the curriculum, setting the value to the curriculum id
  // Memoize the array
  const curriculumOptions = useMemo(
    () =>
      curriculumItems?.map((curriculum) => ({
        label: curriculum.fields?.title || '',
        value: curriculum.sys.id,
      })) ?? [],
    [curriculumItems]
  )

  // State to hold the value of the curriculum input
  const [curriculum, setCurriculum] = useState<string>('')
  // Memoized callback to handle the change of the curriculum input
  const handleCurriculumChange = useCallback((event: SelectChangeEvent<string>) => {
    setCurriculum(event.target.value)
  }, [])

  // Memoized callback to handle the submit of the form
  const handleSubmit = useCallback(() => {
    // Call the onSubmit prop with the selected year, group and curriculum
    props.onSubmit(selectedYear, group, curriculum)
  }, [selectedYear, group, curriculum, props])

  return {
    years,
    selectedYear,
    handleYearChange,
    group,
    handleGroupChange,
    curriculumOptions,
    curriculum,
    handleCurriculumChange,
    handleSubmit,

    onSubmit,
  }
}
