// Function component with Authlayout and placeholder content

import { MenuItem } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'next-i18next'
import { useCallback, useEffect } from 'react'
import { IMyBetterWorldGroupDto, IMyBetterWorldGroupLocalData } from '../../../../api/types'
import AppBarBackButton from '../../../AppBarBackButton'
import AuthLayout from '../../../AuthLayout'
import CamfedAppBar from '../../../CamfedAppBar'
import ListSectionTitle from '../../../Common/ListSectionTitle'
import useMoreMenu from '../../../Common/useMoreMenu'
import usePrompt from '../../../Common/usePrompt'
import useShowMessage from '../../../Common/useShowMessage'
import { IScreenProps } from '../../../Navigation/types'
import Steps from '../../CurriculumPage/Steps/Steps'
import useGetCurriculum from '../../CurriculumPage/hooks/useGetCurriculum'
import useMutateMyBetterWorldGroup from '../hooks/useMutateMyBetterWorldGroup'
import useMyBetterWorldGroup from '../hooks/useMyBetterWorldGroup'
import useStepCheckboxes from '../hooks/useStepCheckboxes'

// Todo: display the steps and data in the format required by the design

interface IData {
  group: IMyBetterWorldGroupDto | IMyBetterWorldGroupLocalData
}

interface IMyBetterWorldGroupPageProps extends IScreenProps<IData> { }

export default observer(function MyBetterWorldGroupPage(props: IMyBetterWorldGroupPageProps) {
  const { navigator } = props

  const { resolvedGroup, isValid, isSuccess } = useMyBetterWorldGroup(props.data?.group)
  const groupTitle = `${resolvedGroup.year} - ${resolvedGroup.title}`

  useEffect(() => {
    if (!isValid) {
      navigator.pop()
    }
  }, [isValid, navigator])

  const { data: curriculum } = useGetCurriculum(resolvedGroup.curriculum_id)

  const { t } = useTranslation('my-better-world-groups')

  const { mutate } = useMutateMyBetterWorldGroup()

  const { checkedSteps, onStepToggle } = useStepCheckboxes(resolvedGroup, mutate)

  const { showPrompt: handleRename, promptDialog: renameDialog } = usePrompt({
    initialValue: resolvedGroup.title ?? '',
    inputLabel: t('my-better-world-group-form:groupLabel'),
    onConfirm: useCallback(
      (title: string) => {
        mutate({ data: { group: { ...resolvedGroup, title }, change: { type: 'updateProps' } } })
      },
      [mutate, resolvedGroup]
    ),
    title: t('renameGroup') ?? '',
  })

  const handleMarkStatus = useCallback(() => {
    const completed = !resolvedGroup.completed ? 1 : 0
    mutate({
      data: {
        group: { ...resolvedGroup, completed },
        change: { type: 'updateProps' },
      },
      onSuccess: () => {
        if (completed) {
          navigator.pop()
        }
      },
    })
  }, [mutate, navigator, resolvedGroup])

  const deleteGroup = useCallback(() => {
    mutate({ data: { group: { ...resolvedGroup, isDeleted: true }, change: { type: 'delete' } } })
    navigator.pop()
  }, [mutate, navigator, resolvedGroup])

  const { showMessage, messageDialog } = useShowMessage()

  const handleDelete = useCallback(() => {
    showMessage({
      title: t('deleteGroup') ?? '',
      message: t('deleteGroupConfirmation', { name: groupTitle }) ?? '',
      buttons: [
        {
          text: t('cancel') ?? '',
          isCancel: true,
        },
        {
          text: t('delete') ?? '',
          onClick: deleteGroup,
        },
      ],
    })
  }, [deleteGroup, groupTitle, showMessage, t])

  const { menu } = useMoreMenu((closeMenu: () => void) => {
    return [
      <MenuItem
        key="renameGroup"
        onClick={() => {
          closeMenu()
          handleRename()
        }}
      >
        {t('renameGroup')}
      </MenuItem>,
      <MenuItem
        key="markStatus"
        onClick={() => {
          closeMenu()
          handleMarkStatus()
        }}
      >
        {resolvedGroup.completed ? t('unmarkAsComplete') : t('markAsComplete')}
      </MenuItem>,
      <MenuItem
        key="deleteGroup"
        onClick={() => {
          closeMenu()
          handleDelete()
        }}
      >
        {t('deleteGroup')}
      </MenuItem>,
    ]
  })

  return (
    <AuthLayout
      appBar={
        <CamfedAppBar
          centralText={groupTitle}
          leftButton={<AppBarBackButton onClick={navigator.pop} />}
          prominent
          rightItems={menu}
        />
      }
    >
      <ListSectionTitle className="mx-4 mt-4 mb-2">
        {t('curriculumStepCompleted', { name: curriculum?.fields?.title })}
      </ListSectionTitle>
      <Steps
        checkboxes
        checkedSteps={checkedSteps}
        onStepClick={onStepToggle}
        onStepToggle={onStepToggle}
        steps={curriculum?.fields?.steps}
      />
      {renameDialog}
      {messageDialog}
    </AuthLayout>
  )
})