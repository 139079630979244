import { toJS } from 'mobx'
import { Instance, applySnapshot, onSnapshot, types } from 'mobx-state-tree'
import { createContext } from 'react'
import { logDebug } from '../components/Common/loggers'
import { loginRoute } from '../constants'
import Auth from './Auth'
import Engagement from './Engagement'
import HomeFeed from './HomeFeed'
import InitialLoading from './InitialLoading'
import Insights from './Insights'
import Messages from './Messages'
import MyBetterWorldGroups from './MyBetterWorldGroups'
import Onboarding from './Onboarding'
import PersistentStorage from './PersistentStorage'
import Profile from './Profile'
import Reports from './Reports'
import Router from './Router'
import SocialSupportCases from './SocialSupportCases'

const createRootStore = () =>
  RootModel.create({
    auth: {},
    engagement: {
      activity: [],
    },
    homeFeed: {
      hiddenIds: [],
    },
    insights: {},
    router: {
      root: loginRoute,
      routes: [],
    },
    persistentStorage: {
      hasAcknowledged: false,
    },
    profile: {},
    reports: {},
    onboarding: {
      showOnboarding: true,
    },
    initialLoading: {
      onboardingDone: false,
    },
    messages: {},
    myBetterWorldGroups: {},
    socialSupportCases: {},
  })

const RootModel = types
  .model({
    auth: Auth,
    engagement: Engagement,
    homeFeed: HomeFeed,
    initialLoading: InitialLoading,
    insights: Insights,
    messages: Messages,
    myBetterWorldGroups: MyBetterWorldGroups,
    onboarding: Onboarding,
    persistentStorage: PersistentStorage,
    profile: Profile,
    reports: Reports,
    router: Router,
    socialSupportCases: SocialSupportCases,
  })
  .actions((self) => ({
    reset() {
      applySnapshot(self, toJS(createRootStore()))
    },
  }))

let initialState = createRootStore()

if (typeof window !== 'undefined') {
  const data = localStorage.getItem('rootState')
  if (data) {
    const json = JSON.parse(data)
    if (RootModel.is(json)) {
      initialState = RootModel.create(json)
    }
  }
}

export const rootStore = initialState

onSnapshot(rootStore, (snapshot) => {
  logDebug('Snapshot: ', snapshot)
  localStorage.setItem('rootState', JSON.stringify(snapshot))
})

export type RootInstance = Instance<typeof RootModel>
export const RootStoreContext = createContext<null | RootInstance>(null)
