import { QueryClient, useQueryClient } from '@tanstack/react-query'
import { toJS } from 'mobx'
import { useCallback } from 'react'
import { socialSupportCaseQueryKey, socialSupportCasesQueryKey } from '../../../../api/constants'
import { ISocialSupportActionDto, ISocialSupportCaseDto, ISocialSupportCaseLocalData } from '../../../../api/types'
import useMst from '../../../../models/useMst'
import { logError } from '../../../Common/loggers'
import { createSocialSupportCase } from '../useCreateSocialSupportCase'
import {
  deleteSocialSupportCase,
  syncSocialSupportCase,
  syncSocialSupportCaseActions,
} from './useMutateSocialSupportCase'

export function updateSocialSupportCaseQueryData(
  queryClient: QueryClient,
  supportCase: ISocialSupportCaseDto | ISocialSupportCaseLocalData | undefined
) {
  if (supportCase) {
    if (supportCase.id && !supportCase.isDeleted) {
      // this is important to avoid the action checkbox value flickering
      queryClient.setQueryData(socialSupportCaseQueryKey(supportCase.id), supportCase)
    }

    // this is important to avoid the listing items flickering
    queryClient.setQueryData(
      socialSupportCasesQueryKey(supportCase.trainer_id),
      (list: (ISocialSupportCaseDto | ISocialSupportCaseLocalData)[] | undefined) =>
        list?.map((g) => (g.id === supportCase.id ? supportCase : g))
    )
  }
}

export function deleteSocialSupportCaseQueryData(queryClient: QueryClient, ssc: ISocialSupportCaseLocalData) {
  queryClient.setQueryData(socialSupportCasesQueryKey(ssc.trainer_id), (list: ISocialSupportActionDto[] | undefined) =>
    list?.filter((g) => g.id !== ssc.id)
  )
}

export default function useSubmitUnsavedSocialSupportCases() {
  const { socialSupportCases } = useMst()
  const queryClient = useQueryClient()

  return useCallback(async () => {
    const queue = socialSupportCases?.queue ?? []
    const userIds = new Set<string>()

    await Promise.all([
      ...queue.map(async (supportCase) => {
        try {
          if (supportCase.id) {
            if (supportCase.isDeleted) {
              // delete existing case
              await deleteSocialSupportCase(supportCase)
              socialSupportCases.removeCase(supportCase)
            } else {
              // update existing case
              await syncSocialSupportCase(supportCase)

              // optimistic update query data
              const localCase = toJS(socialSupportCases?.queue?.find((g) => g.id === supportCase.id))
              updateSocialSupportCaseQueryData(queryClient, localCase as ISocialSupportCaseDto)

              // remove local case
              socialSupportCases.didUpdateCase(supportCase.id)

              // invalidate case query
              queryClient.invalidateQueries(socialSupportCaseQueryKey(supportCase.id))
            }
          } else {
            if (supportCase.isDeleted) {
              // simply remove the local case
              socialSupportCases.removeCase(supportCase)
            } else {
              // create new case
              const newCase = await createSocialSupportCase(supportCase)

              // sync actions
              await syncSocialSupportCaseActions(supportCase, newCase)

              // optimistic update query data
              updateSocialSupportCaseQueryData(queryClient, newCase)

              // remove local case
              socialSupportCases.didAddCase(supportCase.submission_id)
            }
          }
          userIds.add(supportCase.trainer_id)
        } catch (error) {
          logError('Failed to submit unsaved case', supportCase, error)
        }
      }),
    ])

    // invalidate cases query
    userIds.forEach((userId) => {
      queryClient.invalidateQueries(socialSupportCasesQueryKey(userId))
    })
  }, [socialSupportCases, queryClient])
}
