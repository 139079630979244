import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { IPageFields } from '../../../../@types/generated/contentful';
import { IMyBetterWorldGroupLocalData, SimpleEntry } from '../../../../api/types';
import { MutateMyBetterWorldGroup } from './useMutateMyBetterWorldGroup';

interface ApiRequest {
  newCheckedSteps: string[];
  stepId: string;
}

export default function useStepCheckboxes(
  resolvedGroup: IMyBetterWorldGroupLocalData,
  mutate: MutateMyBetterWorldGroup
) {
  // Initial checkboxes state
  const initialCheckedSteps = useMemo(
    () => resolvedGroup.steps?.map((step) => step.step_id) ?? [],
    [resolvedGroup.steps]
  );

  // Checkboxes state
  const [checkedSteps, setCheckedSteps] = useState<string[]>(initialCheckedSteps);

  // Queue for debounced API requests
  const apiRequestQueue = useRef<ApiRequest[]>([]);

  // Update checkboxes state when remote data changes
  useEffect(() => {
    setCheckedSteps((prev) => (isEqual(prev, initialCheckedSteps) ? prev : initialCheckedSteps));
  }, [initialCheckedSteps]);

  // Debounced mutate function
  const debouncedMutate = useCallback(
    debounce(() => {
      while (apiRequestQueue.current.length > 0) {
        const { newCheckedSteps, stepId } = apiRequestQueue.current.shift()!;
        mutate({
          data: {
            group: { ...resolvedGroup, steps: newCheckedSteps.map((step_id) => ({ step_id })) },
            change: {
              type: 'toggleStep',
              stepId,
            },
          },
        });
      }
    }, 700),
    [mutate, resolvedGroup]
  );

  // Handle checkbox toggle with debounce
  const handleStepToggle = useCallback(
    (step: SimpleEntry<IPageFields>) => {
      setCheckedSteps((checkedSteps) => {
        const stepId = step.sys.id;
        const wasChecked = checkedSteps.includes(stepId);
        const isChecked = !wasChecked;
        const newCheckedSteps = isChecked
          ? [...checkedSteps, stepId].sort()
          : checkedSteps.filter((id) => id !== stepId);

        // Update UI immediately
        setCheckedSteps(newCheckedSteps);

        // Queue API request
        apiRequestQueue.current.push({ newCheckedSteps, stepId });

        // Ensure only one debounced call is scheduled
        debouncedMutate();

        return newCheckedSteps;
      });
    },
    [debouncedMutate]
  );

  return { checkedSteps, onStepToggle: handleStepToggle };
}