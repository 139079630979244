import { useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { IPlainReportField } from '../types'

export default function useUnregisterField() {
  const { setValue, unregister } = useFormContext()
  const unregisterRef = useRef(unregister)
  unregisterRef.current = unregister

  return (field: IPlainReportField) => {
    setValue(field.name, undefined) // reset value to undefined
    unregister(field.name, {
      keepDefaultValue: false,
      keepDirty: false,
      keepDirtyValues: false,
      keepError: false,
      keepIsValid: false,
      keepTouched: false,
      keepValue: true, // keep value so that the value will be propagated to the `variables` and the MST model, see `UserReportContext`
    })
  }
}
