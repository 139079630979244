import { Block, Inline } from '@contentful/rich-text-types'
import { useTheme } from '@mui/material'
import { useCallback, useMemo } from 'react'
import useMst from '../../models/useMst'
import getLinkedResourceRoute from '../../utils/getLinkedResourceRoute'
import { richTextOptionsFactory } from '../RichTextParser/constants'

export default function useRichTextOptions() {
  const { router } = useMst()
  const { appendRoute } = router

  const onEmEntBlockClick = useCallback(
    (node: Block | Inline) => {
      const target = node.data?.target
      const type = target?.sys?.contentType?.sys?.id as string | undefined
      const id = target?.sys?.id

      if (!type || !id) {
        return
      }

      const route = getLinkedResourceRoute({ id, type })
      if (route) {
        appendRoute(route)
      }
    },
    [appendRoute]
  )
  const theme = useTheme()
  return useMemo(
    () => richTextOptionsFactory(theme, appendRoute, onEmEntBlockClick),
    [appendRoute, onEmEntBlockClick, theme]
  )
}
