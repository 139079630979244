import { Components } from '@mui/material'
import { palette } from '../palette'

const MuiTabs: Components['MuiTabs'] = {
  styleOverrides: {
    root: {
      backgroundColor: palette.primary.main,
    },
    indicator: {
      backgroundColor: palette.onPrimary.light,
    },
  },
}

export default MuiTabs
