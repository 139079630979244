import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { activeTimeQueryKey } from '../../../../api/constants'
import { activeTimeEndpoint } from '../../../../api/endpoints'
import { ActiveTimeData } from '../../../../api/types'

export default function useGetActiveTime(month: string, userIds: string[] | undefined) {
  return useQuery({
    queryKey: activeTimeQueryKey(month, userIds),
    queryFn: async () => {
      const resp = await axios.get<{ data: ActiveTimeData }>(activeTimeEndpoint, {
        params: {
          month: month,
          ids: userIds,
        },
      })
      return resp.data.data
    },
    enabled: Boolean(userIds?.length && month),
  })
}
