import CancelIcon from '@mui/icons-material/Cancel'
import { AppBar, Box, IconButton, Toolbar } from '@mui/material'
import { useTranslation } from 'next-i18next'
import CentralText from '../CentralText'

export interface IPopupAppBarProps {
  centralText: string
  onClose: () => void
  prominent?: boolean
  rightItems?: React.ReactNode
}
export default function PopupAppBar(props: IPopupAppBarProps): JSX.Element {
  const { centralText, onClose, prominent, rightItems } = props
  const { t } = useTranslation('common')

  return (
    <AppBar position="sticky">
      <Toolbar sx={{ display: 'block', justifyContent: 'space-between' }}>
        <Box
          sx={{
            height: '72px',
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <IconButton color="inherit" aria-label={t('close') ?? ''} onClick={onClose}>
              <CancelIcon />
            </IconButton>
          </Box>
          {prominent ? null : <CentralText centralText={centralText} />}
          {rightItems}
        </Box>
        {prominent ? (
          <Box
            sx={{
              height: '72px',
              marginLeft: '50px',
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <CentralText centralText={centralText} />
          </Box>
        ) : null}
      </Toolbar>
    </AppBar>
  )
}
