import FilterListIcon from '@mui/icons-material/FilterList'
import ViewListIcon from '@mui/icons-material/ViewList'
import { AppBar, Tab, Tabs } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

const TabBar: FC<{ tab: number; onTabChange: (evt: React.SyntheticEvent, tab: number) => void }> = ({
  tab,
  onTabChange,
}) => {
  const { t } = useTranslation('library')

  return (
    <AppBar position="static">
      <Tabs value={tab} onChange={onTabChange} aria-label={t('resourceLibraryTabs') ?? ''} variant="fullWidth">
        <Tab icon={<ViewListIcon />} label={t('list')} />
        <Tab icon={<FilterListIcon />} label={t('filters')} />
      </Tabs>
    </AppBar>
  )
}

export default TabBar
