import compileExp from '../components/pages/Reports/utils/compileExp'
import formatDate from './dates/formatDate'

/**
 * Substitute variables / expressions in a template string. Syntax: `${variableName}` or `${expression}`.
 */
export default function substituteStrings(
  template: string,
  variables: Record<string, string | number | Date | boolean | null | undefined>
) {
  return template.replace(/\${([^}]+)}/g, (_, exp: string) => {
    if (exp in variables) {
      // simple variable substitution
      const value = variables[exp]
      if (value === undefined || value === null) {
        return ''
      }
      if (value instanceof Date) {
        return formatDate(value) ?? ''
      }
      return String(value)
    } else {
      // expression substitution
      const fn = compileExp(exp)
      return fn ? fn(variables) : ''
    }
  })
}
