import { Event } from '@mui/icons-material'
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { DatePicker, DatePickerSlotsComponentsProps } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'
import { useTranslation } from 'next-i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { datePickerZIndex } from '../../../../constants'
import useMst from '../../../../models/useMst'
import formatDate from '../../../../utils/dates/formatDate'

interface DateRangeSelectorProps {
  className?: string
  endDate: Date | null
  onDateRangeChange(startDate: Date | null, endDate: Date | null): void
  startDate: Date | null
}

const DateSelectionDialog = ({
  open,
  initialStartDate,
  initialEndDate,
  onConfirm,
  onCancel,
}: {
  open: boolean
  initialStartDate: Date | null
  initialEndDate: Date | null
  onConfirm(startDate: Date | null, endDate: Date | null): void
  onCancel(): void
}) => {
  const { t } = useTranslation('insights')

  const [startDate, setStartDate] = useState<DateTime | null>(
    initialStartDate ? DateTime.fromJSDate(initialStartDate) : null
  )
  const [endDate, setEndDate] = useState<DateTime | null>(initialEndDate ? DateTime.fromJSDate(initialEndDate) : null)

  // Reset the dates when the dialog is opened
  useEffect(() => {
    if (open) {
      setStartDate(initialStartDate ? DateTime.fromJSDate(initialStartDate) : null)
      setEndDate(initialEndDate ? DateTime.fromJSDate(initialEndDate) : null)
    }
  }, [initialEndDate, initialStartDate, open])

  const clear = useCallback(() => {
    setStartDate(null)
    setEndDate(null)
  }, [])

  const confirm = useCallback(() => {
    if (startDate && endDate) {
      onConfirm(startDate.toJSDate(), endDate.toJSDate())
    } else {
      onConfirm(null, null)
    }
  }, [endDate, onConfirm, startDate])

  const slotProps = useMemo(
    () =>
      ({
        popper: { style: { zIndex: datePickerZIndex } },
        dialog: { style: { zIndex: datePickerZIndex } },
      } as DatePickerSlotsComponentsProps<DateTime>),
    []
  )

  return (
    <Dialog open={open} sx={{ zIndex: datePickerZIndex }}>
      <DialogTitle>{t('selectDateRange')}</DialogTitle>
      <DialogContent>
        <div className="flex flex-col">
          <DatePicker
            className="mt-4"
            label={t('common:startDate')}
            maxDate={endDate ?? undefined}
            onChange={setStartDate}
            slotProps={slotProps}
            value={startDate}
          />
          <DatePicker
            className="mt-4"
            label={t('common:endDate')}
            minDate={startDate ?? undefined}
            onChange={setEndDate}
            slotProps={slotProps}
            value={endDate}
          />
        </div>
      </DialogContent>
      <DialogActions className="flex flex-row justify-between">
        <div>{startDate || endDate ? <Button onClick={clear}>{t('common:clear')}</Button> : null}</div>
        <div>
          <Button onClick={onCancel}>{t('common:cancel')}</Button>
          <Button onClick={confirm}>{t('common:confirm')}</Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

const DateRangeSelector = (props: DateRangeSelectorProps) => {
  const { className, startDate, endDate, onDateRangeChange } = props

  const { t } = useTranslation('insights')

  const allTime = !startDate || !endDate
  const dateLabel = formatDate(startDate) + ' - ' + formatDate(endDate)
  const [open, setOpen] = useState(false)

  const selectDates = () => {
    setOpen(true)
  }

  const handleChange = (startDate: Date | null, endDate: Date | null) => {
    onDateRangeChange(startDate, endDate)
    setOpen(false)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <div className={className}>
      <span className="mr-2">{t('showing')}</span>
      <Chip clickable label={allTime ? t('allTime') : dateLabel} onClick={selectDates} icon={<Event />} />
      <DateSelectionDialog
        initialEndDate={endDate}
        initialStartDate={startDate}
        onCancel={handleCancel}
        onConfirm={handleChange}
        open={open}
      />
    </div>
  )
}

export function useDateRangeSelector({ className }: { className?: string }) {
  const { insights } = useMst()

  const startDate = useMemo(
    () => (insights.startDate ? DateTime.fromISO(insights.startDate).toJSDate() : null),
    [insights.startDate]
  )

  const endDate = useMemo(
    () => (insights.endDate ? DateTime.fromISO(insights.endDate).toJSDate() : null),
    [insights.endDate]
  )

  const dateRangeSelector = useMemo(() => {
    return (
      <DateRangeSelector
        className={className}
        endDate={endDate}
        onDateRangeChange={insights.setDateRange}
        startDate={startDate}
      />
    )
  }, [className, endDate, insights, startDate])

  return {
    dateRangeSelector,
    startDate,
    endDate,
  }
}

export default DateRangeSelector
