import boolValue from '../../../../utils/boolValue'
import parseDate from '../../../../utils/dates/parseDate'
import { IPlainReportField, Variables } from '../types'
import compileExp from './compileExp'

const arrayValue = (value: unknown) => {
  if (Array.isArray(value)) {
    return value
  } else if (typeof value === 'string') {
    return value.split(/\s*,\s*/).filter(Boolean)
  }
  return []
}

const dateValue = (value: unknown) => {
  if (value instanceof Date) {
    return value
  } else if (typeof value === 'string') {
    return parseDate(value)
  }
}

export default function resolveDefaultValue(field: IPlainReportField, variables: Variables) {
  const value = compileExp(field.defaultValue)?.(variables)

  if (value !== undefined) {
    switch (field.dataType) {
      case 'Text':
        return String(value ?? '')
      case 'Hidden':
        return value
      case 'Picklist':
        if (field.isMultiple) {
          return arrayValue(value)
        } else {
          return String(value ?? '')
        }
      case 'Number':
      case 'Integer':
        return Number(value)
      case 'Tickbox':
        return boolValue(value)
      case 'Date':
        return dateValue(value)
    }
  }
  return undefined
}
