import { useUser } from '@auth0/nextjs-auth0/client'
import orderBy from 'lodash/orderBy'
import { toJS } from 'mobx'
import { useMemo } from 'react'
import { IUserReport } from '../../../../api/types'
import useStableMobxSnapshot from '../../../../hooks/useStableMobxSnapshot'
import useMst from '../../../../models/useMst'
import useGetUserReports from './useGetUserReports'

const mergeLocalAndRemoteReports = (localReports: IUserReport[], remoteReports: IUserReport[] | undefined) => {
  const localExistingReports = localReports.filter((g) => g.id)
  const localNewReports = localReports.filter((g) => !g.id)

  // map local reports by id
  // currently submitted reports will not be updated, there should not be any local reports with an id
  const localReportsById = localExistingReports.reduce((acc, report) => {
    if (report.id) {
      acc[report.id] = report
    }
    return acc
  }, {} as Record<number, IUserReport>)

  // merge local and remote reports
  const mergedReports: IUserReport[] = (remoteReports ?? []).map((report) => {
    const localReport = report.id !== undefined ? localReportsById[report.id] : undefined
    if (localReport) {
      delete localReportsById[report.id!]
      return localReport
    }
    return report
  })

  // add any remaining local reports
  mergedReports.push(...Object.values(localReportsById), ...localNewReports)

  // only return reports that are not deleted
  return mergedReports.filter((g) => !g.isDeleted)
}

export default function useUserReports() {
  // get remote data
  const { user } = useUser()
  const { data, isLoading, isError, error, fetchStatus, isSuccess } = useGetUserReports(user?.sub)

  // get local data
  const { reports: reportStore } = useMst()
  const localData = useStableMobxSnapshot(() =>
    (toJS(reportStore?.queue) as IUserReport[]).map((r) => ({ ...r, isLocal: true }))
  )

  // merge local and remote data
  const reports = useMemo(() => mergeLocalAndRemoteReports(localData, data), [data, localData])

  const sortedReports = orderBy(reports, ['Year_of_Report__c', 'Reporting_Period__c'], ['desc', 'desc'])

  return { data: sortedReports, isLoading, isError, error, fetchStatus, isSuccess }
}
