import { Typography, useTheme } from '@mui/material'

export default function CentralText({ centralText }: { centralText: string }): JSX.Element {
  const { typography } = useTheme()
  return (
    <Typography
      variant="h6"
      component="h6"
      sx={{
        flex: 1,
        padding: '8px',
        justifyContent: 'flex-start',
        ...typography.h6_onSurface.dark,
      }}
    >
      {centralText}
    </Typography>
  )
}
