import { AxiosError } from 'axios'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'

export function getErrorMessage(error: unknown, unknownErrorMessage: string | null | undefined = 'Unknown error'): string {
  if (!error) {
    return ''
  } else if (error instanceof AxiosError) {
    return error.response?.data.message || error.response?.data.error || error.message
  } else if (error instanceof Error) {
    return error.message
  } else if (typeof error === 'string') {
    return error
  } else {
    return unknownErrorMessage ?? 'Unknown error'
  }
}

export default function useErrorMessage(error: unknown): string {
  const { t: tCommon } = useTranslation('common')
  return useMemo(() => getErrorMessage(error, tCommon('unknownError')), [error, tCommon])
}
