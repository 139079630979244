const environment = process.env.NODE_ENV || 'development'
const isProduction = environment === 'production'

const Levels = ['error', 'warn', 'info', 'trace', 'debug'] as const
type Level = typeof Levels[number]

// Set default log level based on environment
let logLevel = isProduction ? Levels.indexOf('error') : Levels.indexOf('info')

// Manually override log level
export function setLogLevel(level: Level) {
  logLevel = Levels.indexOf(level)
}

export function log(level: Level, ...args: any[]) {
  // Log to console if log level is high enough
  if (Levels.indexOf(level) <= logLevel) {
    console[level](...args)
  }
}

export function logError(...args: any[]) {
  log('error', ...args)
}

export function logWarn(...args: any[]) {
  log('warn', ...args)
}

export function logInfo(...args: any[]) {
  log('info', ...args)
}

export function logTrace(...args: any[]) {
  log('trace', ...args)
}

export function logDebug(...args: any[]) {
  log('debug', ...args)
}
