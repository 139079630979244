import { Typography, useTheme } from '@mui/material'
import { PropsWithChildren } from 'react'

const PageTitle = ({ children, className = '' }: PropsWithChildren<{ className?: string }>) => {
  const { typography } = useTheme()
  return (
    <Typography className={className} sx={{ ...typography.subtitle2.onSurface.light.highEmphasis }} variant="h2">
      {children}
    </Typography>
  )
}

export default PageTitle
