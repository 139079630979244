import { CSSProperties, FC, Key, ReactNode, useCallback, useEffect, useMemo, useRef } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { VariableSizeList } from 'react-window'

type VariableHeightItemProps = {
  children: React.ReactNode
  className?: string
  index: number
  setSize: (index: number, height: number) => void
  width: number
}

const VariableHeightItem: FC<VariableHeightItemProps> = ({ children, className = '', index, setSize, width }) => {
  const root = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const bounds = root.current?.getBoundingClientRect()
    setSize(index, bounds?.height || 0)
  }, [setSize, index, width])

  return (
    <div ref={root} className={className}>
      {children}
    </div>
  )
}

type FlatListProps<T> = {
  children: (item: T, index: number) => React.ReactNode
  emptyView?: React.ReactNode
  header?: React.ReactNode
  itemKey?: (item: T, index: number) => Key
  items: T[] | null | undefined
}

type RenderData<T> = {
  emptyView: ReactNode | undefined
  header: ReactNode | undefined
  items: (Symbol | T)[]
}

const HeaderItem = Symbol('HeaderItem')
const EmptyItem = Symbol('EmptyItem')

function List<T>({
  children: renderItem,
  emptyView,
  header,
  itemKey,
  items,
  width,
  height,
}: FlatListProps<T> & { width: number; height: number }) {
  const hasHeader = !!header
  const hasEmptyView = !!emptyView
  const showEmptyView = hasEmptyView && (!items || items.length === 0)

  const augmentedItems = useMemo(() => {
    const ret: (symbol | T)[] = items?.slice() || []
    if (hasHeader) {
      ret.unshift(HeaderItem)
    }
    if (showEmptyView) {
      ret.push(EmptyItem)
    }
    return ret
  }, [items, hasHeader, showEmptyView])

  const listRef = useRef<VariableSizeList<RenderData<T>> | null>(null)

  const sizeMap = useRef<Record<number, number>>({})
  const getItemSize = useCallback((index: number) => {
    return sizeMap.current[index] ?? 0
  }, [])
  const setItemSize = useCallback((index: number, height: number) => {
    sizeMap.current[index] = height
    listRef.current?.resetAfterIndex(index)
  }, [])

  const itemIndexOffset = hasHeader ? 1 : 0
  const count = augmentedItems.length

  const getItemKey = useCallback(
    (index: number) => {
      const item = augmentedItems[index]
      if (item === HeaderItem) {
        return '__header'
      } else if (item === EmptyItem) {
        return '__empty'
      } else if (itemKey) {
        return itemKey(item as T, index - itemIndexOffset)
      } else {
        // this won't be triggered if itemKey is provided; just to make TS happy
        return index
      }
    },
    [itemIndexOffset, itemKey, augmentedItems]
  )

  // The `renderer` should be as stable as possible. Otherwise the list will be re-rendered and any input component will lose focus.
  const renderer = useCallback(
    ({ data, index, style }: { data: RenderData<T>; index: number; style: CSSProperties }) => {
      const { items } = data
      const item = items[index]

      if (!item) {
        return null
      }
      return (
        <div style={style}>
          <VariableHeightItem index={index} width={width} setSize={setItemSize}>
            {item === HeaderItem
              ? data.header
              : item === EmptyItem
              ? data.emptyView
              : renderItem(item as T, index - itemIndexOffset)}
          </VariableHeightItem>
        </div>
      )
    },
    [itemIndexOffset, renderItem, setItemSize, width]
  )

  const itemData = useMemo(() => ({ emptyView, header, items: augmentedItems }), [emptyView, header, augmentedItems])

  return (
    <VariableSizeList<RenderData<T>>
      ref={listRef}
      width={width}
      height={height}
      itemCount={count}
      itemData={itemData}
      itemKey={itemKey ? getItemKey : undefined}
      itemSize={getItemSize}
    >
      {renderer}
    </VariableSizeList>
  )
}

/**
 * Render a virtualized list of items. Note that this list view will take the
 * full height of its parent. It must be nested inside a relative or absolute
 * positioned element.
 *
 * The item renderer should be as stable as possible. It should not be
 * recreated when items change.
 */
export default function FlatList<T>(props: FlatListProps<T>) {
  return (
    <AutoSizer>
      {({ width, height }: { width: number; height: number }) => {
        if (!width || !height) return <></>
        return <List {...props} width={width} height={height} />
      }}
    </AutoSizer>
  )
}
