import { AppBar, Toolbar } from '@mui/material'
import Box from '@mui/system/Box/Box'
import AccountButton from './AccountButton'
import CentralText from './CentralText'
import HomeButton from './HomeButton'
import SettingsButton from './SettingsButton'

export interface ICamfedAppBarProps {
  centralText: string
  hideRightButtons?: boolean
  leftButton?: React.ReactNode
  prominent?: boolean
  rightItems?: React.ReactNode
}

export default function CamfedAppBar(props: ICamfedAppBarProps): JSX.Element {
  const { leftButton, centralText, hideRightButtons, prominent, rightItems } = props

  return (
    <AppBar position="sticky">
      <Toolbar sx={{ display: 'block', justifyContent: 'space-between' }}>
        <Box
          sx={{
            height: '72px',
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box>{leftButton}</Box>
          {prominent ? null : <CentralText centralText={centralText} />}
          {hideRightButtons ? null : (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flex: 1 }}>
              <HomeButton />
              <AccountButton />
              <SettingsButton />
            </Box>
          )}
        </Box>
        {prominent ? (
          <Box
            sx={{
              height: '72px',
              marginLeft: '50px',
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <CentralText centralText={centralText} />
            {rightItems}
          </Box>
        ) : null}
      </Toolbar>
    </AppBar>
  )
}
