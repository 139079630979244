import { Palette } from '@mui/material'

interface IButtonTypography {
  onSurface: {
    light: { enabled: React.CSSProperties; disabled: React.CSSProperties }
    dark: { enabled: React.CSSProperties; disabled: React.CSSProperties }
  }
  onPrimary: {
    dark: {
      enabled: React.CSSProperties
      enabledMediumEmphasis: React.CSSProperties
      disabled: React.CSSProperties
    }
  }
  primary: {
    light: { enabled: React.CSSProperties }
    dark: {
      enabled: React.CSSProperties
      enabledMediumEmphasis: React.CSSProperties
      disabled: React.CSSProperties
    }
  }
  primaryVariant: {
    enabled: React.CSSProperties
    disabled: React.CSSProperties
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    button: IButtonTypography
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    button: IButtonTypography
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    button: true
  }
}

interface IButtonTheme {
  button: IButtonTypography
}

export const buttonTypography = (palette: Palette): IButtonTheme => ({
  button: {
    onSurface: {
      light: {
        enabled: {
          fontFamily: 'droid-sans',
          fontWeight: 700,
          fontSize: '14px',
          letterSpacing: 1.25,
          lineHeight: 1.143,
          color: palette.onSurface.light_87,
          textTransform: 'uppercase',
        },
        disabled: {
          fontFamily: 'droid-sans',
          fontWeight: 700,
          fontSize: '14px',
          letterSpacing: 1.25,
          lineHeight: 1.143,
          color: palette.onSurface.light_38,
          textTransform: 'uppercase',
        },
      },
      dark: {
        enabled: {
          fontFamily: 'droid-sans',
          fontWeight: 700,
          fontSize: '14px',
          letterSpacing: 1.25,
          lineHeight: 1.143,
          color: palette.onSurface.dark,
          textTransform: 'uppercase',
        },
        disabled: {
          fontFamily: 'droid-sans',
          fontWeight: 700,
          fontSize: '14px',
          letterSpacing: 1.25,
          lineHeight: 1.143,
          color: palette.onSurface.dark_38,
          textTransform: 'uppercase',
        },
      },
    },
    onPrimary: {
      dark: {
        enabled: {
          fontFamily: 'droid-sans',
          fontWeight: 700,
          fontSize: '14px',
          letterSpacing: 1.25,
          lineHeight: 1.143,
          color: palette.onSurface.dark,
          textTransform: 'uppercase',
        },
        enabledMediumEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 700,
          fontSize: '14px',
          letterSpacing: 1.25,
          lineHeight: 1.143,
          color: palette.onPrimary.dark_74,
          textTransform: 'uppercase',
        },
        disabled: {
          fontFamily: 'droid-sans',
          fontWeight: 700,
          fontSize: '14px',
          letterSpacing: 1.25,
          lineHeight: 1.143,
          color: palette.onPrimary.dark_38,
          textTransform: 'uppercase',
        },
      },
    },

    primary: {
      light: {
        enabled: {
          fontFamily: 'droid-sans',
          fontWeight: 700,
          fontSize: '14px',
          letterSpacing: 1.25,
          lineHeight: 1.143,
          color: palette.primary.color500,
          textTransform: 'uppercase',
        },
      },
      dark: {
        enabled: {
          fontFamily: 'droid-sans',
          fontWeight: 700,
          fontSize: '14px',
          letterSpacing: 1.25,
          lineHeight: 1.143,
          color: palette.primary.color200,
          textTransform: 'uppercase',
        },
        enabledMediumEmphasis: {
          fontFamily: 'droid-sans',
          fontWeight: 700,
          fontSize: '14px',
          letterSpacing: 1.25,
          lineHeight: 1.143,
          color: palette.primary.color200_60,
          textTransform: 'uppercase',
        },
        disabled: {
          fontFamily: 'droid-sans',
          fontWeight: 700,
          fontSize: '14px',
          letterSpacing: 1.25,
          lineHeight: 1.143,
          color: palette.onSurface.dark_38,
          textTransform: 'uppercase',
        },
      },
    },
    primaryVariant: {
      enabled: {
        fontFamily: 'droid-sans',
        fontWeight: 700,
        fontSize: '14px',
        letterSpacing: 1.25,
        lineHeight: 1.143,
        color: palette.primary.color500,
        textTransform: 'uppercase',
      },
      disabled: {
        fontFamily: 'droid-sans',
        fontWeight: 700,
        fontSize: '14px',
        letterSpacing: 1.25,
        lineHeight: 1.143,
        color: palette.onPrimary.light_38,
        textTransform: 'uppercase',
      },
    },
  },
})
