import { useUser } from '@auth0/nextjs-auth0/client'
import orderBy from 'lodash/orderBy'
import { toJS } from 'mobx'
import { useMemo } from 'react'
import { ISocialSupportCaseDto, ISocialSupportCaseLocalData } from '../../../../api/types'
import useStableMobxSnapshot from '../../../../hooks/useStableMobxSnapshot'
import useMst from '../../../../models/useMst'
import useGetSocialSupportCases from './useGetSocialSupportCases'
import { resolveSocialSupportCase } from './useSocialSupportCase'

const mergeLocalAndRemoteCases = (
  localCases: ISocialSupportCaseLocalData[],
  remoteCases: ISocialSupportCaseDto[] | undefined
) => {
  const localExistingCases = localCases.filter((g) => g.id)
  const localNewCases = localCases.filter((g) => !g.id)

  // map local cases by id
  const localCasesById = localExistingCases.reduce((acc, c: ISocialSupportCaseLocalData) => {
    if (c.id) {
      acc[c.id] = c
    }
    return acc
  }, {} as Record<number, ISocialSupportCaseLocalData>)

  // merge local and remote cases
  const mergedCases: (ISocialSupportCaseLocalData | ISocialSupportCaseDto)[] = (remoteCases ?? []).map((remoteCase) => {
    const localCase = localCasesById[remoteCase.id]
    if (localCase) {
      delete localCasesById[remoteCase.id]
      return resolveSocialSupportCase(remoteCase, remoteCase, localCase)
    }
    return { ...remoteCase, isLocal: false }
  })

  // add any remaining local cases
  mergedCases.push(...Object.values(localCasesById), ...localNewCases)

  // only return cases that are not deleted
  return mergedCases.filter((g) => !g.isDeleted)
}

export default function useSocialSupportCases() {
  const { user } = useUser()
  const userId = user?.sub ?? ''
  const { data, isLoading, isError, error, fetchStatus, isSuccess } = useGetSocialSupportCases(userId, true)

  // get local data
  const { socialSupportCases } = useMst()
  const localData = useStableMobxSnapshot(() => toJS(socialSupportCases?.queue) as ISocialSupportCaseLocalData[])

  // merge local and remote data
  const cases = useMemo(() => mergeLocalAndRemoteCases(localData, data), [data, localData])

  // Group by closed status then sort by year and group name
  const closedCases = orderBy(
    cases.filter((c) => c.closed),
    ['year', 'title'],
    ['desc', 'asc']
  )
  const openCases = orderBy(
    cases.filter((c) => !c.closed),
    ['year', 'title'],
    ['desc', 'asc']
  )

  return {
    cases,
    closedCases,
    error,
    fetchStatus,
    isError,
    isLoading,
    isSuccess,
    openCases,
  }
}
