import { Button, Typography, useTheme } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import useMst from '../../../models/useMst'

export const StoragePermissionPage = observer(() => {
  const { persistentStorage } = useMst()
  const { setHasAcknowledged } = persistentStorage

  const { t } = useTranslation('request-storage-permission')

  // Function to set persistent storage to true
  // Set the hasAcknowledgedPersistentStorage to true
  const onClick = useCallback(async () => {
    if (navigator.storage) {
      await navigator.storage.persist()
    }
    setHasAcknowledged(true)
  }, [setHasAcknowledged])

  // Get the typoegraphy from the theme
  const { typography } = useTheme()

  // Get the title and text from the translation file
  const bodyText = t('text')
  const header = t('title')

  return (
    <div className="flex justify-center items-center h-screen w-screen ">
      <div className="max-w-xl h-full text-center  flex flex-col p-8" style={{ maxHeight: '600px' }}>
        <div className="flex-1  flex overflow-hidden justify-center">
          {/* Disable eslint on next line */}
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src="/images/camfed_people.png" alt="" className="max-h-full max-w-full object-contain" />
        </div>
        <Typography variant="h5" sx={{ ...typography.h5_onSurface.light, marginBottom: '14px' }}>
          {header}
        </Typography>
        <Typography variant="subtitle2" sx={{ ...typography.subtitle2.onSurface.light.lowEmphasis }}>
          {bodyText}
        </Typography>
        <div className="flex justify-center items-center mt-4">
          <Button variant="contained" color="primary" onClick={onClick}>
            {t('buttonText')}
          </Button>
        </div>
      </div>
    </div>
  )
})
