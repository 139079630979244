import { Components } from '@mui/material'
import { palette } from '../palette'

const MuiTab: Components['MuiTab'] = {
  styleOverrides: {
    root: {
      color: palette.onPrimary.light_60,
      '&.Mui-selected': {
        color: `${palette.onPrimary.light} !important`,
      },
    },
  },
}

export default MuiTab
