import axios from 'axios'
import { toJS } from 'mobx'
import { SnapshotIn, cast, flow, types } from 'mobx-state-tree'
import { activeTimeEndpoint, commitActiveTimeEndpoint } from '../api/endpoints'
import generateSubmissionId from '../utils/generateSubmissionId'

const SEAL_THRESHOLD = 1000 * 60 * 5 // 5 minutes

const Activity = types
  .model({
    token: types.string,
    yearMonth: types.string,
    activeTime: types.number,
    sealed: types.boolean, // sealed means the activity is ready to be submitted and no more changes should be made
    submitted: types.boolean, // submitted means the activity has been submitted to the server, but not committed
    committed: types.boolean, // committed means the activity has been committed to the server and should be removed from the store
  })
  .actions((self) => ({
    addActiveTime(activeTime: number) {
      self.activeTime += activeTime
    },
  }))

export type ActivityType = SnapshotIn<typeof Activity>

const createActivity = (yearMonth: string) =>
  Activity.create({
    token: generateSubmissionId(),
    yearMonth,
    activeTime: 0,
    sealed: false,
    submitted: false,
    committed: false,
  })

const Engagement = types
  .model({
    activity: types.array(Activity),
  })
  .actions((self) => ({
    addActiveTime(yearMonth: string, activeTime: number) {
      const existing = self.activity.find((a) => a.yearMonth === yearMonth && !a.sealed)
      if (existing) {
        existing.addActiveTime(activeTime)

        if (existing.activeTime >= SEAL_THRESHOLD) {
          existing.sealed = true
        }
      } else {
        const newActivity = createActivity(yearMonth)
        newActivity.addActiveTime(activeTime)
        self.activity.push(newActivity)
      }
    },
    seal() {
      self.activity.forEach((a) => {
        if (!a.sealed) {
          a.sealed = true
        }
      })
    },
    resetStats() {
      self.activity = cast([])
    },
    _didSubmitActivity(token: string) {
      const existing = self.activity.find((a) => a.token === token)
      if (existing) {
        existing.submitted = true
      }
    },
    _didCommitActivity(token: string) {
      const existing = self.activity.find((a) => a.token === token)
      if (existing) {
        existing.committed = true
      }
    },
    _removeCommittedActivities() {
      self.activity = cast(self.activity.filter((a) => !a.committed))
    },
  }))
  .actions((self) => ({
    submitActivities: flow(function* () {
      const active_time = toJS(self.activity.filter((a) => a.sealed && !a.submitted)).map((a) => ({
        duration: Math.round(a.activeTime / 1000),
        month: a.yearMonth,
        token: a.token,
      }))
      if (!active_time.length) {
        return
      }

      try {
        const res = yield axios.post(activeTimeEndpoint, {
          active_time,
        })

        if (res.status === 200) {
          active_time.forEach((a) => {
            self._didSubmitActivity(a.token)
          })
        }
      } catch (e) {
        // Fail silently. Retry next time.
        // Since a sealed activity will not be updated, it is safe to retry.
      }
    }),
    commitActivities: flow(function* () {
      const tokens = toJS(self.activity.filter((a) => a.submitted && !a.committed)).map((a) => a.token)
      if (!tokens.length) {
        return
      }

      try {
        const res = yield axios.post(commitActiveTimeEndpoint, {
          tokens,
        })

        if (res.status === 200) {
          tokens.forEach((t) => {
            self._didCommitActivity(t)
          })
          self._removeCommittedActivities()
        }
      } catch (e) {
        // Fail silently. Retry next time.
        // A submitted activity is stored in the backend and will not be updated. It is also safe to retry.
      }
    }),
  }))

export default Engagement
