import { PaletteMode } from '@mui/material'

interface IColors {
  color900?: string
  color800?: string
  color700?: string
  color600?: string
  color500?: string
  color500_60?: string
  color500_38?: string
  color500_32?: string
  color500_24?: string
  color500_16?: string
  color500_12?: string
  color500_8?: string
  color500_4?: string
  color400?: string
  color300?: string
  color200?: string
  color200_60?: string
  color200_38?: string
  color200_32?: string
  color200_24?: string
  color200_16?: string
  color200_12?: string
  color200_8?: string
  color200_4?: string
  color100?: string
  color050?: string
}

interface ISurfaceColors {
  light: string
  dark: string
}

interface IOnPrimaryColors extends ISurfaceColors {
  light_87: string
  light_74: string
  light_60: string
  light_38: string
  dark_87: string
  dark_74: string
  dark_38: string
}

interface IOnSurfaceColors extends ISurfaceColors {
  light_87: string
  light_60: string
  light_38: string
  light_12: string
  dark_87: string
  dark_60: string
  dark_38: string
  dark_12: string
}

interface IOverlayColors extends ISurfaceColors {
  light_12: string
  light_8: string
  light_4: string
  dark_12: string
  dark_8: string
  dark_4: string
}

interface IShadowColors {
  shadow: string
  shadow_20: string
  shadow_14: string
  shadow_12: string
}

interface ISlideBackgroundColors extends ISurfaceColors {}

interface IBrandColors {
  light: string
  dark: string
}

declare module '@mui/material/styles' {
  interface Palette {
    surface: ISurfaceColors
    onPrimary: IOnPrimaryColors
    onSecondary: string
    onBackground: string
    onSurface: IOnSurfaceColors
    onError: string
    scrim: string
    overlay: IOverlayColors
    shadow: IShadowColors
    slideBackground: ISlideBackgroundColors
    outline: string
    brand: IBrandColors
  }
  interface PaletteOptions {
    surface: ISurfaceColors
    onPrimary: IOnPrimaryColors
    onSecondary: string
    onBackground: string
    onSurface: IOnSurfaceColors
    onError: string
    scrim: string
    overlay: IOverlayColors
    shadow: IShadowColors
    slideBackground: ISlideBackgroundColors
    outline: string
  }
  interface PaletteColor extends IColors {}
  interface SimplePaletteColorOptions extends IColors {}
}

// Palette values
export const palette = {
  mode: 'light' as PaletteMode,
  primary: {
    main: '#8F4085', // Setting main to same as 500 as default fallback
    color900: '#4C2659',
    color800: '#662F6C',
    color700: '#743476',
    color600: '#843B7F',
    color500: '#8F4085',
    color500_60: 'rgba(143, 64, 133, 0.6)',
    color500_38: 'rgba(143, 64, 133, 0.38)',
    color500_32: 'rgba(143, 64, 133, 0.32)',
    color500_24: 'rgba(143, 64, 133, 0.24)',
    color500_16: 'rgba(143, 64, 133, 0.16)',
    color500_12: 'rgba(143, 64, 133, 0.12)',
    color500_8: 'rgba(143, 64, 133, 0.08)',
    color500_4: 'rgba(143, 64, 133, 0.04)',
    color400: '#A05696',
    color300: '#B171A8',
    color200: '#C898C1',
    color200_60: 'rgba(200, 152, 193, 0.6)',
    color200_38: 'rgba(200, 152, 193, 0.38)',
    color200_32: 'rgba(200, 152, 193, 0.32)',
    color200_24: 'rgba(200, 152, 193, 0.24)',
    color200_16: 'rgba(200, 152, 193, 0.16)',
    color200_12: 'rgba(200, 152, 193, 0.12)',
    color200_8: 'rgba(200, 152, 193, 0.08)',
    color200_4: 'rgba(200, 152, 193, 0.04)',
    color100: '#DDC0D9',
    color050: '#F2E7FE',
  },
  secondary: {
    main: '#FB5424', // Setting main to same as 500 as default fallback
    color900: '#BB340F',
    color800: '#D44117',
    color700: '#E2481B',
    color600: '#F04F20',
    color500: '#FB5424',
    color400: '#FB6E44',
    color300: '#FC8865',
    color200: '#FDAA91',
    color200_60: 'rgba(253, 170, 145, 0.6)',
    color200_38: 'rgba(253, 170, 145, 0.38)',
    color100: '#FECBBC',
    color050: '#FAE9E7',
  },
  background: {
    default: '#fff',
    paper: '#fff',
  },
  surface: {
    light: '#FFFFFF',
    dark: '#121212',
  },
  error: {
    main: '#B00020', // Setting main to light as default fallback
    light: '#B00020',
    dark: '#CF6679',
  },
  onPrimary: {
    light: '#FFFFFF',
    light_87: 'rgba(255, 255, 255, 0.87)',
    light_74: 'rgba(255, 255, 255, 0.74)',
    light_60: 'rgba(255, 255, 255, 0.6)',
    light_38: 'rgba(255, 255, 255, 0.38)',
    dark: '#000000',
    dark_87: 'rgba(0, 0, 0, 0.87)',
    dark_74: 'rgba(0, 0, 0, 0.74)',
    dark_38: 'rgba(0, 0, 0, 0.38)',
  },
  onSecondary: '#000000',
  onBackground: '#000000',

  onSurface: {
    light: '#000000',
    light_87: 'rgba(0, 0, 0, 0.87)',
    light_60: 'rgba(0, 0, 0, 0.6)',
    light_38: 'rgba(0, 0, 0, 0.38)',
    light_12: 'rgba(0, 0, 0, 0.12)',
    dark: '#FFFFFF',
    dark_87: 'rgba(255, 255, 255, 0.87)',
    dark_60: 'rgba(255, 255, 255, 0.6)',
    dark_38: 'rgba(255, 255, 255, 0.38)',
    dark_12: 'rgba(255, 255, 255, 0.12)',
  },
  onError: '#FFFFFF',
  scrim: 'rgba(0, 0, 0, 0.32)',
  overlay: {
    light: '#000000',
    light_12: 'rgba(0, 0, 0, 0.12)',
    light_8: 'rgba(0, 0, 0, 0.08)',
    light_4: 'rgba(0, 0, 0, 0.04)',
    dark: '#FFFFFF',
    dark_12: 'rgba(255, 255, 255, 0.12)',
    dark_8: 'rgba(255, 255, 255, 0.08)',
    dark_4: 'rgba(255, 255, 255, 0.04)',
  },
  shadow: {
    shadow: '#000000',
    shadow_20: 'rgba(0, 0, 0, 0.2)',
    shadow_14: 'rgba(0, 0, 0, 0.14)',
    shadow_12: 'rgba(0, 0, 0, 0.12)',
  },
  slideBackground: {
    light: '#F5F5F5',
    dark: '#292929',
  },
  outline: 'rgba(255, 255, 255, 0.12)',
  brand: {
    light: '#D69929',
    dark: '#BB350F',
  },
}
