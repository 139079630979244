import { Button, CircularProgress, DialogContent, TextField, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useCallback, useState } from 'react'
import useConstrainedImage from '../../hooks/useConstrainedImage'
import useMst from '../../models/useMst'
import logo from '../../public/images/camfed_logo.png'
import useShowMessage from '../Common/useShowMessage'
import useNavigator from '../Navigation/useNavigator'
import ResponsiveFrame from './ResponsiveFrame'

type FindYourAccountPageProps = {}

const FindYourAccountPage = ({}: FindYourAccountPageProps) => {
  const { auth } = useMst()
  const { typography } = useTheme()
  const navigator = useNavigator()
  const { t } = useTranslation('auth')
  const { t: tCommon } = useTranslation('common')
  const [username, setUsername] = useState('')

  const { showMessage, messageDialog } = useShowMessage()

  const handleUsernameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value)
  }, [])

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault()
      try {
        showMessage({
          buttons: [], // no buttons
          content: (
            <DialogContent className="text-center py-4">
              <CircularProgress />
            </DialogContent>
          ),
          title: t('requestingPasswordReset') ?? '',
        })

        const message = await auth.requestChangePassword(username)

        showMessage({
          buttons: [{ text: tCommon('okay') ?? '', onClick: () => navigator.pop() }],
          message,
          title: tCommon('success') ?? '',
        })
      } catch (e) {
        let message = t('failedToRequestPasswordReset')
        if (e instanceof Error) {
          message = e.message
        }
        showMessage({
          buttons: [{ text: tCommon('okay') ?? '' }],
          message,
          title: tCommon('error') ?? '',
        })
      }
    },
    [auth, navigator, showMessage, t, tCommon, username]
  )

  const handleCancelClick = useCallback(() => {
    navigator.pop()
  }, [navigator])

  const { setParentElement, width, height, onLoadingComplete } = useConstrainedImage({
    maxHeight: 80,
  })

  const submitDisabled = !username.length

  return (
    <ResponsiveFrame>
      <form className="flex flex-col" ref={setParentElement} onSubmit={handleSubmit}>
        <Image
          alt=""
          className="max-w-full object-contain object-left"
          src={logo}
          width={width}
          height={height}
          onLoadingComplete={onLoadingComplete}
        />
        <Typography className="font-bold mt-6" variant="h4">
          {t('findYourGuideHubAccount')}
        </Typography>
        <TextField
          variant="outlined"
          className="mt-7"
          label={t('phoneOrEmail')}
          value={username}
          onChange={handleUsernameChange}
        />
        <div className="mt-8 flex flex-row">
          <Button
            color="primary"
            disabled={submitDisabled}
            sx={{ ...typography.button.primary.dark }}
            type="submit"
            variant="contained"
          >
            {tCommon('search')}
          </Button>
          <Button className="ml-4" color="primary" onClick={handleCancelClick} variant="outlined">
            {tCommon('cancel')}
          </Button>
        </div>
      </form>
      {messageDialog}
    </ResponsiveFrame>
  )
}

export default FindYourAccountPage
