import { CircularProgress } from '@mui/material'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import useErrorMessage from '../../../../hooks/useErrorMessage'
import image from '../../../../public/images/camfed_people.png'
import PlaceholderMessage from '../../../PlaceholderMessage'

interface PlaceholderProps {
  className?: string
  error?: unknown
  isLoading?: boolean
  message?: string
}

const Placeholder = ({ className = '', error, isLoading, message }: PlaceholderProps) => {
  const { t: tCommon } = useTranslation('common')
  const errorMessage = useErrorMessage(error)

  return (
    <div className={`flex justify-center ${className}`}>
      <PlaceholderMessage
        alignBodyText="center"
        header={
          isLoading ? (
            <CircularProgress size={24} />
          ) : error ? (
            `${tCommon('error')}: ${errorMessage}`
          ) : (
            message ?? tCommon('noEntriesFound')
          )
        }
        image={<Image className="object-contain" src={image} height={156} width={156} alt="" />}
      />
    </div>
  )
}

export default Placeholder
