import { ReactElement, useMemo } from 'react'
import useOnValueChange from '../../../../hooks/useOnValueChange'
import compileExp from '../utils/compileExp'
import { useUserReportContext } from './UserReportContext'

type RenderGuardProps = {
  children: ReactElement | (() => ReactElement)
  condition: string | undefined
  onInvisible?: () => void
}

export function RenderGuard(props: RenderGuardProps) {
  const { children, condition, onInvisible } = props

  const { variables } = useUserReportContext()

  const visibilityPredicate = useMemo(() => compileExp(condition), [condition])

  const isVisible = useMemo(() => {
    return visibilityPredicate ? Boolean(visibilityPredicate(variables)) : true
  }, [variables, visibilityPredicate])

  // detect becoming invisible
  useOnValueChange(isVisible, (isVisible, wasVisible) => {
    if (wasVisible && !isVisible) {
      onInvisible?.()
    }
  })

  return isVisible ? (typeof children === 'function' ? children() : children) : null
}
