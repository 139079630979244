import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { myBetterWorldGroupsQueryKey } from '../../../../api/constants'
import { myBetterWorldGroupsEndpoint } from '../../../../api/endpoints'
import { IMyBetterWorldGroupDto } from '../../../../api/types'

export default function useGetMyBetterWorldGroups() {
  return useQuery({
    queryKey: myBetterWorldGroupsQueryKey,
    queryFn: async (): Promise<IMyBetterWorldGroupDto[] | undefined> => {
      const resp = await axios.get<{ data: IMyBetterWorldGroupDto[] }>(myBetterWorldGroupsEndpoint)
      return resp.data.data
    },
  })
}
