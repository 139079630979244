import { Box, CircularProgress, Typography, useTheme } from '@mui/material'
import { FetchStatus } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useTranslation } from 'next-i18next'
import { PropsWithChildren } from 'react'
import useMst from '../../models/useMst'

interface IRequestLoadingHandler<ErrorType = unknown> {
  error: ErrorType
  errorClassName?: string
  fetchStatus: FetchStatus
  isError: boolean
  isLoading: boolean
  isSuccess: boolean
  loadingClassName?: string
}

export default function RequestLoadingHandler(props: PropsWithChildren<IRequestLoadingHandler>): JSX.Element {
  const { typography } = useTheme()
  const { t } = useTranslation('loading-handler')

  const { messages } = useMst()
  const { showMessage } = messages

  const { children, error, errorClassName, fetchStatus, isError, isLoading, isSuccess, loadingClassName } = props

  if (isLoading) {
    if (fetchStatus === 'fetching' || fetchStatus === 'idle') {
      // Loading and idle would encompass background refetches
      return (
        <Box className={loadingClassName} sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )
    } else {
      // The only other option is paused
      return (
        <Box className={loadingClassName}>
          <Typography component="p" sx={{ ...typography.caption.error.light }}>
            {t('noInternet')}
          </Typography>
        </Box>
      )
    }
  } else if (isError) {
    const err = error as AxiosError
    showMessage(t('requestError', { message: err.message }), 'error')
    return <div className={errorClassName}>{String(error)}</div>
  } else if (isSuccess) {
    return <>{children}</>
  } else {
    // Todo: handle no data in elegant way
    return <div className={errorClassName}>NO DATA</div>
  }
}
