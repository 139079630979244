import { useUser } from '@auth0/nextjs-auth0/client'
import FlagIcon from '@mui/icons-material/Flag'
import GpsFixedIcon from '@mui/icons-material/GpsFixed'
import MailIcon from '@mui/icons-material/Mail'
import PersonIcon from '@mui/icons-material/Person'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import PublicIcon from '@mui/icons-material/Public'
import SchoolIcon from '@mui/icons-material/School'
import WorkIcon from '@mui/icons-material/Work'
import { Divider } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { USER_ROLES } from '../../../../hooks/useRoles'
import { useAnalytics } from '../../../Analytics/AnalyticsContext'
import AppBarBackButton from '../../../AppBarBackButton'
import AuthLayout from '../../../AuthLayout'
import CamfedAppBar from '../../../CamfedAppBar'
import ListSectionTitle from '../../../Common/ListSectionTitle'
import usePrompt from '../../../Common/usePrompt'
import useCurrentLanguage from '../../../Languages/useCurrentLanguage'
import useLanguageDialog from '../../../Languages/useLanguageDialog'
import { IScreenProps } from '../../../Navigation/types'
import useNavigator from '../../../Navigation/useNavigator'
import BodyText from '../Common/BodyText'
import IconTextRow from '../Common/IconTextRow'
import SectionHeading from '../Common/SectionHeading'
import useMutateProfile from './hooks/useMutateProfile'
import useResolvedProfile from './hooks/useResolvedProfile'

interface AccountProfilePageProps extends IScreenProps {}

const AccountProfilePage = observer(({}: AccountProfilePageProps) => {
  const { user } = useUser()
  const navigator = useNavigator()
  const { t } = useTranslation('account-profile')
  const { t: tCommon } = useTranslation('common')
  const title = t('myProfile')
  const { languageDialog, presentLanguageDialog } = useLanguageDialog()
  const language = useCurrentLanguage()
  const { data: profile } = useResolvedProfile()

  const isLearnerGuide = user?.meta?.roles?.some((r) => r.name === USER_ROLES.learnerGuide)

  const { mutate } = useMutateProfile()

  const { trackPhoneUpdated, trackEmailUpdated } = useAnalytics()

  const { showPrompt: changePhoneNumber, promptDialog: phoneNumberDialog } = usePrompt({
    initialValue: profile.contact_phone_number ?? '',
    inputLabel: t('phoneNumber'),
    onConfirm: useCallback(
      (contact_phone_number: string) => {
        if (user?.sub) {
          mutate({
            data: { user_id: user.sub, contact_phone_number },
            onSuccess(data, saved) {
              if (saved) {
                trackPhoneUpdated()
              }
            },
          })
        }
      },
      [mutate, trackPhoneUpdated, user?.sub]
    ),
    title: t('phoneNumber') ?? '',
  })

  const { showPrompt: changeEmail, promptDialog: emailDialog } = usePrompt({
    initialValue: profile.contact_email ?? '',
    inputLabel: t('emailAddress'),
    onConfirm: useCallback(
      (contact_email: string) => {
        if (user?.sub) {
          mutate({
            data: { user_id: user.sub, contact_email },
            onSuccess(data, saved) {
              if (saved) {
                trackEmailUpdated()
              }
            },
          })
        }
      },
      [mutate, trackEmailUpdated, user?.sub]
    ),
    title: t('emailAddress') ?? '',
  })

  return (
    <AuthLayout appBar={<CamfedAppBar centralText={title} leftButton={<AppBarBackButton onClick={navigator.pop} />} />}>
      <div className="flex flex-col py-4">
        <div>
          <ListSectionTitle className="mx-4 mb-1">{t('contactDetails')}</ListSectionTitle>
          <IconTextRow label={t('name')} detailText={user?.name} icon={<PersonIcon />} />
          <IconTextRow
            actionText={tCommon('edit')}
            detailText={profile.contact_phone_number}
            icon={<PhoneIphoneIcon />}
            label={t('phoneNumber')}
            onClick={changePhoneNumber}
          />
          <IconTextRow
            actionText={tCommon('edit')}
            detailText={profile.contact_email}
            icon={<MailIcon />}
            label={t('emailAddress')}
            onClick={changeEmail}
          />
          <BodyText className="mx-4">{t('accountDetailsDescription')}</BodyText>
        </div>
        <Divider className="my-4" />
        <div>
          <ListSectionTitle className="mx-4 mb-1">{t('locationDetails')}</ListSectionTitle>
          <IconTextRow label={t('country')} detailText={profile.country} icon={<FlagIcon />} />
          <IconTextRow label={t('district')} detailText={profile.district} icon={<GpsFixedIcon />} />
          {isLearnerGuide ? (
            <IconTextRow label={t('school')} detailText={profile.school} icon={<SchoolIcon />} />
          ) : null}
        </div>
        <Divider className="my-4" />
        <div>
          <ListSectionTitle className="mx-4 mb-1">{tCommon('languagePreferences')}</ListSectionTitle>
          <IconTextRow
            label={tCommon('language')}
            detailText={tCommon(language?.name ?? '')}
            icon={<PublicIcon />}
            onClick={presentLanguageDialog}
            actionText={tCommon('edit')}
          />
        </div>
        <Divider className="my-4" />
        <div>
          <ListSectionTitle className="mx-4 mb-1">{t('roleAndStatus')}</ListSectionTitle>
          <IconTextRow label={t('roles')} detailText={profile.roles?.join(', ')} icon={<WorkIcon />} />
        </div>
        <Divider className="my-4" />
        <div>
          <SectionHeading className="mx-4 mb-1">{t('areAnyDetailsWrong')}</SectionHeading>
          <BodyText className="mx-4">{t('detailsWrongDescription')}</BodyText>
        </div>
      </div>
      {languageDialog}
      {phoneNumberDialog}
      {emailDialog}
    </AuthLayout>
  )
})

export default AccountProfilePage
