import { useEffect, useRef } from 'react'

export default function useOnlineEffect(cb: () => void) {
  const cbRef = useRef(cb)
  cbRef.current = cb

  useEffect(() => {
    const onlineHandler = () => cbRef.current()
    window.addEventListener('online', onlineHandler)

    if (navigator.onLine) {
      onlineHandler()
    }

    return () => window.removeEventListener('online', onlineHandler)
  }, [])
}
