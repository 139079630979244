import { AxiosError } from 'axios'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { IMyBetterWorldGroupBaseDto } from '../../../../api/types'
import useMst from '../../../../models/useMst'
import generateSubmissionId from '../../../../utils/generateSubmissionId'
import AppBarBackButton from '../../../AppBarBackButton'
import AuthLayout from '../../../AuthLayout'
import BodyPadding from '../../../BodyPadding'
import CamfedAppBar from '../../../CamfedAppBar'
import { IScreenProps } from '../../../Navigation/types'
import MyBetterWorldGroupForm from '../MyBetterWorldGroupForm'
import useCreateMyBetterWorldGroup from '../hooks/useCreateMyBetterWorldGroup'

// Props extends IScreenProps
interface IAddMyBetterWorldGroupPageProps extends IScreenProps {}

const AddMyBetterWorldGroupPage = (props: IAddMyBetterWorldGroupPageProps) => {
  // Get the messages store
  const { messages } = useMst()
  const { showMessage } = messages

  // Get the my better world groups title based on the current language
  const { t } = useTranslation('my-better-world-group-form')

  const title = t('title')

  // Get the navigator from the props
  const { navigator } = props
  const { pop } = navigator

  // Remove this route from the router on click of the back button
  // Memoize the function
  const handleBack = useCallback(() => {
    pop()
  }, [pop])

  // OnSuccess callback for the mutation
  const onSuccess = useCallback(
    (data: IMyBetterWorldGroupBaseDto | undefined) => {
      // Redirect to the previous page
      pop()

      // For now, just log the data to the console
      showMessage(t('addSuccess'), 'success')
    },
    [pop, showMessage, t]
  )

  // OnError callback for the mutation
  const onError = useCallback(
    (error: unknown) => {
      const err = error as AxiosError
      const message = err.message
      showMessage(t('addError', { message }), 'error')
    },
    [showMessage, t]
  )

  // Get the mutation function for creating a new my better world group
  const { mutate } = useCreateMyBetterWorldGroup({ onSuccess, onError })

  // Memoized callback to handle the submission of the form
  // To be passed as onSubmit prop to the MyBetterWorldGroupForm component
  const handleSubmit = useCallback(
    (year: string, group: string, curriculum: string) => {
      // Call the mutation function with the value of completed set to false
      mutate({
        data: {
          completed_date: null,
          completed: 0,
          created_at: new Date().toISOString(),
          curriculum_id: curriculum,
          steps: [],
          submission_id: generateSubmissionId(),
          title: group,
          trainer_id: '', // to be set by the backend
          updated_at: new Date().toISOString(),
          year,
        },
      })
    },
    [mutate]
  )

  return (
    <AuthLayout
      appBar={<CamfedAppBar centralText={title} leftButton={<AppBarBackButton onClick={handleBack} />} prominent />}
    >
      <BodyPadding>
        <MyBetterWorldGroupForm onSubmit={handleSubmit} />
      </BodyPadding>
    </AuthLayout>
  )
}

export default AddMyBetterWorldGroupPage
