import { types } from 'mobx-state-tree'

const HomeFeed = types
  .model({
    hiddenIds: types.array(types.string),
  })

  .actions((self) => ({
    addHiddenId(id: string) {
      self.hiddenIds.push(id)
    },
  }))

export default HomeFeed
