import { Button, CircularProgress } from '@mui/material'
import * as Sentry from '@sentry/nextjs'
import { useTranslation } from 'next-i18next'
import { PropsWithChildren, useCallback, useState } from 'react'

interface ErrorBoundaryProps extends PropsWithChildren {}

export default function ErrorBoundary(props: ErrorBoundaryProps) {
  const { children } = props

  const { t } = useTranslation('common')

  const [intermission, setIntermission] = useState(false)

  const retry = useCallback((reset: () => void) => {
    setIntermission(true)
    setTimeout(() => {
      setIntermission(false)
      reset()
    }, 1000)
  }, [])

  return (
    <Sentry.ErrorBoundary
      fallback={({ resetError }: { resetError(): void }) => {
        // Return loading component if it is in intermission
        // This is to provide a visual clue that the component is being reloaded
        // This also prevents the user from clicking the button too fast and too many times, flooding the server / Sentry
        if (intermission) {
          return (
            <div className="py-8 px-4 text-center">
              <CircularProgress />
            </div>
          )
        }

        return (
          <div className="p-4">
            <h2>{t('oopsThereIsAnError')}</h2>
            <Button onClick={() => retry(resetError)} variant="contained">
              {t('tryAgain?')}
            </Button>
          </div>
        )
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}
