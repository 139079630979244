import { Dispatch, SetStateAction, useCallback, useState } from 'react'

export default function useDialogue(): [boolean, () => void, () => void, () => void] {
  const [visible, setVisible] = useState(false)

  const close = useCallback(() => setVisible(false), [])
  const open = useCallback(() => setVisible(true), [])
  const toggleVisible = useCallback(() => setVisible((old) => !old), [])

  return [visible, close, open, toggleVisible]
}
