import { forwardRef } from 'react'

const TargetBlankLink = forwardRef(
  ({ children, ...props }: React.AnchorHTMLAttributes<HTMLAnchorElement>, ref: React.Ref<HTMLAnchorElement>) => {
    return (
      <a {...props} target="__blank" rel="noopener noreferrer" ref={ref}>
        {children}
      </a>
    )
  }
)

TargetBlankLink.displayName = 'TargetBlankLink'

export default TargetBlankLink
