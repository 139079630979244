/**
 * Hook to search entries for content type of curruiculum
 *
 */

import { ISocialSupportCategoryFields } from '../../../../@types/generated/contentful'
import { ContentTypes } from '../../../../api/contentTypeIds'
import useGetAllEntries from '../../../../hooks/requests/useGetAllEntries'

export default function useGetSocialSupportCategories() {
  // Search the entries
  return useGetAllEntries<ISocialSupportCategoryFields>(ContentTypes.socialSupportCategory)
}
