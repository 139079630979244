import Auth0 from 'auth0-js'
import { flow, SnapshotIn, types } from 'mobx-state-tree'
import { logError } from '../components/Common/loggers'

const clientID = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID as string
const domain = process.env.NEXT_PUBLIC_AUTH0_DOMAIN as string
const loginRedirectUri = process.env.NEXT_PUBLIC_AUTH0_LOGIN_REDIRECT_URI as string

const Auth = types
  .model({
    loggingOut: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    confirmLogout() {
      self.loggingOut = true
    },
  }))
  .actions((self) => {
    let webAuth: Auth0.WebAuth

    function afterCreate() {
      webAuth = new Auth0.WebAuth({
        domain,
        clientID,
        redirectUri: loginRedirectUri,
        responseType: 'token id_token',
        scope: 'openid profile email offline_access',
      })
    }

    return {
      afterCreate,
      requestChangePassword: flow(function* (email: string) {
        return yield new Promise<string>((resolve, reject) => {
          webAuth.changePassword(
            {
              connection: 'Username-Password-Authentication',
              email,
            },
            (err, message: string) => {
              if (err) {
                logError('Failed to request change password', err)
                reject(new Error(err.description))
              } else {
                resolve(message)
              }
            }
          )
        })
      }),
    }
  })

const PersistedAuth = types.snapshotProcessor(Auth, {
  postProcessor(sn: SnapshotIn<typeof Auth>) {
    const ret = {
      ...sn,
      loggingOut: false, // always set loggingOut to false when restoring from snapshot
    }
    return ret
  },
})

export default PersistedAuth
