import { Box } from '@mui/material'
import { PropsWithChildren } from 'react'
import ErrorBoundary from '../ErrorBoundary'
import StoragePermissionWall from '../StoragePermissionWall'

export interface IAuthLayout {
  appBar: JSX.Element
}

export default function AuthLayout(props: PropsWithChildren<IAuthLayout>): JSX.Element {
  const { appBar, children } = props

  return (
    <StoragePermissionWall>
      <Box
        sx={{
          display: 'flex',
          minHeight: '100vh',
          flexDirection: 'column',
        }}
      >
        {appBar}
        <Box
          sx={{ flexGrow: 1, backgroundColor: '#fff', display: 'flex', flexDirection: 'column', position: 'relative' }}
        >
          <ErrorBoundary>{children}</ErrorBoundary>
        </Box>
      </Box>
    </StoragePermissionWall>
  )
}
