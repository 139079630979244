import { types } from 'mobx-state-tree'
import { IRoute } from './Router'

const PersistentStorage = types
  .model({
    hasAcknowledged: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setHasAcknowledged(value: boolean) {
      self.hasAcknowledged = value
    },
  }))
  .views((self) => ({
    shouldShowStackNavigation(route: IRoute) {
      // Only show stack navigation if the route is not the login route and the user has acknowledged the disclaimer
      return route.name !== 'login' && self.hasAcknowledged
    },
  }))

export default PersistentStorage
