import { toJS } from 'mobx'
import { useMemo } from 'react'
import useStableMobxSnapshot from '../../../../../hooks/useStableMobxSnapshot'
import useMst from '../../../../../models/useMst'
import useMyProfile from '../../../../Auth/useMyProfile'

export default function useResolvedProfile() {
  const { data: profile, ...useQueryResult } = useMyProfile()

  const mst = useMst()
  const localData = useStableMobxSnapshot(() => toJS(mst.profile.localProfile))

  const resolved = useMemo(
    () => ({
      ...profile,
      ...localData,
    }),
    [localData, profile]
  )

  return { data: resolved, ...useQueryResult }
}
