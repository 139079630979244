import { useMemo } from 'react'
import useMst from '../../models/useMst'
import { INavigator } from './types'

export default function useNavigator(): INavigator {
  const { router } = useMst()
  const { appendRoute, popRoute, removeRoute, replaceRoot, replaceTopRoute } = router

  return useMemo(
    () => ({ replaceRoot, replaceTopRoute, push: appendRoute, pop: popRoute, remove: removeRoute }),
    [appendRoute, popRoute, removeRoute, replaceRoot, replaceTopRoute]
  )
}
