import { FieldError } from 'react-hook-form'
import { getErrorMessage } from '../../../../hooks/useErrorMessage'
import { IPlainReportField } from '../types'

export default function getFormFieldError(
  name: string,
  field: IPlainReportField | undefined,
  error: FieldError | unknown,
  t: (key: string, variables?: any) => string | null | undefined,
  short = false
): string | undefined {
  if (error) {
    const fieldName = field?.label || field?.name || name
    if (typeof error === 'object' && 'type' in error) {
      if (error.type === 'required') {
        return short
          ? t('validation:fieldIsRequiredShort') || `Required`
          : t('validation:fieldIsRequired', { fieldName }) || `${fieldName} is required`
      } else if (error.type === 'minLength') {
        return short
          ? t('validation:fieldIsTooShortShort', { n: field?.minLength }) || `Too short`
          : t('validation:fieldIsTooShort', { fieldName, n: field?.minLength }) || `${fieldName} is too short`
      } else if (error.type === 'maxLength') {
        return short
          ? t('validation:fieldIsTooLongShort', { n: field?.maxLength }) || `Too long`
          : t('validation:fieldIsTooLong', { fieldName, n: field?.maxLength }) || `${fieldName} is too long`
      } else if (error.type === 'min') {
        return short
          ? t('validation:fieldIsTooSmallShort', { n: field?.minValue }) || `Too small`
          : t('validation:fieldIsTooSmall', { fieldName, n: field?.minValue }) || `${fieldName} is too small`
      } else if (error.type === 'max') {
        return short
          ? t('validation:fieldIsTooBigShort', { n: field?.maxValue }) || `Too big`
          : t('validation:fieldIsTooBig', { fieldName, n: field?.maxValue }) || `${fieldName} is too big`
      } else if (error.type === 'pattern') {
        return short
          ? t('validation:fieldIsInvalidShort') || `Invalid`
          : t('validation:fieldIsInvalid', { fieldName }) || `${fieldName} is invalid`
      } else if (error.type === 'validate') {
        if ('message' in error && error.message) {
          return String(error.message)
        } else {
          return short
            ? t('validation:fieldIsInvalidShort') || `Invalid`
            : t('validation:fieldIsInvalid', { fieldName }) || `${fieldName} is invalid`
        }
      }
    }
    return `${fieldName}: ${getErrorMessage(error, t('common:unknownError'))}`
  }
}
