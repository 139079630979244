import { useEffect, useState } from 'react'

export function isOnline() {
  return Boolean(
    typeof navigator === 'undefined' || // when server side rendering, assume online
      navigator.onLine // when client side, use the browser API
  )
}

export default function useIsOnline() {
  const [online, setOnline] = useState<boolean>(isOnline())

  useEffect(() => {
    const onlineHandler = () => {
      setOnline(true)
    }
    window.addEventListener('online', onlineHandler)
    return () => window.removeEventListener('online', onlineHandler)
  }, [])

  useEffect(() => {
    const offlineHandler = () => {
      setOnline(false)
    }
    window.addEventListener('offline', offlineHandler)
    return () => window.removeEventListener('offline', offlineHandler)
  }, [])

  return online
}
