import { useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import { toJS } from 'mobx'
import { useTranslation } from 'next-i18next'
import { myBetterWorldGroupQueryKey, myBetterWorldGroupsQueryKey } from '../../../../api/constants'
import { myBetterWorldGroupEndpoint } from '../../../../api/endpoints'
import { IMyBetterWorldGroupDto, IMyBetterWorldGroupLocalData } from '../../../../api/types'
import useOfflineMutation from '../../../../hooks/offline/useOfflineMutation'
import useMst from '../../../../models/useMst'
import { getMyBetterWorldGroup } from './useGetMyBetterWorldGroup'
import { deleteMyBetterWorldGroupQueryData, updateMyBetterWorldGroupQueryData } from './useSubmitUnsavedGroups'

interface IMutateStepVars {
  completed: boolean
  groupId: number
  stepId: string
}

const allowedUpdateFields = ['completed', 'title'] // only update these fields

export const syncMyBetterWorldGroup = async (group: IMyBetterWorldGroupLocalData) => {
  if (group.id) {
    const groupId = group.id

    // fetch latest group data
    const remote = await getMyBetterWorldGroup(group.id)

    if (remote) {
      // update group if any fields have changed
      const isDirty = !isEqual(pick(group, allowedUpdateFields), pick(remote, allowedUpdateFields))
      if (isDirty) {
        await updateMyBetterWorldGroup(group)
      }

      // get existing step ids
      const existingStepIds = new Set(remote.steps?.map((step) => step.step_id))

      // get new step ids
      const newStepIds = new Set(group.steps?.map((step) => step.step_id))

      // get steps to add
      const stepsToAdd = group.steps?.filter((step) => !existingStepIds.has(step.step_id)) ?? []

      // get steps to remove
      const stepsToRemove = remote.steps?.filter((step) => !newStepIds.has(step.step_id)) ?? []

      // sync steps
      await Promise.all([
        ...stepsToAdd.map((step) => updateSingleStep({ completed: true, groupId: groupId, stepId: step.step_id })),
        ...stepsToRemove.map((step) => updateSingleStep({ completed: false, groupId: groupId, stepId: step.step_id })),
      ])
    }
  }
}

export const updateMyBetterWorldGroup = async (
  group: IMyBetterWorldGroupLocalData
): Promise<IMyBetterWorldGroupDto | undefined> => {
  if (group.id) {
    const { completed, curriculum_id, submission_id, title, trainer_id, year } = group
    const attributes = { completed, curriculum_id, submission_id, title, trainer_id, year }
    const res = await axios.put<{ data: IMyBetterWorldGroupDto }>(myBetterWorldGroupEndpoint(group.id), {
      attributes,
    })
    return res.data.data
  }
}

export const deleteMyBetterWorldGroup = async (group: IMyBetterWorldGroupLocalData) => {
  if (group.id) {
    await axios.delete<IMyBetterWorldGroupDto>(myBetterWorldGroupEndpoint(group.id))
  }
}

export const updateSingleStep = async (vars: IMutateStepVars) => {
  const { completed, groupId, stepId } = vars

  const res = await fetch(
    `/api/my-better-world-groups/${encodeURIComponent(groupId)}/steps/${encodeURIComponent(stepId)}`,
    {
      method: completed ? 'POST' : 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  if (!res.ok) {
    throw new Error('Error mutating step')
  }
  const data = await res.json()
  return data
}

interface IMutateArgs {
  group: IMyBetterWorldGroupLocalData
  change: { type: 'updateProps' } | { type: 'toggleStep'; stepId: string } | { type: 'delete' }
}

export default function useMutateMyBetterWorldGroup() {
  const queryClient = useQueryClient()

  const { messages, myBetterWorldGroups } = useMst()

  const { t } = useTranslation('my-better-world-group-form')

  return useOfflineMutation<IMutateArgs, number | undefined>({
    addLocal: (data) => {
      myBetterWorldGroups.enqueueForUpdate(data.group)
    },
    removeLocal: (data) => {
      if (data.group.id) {
        // Optimistic update query data
        const localGroup = toJS(myBetterWorldGroups?.queue?.find((g) => g.id === data.group.id))
        if (localGroup) {
          if (localGroup.isDeleted) {
            deleteMyBetterWorldGroupQueryData(queryClient, data.group.id)
            myBetterWorldGroups.removeGroup(data.group)
          } else {
            updateMyBetterWorldGroupQueryData(queryClient, localGroup as IMyBetterWorldGroupDto)
            myBetterWorldGroups.didUpdateGroup(data.group.id)
          }
        }
      }
    },
    saveRemote: async ({ group, change }) => {
      if (group.id) {
        // only update existing groups; new groups are handled by `useSubmitUnsavedGroups`
        if (change.type === 'toggleStep') {
          const completed = (group.steps ?? []).findIndex((s) => s.step_id === change.stepId) !== -1
          await updateSingleStep({
            completed,
            groupId: group.id,
            stepId: change.stepId,
          })
        } else if (change.type === 'updateProps') {
          await updateMyBetterWorldGroup(group)
        } else if (change.type === 'delete') {
          await deleteMyBetterWorldGroup(group)
        }
        return group.id
      }
    },
    onSuccess: (data, saved, savedGroupId) => {
      const changeType = data.change.type

      if (changeType === 'delete' && !data.group.id) {
        // if group is not created yet and we're deleting it, simply remove it from the queue
        myBetterWorldGroups.removeGroup(data.group)
      }

      if (savedGroupId && changeType !== 'delete') {
        // refresh individual group
        queryClient.invalidateQueries(myBetterWorldGroupQueryKey(savedGroupId))
      }

      if (changeType === 'updateProps' || changeType === 'delete') {
        // refresh listing
        queryClient.invalidateQueries(myBetterWorldGroupsQueryKey)
      }

      // Messages
      if (saved) {
        if (changeType === 'delete') {
          messages.showMessage(t('deleteSuccess'), 'success')
        } else {
          // Show success message
          messages.showMessage(t('updateSuccess'), 'success')
        }
      }
    },
    onError: (error, data) => {
      const err = error as AxiosError
      const message = err.message
      // Error messages

      // If change is delete, show delete error message
      if (data.data.change.type === 'delete') {
        messages.showMessage(t('deleteError', { message }), 'error')
      } else {
        messages.showMessage(t('updateError', { message }), 'error')
      }
    },
  })
}

export type MutateMyBetterWorldGroup = ReturnType<typeof useMutateMyBetterWorldGroup>['mutate']
