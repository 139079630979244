import { Button, useTheme } from '@mui/material'
import { MouseEventHandler, PropsWithChildren } from 'react'

interface ISimpleActionButton {
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export default function SimpleActionButton(props: PropsWithChildren<ISimpleActionButton>): JSX.Element {
  const { children, onClick } = props

  const { typography } = useTheme()

  return (
    <Button sx={{ ...typography.button.primary.light.enabled }} onClick={onClick}>
      {children}
    </Button>
  )
}
