import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { socialSupportCaseQueryKey } from '../../../../api/constants'
import { socialSupportCaseEndpoint } from '../../../../api/endpoints'
import { ISocialSupportCaseDto } from '../../../../api/types'

interface IUseGetSocialSupportCaseProps {
  caseId: number | undefined
}

export async function getSocialSupportCase(caseId: number): Promise<ISocialSupportCaseDto> {
  const res = await axios.get<{ data: ISocialSupportCaseDto }>(socialSupportCaseEndpoint(caseId))
  return res.data.data
}

const useGetSocialSupportCase = (props: IUseGetSocialSupportCaseProps) => {
  const { caseId } = props

  return useQuery<ISocialSupportCaseDto, Error>({
    queryKey: socialSupportCaseQueryKey(caseId!),
    queryFn: () => getSocialSupportCase(caseId!),
    enabled: !!caseId,
  })
}

export default useGetSocialSupportCase
