// Function that accepts an array of my better world groups and returns a new array that is sorted first by year and then by group name
// Most recent year first
// Within the same year, sort by group title in ascending alphabetical order

import { toJS } from 'mobx'
import { useMemo } from 'react'
import { IMyBetterWorldGroupDto, IMyBetterWorldGroupLocalData } from '../../../../api/types'
import useStableMobxSnapshot from '../../../../hooks/useStableMobxSnapshot'
import useMst from '../../../../models/useMst'
import useGetMyBetterWorldGroups from './useGetMyBetterWorldGroups'
import { resolveMyBetterWorldGroup } from './useMyBetterWorldGroup'

// The original array should not be mutated
const sortMyBetterWorldGroups = (myBetterWorldGroups: (IMyBetterWorldGroupDto | IMyBetterWorldGroupLocalData)[]) => {
  const sortedGroups = [...myBetterWorldGroups]
  sortedGroups.sort((a, b) => {
    if (a.year > b.year) {
      return -1
    } else if (a.year < b.year) {
      return 1
    } else {
      if (a.title > b.title) {
        return 1
      } else if (a.title < b.title) {
        return -1
      } else {
        return 0
      }
    }
  })
  return sortedGroups
}

const mergeLocalAndRemoteGroups = (
  localGroups: IMyBetterWorldGroupLocalData[],
  remoteGroups: IMyBetterWorldGroupDto[] | undefined
) => {
  const localExistingGroups = localGroups.filter((g) => g.id)
  const localNewGroups = localGroups.filter((g) => !g.id)

  // map local groups by id
  const localGroupsById = localExistingGroups.reduce((acc, group) => {
    if (group.id) {
      acc[group.id] = group
    }
    return acc
  }, {} as Record<number, IMyBetterWorldGroupLocalData>)

  // merge local and remote groups
  const mergedGroups: (IMyBetterWorldGroupLocalData | IMyBetterWorldGroupDto)[] = (remoteGroups ?? []).map(
    (remoteGroup) => {
      const localGroup = localGroupsById[remoteGroup.id]
      if (localGroup) {
        delete localGroupsById[remoteGroup.id]
        const [resolvedGroup] = resolveMyBetterWorldGroup(remoteGroup, remoteGroup, localGroup)
        return resolvedGroup
      }
      return { ...remoteGroup, isLocal: false }
    }
  )

  // add any remaining local groups
  mergedGroups.push(...Object.values(localGroupsById), ...localNewGroups)

  // only return groups that are not deleted
  return mergedGroups.filter((g) => !g.isDeleted)
}

export default function useMyBetterWorldGroups() {
  const { data, isLoading, isError, error, fetchStatus, isSuccess } = useGetMyBetterWorldGroups()

  // get local data
  const { myBetterWorldGroups } = useMst()
  const localData = useStableMobxSnapshot(() => toJS(myBetterWorldGroups?.queue) as IMyBetterWorldGroupLocalData[])

  // merge local and remote data
  const groups = useMemo(() => mergeLocalAndRemoteGroups(localData, data), [data, localData])

  // Group the myBetterWorldGroups by whether they are completed or not
  const groupsComplete = groups.filter((group) => group.completed)
  const groupsIncomplete = groups.filter((group) => !group.completed)

  // Sort the groups by year and then by group name
  const sortedGroupsComplete = sortMyBetterWorldGroups(groupsComplete)
  const sortedGroupsIncomplete = sortMyBetterWorldGroups(groupsIncomplete)

  return { isLoading, isError, error, fetchStatus, isSuccess, groups, sortedGroupsComplete, sortedGroupsIncomplete }
}
