import { ISocialSupportCategoryFields } from '../../../../@types/generated/contentful'
import { ISocialSupportCaseCategoryDto, ResolvedFields } from '../../../../api/types'
import getCategoryCode from './getCategoryCode'

export default function isCategoryEqual(
  lhs: string | ISocialSupportCaseCategoryDto | ResolvedFields<ISocialSupportCategoryFields> | null | undefined,
  rhs: string | ISocialSupportCaseCategoryDto | ResolvedFields<ISocialSupportCategoryFields> | null | undefined
) {
  return getCategoryCode(lhs) === getCategoryCode(rhs)
}
