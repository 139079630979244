import { SelectChangeEvent } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import generateYearOptions from '../../../utils/generateYearOptions'
import { logInfo } from '../../Common/loggers'
import useGetSocialSupportCategories from './hooks/useGetSocialSupportCategories'

export type OnSubmitSocialSupportCaseForm = (data: {
  year: string
  caseTitle: string
  selectedCategory: string[]
  caseNotes: string
  number_of_boys: number
  number_of_girls: number
}) => void
interface IUseSocialSupportCaseFormProps {
  onSubmit: OnSubmitSocialSupportCaseForm
}

export default function useSocialSupportCaseForm(props: IUseSocialSupportCaseFormProps) {
  const { onSubmit } = props

  const { data: categoryData, isError, error } = useGetSocialSupportCategories()

  // TODO: Handle errors gracefully
  // If there is an error, log it
  useEffect(() => {
    if (isError) {
      logInfo('Error', error)
    }
  }, [error, isError])

  // Get a memoized values for the categories.
  // Sort the categories by the displayOrder field. Return an array of value and label, where value is fields.code and label is the fields.title
  // The code is used to identify the category by Camfed for analytic purposes.
  const categories = useMemo(
    () =>
      categoryData
        ?.sort((a, b) => (a.fields?.displayOrder ?? Infinity) - (b.fields?.displayOrder ?? Infinity))
        ?.map((category) => ({ value: category.fields?.code ?? '', label: category.fields?.title ?? '' })),
    [categoryData]
  )

  // Memoized array of year options
  // Sort the most recent year first
  const years = useMemo(() => generateYearOptions(2020).sort((a, b) => Number(b.value) - Number(a.value)), [])

  // Setup state for the caseTitle text input
  const [caseTitle, setCaseTitle] = useState('')
  // Memoized function to set the caseTitle state
  const handleCaseTitleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCaseTitle(event.target.value)
  }, [])

  // Setup state for the selected year
  const [selectedYear, setSelectedYear] = useState<string>('')
  // Memoized function to set the selectedYear state
  const handleYearChange = useCallback((event: SelectChangeEvent<string>) => {
    setSelectedYear(event.target.value)
  }, [])

  // Setup state for the selected category
  const [selectedCategory, setSelectedCategory] = useState<string[]>([])
  // Memoized function to set the selectedCategory state
  const handleCategoryChange = useCallback((event: SelectChangeEvent<string[]>) => {
    setSelectedCategory(event.target.value as string[])
  }, [])

  // Setup state for the caseNotes text input
  const [caseNotes, setCaseNotes] = useState('')
  // Memoized function to set the caseNotes state
  const handleCaseNotesChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCaseNotes(event.target.value)
  }, [])

  // Setup state for the boysNumber text input
  const [boysNumber, setBoysNumber] = useState('')
  // Memoized function to set the boysNumber state
  const handleBoysNumberChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setBoysNumber(event.target.value)
  }, [])

  // Setup state for the girlsNumber text input
  const [girlsNumber, setGirlsNumber] = useState('')
  // Memoized function to set the girlsNumber state
  const handleGirlsNumberChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setGirlsNumber(event.target.value)
  }, [])

  // Memoize the handleSubmit function for the form
  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      const b = parseInt(boysNumber)
      const g = parseInt(girlsNumber)
      onSubmit({ caseTitle, selectedCategory, caseNotes, number_of_boys: b, number_of_girls: g, year: selectedYear })
    },
    [boysNumber, caseNotes, caseTitle, girlsNumber, onSubmit, selectedCategory, selectedYear]
  )

  return {
    categories,
    caseTitle,
    handleCaseTitleChange,
    selectedCategory,
    handleCategoryChange,
    caseNotes,
    handleCaseNotesChange,
    boysNumber,
    handleBoysNumberChange,
    girlsNumber,
    handleGirlsNumberChange,
    handleSubmit,
    years,
    selectedYear,
    handleYearChange,
  }
}
