import { PropsWithChildren } from 'react'
import { rootStore, RootStoreContext } from './Root'

export const Provider = RootStoreContext.Provider

const MobxProvider = ({ children }: PropsWithChildren) => {
  return <Provider value={rootStore}>{children}</Provider>
}

export default MobxProvider
