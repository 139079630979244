import {
  FormHelperText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import { IUserReport } from '../../../../api/types'
import RichText from '../../../RichText'
import useUnregisterField from '../hooks/useUnregisterField'
import { IPlainReportInputTable, IPlainReportInputTableRow } from '../types'
import walkTableRowFields from '../utils/walkTableRowFields'
import ErrorList from './ErrorList'
import { RenderGuard } from './RenderGuard'
import ReportField from './ReportField'
import { useUserReportContext } from './UserReportContext'
interface ReportInputTableProps {
  className?: string
  isReadonly?: boolean
  table: IPlainReportInputTable
}

function Row(props: { isReadonly?: boolean; row: IPlainReportInputTableRow; userReport: IUserReport }) {
  const { isReadonly, row, userReport } = props
  const unregisterField = useUnregisterField()

  return (
    <RenderGuard
      condition={row.isVisible}
      onInvisible={() => {
        // clear values when table row becomes invisible
        walkTableRowFields(row, unregisterField)
      }}
    >
      <TableRow>
        {row.cells?.map((cell) => {
          return (
            <TableCell key={cell.id} colSpan={cell.colSpan} rowSpan={cell.rowSpan}>
              {cell.description ? <RichText document={cell.description} /> : null}
              {cell.inputs?.map((field) => {
                const initialValue = userReport[field.name]
                return (
                  <RenderGuard
                    condition={field.isVisible}
                    key={field.id}
                    onInvisible={() => {
                      unregisterField(field)
                    }}
                  >
                    <ReportField field={field} initialValue={initialValue} isReadonly={isReadonly} />
                  </RenderGuard>
                )
              })}
            </TableCell>
          )
        })}
      </TableRow>
    </RenderGuard>
  )
}

export default function ReportInputTable(props: ReportInputTableProps) {
  const { className, isReadonly, table } = props
  const { tableErrors, userReport } = useUserReportContext()
  const { typography } = useTheme()
  const errors = tableErrors[table.id]

  return (
    <div className={className}>
      {table.title ? (
        <Typography className="mb-4" sx={{ ...typography.body1.onSurface.light.mediumEmphasis }} variant="h3">
          {table.title}
        </Typography>
      ) : null}
      {table.description ? (
        <div className="mb-4">
          <RichText document={table.description} />
        </div>
      ) : null}
      {table.headerRows?.length || table.rows?.length ? (
        <TableContainer className="mb-4" component={'div'}>
          <Table
            size="small"
            sx={{
              td: { px: 1, borderBottomWidth: 0, verticalAlign: 'top' },
              'td:first-of-type': { pl: 0 },
              'td:last-of-type': { pr: 0 },
              'p:last-of-type': { mb: 0 },
            }}
          >
            <TableHead>
              {table.headerRows?.map((row) => (
                <Row isReadonly={isReadonly} key={row.id} row={row} userReport={userReport} />
              ))}
            </TableHead>
            <TableBody>
              {table.rows?.map((row) => (
                <Row isReadonly={isReadonly} key={row.id} row={row} userReport={userReport} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
      {table.helperText ? <FormHelperText className="mb-4">{table.helperText}</FormHelperText> : null}
      <ErrorList className="mb-4" errors={errors} />
    </div>
  )
}
