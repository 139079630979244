import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import useShowMessage from '../Common/useShowMessage'
import LanguageMenu from './LanguageMenu'

export default function useLanguageDialog() {
  const { t: tCommon } = useTranslation('common')
  const { dismissMessage, showMessage, messageDialog } = useShowMessage()

  const handleClick = useCallback(() => {
    showMessage({
      buttons: [
        {
          text: tCommon('cancel') ?? '',
          isCancel: true,
        },
      ],
      cancelOnBackdropClick: true,
      cancelOnEscapeKeyDown: true,
      dialogProps: {
        fullWidth: true,
        maxWidth: 'xs',
      },
      title: tCommon('selectALanguage') ?? '',
      content: (
        <div className="">
          <LanguageMenu didSelect={dismissMessage} />
        </div>
      ),
    })
  }, [dismissMessage, showMessage, tCommon])

  return { languageDialog: messageDialog, presentLanguageDialog: handleClick }
}
