import { cast, types } from 'mobx-state-tree'
import { IUserReport } from '../api/types'

const Reports = types
  .model({
    queue: types.array(types.frozen<IUserReport>()),
  })
  .actions((self) => ({
    findIndexById(id: number | undefined) {
      if (typeof id !== 'number') return -1
      return self.queue.findIndex((report) => report.id === id)
    },
    findIndexBySubmissionId(submissionId: string | undefined) {
      if (typeof submissionId !== 'string') return -1
      return self.queue.findIndex((report) => report.submission_id === submissionId)
    },
    replaceAtIndex(index: number, report: IUserReport) {
      if (index > -1) {
        self.queue.splice(index, 1, { ...report, isLocal: true })
      } else {
        self.queue.push({ ...report, isLocal: true })
      }
    },
    removeAtIndex(index: number) {
      if (index > -1) {
        self.queue.splice(index, 1)
      }
    },
  }))
  .actions((self) => ({
    findIndex: (report: IUserReport) => {
      if (report.id) {
        return self.findIndexById(report.id)
      } else if (report.submission_id) {
        return self.findIndexBySubmissionId(report.submission_id)
      } else {
        return -1
      }
    },
  }))
  .actions((self) => ({
    enqueueForUpdate(report: IUserReport) {
      if (!report.submission_id) {
        throw new Error('Submission id is required') // this should never happen so we don't need to translate it
      }
      self.replaceAtIndex(self.findIndex(report), report)
    },
    removeReport(report: IUserReport) {
      self.removeAtIndex(self.findIndex(report))
      // also clear invalid records
      self.queue = cast(self.queue.filter((r) => r.submission_id))
    },
    didSubmitReport(submissionId: string) {
      self.removeAtIndex(self.findIndexBySubmissionId(submissionId))
    },
  }))

export default Reports
