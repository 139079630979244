import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { IconButton } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

interface IAppBarBackButtonProps {
  onClick?: () => void
}

export default function AppBarBackButton(props: IAppBarBackButtonProps): JSX.Element {
  const { onClick: propOnClick } = props
  const router = useRouter()

  const { t } = useTranslation('common')

  const onClick = useCallback(() => (propOnClick ? propOnClick() : router.back()), [propOnClick, router])

  return (
    <IconButton color="inherit" aria-label={t('back') ?? ''} sx={{ mr: 2 }} onClick={onClick}>
      <ArrowBackIcon />
    </IconButton>
  )
}
