import { Options } from '@contentful/rich-text-react-renderer'
import { Block, BLOCKS, Inline, INLINES } from '@contentful/rich-text-types'
import { Divider, Theme, Typography } from '@mui/material'
import { IButtonLinkFields } from '../../@types/generated/contentful'
import { ContentTypes } from '../../api/contentTypeIds'
import { IRoute } from '../../models/Router'

import { ResolvedFields } from '../../api/types'
import getLinkedResourceRoute from '../../utils/getLinkedResourceRoute'
import platformSelect from '../../utils/platformSelect'
import SimpleArticleButton from '../SimpleArticleButton'
import ButtonLink from './components/ButtonLink'
import RichTextSubtitle from './RichTextSubtitle'

export const richTextOptionsFactory = (
  theme: Theme,
  onLinkClick: (route: IRoute) => void,
  onEmbedEntryBlockClick?: (node: Block | Inline) => void
): Options => {
  const { typography, palette } = theme
  const options: Options = {
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const target = node.data?.target
        const type = target?.sys?.contentType?.sys?.id as string | undefined
        const id = target?.sys?.id

        let route: IRoute | undefined

        if (type && id) {
          route = getLinkedResourceRoute({ id, type })
        }

        const onClick = () => {
          if (!route) {
            return
          }
          onLinkClick(route)
        }

        return (
          <span style={{ ...typography.body2.onSurface.body2.lightLink }} onClick={onClick}>
            {children}
          </span>
        )
      },
      [BLOCKS.PARAGRAPH]: (_node, children) => (
        <Typography sx={{ ...typography.body2.onSurface.body2.light, marginBottom: '12px' }}>{children}</Typography>
      ),
      [BLOCKS.HEADING_1]: (_node, children) => (
        <RichTextSubtitle component="h1" typography={typography}>
          {children}
        </RichTextSubtitle>
      ),
      [BLOCKS.HEADING_2]: (_node, children) => (
        <RichTextSubtitle component="h2" typography={typography}>
          {children}
        </RichTextSubtitle>
      ),
      [BLOCKS.HEADING_3]: (_node, children) => (
        <RichTextSubtitle component="h3" typography={typography}>
          {children}
        </RichTextSubtitle>
      ),
      [BLOCKS.HEADING_4]: (_node, children) => (
        <RichTextSubtitle component="h4" typography={typography}>
          {children}
        </RichTextSubtitle>
      ),
      [BLOCKS.HEADING_5]: (_node, children) => (
        <RichTextSubtitle component="h5" typography={typography}>
          {children}
        </RichTextSubtitle>
      ),
      [BLOCKS.HEADING_6]: (_node, children) => (
        <RichTextSubtitle component="h6" typography={typography}>
          {children}
        </RichTextSubtitle>
      ),
      [BLOCKS.HR]: () => {
        return <Divider sx={{ marginY: '8px', marginX: '-16px' }} />
      },
      [BLOCKS.QUOTE]: (_node, children) => {
        return (
          <Typography
            component="div"
            sx={{
              ...typography.body2.onSurface.body2.light,
              paddingX: '24px',
              paddingTop: '12px',
              marginY: '16px',
              display: 'block',
              borderLeft: `10px solid ${palette.brand.dark}`,
            }}
          >
            {children}
          </Typography>
        )
      },
      [BLOCKS.OL_LIST]: (_node, children) => {
        return (
          <ol style={{ marginLeft: '16px', listStyleType: 'number', ...typography.body2.onSurface.body2.light }}>
            {children}
          </ol>
        )
      },
      [BLOCKS.UL_LIST]: (_node, children) => {
        return (
          <ul style={{ marginLeft: '16px', listStyleType: 'disc', ...typography.body2.onSurface.body2.light }}>
            {children}
          </ul>
        )
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const target = node.data?.target
        const fields = target?.fields
        const id = target?.sys?.contentType?.sys?.id
        const title = fields?.title
        const excerpt = fields?.excerpt

        const onClick = () => {
          onEmbedEntryBlockClick?.(node)
        }

        if (id && (id === ContentTypes.resource || id === ContentTypes.page)) {
          return <SimpleArticleButton sx={{ padding: 0 }} title={title} excerpt={excerpt} onClick={onClick} />
        }

        // If the id equals buttonLink, render a button with the on click redirecting to the buttonURL, which is an external link so set target="_blank" and rel="noopener noreferrer"
        // Use the material-ui Button component
        if (fields && id === ContentTypes.buttonLink) {
          const button: ResolvedFields<IButtonLinkFields> = fields
          const buttonURL = platformSelect({
            android: button.buttonUrlAndroid,
            ios: button.buttonUrl_iOS,
            default: button.buttonUrl,
          })
          const buttonLabel = platformSelect({
            android: button.buttonTextAndroid,
            ios: button.buttonText_iOS,
            default: button.buttonText,
          })
          return <ButtonLink href={buttonURL} labelText={buttonLabel} theme={theme} />
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const target = node.data?.target
        const fields = target?.fields
        const title = fields?.title
        const description = fields?.description
        const file = fields?.file
        const url = file?.url
        const mime = file?.contentType

        if (url && mime) {
          if (mime.startsWith('video')) {
            return (
              <video className="my-4 w-full" controls>
                <source src={url} type={mime} />
                Your browser does not support the video element.
              </video>
            )
          } else if (mime.startsWith('audio')) {
            return (
              <audio className="my-4 w-full" controls>
                <source src={url} type={mime} />
                Your browser does not support the audio element.
              </audio>
            )
          } else if (mime.startsWith('image')) {
            // eslint-disable-next-line @next/next/no-img-element
            return <img className="my-4 max-w-full h-auto" src={url} alt={title} title={description} />
          }
        }
      },
    },
  }

  return options
}
