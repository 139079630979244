import { ListItem } from '@mui/material'
import { useTranslation } from 'next-i18next'
import BodyText from '../pages/Account/Common/BodyText'

export default function UnsyncedFootnote() {
  const { t } = useTranslation('common')

  return (
    <ListItem>
      <BodyText>* {t('unsyncedContent')}</BodyText>
    </ListItem>
  )
}
