import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { learnerGuidesInsightsQueryKey } from '../../../../api/constants'
import { learnerGuidesInsightsEndpoint } from '../../../../api/endpoints'
import { ILearnerGuideInsightsDto } from '../../../../api/types'

export interface IUseGetLearnerGuidesInsightsVars {
  learnerGuideIds: string[] | undefined
  startDate: Date | null
  endDate: Date | null
}

export default function useGetLearnerGuidesInsights(vars: IUseGetLearnerGuidesInsightsVars) {
  const { learnerGuideIds, startDate, endDate } = vars

  return useQuery({
    queryKey: learnerGuidesInsightsQueryKey(learnerGuideIds, startDate?.toISOString(), endDate?.toISOString()), // This will always be true because of the enabled flag below
    queryFn: async () => {
      // TODO: verify that this is the correct response when backend ready
      const res = await axios.post<{ data: ILearnerGuideInsightsDto; message: string; status: string }>(
        learnerGuidesInsightsEndpoint,
        {
          ids: learnerGuideIds,
          start_date: startDate?.toISOString(),
          end_date: endDate?.toISOString(),
        }
      )
      return res.data
    },
    enabled: Boolean(learnerGuideIds?.length),
  })
}
