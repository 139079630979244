import { useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { socialSupportCasesQueryKey } from '../../../api/constants'
import { socialSupportCasesEndpoint } from '../../../api/endpoints'
import { ISocialSupportCaseDto, ISocialSupportCaseLocalData } from '../../../api/types'
import useOfflineMutation from '../../../hooks/offline/useOfflineMutation'
import useMst from '../../../models/useMst'

// Mutation function
export const createSocialSupportCase = async (data: ISocialSupportCaseLocalData) => {
  const formattedData = {
    year: data.year,
    title: data.title,
    number_of_boys: data.number_of_boys,
    number_of_girls: data.number_of_girls,
    categories: data.categories,
    notes: data.notes,
    submission_id: data.submission_id,
  }
  const res = await axios.post<{ data: ISocialSupportCaseDto }>(socialSupportCasesEndpoint, formattedData)
  return res.data.data
}

// Props include an onSuccess callback and an onError callback
interface IUseCreateSocialSupportCaseProps {
  onSuccess?: (data: unknown) => void
  onError?: (error: unknown) => void
}

export default function useCreateSocialSupportCase(props: IUseCreateSocialSupportCaseProps) {
  // Destructure the props
  const { onSuccess, onError } = props

  // Query client
  const queryClient = useQueryClient()

  const { socialSupportCases } = useMst()
  const { enqueueForUpdate, didAddCase } = socialSupportCases

  return useOfflineMutation<ISocialSupportCaseLocalData, ISocialSupportCaseLocalData>({
    addLocal: (data: ISocialSupportCaseLocalData) => {
      enqueueForUpdate(data)
    },
    removeLocal: (data: ISocialSupportCaseLocalData) => {
      didAddCase(data.submission_id)
    },
    saveRemote: createSocialSupportCase,
    onSuccess: (data, saved, remoteData) => {
      if (saved) {
        // Invalidate and refetch the socialSupportCases query
        queryClient.invalidateQueries(socialSupportCasesQueryKey(remoteData?.trainer_id ?? ''))

        // Call the onSuccess callback if it exists
        onSuccess?.(remoteData)
      }
    },
    onError,
  })
}
