import { useUser } from '@auth0/nextjs-auth0/client'
import { createContext, ReactElement, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { loginRouteName } from '../../constants/routeNames'
import {
  trackButtonClick,
  trackChangePassword,
  trackEmailUpdated,
  trackFileDownload,
  trackHomeCardAction,
  trackHomeCardDismiss,
  trackLogin,
  trackLogout,
  trackOnboardingCardDismiss,
  trackOnboardingCardNext,
  trackOnboardingCardPrev,
  trackPageView,
  trackPhoneUpdated,
  trackSearchResource,
} from './trackers'

interface IAnalyticsContextValue {
  isReady: boolean
  section: string
  setCurrentOnboardingCardTitle: (title: string) => void
  trackButtonClick: (linkText: string, linkUrl: string) => void
  trackChangePassword: () => void
  trackEmailUpdated: () => void
  trackFileDownload: (resourceTitle: string, fileName: string, linkText: string) => void
  trackHomeCardAction: (cardTitle: string) => void
  trackHomeCardDismiss: (cardTitle: string) => void
  trackLogout: () => void
  trackOnboardingCardDismiss: () => void
  trackOnboardingCardNext: (cardTitle: string) => void
  trackOnboardingCardPrev: (cardTitle: string) => void
  trackPhoneUpdated: () => void
  trackSearchResource: (searchQuery: string) => void
}

const noop = () => {}

const DefaultAnalyticsContextValue: IAnalyticsContextValue = {
  isReady: false,
  section: '',
  setCurrentOnboardingCardTitle: noop,
  trackButtonClick: noop,
  trackChangePassword: noop,
  trackEmailUpdated: noop,
  trackFileDownload: noop,
  trackHomeCardAction: noop,
  trackHomeCardDismiss: noop,
  trackLogout: noop,
  trackOnboardingCardDismiss: noop,
  trackOnboardingCardNext: noop,
  trackOnboardingCardPrev: noop,
  trackPhoneUpdated: noop,
  trackSearchResource: noop,
}

const AnalyticsContext = createContext(DefaultAnalyticsContextValue)

export const AnalyticsContextProvider = ({
  children,
  section,
}: {
  children: ReactNode
  section: string
}): ReactElement | null => {
  const { user, isLoading } = useUser()

  const [currentOnboardCardTitle, setCurrentOnboardingCardTitle] = useState('')

  const value: IAnalyticsContextValue = useMemo(() => {
    return {
      isReady: !isLoading,
      section,
      setCurrentOnboardingCardTitle,
      trackButtonClick: user ? (...args) => trackButtonClick(user, section, ...args) : noop,
      trackChangePassword: user ? (...args) => trackChangePassword(user, section, ...args) : noop,
      trackEmailUpdated: user ? (...args) => trackEmailUpdated(user, section, ...args) : noop,
      trackFileDownload: user ? (...args) => trackFileDownload(user, section, ...args) : noop,
      trackHomeCardAction: user ? (...args) => trackHomeCardAction(user, section, ...args) : noop,
      trackHomeCardDismiss: user ? (...args) => trackHomeCardDismiss(user, section, ...args) : noop,
      trackLogout: user ? (...args) => trackLogout(user, section, ...args) : noop,
      trackOnboardingCardDismiss: user
        ? (...args) => trackOnboardingCardDismiss(user, section, currentOnboardCardTitle, ...args)
        : noop,
      trackOnboardingCardNext: user ? (...args) => trackOnboardingCardNext(user, section, ...args) : noop,
      trackOnboardingCardPrev: user ? (...args) => trackOnboardingCardPrev(user, section, ...args) : noop,
      trackPhoneUpdated: user ? (...args) => trackPhoneUpdated(user, section, ...args) : noop,
      trackSearchResource: user ? (...args) => trackSearchResource(user, section, ...args) : noop,
    }
  }, [currentOnboardCardTitle, isLoading, section, user])

  useEffect(() => {
    if (user) {
      trackPageView(user, section)
    }
  }, [section, user])

  useEffect(() => {
    if (user) {
      // read and parse cookies, find a cookie named 'logging_in'
      const cookies = document.cookie.split(/\s*;\s*/).map((cookie) => cookie.split('='))
      const loggingInCookie = cookies.find((cookie) => cookie[0] === 'logging_in')
      if (loggingInCookie?.[1] === 'true') {
        // if the cookie and user exist, then we are logged in successfully, so track the login event
        trackLogin(user, loginRouteName)

        // remove the cookie
        document.cookie = 'logging_in=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      }
    }
  }, [user])

  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>
}

export const useAnalytics = (): IAnalyticsContextValue => {
  return useContext(AnalyticsContext)
}

// convenience HOC for wrapping components with AnalyticsContextProvider
export const withAnalytics = <T extends object>(
  Component: React.ComponentType<T>,
  section: string
): React.ComponentType<T> => {
  function WrappedComponent(props: T) {
    return (
      <AnalyticsContextProvider section={section}>
        <Component {...props} />
      </AnalyticsContextProvider>
    )
  }

  return WrappedComponent
}
