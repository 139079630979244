import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

export default function useChangeLanguage() {
  const { i18n } = useTranslation('common')
  const language = i18n.language
  const router = useRouter()

  const changeLanguage = useCallback(
    (newLocale: string) => {
      const { pathname, query } = router

      // Set the new locale in the cookie so that it redirects to the correct locale after login
      document.cookie = `NEXT_LOCALE=${newLocale}; max-age=31536000; path=/;`

      // Replace the current route with the new locale
      router.replace({ pathname, query }, undefined, { locale: newLocale })
    },
    [router]
  )

  return { language, changeLanguage }
}
