// Function component with Authlayout and placeholder content

import { toJS } from 'mobx'
import { useMemo } from 'react'
import { IMyBetterWorldGroupDto, IMyBetterWorldGroupLocalData } from '../../../../api/types'
import useStableMobxSnapshot from '../../../../hooks/useStableMobxSnapshot'
import useMst from '../../../../models/useMst'
import useGetMyBetterWorldGroup from './useGetMyBetterWorldGroup'

export function resolveMyBetterWorldGroup(
  listItem: IMyBetterWorldGroupDto | IMyBetterWorldGroupLocalData | undefined,
  remoteGroup: IMyBetterWorldGroupDto | undefined,
  localGroup: IMyBetterWorldGroupLocalData | undefined
): readonly [IMyBetterWorldGroupLocalData, boolean] {
  const groupId = listItem && 'id' in listItem ? listItem.id : undefined
  const submissionId = listItem?.submission_id
  const isLocalGroupNew = localGroup && !localGroup.id
  const isValid = Boolean(groupId || isLocalGroupNew)

  // resolve attributes from remote data or local data
  const resolvedGroup: IMyBetterWorldGroupLocalData = {
    completed: localGroup?.completed ?? remoteGroup?.completed ?? listItem?.completed ?? 0,
    completed_date: remoteGroup?.completed_date ?? listItem?.completed_date ?? localGroup?.completed_date ?? '',
    created_at: remoteGroup?.created_at ?? listItem?.created_at ?? localGroup?.created_at ?? '',
    curriculum_id: localGroup?.curriculum_id ?? remoteGroup?.curriculum_id ?? listItem?.curriculum_id ?? '',
    id: groupId,
    isLocal: Boolean(localGroup),
    steps: localGroup?.steps ?? remoteGroup?.steps ?? listItem?.steps ?? [],
    submission_id: submissionId ?? '',
    title: localGroup?.title ?? remoteGroup?.title ?? listItem?.title ?? '',
    trainer_id: remoteGroup?.trainer_id ?? listItem?.trainer_id ?? localGroup?.trainer_id ?? '',
    updated_at: remoteGroup?.updated_at ?? listItem?.updated_at ?? localGroup?.updated_at ?? '',
    year: localGroup?.year ?? remoteGroup?.year ?? listItem?.year ?? '',
  }
  return [resolvedGroup, isValid] as const
}

export default function useMyBetterWorldGroup(
  listItem: IMyBetterWorldGroupDto | IMyBetterWorldGroupLocalData | undefined
) {
  // get the group id from the listItem
  const groupId = listItem && 'id' in listItem ? listItem.id : undefined
  const submissionId = listItem?.submission_id

  // fetch group from remote / cache
  const { data: remoteGroup, isLoading, isError, error, fetchStatus, isSuccess } = useGetMyBetterWorldGroup({ groupId })

  // get any pending updates from the store
  const { myBetterWorldGroups } = useMst()
  const localGroups = useStableMobxSnapshot(() => toJS(myBetterWorldGroups?.queue))
  const localGroup = localGroups.find(
    (g) => (groupId && g.id === groupId) || (submissionId && g.submission_id === submissionId)
  )

  const [resolvedGroup, isValid] = useMemo(
    () => resolveMyBetterWorldGroup(listItem, remoteGroup, localGroup),
    [listItem, localGroup, remoteGroup]
  )

  return { resolvedGroup, remoteGroup, isValid, isLoading, isError, error, fetchStatus, isSuccess }
}
