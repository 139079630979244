// Generate an array of controlled select options for the years based on a start and end year
// Default the end year to the current year
// The start year is a required parameter
const generateYearOptions = (startYear: number, endYear = new Date().getFullYear()) => {
  const yearOptions = []
  for (let i = startYear; i <= endYear; i++) {
    yearOptions.push({
      label: i.toString(),
      value: i.toString(),
    })
  }
  return yearOptions
}

export default generateYearOptions
