import { DateTime } from 'luxon'

const commonFormats: [RegExp, string][] = [
  [/^\d{1,2}\/\d{1,2}\/\d{4}$/, 'd/M/yyyy'],
  [/^\d{1,2}\/\d{1,2}\/\d{4} \d{1,2}:\d{1,2}$/, 'd/M/yyyy H:m'],
  [/^\d{1,2}\/\d{1,2}\/\d{4} \d{1,2}:\d{1,2}:\d{1,2}$/, 'd/M/yyyy H:m:s'],
  [/^\d{1,2}\/\d{1,2}\/\d{4} \d{1,2}:\d{1,2} (am|pm)$/i, 'd/M/yyyy h:m a'],
  [/^\d{1,2}\/\d{1,2}\/\d{4} \d{1,2}:\d{1,2}:\d{1,2} (am|pm)$/i, 'd/M/yyyy h:m:s a'],
]

function parseDate(date: Date, format?: string): Date
function parseDate(date: string, format?: string): Date | undefined
function parseDate(date: null, format?: string): null
function parseDate(date: undefined, format?: string): undefined
function parseDate(date: Date | string, format?: string): Date | undefined
function parseDate(date: Date | string | null, format?: string): Date | null | undefined
function parseDate(date: Date | string | undefined, format?: string): Date | undefined
function parseDate(date: string, format?: string): Date | undefined
function parseDate(date: string | null, format?: string): Date | null | undefined
function parseDate(date: string | undefined, format?: string): Date | undefined
function parseDate(date: string | null | undefined, format?: string): Date | null | undefined
function parseDate(date: Date | string | null | undefined, format?: string): Date | null | undefined {
  if (date === undefined || date === null || date instanceof Date) {
    return date
  } else if (typeof date === 'string') {
    if (date) {
      // try custom format
      if (format) {
        const d = DateTime.fromFormat(date, format).toJSDate()
        if (!isNaN(d.getTime())) {
          return d
        }
      }

      // try some common formats
      for (let [pattern, format] of commonFormats) {
        if (date.match(pattern)) {
          const d = DateTime.fromFormat(date, format).toJSDate()
          if (!isNaN(d.getTime())) {
            return d
          }
        }
      }

      // try to convert to ISO format by replacing the space before the time with a 'T'
      if (date.match(/^\d{4}-\d{2}-\d{2}\s\d/)) {
        date = date.replace(/\s/, 'T')
      }

      // try ISO format
      const d = DateTime.fromISO(date).toJSDate()
      if (!isNaN(d.getTime())) {
        return d
      }
    }
  }
  // failed to parse, return undefined
}

export default parseDate
