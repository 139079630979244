import CheckIcon from '@mui/icons-material/Check'
import { Button, Chip, Typography } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { FC, useMemo } from 'react'
import { useResourceLibraryContext } from '../Context/ResourceLibraryContext'
import useTags from './useTags'

interface IFilterViewProps {
  onApplyClick: () => void
}

const FilterView: FC<IFilterViewProps> = ({ onApplyClick }) => {
  const { t } = useTranslation('library')
  const { t: tCommon } = useTranslation('common')

  const { tags } = useTags()

  const typeTags = useMemo(() => tags.filter((tag) => tag.name.startsWith('Type: ')), [tags])
  const languageTags = useMemo(() => tags.filter((tag) => tag.name.startsWith('Language: ')), [tags])

  const { selectedTypeTags, selectedLanguages, toggleTypeTag, toggleLanguage } = useResourceLibraryContext()

  return (
    <>
      <div className="p-4 border-b">
        <Typography variant="body1">{t('filterByType')}</Typography>
        <div className="py-4 flex flex-wrap">
          {typeTags.map((tag) => {
            const isSelected = selectedTypeTags.has(tag.sys.id)
            return (
              <Chip
                className="mr-2 mb-2"
                color={isSelected ? 'primary' : undefined}
                icon={isSelected ? <CheckIcon className="text-base" /> : undefined}
                key={tag.sys.id}
                label={tag.name.replace(/^Type:\s/, '')}
                onClick={() => toggleTypeTag(tag.sys.id)}
              />
            )
          })}
        </div>
      </div>
      <div className="p-4">
        <Typography variant="body1">{t('filterByLanguage')}</Typography>
        <div className="py-4 flex flex-wrap">
          {languageTags.map((tag) => {
            const isSelected = selectedLanguages.has(tag.sys.id)
            return (
              <Chip
                className="mr-2 mb-2"
                color={isSelected ? 'primary' : undefined}
                icon={isSelected ? <CheckIcon className="text-base" /> : undefined}
                key={tag.sys.id}
                label={tCommon(tag.name.replace(/^Language\:\s/, ''))}
                onClick={() => toggleLanguage(tag.sys.id)}
              />
            )
          })}
        </div>
      </div>
      <div className="p-4">
        <Button variant="contained" color="primary" onClick={onApplyClick}>
          {t('apply')}
        </Button>
      </div>
    </>
  )
}

export default FilterView
