import { Divider, List, ListItem, ListItemButton, ListItemText, Typography, useTheme } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'next-i18next'
import { useCallback, useMemo } from 'react'
import { IMyBetterWorldGroupDto, IMyBetterWorldGroupLocalData } from '../../../../api/types'
import { addMyBetterWorldGroupRoute, myBetterWorldGroupRoute } from '../../../../constants'
import AppBarBackButton from '../../../AppBarBackButton'
import AuthLayout from '../../../AuthLayout'
import CamfedAppBar from '../../../CamfedAppBar'
import AddListItemButton from '../../../Common/AddListItemButton'
import UnsyncedFootnote from '../../../Common/UnsyncedFootnote'
import { INavigator, IScreenProps } from '../../../Navigation/types'
import RequestLoadingHandler from '../../../RequestLoadingHandler'
import useMyBetterWorldGroups from '../hooks/useMyBetterWorldGroups'

// Group component
const Group = (props: { navigator: INavigator; group: IMyBetterWorldGroupLocalData | IMyBetterWorldGroupDto }) => {
  const { navigator, group } = props
  const title = `${group.year} - ${group.title}${group.isLocal ? '*' : ''}`

  const { typography } = useTheme()

  // Onclick handler to push the route to the router
  const onClick = useCallback(() => {
    navigator.push(myBetterWorldGroupRoute(group))
  }, [group, navigator])

  return (
    <ListItem disableGutters disablePadding>
      <ListItemButton onClick={onClick}>
        <ListItemText>
          <Typography variant="subtitle1" sx={{ ...typography.subtitle1.onSurface.light.highEmphasis }}>
            {title}
          </Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  )
}

// Props  extends IScreenProps
interface IMyBetterWorldGroupsPageProps extends IScreenProps {}

const MyBetterWorldGroupsPage = observer((props: IMyBetterWorldGroupsPageProps) => {
  const { navigator } = props

  const { sortedGroupsComplete, sortedGroupsIncomplete, isLoading, isError, error, fetchStatus, isSuccess } =
    useMyBetterWorldGroups()

  // Create a memoized list of Group components for the completed groups
  const completedList = useMemo(() => {
    const hasLocalGroups = sortedGroupsComplete.some((group) => group.isLocal)
    return (
      <List>
        {sortedGroupsComplete.map((group, idx) => (
          <Group key={group.submission_id || idx} group={group} navigator={navigator} />
        ))}
        {hasLocalGroups ? <UnsyncedFootnote /> : null}
      </List>
    )
  }, [navigator, sortedGroupsComplete])

  // Create a memoized list of Group components for the incomplete groups
  const incompleteList = useMemo(() => {
    const hasLocalGroups = sortedGroupsIncomplete.some((group) => group.isLocal)
    return (
      <List>
        {sortedGroupsIncomplete.map((group, idx) => (
          <Group key={group.submission_id || idx} group={group} navigator={navigator} />
        ))}
        {hasLocalGroups ? <UnsyncedFootnote /> : null}
      </List>
    )
  }, [navigator, sortedGroupsIncomplete])

  const { pop, push } = navigator
  // Memoized function  to go back on click of back button
  const handleBack = useCallback(() => {
    pop()
  }, [pop])

  //Memoized function to handle the click of the add new group button
  // Push the addMyBetterWorldGroupPage to the router
  const handleAddNewGroup = useCallback(() => {
    push(addMyBetterWorldGroupRoute)
  }, [push])

  // Get the title based on the current language
  const { t } = useTranslation('my-better-world-groups')
  const title = t('title')
  const currentGroups = t('currentGroups')
  const completedGroups = t('completedGroups')

  // Get the typography from the theme
  const { typography } = useTheme()

  return (
    <AuthLayout
      appBar={<CamfedAppBar centralText={title} leftButton={<AppBarBackButton onClick={handleBack} />} prominent />}
    >
      <RequestLoadingHandler
        error={error}
        errorClassName="p-4"
        fetchStatus={fetchStatus}
        isError={isError}
        isLoading={isLoading}
        isSuccess={isSuccess}
        loadingClassName="p-4"
      >
        <Typography
          className="mt-4 mx-4"
          variant="subtitle2"
          sx={{ ...typography.subtitle2.onSurface.light.mediumEmphasis }}
        >
          {currentGroups}
        </Typography>
        {incompleteList}

        <AddListItemButton className="px-4 py-2" onClick={handleAddNewGroup}>
          {t('addNewGroup')}
        </AddListItemButton>

        {sortedGroupsComplete.length ? (
          <>
            <Divider className="my-4" />

            <Typography
              className="mx-4"
              variant="subtitle2"
              sx={{ ...typography.subtitle2.onSurface.light.mediumEmphasis }}
            >
              {completedGroups}
            </Typography>
            {completedList}
          </>
        ) : null}
      </RequestLoadingHandler>
    </AuthLayout>
  )
})

export default MyBetterWorldGroupsPage
