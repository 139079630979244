export default function saveBlob(blob: Blob, fileName?: string | null | undefined) {
  const href = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", fileName ?? "");

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
