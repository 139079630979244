import { DialogContent, DialogProps, TextField } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { useCallback, useEffect, useRef, useState } from 'react'
import useShowMessage from './useShowMessage'

interface PromptContentProps {
  inputLabel?: React.ReactNode
  multiLine?: boolean
  onChange: (value: string) => void
  onConfirm: () => void
  rows?: number
  value: string | undefined
}

const PromptContent = ({ inputLabel, multiLine, onChange, onConfirm, rows, value }: PromptContentProps) => {
  // ref the input element
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    // focus the input element with a delay to avoid the dialog to steal the focus
    setTimeout(() => inputRef?.current?.focus(), 100)
  }, [])

  // confirm on enter
  const handleKeyDown = useCallback(
    (evt: React.KeyboardEvent<HTMLInputElement>) => {
      if (evt.key === 'Enter') {
        // if single line / (multiline & ctrl-enter / cmd-enter), confirm
        if (!multiLine || (multiLine && (evt.ctrlKey || evt.metaKey))) {
          evt.preventDefault()
          evt.stopPropagation()
          onConfirm()
        }
      }
    },
    [multiLine, onConfirm]
  )

  return (
    <DialogContent>
      <TextField
        autoFocus
        className="mt-4"
        fullWidth
        multiline={multiLine}
        label={inputLabel}
        onChange={(evt) => onChange(evt.target.value)}
        onKeyDown={handleKeyDown}
        inputRef={inputRef}
        rows={rows}
        value={value ?? ''}
        variant="outlined"
      />
    </DialogContent>
  )
}

const dialogProps: Omit<DialogProps, 'open' | 'onClose'> = {
  fullWidth: true,
  maxWidth: 'xs',
}

interface usePromptArgs {
  initialValue?: string
  inputLabel?: React.ReactNode
  multiLine?: boolean
  onConfirm: (value: string) => void
  rows?: number
  title?: string
}

type DialogData = {
  value: string
  onConfirm: usePromptArgs['onConfirm']
}

export default function usePrompt({ initialValue = '', inputLabel, onConfirm, title, multiLine, rows }: usePromptArgs) {
  const { t } = useTranslation('my-better-world-groups')

  const [value, setValue] = useState(initialValue)
  const { showMessage, messageDialog } = useShowMessage({ value, onConfirm } as DialogData)

  const handleDone = useCallback(
    (dismiss: () => void, data: DialogData | undefined) => {
      dismiss()
      const value = data?.value ?? ''

      // Check if the value has changed
      // Note: if initialValue is undefined, the value will always be changed
      const changed = value !== initialValue

      if (changed) {
        // Only call onConfirm if the value has changed
        data?.onConfirm(value)
      }
    },
    [initialValue]
  )

  const showPrompt = useCallback(() => {
    setValue(initialValue)
    showMessage({
      cancelOnEscapeKeyDown: true,
      title,
      content: (data: DialogData | undefined, dismiss: () => void) => (
        <PromptContent
          inputLabel={inputLabel}
          onChange={setValue}
          onConfirm={() => handleDone(dismiss, data)}
          value={data?.value}
          multiLine={multiLine}
          rows={rows}
        />
      ),
      buttons: [
        {
          text: t('common:cancel') ?? '',
          isCancel: true,
        },
        {
          text: t('common:confirm') ?? '',
          onClick: (dismiss: () => void, data: DialogData | undefined) => {
            handleDone(dismiss, data)
          },
        },
      ],
      dialogProps,
    })
  }, [handleDone, initialValue, inputLabel, multiLine, rows, showMessage, t, title])

  return { showPrompt, promptDialog: messageDialog }
}
