import { QueryClient, useQueryClient } from '@tanstack/react-query'
import { toJS } from 'mobx'
import { useCallback } from 'react'
import { myBetterWorldGroupQueryKey, myBetterWorldGroupsQueryKey } from '../../../../api/constants'
import { IMyBetterWorldGroupDto } from '../../../../api/types'
import useMst from '../../../../models/useMst'
import { logError } from '../../../Common/loggers'
import { createMyBetterWorldGroup } from './useCreateMyBetterWorldGroup'
import { deleteMyBetterWorldGroup, syncMyBetterWorldGroup } from './useMutateMyBetterWorldGroup'

export function updateMyBetterWorldGroupQueryData(queryClient: QueryClient, group: IMyBetterWorldGroupDto | undefined) {
  if (group) {
    if (group.id && !group.isDeleted) {
      // this is important to avoid the step checkbox value flickering
      queryClient.setQueryData(myBetterWorldGroupQueryKey(group.id), group)
    }

    // this is important to avoid the listing items flickering
    queryClient.setQueryData(myBetterWorldGroupsQueryKey, (list: IMyBetterWorldGroupDto[] | undefined) =>
      list?.map((g) => (g.id === group.id ? group : g))
    )
  }
}

export function deleteMyBetterWorldGroupQueryData(queryClient: QueryClient, groupId: number) {
  queryClient.setQueryData(myBetterWorldGroupsQueryKey, (list: IMyBetterWorldGroupDto[] | undefined) =>
    list?.filter((g) => g.id !== groupId)
  )
}

export default function useSubmitUnsavedGroups() {
  const { myBetterWorldGroups } = useMst()
  const queryClient = useQueryClient()

  return useCallback(async () => {
    const queue = myBetterWorldGroups?.queue ?? []

    await Promise.all([
      ...queue.map(async (group) => {
        try {
          if (group.id) {
            if (group.isDeleted) {
              // delete existing group
              await deleteMyBetterWorldGroup(group)
              myBetterWorldGroups.removeGroup(group)
            } else {
              // update existing group
              await syncMyBetterWorldGroup(group)

              // optimistic update query data
              const localGroup = toJS(myBetterWorldGroups?.queue?.find((g) => g.id === group.id))
              updateMyBetterWorldGroupQueryData(queryClient, localGroup as IMyBetterWorldGroupDto)

              // remove local group
              myBetterWorldGroups.didUpdateGroup(group.id)

              // invalidate group query
              queryClient.invalidateQueries(myBetterWorldGroupQueryKey(group.id))
            }
          } else {
            if (group.isDeleted) {
              // simply remove the local group
              myBetterWorldGroups.removeGroup(group)
            } else {
              // create new group
              const newGroup = await createMyBetterWorldGroup(group)

              // optimistic update query data
              updateMyBetterWorldGroupQueryData(queryClient, newGroup)

              // remove local group
              myBetterWorldGroups.didAddGroup(group.submission_id)
            }
          }
        } catch (error) {
          logError('Failed to submit unsaved group', group, error)
        }
      }),
    ])
    queryClient.invalidateQueries(myBetterWorldGroupsQueryKey)
  }, [myBetterWorldGroups, queryClient])
}
