import { Box, Button, TextField, useTheme } from '@mui/material'
import { useTranslation } from 'next-i18next'
import ControlledSelect from '../../ControlledSelect'
import useSocialSupportCaseForm, { OnSubmitSocialSupportCaseForm } from './useSocialSupportCaseForm'

interface ISocialSupportCaseFormProps {
  onSubmit: OnSubmitSocialSupportCaseForm
}

export default function SocialSupportCaseForm(props: ISocialSupportCaseFormProps) {
  const { onSubmit } = props

  // Load the title from the translation
  const { t } = useTranslation('social-support-cases-form')

  const {
    caseNotes,
    handleCaseNotesChange,
    caseTitle,
    handleCaseTitleChange,
    selectedCategory,
    handleCategoryChange,
    categories,
    handleSubmit,
    girlsNumber,
    handleGirlsNumberChange,
    boysNumber,
    handleBoysNumberChange,
    years,
    selectedYear,
    handleYearChange,
  } = useSocialSupportCaseForm({ onSubmit })

  //   Get the typography from the theme
  const { typography } = useTheme()

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ mb: 2 }}>
        <TextField
          required
          value={caseTitle}
          onChange={handleCaseTitleChange}
          id="caseTitle"
          label={t('titleFieldLabel')}
          variant="outlined"
          fullWidth
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <ControlledSelect<string>
          label={t('yearFieldLabel') ?? ''}
          options={years}
          value={selectedYear}
          onChange={handleYearChange}
          fullWidth
          required
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <ControlledSelect<string[]>
          label={t('categoryFieldLabel') ?? ''}
          options={categories || []}
          value={selectedCategory}
          onChange={handleCategoryChange}
          fullWidth
          required
          multiple
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <TextField
          multiline
          value={caseNotes}
          onChange={handleCaseNotesChange}
          id="caseNotes"
          label={t('notesFieldLabel')}
          variant="outlined"
          fullWidth
          minRows={4}
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        {/* Material UI number input*/}
        <TextField
          required
          label={t('girlsNumberFieldLabel')}
          type="number"
          variant="outlined"
          fullWidth
          value={girlsNumber}
          onChange={handleGirlsNumberChange}
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        {/* Material UI number input*/}
        <TextField
          required
          label={t('boysNumberFieldLabel')}
          type="number"
          variant="outlined"
          fullWidth
          value={boysNumber}
          onChange={handleBoysNumberChange}
        />
      </Box>
      <Button
        variant="contained"
        sx={{ ...typography.button.primary.dark.enabled, color: '#fff', padding: '10px' }}
        type="submit"
      >
        {t('submit')}
      </Button>
    </form>
  )
}
