import { Box, SxProps, Theme } from '@mui/material'
import { PropsWithChildren } from 'react'

interface IBodyPaddingProps {
  // sx prop is used to style the component
  sx?: SxProps<Theme>
}

export default function BodyPadding({ children, sx }: PropsWithChildren<IBodyPaddingProps>): JSX.Element {
  return <Box sx={{ padding: '16px', paddingBottom: '30px', ...sx }}>{children}</Box>
}
