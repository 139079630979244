import cloneDeepWith from 'lodash/cloneDeepWith'
import isUndefined from 'lodash/isUndefined'
import { DateTime } from 'luxon'
import omitDeepWith from './omitDeepWith'

export default function serialiseObject<T extends object>(obj: T): T {
  return omitDeepWith(
    cloneDeepWith(obj, (value) => {
      if (value instanceof Date) {
        return value.toISOString()
      } else if (DateTime.isDateTime(value)) {
        return value.toISO()
      }
    }),
    isUndefined
  )
}
