import { IPlainReportField, IPlainReportInputTableRow } from '../types'

export default function walkTableRowFields(
  row: IPlainReportInputTableRow | undefined,
  cb: (field: IPlainReportField) => void | false
): void | false {
  if (row?.cells?.length) {
    for (let cell of row.cells) {
      if (cell.inputs?.length) {
        for (let input of cell.inputs) {
          if (cb(input) === false) {
            return false // early exit
          }
        }
      }
    }
  }
}
