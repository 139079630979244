import AddIcon from '@mui/icons-material/Add'
import { Button, useTheme } from '@mui/material'
import { PropsWithChildren } from 'react'

interface AddListItemButtonProps extends PropsWithChildren {
  className?: string
  onClick: () => void
}

export default function AddListItemButton(props: AddListItemButtonProps) {
  const { children, className = '', onClick } = props

  const { typography } = useTheme()

  return (
    <Button
      className={`justify-start ${className}`}
      onClick={onClick}
      sx={{ ...typography.button.primary.light.enabled }}
    >
      <AddIcon sx={{ marginRight: '0.5rem' }} />
      {children}
    </Button>
  )
}
