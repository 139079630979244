import { useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { myBetterWorldGroupsQueryKey } from '../../../../api/constants'
import { myBetterWorldGroupsEndpoint } from '../../../../api/endpoints'
import { IMyBetterWorldGroupBaseDto, IMyBetterWorldGroupDto, IMyBetterWorldGroupLocalData } from '../../../../api/types'
import useOfflineMutation from '../../../../hooks/offline/useOfflineMutation'
import useMst from '../../../../models/useMst'
import { updateSingleStep } from './useMutateMyBetterWorldGroup'

// Props interface for the hook
interface IUseCreateMyBetterWorldGroupProps {
  // onSuccess callback accepts the data returned from the mutation
  onSuccess?: (data: IMyBetterWorldGroupBaseDto | undefined) => void
  // onError callback accepts the error returned from the mutation
  onError?: (error: unknown) => void
}

// Mutation function that returns a promise
export const createMyBetterWorldGroup = async (
  payload: IMyBetterWorldGroupLocalData
): Promise<IMyBetterWorldGroupDto> => {
  const { steps, ...attributes } = payload

  // Use axios to make a request to myBetterWorldGroupsEndpoint
  const res = await axios.post<{ data: IMyBetterWorldGroupDto }>(myBetterWorldGroupsEndpoint, { attributes })

  const group = res.data.data
  if (group && group.id && steps?.length) {
    const groupId = group.id
    // Update the steps
    await Promise.all(steps.map((step) => updateSingleStep({ completed: true, groupId, stepId: step.step_id })))
  }

  // Return the data
  return group
}

export default function useCreateMyBetterWorldGroup(props: IUseCreateMyBetterWorldGroupProps) {
  // Destructure the props
  const { onSuccess, onError } = props

  const queryClient = useQueryClient()

  const { myBetterWorldGroups } = useMst()

  return useOfflineMutation<IMyBetterWorldGroupLocalData, IMyBetterWorldGroupBaseDto>({
    addLocal: (data: IMyBetterWorldGroupLocalData) => {
      myBetterWorldGroups.enqueueForUpdate(data)
    },
    removeLocal: (data: IMyBetterWorldGroupLocalData) => {
      myBetterWorldGroups.didAddGroup(data.submission_id)
    },
    saveRemote: createMyBetterWorldGroup,

    onSuccess: (data, saved, remoteData) => {
      // Invalidate and refetch the myBetterWorldGroups query
      queryClient.invalidateQueries(myBetterWorldGroupsQueryKey)

      // Call the onSuccess callback if it exists
      onSuccess?.(remoteData)
    },
    onError,
  })
}
