import { observer } from 'mobx-react-lite'
import RequestLoadingHandler from '../../../RequestLoadingHandler'
import useLocalInsightsStats from '../hooks/useLocalInsightsStats'
import { useDateRangeSelector } from './DateRangeSelector'
import InsightsCharts from './InsightsCharts'

const LocalInsightsCharts = observer(() => {
  const { dateRangeSelector, startDate, endDate } = useDateRangeSelector({
    className: 'mb-4',
  })

  const { data, isLoading, isSuccess, isError, error, fetchStatus } = useLocalInsightsStats({
    startDate,
    endDate,
  })

  return (
    <RequestLoadingHandler
      error={error}
      fetchStatus={fetchStatus}
      isError={isError}
      isLoading={isLoading}
      isSuccess={isSuccess}
      loadingClassName="py-16"
    >
      {dateRangeSelector}
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
        <InsightsCharts data={data} />
      </div>
    </RequestLoadingHandler>
  )
})

export default LocalInsightsCharts
