import { useUser } from '@auth0/nextjs-auth0/client'
import { useTranslation } from 'next-i18next'
import AppBarBackButton from '../../AppBarBackButton'
import AuthLayout from '../../AuthLayout'
import CamfedAppBar from '../../CamfedAppBar'
import { IScreenProps } from '../../Navigation/types'
import RequestLoadingHandler from '../../RequestLoadingHandler'
import Placeholder from '../MyGuides/OverviewPage/Placeholder'
import ReportOverview from './components/ReportOverview'
import useGetReports from './hooks/useGetReports'

interface ReportsOverviewPageProps extends IScreenProps {}

export default function ReportsOverviewPage(props: ReportsOverviewPageProps) {
  const { navigator } = props
  const { t } = useTranslation('reports')
  const { user } = useUser()

  // fetch report by user country/district
  const { data, error, fetchStatus, isError, isLoading, isSuccess } = useGetReports(user?.meta?.country)

  const report = data?.[0]

  return (
    <AuthLayout
      appBar={
        <CamfedAppBar centralText={t('reports')} leftButton={<AppBarBackButton onClick={navigator.pop} />} prominent />
      }
    >
      <RequestLoadingHandler
        error={error}
        errorClassName="p-4"
        fetchStatus={fetchStatus}
        isError={isError}
        isLoading={isLoading}
        isSuccess={isSuccess}
        loadingClassName="p-4"
      >
        {report ? <ReportOverview report={report} /> : <Placeholder className="my-8" />}
      </RequestLoadingHandler>
    </AuthLayout>
  )
}
