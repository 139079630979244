import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { IAssociatedGuide } from '../../../../api/types'
import AppBarBackButton from '../../../AppBarBackButton'
import AuthLayout from '../../../AuthLayout'
import CamfedAppBar from '../../../CamfedAppBar'
import FlatList from '../../../FlatList/FlatList'
import { INavigator, IScreenProps } from '../../../Navigation/types'
import useNavigator from '../../../Navigation/useNavigator'
import GuideCard from './GuideCard'
import useGetMyGuides from './hooks/useGetMyGuides'
import Placeholder from './Placeholder'

interface MyGuidesOverviewPageProps extends IScreenProps {}

const itemKey = (item: IAssociatedGuide) => item.data.id

const MyGuidesOverviewPage = ({}: MyGuidesOverviewPageProps) => {
  const navigator: INavigator = useNavigator()
  const { t } = useTranslation('my-guides')

  const { data, error, isLoading } = useGetMyGuides()

  const renderItem = useCallback(
    (item: IAssociatedGuide, index: number) => {
      return <GuideCard index={index} item={item} navigator={navigator} />
    },
    [navigator]
  )

  return (
    <AuthLayout
      appBar={<CamfedAppBar centralText={t('myGuides')} leftButton={<AppBarBackButton onClick={navigator.pop} />} />}
    >
      <FlatList
        itemKey={itemKey}
        items={data}
        header={<div className="h-4" />}
        emptyView={<Placeholder className="my-8" error={error} isLoading={isLoading} />}
      >
        {renderItem}
      </FlatList>
    </AuthLayout>
  )
}

export default MyGuidesOverviewPage
