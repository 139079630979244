export default function boolValue(input: unknown): boolean {
  if (typeof input === 'string') {
    const c = input[0]
    // Ref: https://developer.apple.com/documentation/foundation/nsstring/1409420-boolvalue
    return (
      c === 'Y' ||
      c === 'y' ||
      c === 'T' ||
      c === 't' ||
      c === '1' ||
      c === '2' ||
      c === '3' ||
      c === '4' ||
      c === '5' ||
      c === '6' ||
      c === '7' ||
      c === '8' ||
      c === '9'
    )
  } else {
    return Boolean(input)
  }
}
