import { ButtonBase, Card, CardContent, Typography, useTheme } from '@mui/material'
import { useCallback } from 'react'
import { IAssociatedGuide } from '../../../../api/types'
import { myGuideDetailsRoute } from '../../../../constants'
import { INavigator } from '../../../Navigation/types'

interface IGuideCardProps {
  index: number
  item: IAssociatedGuide
  navigator: INavigator
}

const GuideCard = ({ item, navigator }: IGuideCardProps): JSX.Element => {
  const { typography } = useTheme()

  const className = `px-4 pt-0 pb-4 w-full text-center`

  const handleClick = useCallback(() => {
    navigator.push(myGuideDetailsRoute(item))
  }, [item, navigator])

  return (
    <div className={className}>
      <ButtonBase className="text-start w-full max-w-2xl" onClick={handleClick}>
        <Card className="w-full">
          <CardContent>
            <Typography variant="h6" sx={typography.h6_onSurface.light}>
              {item.data.fullName}
            </Typography>
            <Typography variant="subtitle2" sx={typography.subtitle2.onSurface.light.lowEmphasis}>
              {item.data.school}
            </Typography>
          </CardContent>
        </Card>
      </ButtonBase>
    </div>
  )
}

export default GuideCard
