import { useUser } from '@auth0/nextjs-auth0/client'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import AppBarBackButton from '../../AppBarBackButton'
import AuthLayout from '../../AuthLayout'
import CamfedAppBar from '../../CamfedAppBar'
import { IScreenProps } from '../../Navigation/types'
import ActiveTimeCard from './components/ActiveTimeCard'
import OfflineInsightsCharts from './components/LocalInsightsCharts'

const SelfInsightsPage = (props: IScreenProps) => {
  const { navigator } = props

  const { user } = useUser()
  const userIds = useMemo(() => {
    const userId = user?.sub
    return userId ? [userId] : []
  }, [user?.sub])

  const { t: tInsights } = useTranslation('insights')

  return (
    <AuthLayout
      appBar={
        <CamfedAppBar centralText={tInsights('insights')} leftButton={<AppBarBackButton onClick={navigator.pop} />} />
      }
    >
      <div className="flex-1 flex flex-col justify-center items-center">
        <div className="flex-1 h-full w-full flex flex-col p-[16px]">
          <ActiveTimeCard className="mb-4" userIds={userIds} />
          <OfflineInsightsCharts />
        </div>
      </div>
    </AuthLayout>
  )
}

export default SelfInsightsPage
