import { ISocialSupportCategoryFields } from '../../../../@types/generated/contentful'
import { ISocialSupportCaseCategoryDto, ResolvedFields } from '../../../../api/types'

export default function getCategoryCode(
  category: string | ISocialSupportCaseCategoryDto | ResolvedFields<ISocialSupportCategoryFields> | null | undefined
) {
  if (typeof category === 'string') {
    return category
  } else if (category) {
    if ('category_id' in category) {
      return category.category_id
    } else if ('code' in category) {
      return category.code
    }
  }
  return ''
}
