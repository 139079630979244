import { useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import isEqual from 'lodash/isEqual'
import keyBy from 'lodash/keyBy'
import pick from 'lodash/pick'
import { toJS } from 'mobx'
import { useTranslation } from 'next-i18next'
import { socialSupportCasesQueryKey } from '../../../../api/constants'
import {
  socialSupportCaseActionEndpoint,
  socialSupportCaseActionsEndpoint,
  socialSupportCaseEndpoint,
} from '../../../../api/endpoints'
import {
  ISocialSupportCaseActionLocalData,
  ISocialSupportCaseDto,
  ISocialSupportCaseLocalData,
} from '../../../../api/types'
import useOfflineMutation from '../../../../hooks/offline/useOfflineMutation'
import useMst from '../../../../models/useMst'
import getCategoryCode from '../helpers/getCategoryCode'
import { getSocialSupportCase } from './useGetSocialSupportCase'
import {
  deleteSocialSupportCaseQueryData,
  updateSocialSupportCaseQueryData,
} from './useSubmitUnsavedSocialSupportCases'

const allowedUpdateFields = ['closed', 'title', 'notes'] // only update these fields
const allowedActionUpdateFields = ['completed', 'date', 'notes', 'title'] // only update these fields

export const syncSocialSupportCaseActions = async (
  supportCase: ISocialSupportCaseLocalData,
  remote: ISocialSupportCaseDto
) => {
  // map existing actions by id
  const existingActions = keyBy(remote.actions, 'id')

  // get actions to add
  const actionsToAdd = supportCase.actions?.filter((action) => !action.id && !action.isDeleted) ?? []

  // get actions to remove
  const actionsToRemove = supportCase.actions?.filter((action) => action.isDeleted) ?? []

  // get actions to update
  const actionsToUpdate =
    supportCase.actions?.filter((action) => {
      if (!action.id || action.isDeleted) {
        return false
      }
      const existingAction = existingActions[action.id]
      if (!existingAction) {
        return false
      }
      const isDirty = !isEqual(pick(action, allowedActionUpdateFields), pick(existingAction, allowedActionUpdateFields))
      return isDirty
    }) ?? []

  // sync steps
  await Promise.all([
    ...actionsToAdd.map((action) => updateAction(remote.id, action)),
    ...actionsToUpdate.map((action) => updateAction(remote.id, action)),
    ...actionsToRemove.map((action) => updateAction(remote.id, action)),
  ])
}

export const syncSocialSupportCase = async (supportCase: ISocialSupportCaseLocalData) => {
  if (supportCase.id) {
    // fetch latest case data
    const remote = await getSocialSupportCase(supportCase.id)

    if (remote) {
      // update case if any fields have changed
      const isDirty = !isEqual(pick(supportCase, allowedUpdateFields), pick(remote, allowedUpdateFields))
      if (isDirty) {
        await updateSocialSupportCase(supportCase)
      }

      await syncSocialSupportCaseActions(supportCase, remote)
    }
  }
}

export const updateSocialSupportCase = async (
  supportCase: ISocialSupportCaseLocalData
): Promise<ISocialSupportCaseDto | undefined> => {
  if (supportCase.id) {
    const { categories, closed, notes, number_of_boys, number_of_girls, submission_id, title, trainer_id, year } =
      supportCase
    const attributes = {
      categories: (categories ?? []).map(getCategoryCode),
      closed,
      notes,
      number_of_boys,
      number_of_girls,
      title,
      trainer_id,
      submission_id,
      year,
    }

    const res = await axios.put<{ data: ISocialSupportCaseDto }>(socialSupportCaseEndpoint(supportCase.id), {
      attributes,
    })
    return res.data.data
  }
}

export const deleteSocialSupportCase = async (supportCase: ISocialSupportCaseLocalData) => {
  if (supportCase.id) {
    await axios.delete<ISocialSupportCaseDto>(socialSupportCaseEndpoint(supportCase.id))
  }
}

export const updateAction = async (caseId: number, action: ISocialSupportCaseActionLocalData) => {
  if (action.isDeleted && !action.id) {
    return
  }

  const url = action.id ? socialSupportCaseActionEndpoint(caseId, action.id) : socialSupportCaseActionsEndpoint(caseId)

  const method = action.isDeleted ? 'DELETE' : action.id ? 'PUT' : 'POST'

  const { completed, date, notes, submission_id, title } = action

  const res = await axios({
    url,
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      attributes: {
        completed,
        date,
        notes,
        submission_id,
        title,
      },
    },
  })

  const data = res.data
  return data
}

interface IMutateArgs {
  supportCase: ISocialSupportCaseLocalData & { trainer_id: string }
  change: { type: 'updateProps' } | { type: 'upsertAction'; submissionId: string } | { type: 'delete' }
}

export default function useMutateSocialSupportCase() {
  const queryClient = useQueryClient()

  const { messages, socialSupportCases } = useMst()

  const { t } = useTranslation('social-support-cases-form')

  return useOfflineMutation<IMutateArgs, number | undefined>({
    addLocal: (local) => {
      socialSupportCases.enqueueForUpdate(local.supportCase)
    },
    removeLocal: (local) => {
      if (local.supportCase.id) {
        // Optimistic update query data
        const localCase = toJS(socialSupportCases?.queue?.find((g) => g.id === local.supportCase.id))
        if (localCase) {
          if (localCase.isDeleted) {
            deleteSocialSupportCaseQueryData(queryClient, local.supportCase)
            socialSupportCases.removeCase(local.supportCase)
          } else {
            updateSocialSupportCaseQueryData(queryClient, localCase as ISocialSupportCaseDto)
            socialSupportCases.didUpdateCase(local.supportCase.id)
          }
        }
      }
    },
    saveRemote: async ({ supportCase, change }) => {
      if (supportCase.id) {
        if (change.type === 'upsertAction') {
          const action = (supportCase.actions ?? []).find((a) => a.submission_id === change.submissionId)
          if (action) {
            await updateAction(supportCase.id, action)
          }
        } else if (change.type === 'updateProps') {
          // only update existing cases; new cases are handled by `useSubmitUnsavedCases`
          await updateSocialSupportCase(supportCase)
        } else if (change.type === 'delete') {
          await deleteSocialSupportCase(supportCase)
        }
        return supportCase.id
      }
    },
    onSuccess: (data, saved, savedCaseId) => {
      const changeType = data.change.type

      if (saved) {
        if (changeType === 'delete' && !data.supportCase.id) {
          // if case is not created yet and we're deleting it, simply remove it from the queue
          socialSupportCases.removeCase(data.supportCase)
        }

        if (changeType === 'updateProps' || changeType === 'upsertAction' || changeType === 'delete') {
          // refresh listing
          queryClient.invalidateQueries(socialSupportCasesQueryKey(data.supportCase.trainer_id))
        }

        if (changeType === 'delete') {
          messages.showMessage(t('deleteCaseSuccess'), 'success')
        } else {
          messages.showMessage(t('editCaseSuccess'), 'success')
        }
      }
    },
    onError: (error, data) => {
      const err = error as AxiosError
      // If the change was a delete
      if (data.data.change.type === 'delete') {
        messages.showMessage(t('deleteCaseError', { message: err.message }), 'error')
      } else {
        messages.showMessage(t('editCaseError', { message: err.message }), 'error')
      }
    },
  })
}

export type MutateSocialSupportCase = ReturnType<typeof useMutateSocialSupportCase>['mutate']
