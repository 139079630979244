import { UserProfile } from '@auth0/nextjs-auth0/client'
import ReactGA from 'react-ga4'
import { UaEventOptions } from 'react-ga4/types/ga4'
import { logInfo } from '../Common/loggers'

const ga4MeasurementId = process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID
let isInitialized = false

export function initTrackers() {
  if (ga4MeasurementId && !isInitialized) {
    ReactGA.initialize(ga4MeasurementId)
    isInitialized = true
  }
}

// Generic track function
const trackEvent: typeof ReactGA['event'] = (optionsOrName: UaEventOptions | string, params?: any) => {
  logInfo('Tracking event' + (isInitialized ? '' : ' [DISABLED]'), optionsOrName, params)
  if (isInitialized) {
    ReactGA.event(optionsOrName, params)
  }
}

//
// Specific track functions
//

export const trackButtonClick = (user: UserProfile, section: string, linkText: string, linkUrl: string) => {
  trackEvent('button_click', {
    country: user.meta?.country,
    link_text: linkText,
    link_url: linkUrl,
    method: 'get',
    section,
    user_role: user.meta?.roles?.map((r) => r.name).join(','),
  })
}

export const trackChangePassword = (user: UserProfile, section: string) => {
  trackEvent('change_password', {
    country: user.meta?.country,
    section,
    user_role: user.meta?.roles?.map((r) => r.name).join(','),
  })
}

export const trackEmailUpdated = (user: UserProfile, section: string) => {
  trackEvent('email_updated', {
    country: user.meta?.country,
    section,
    user_role: user.meta?.roles?.map((r) => r.name).join(','),
  })
}

export const trackFileDownload = (
  user: UserProfile,
  section: string,
  resourceTitle: string,
  fileName: string,
  linkText: string
) => {
  const hasFileExtension = fileName.includes('.')
  const fileExtension = hasFileExtension ? fileName.split('.').pop() : ''

  trackEvent('file_download', {
    country: user.meta?.country,
    file_extension: fileExtension,
    file_name: fileName,
    link_text: linkText,
    resourceTitle: resourceTitle,
    section,
    user_role: user.meta?.roles?.map((r) => r.name).join(','),
  })
}

export const trackHomeCardAction = (user: UserProfile, section: string, cardTitle: string) => {
  trackEvent('home_card_action', {
    feed_card_title: cardTitle,
    country: user.meta?.country,
    section,
    user_role: user.meta?.roles?.map((r) => r.name).join(','),
  })
}

export const trackHomeCardDismiss = (user: UserProfile, section: string, cardTitle: string) => {
  trackEvent('home_card_dismiss', {
    feed_card_title: cardTitle,
    country: user.meta?.country,
    section,
    user_role: user.meta?.roles?.map((r) => r.name).join(','),
  })
}

export const trackLogin = (user: UserProfile, section: string) => {
  trackEvent('login', {
    country: user.meta?.country,
    section,
    user_role: user.meta?.roles?.map((r) => r.name).join(','),
  })
}

export const trackLogout = (user: UserProfile, section: string) => {
  trackEvent('logout', {
    country: user.meta?.country,
    section,
    user_role: user.meta?.roles?.map((r) => r.name).join(','),
  })
}

export const trackOnboardingCardDismiss = (user: UserProfile, section: string, cardTitle: string) => {
  trackEvent('carousel_interaction_dismiss', {
    carousel_slide_title: cardTitle,
    country: user.meta?.country,
    section,
    user_role: user.meta?.roles?.map((r) => r.name).join(','),
  })
}

export const trackOnboardingCardNext = (user: UserProfile, section: string, cardTitle: string) => {
  trackEvent('carousel_interaction_next', {
    carousel_slide_title: cardTitle,
    country: user.meta?.country,
    section,
    user_role: user.meta?.roles?.map((r) => r.name).join(','),
  })
}

export const trackOnboardingCardPrev = (user: UserProfile, section: string, cardTitle: string) => {
  trackEvent('carousel_interaction_prev', {
    carousel_slide_title: cardTitle,
    country: user.meta?.country,
    section,
    user_role: user.meta?.roles?.map((r) => r.name).join(','),
  })
}

export const trackPageView = (user: UserProfile, section: string) => {
  trackEvent('page_view', {
    country: user.meta?.country,
    section,
    user_role: user.meta?.roles?.map((r) => r.name).join(','),
  })
}

export const trackPhoneUpdated = (user: UserProfile, section: string) => {
  trackEvent('phone_updated', {
    country: user.meta?.country,
    section,
    user_role: user.meta?.roles?.map((r) => r.name).join(','),
  })
}

export const trackSearchResource = (user: UserProfile, section: string, searchQuery: string) => {
  trackEvent('resource_search', {
    country: user.meta?.country,
    resource_search_term: searchQuery,
    section,
    user_role: user.meta?.roles?.map((r) => r.name).join(','),
  })
}
