import PopupAppBar from '../PopupAppbar'

export interface IResourceAppBarProps {
  centralText: string
  onClose: () => void
  prominent?: boolean
}
export default function ResourceAppBar(props: IResourceAppBarProps): JSX.Element {
  const { centralText, onClose, prominent } = props
  return <PopupAppBar centralText={centralText} onClose={onClose} prominent={prominent} />
}
