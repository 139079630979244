// Function component with Authlayout and placeholder content

import { toJS } from 'mobx'
import { useMemo } from 'react'
import { ISocialSupportCaseDto, ISocialSupportCaseLocalData } from '../../../../api/types'
import useStableMobxSnapshot from '../../../../hooks/useStableMobxSnapshot'
import useMst from '../../../../models/useMst'
import useGetSocialSupportCase from './useGetSocialSupportCase'

export function resolveSocialSupportCase(
  listItem: ISocialSupportCaseDto | ISocialSupportCaseLocalData | undefined,
  remoteCase: ISocialSupportCaseDto | undefined,
  localCase: ISocialSupportCaseLocalData | undefined
): ISocialSupportCaseLocalData {
  const caseId = listItem && 'id' in listItem ? listItem.id : undefined
  const submissionId = listItem?.submission_id

  const resolved: ISocialSupportCaseLocalData = {
    actions: localCase?.actions ?? remoteCase?.actions ?? listItem?.actions ?? [],
    categories: localCase?.categories ?? remoteCase?.categories ?? listItem?.categories ?? [],
    closed: localCase?.closed ?? remoteCase?.closed ?? listItem?.closed ?? false,
    closed_date: remoteCase?.closed_date ?? listItem?.closed_date ?? localCase?.closed_date ?? '',
    created_at: remoteCase?.created_at ?? listItem?.created_at ?? localCase?.created_at ?? '',
    id: caseId,
    isDeleted: localCase?.isDeleted ?? listItem?.isDeleted ?? false,
    isLocal: Boolean(localCase),
    notes: localCase?.notes ?? remoteCase?.notes ?? listItem?.notes ?? '',
    number_of_boys: localCase?.number_of_boys ?? remoteCase?.number_of_boys ?? listItem?.number_of_boys ?? 0,
    number_of_girls: localCase?.number_of_girls ?? remoteCase?.number_of_girls ?? listItem?.number_of_girls ?? 0,
    submission_id: submissionId ?? '',
    title: localCase?.title ?? remoteCase?.title ?? listItem?.title ?? '',
    trainer_id: localCase?.trainer_id ?? remoteCase?.trainer_id ?? listItem?.trainer_id ?? '',
    updated_at: remoteCase?.updated_at ?? listItem?.updated_at ?? localCase?.updated_at ?? '',
    year: localCase?.year ?? remoteCase?.year ?? listItem?.year ?? '',
  }
  return resolved
}

export default function useSocialSupportCase(
  listItem: ISocialSupportCaseDto | ISocialSupportCaseLocalData | undefined
) {
  // get the case id from the listItem
  const caseId = listItem && 'id' in listItem ? listItem.id : undefined
  const submissionId = listItem?.submission_id

  // fetch group from remote / cache
  const { data: remoteCase, isLoading, isError, error, fetchStatus, isSuccess } = useGetSocialSupportCase({ caseId })

  // get any pending updates from the store
  const { socialSupportCases } = useMst()
  const localCases = useStableMobxSnapshot(() => toJS(socialSupportCases?.queue))
  const localCase = localCases.find(
    (g) => (caseId && g.id === caseId) || (submissionId && g.submission_id === submissionId)
  )
  const isLocalGroupNew = localCase && !localCase.id
  const isValid = Boolean(caseId || isLocalGroupNew)

  // resolve attributes from remote data or local data
  const resolvedCase: ISocialSupportCaseLocalData = useMemo(() => {
    return resolveSocialSupportCase(listItem, remoteCase, localCase)
  }, [listItem, remoteCase, localCase])

  return {
    resolvedCase,
    remoteCase,
    isValid,
    isLoading,
    isError,
    error,
    fetchStatus,
    isSuccess,
  }
}
