import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { Asset, AssetFile } from 'contentful'
import { FC, MouseEventHandler, useCallback } from 'react'
import { assetDownloadDialogZIndex } from '../../constants'
import useDialogue from '../../hooks/useDialogue'
import useDownload from '../../hooks/useDownload'
import { useAnalytics } from '../Analytics/AnalyticsContext'

const AssetButton: FC<{ asset: Asset }> = ({ asset }) => {
  const { download, isLoading } = useDownload({
    filename: (asset.fields?.file as AssetFile | undefined)?.fileName,
    href: (asset.fields?.file as AssetFile | undefined)?.url,
  })

  const [isOpen, close, open] = useDialogue()

  const { trackFileDownload } = useAnalytics()

  const title = String(asset.fields?.title ?? '')

  const onConfirm: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      download(e)
      if (asset.fields?.file?.url) {
        const assetFile = asset.fields.file as AssetFile
        trackFileDownload(title as string, assetFile.fileName, assetFile.url as string)
      }
      close()
    },
    [asset.fields.file, close, download, title, trackFileDownload]
  )

  return (
    <>
      <Button
        className="w-full justify-start"
        disabled={isLoading}
        onClick={() => open()}
        startIcon={<DownloadForOfflineIcon />}
      >
        {title}
      </Button>
      <Dialog open={isOpen} onClose={close} sx={{ zIndex: assetDownloadDialogZIndex }}>
        <DialogTitle>Download Document</DialogTitle>
        <DialogContent>
          <DialogContentText>by clicking Confirm this document will be saved to your device</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Cancel</Button>
          <Button onClick={onConfirm}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AssetButton
