import { ListItem, ListItemButton, ListItemText, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { IUserReport } from '../../../../api/types'
import userReportTitle from '../utils/userReportTitle'

interface UserReportListItemProps {
  data: IUserReport
  onClick: (report: IUserReport) => void
}

export default function UserReportListItem(props: UserReportListItemProps) {
  const { data, onClick } = props

  const { t } = useTranslation('common')

  const { typography } = useTheme()

  const handleClick = useCallback(() => {
    onClick(data)
  }, [data, onClick])

  return (
    <ListItem disableGutters disablePadding className="pr-1.5">
      <ListItemButton onClick={handleClick}>
        <ListItemText classes={{ primary: 'flex flex-row items-center' }}>
          <Typography variant="subtitle1" sx={typography.button.primary.light.enabled}>
            {userReportTitle(data)}
          </Typography>
          <Typography variant="body2" sx={typography.body2.onSurface.body2.light}>
            {data.id ? ` - ${t('submitted')}` : `*`}
          </Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  )
}
