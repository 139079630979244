import { Card, CardContent, Chip, CircularProgress } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import formatDate from '../../../../utils/dates/formatDate'
import formatDuration from '../../../../utils/dates/formatDuration'
import useGetActiveTime from '../hooks/useGetActiveTime'

interface ActiveTimeCardProps {
  className?: string
  userIds: string[]
}

export default function ActiveTimeCard(props: ActiveTimeCardProps) {
  const { className = '', userIds } = props

  const { t } = useTranslation('insights')

  // get last month
  const [lastMonth, lastMonthYYYYMM] = useMemo(() => {
    const lastMonth = new Date()
    lastMonth.setMonth(lastMonth.getMonth() - 1)
    return [
      lastMonth,
      lastMonth.toISOString().slice(0, 7), // format in YYYY-MM
    ]
  }, [])

  // fetch from API
  const { data, isLoading } = useGetActiveTime(lastMonthYYYYMM, userIds)

  // loop through all users and add up the active time
  const activeTime = useMemo(() => {
    let activeTime = NaN
    if (data) {
      activeTime = 0
      Object.entries(data).map(([userId, monthlyActiveTime]) => {
        if (monthlyActiveTime) {
          Object.entries(monthlyActiveTime).map(([month, t]) => {
            activeTime += t
          })
        }
      })
    }
    return activeTime
  }, [data])

  return (
    <Card className={`w-full ${className}`}>
      <CardContent className="flex flex-row justify-between items-center">
        <div>
          {t('activeTimeLastMonth')} ({formatDate(lastMonth, 'MM/yy')})
        </div>
        <div>
          {isLoading ? (
            <CircularProgress size={16} />
          ) : isNaN(activeTime) ? (
            <Chip label="N/A" />
          ) : (
            <Chip label={formatDuration(activeTime)} />
          )}
        </div>
      </CardContent>
    </Card>
  )
}
