import { useCallback, useEffect, useRef, useState } from 'react'

/**
 * Credit: https://usehooks.com/useDebounce/
 *
 * @param value value to debounce
 * @param delay in milliseconds
 * @returns debounced value
 */
export default function useDebouncedValue<T>(value: T, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  const timer = useRef<NodeJS.Timeout | null>(null)

  useEffect(
    () => {
      // Update debounced value after delay
      timer.current = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        if (timer.current) {
          clearTimeout(timer.current)
          timer.current = null
        }
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )

  const commit = useCallback(() => {
    // Update debounced value immediately
    setDebouncedValue(value)
    if (timer.current) {
      clearTimeout(timer.current)
      timer.current = null
    }
  }, [value])

  const setValue = useCallback((newValue: T) => {
    // Set debounced value immediately
    setDebouncedValue(newValue)
    if (timer.current) {
      clearTimeout(timer.current)
      timer.current = null
    }
  }, [])

  return { commit, debouncedValue, setValue }
}
