import { ContentTypes } from '../../../../api/contentTypeIds'
import { IPlainReportField, IPlainReportFormGroup } from '../types'
import walkTableFields from './walkTableFields'

export default function walkFormGroupFields(
  group: IPlainReportFormGroup | undefined,
  cb: (field: IPlainReportField) => void | false
): void | false {
  if (group?.elements?.length) {
    for (let element of group.elements) {
      if (element.contentType === ContentTypes.reportField) {
        if (cb(element) === false) {
          return false // early exit
        }
      } else if (element.contentType === ContentTypes.reportInputTable) {
        if (walkTableFields(element, cb) === false) {
          return false // early exit
        }
      } else if (element.contentType === ContentTypes.reportFormGroup) {
        if (walkFormGroupFields(element, cb) === false) {
          return false // early exit
        }
      }
    }
  }
}
