import { useUser } from '@auth0/nextjs-auth0/client'
import { useTranslation } from 'next-i18next'
import { useEffect } from 'react'
import useMst from '../../models/useMst'

export default function useLeaveConfirmation() {
  const { auth } = useMst()
  const { user } = useUser()
  const { t } = useTranslation()
  const areYouSureToLeaveText = t('areYouSureToLeave')

  useEffect(() => {
    function handler(event: BeforeUnloadEvent) {
      if (!auth.loggingOut) {
        // except for logging out, ask for confirmation
        event.preventDefault()
        event.returnValue = areYouSureToLeaveText
      }
    }

    if (user) {
      addEventListener('beforeunload', handler)
      return () => {
        removeEventListener('beforeunload', handler)
      }
    }
  }, [areYouSureToLeaveText, auth, user])
}
