import { MenuItem } from '@mui/material'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { ISocialSupportCaseLocalData } from '../../../../api/types'
import useMoreMenu from '../../../Common/useMoreMenu'
import usePrompt from '../../../Common/usePrompt'
import useShowMessage from '../../../Common/useShowMessage'
import { INavigator } from '../../../Navigation/types'
import socialSupportCaseTitle from '../helpers/socialSupportCaseTitle'
import { MutateSocialSupportCase } from '../hooks/useMutateSocialSupportCase'

interface IUseSocialSupportCaseMenuProps {
  mutate: MutateSocialSupportCase
  navigator: INavigator
  supportCase: ISocialSupportCaseLocalData
}

export default function useSocialSupportCaseMenu(props: IUseSocialSupportCaseMenuProps) {
  // Get the navigator from the props
  const { mutate, navigator, supportCase } = props
  const isClosed = Boolean(supportCase.closed)

  // Get the t function from the translation
  const { t } = useTranslation('social-support-cases-form')

  const { showMessage, messageDialog } = useShowMessage()

  const { showPrompt: handleRename, promptDialog: renameDialog } = usePrompt({
    initialValue: supportCase.title,
    inputLabel: t('titleFieldLabel'),
    onConfirm: useCallback(
      (title: string) => {
        mutate({
          data: {
            supportCase: {
              ...supportCase,
              title,
            },
            change: {
              type: 'updateProps',
            },
          },
        })
      },
      [mutate, supportCase]
    ),
    title: t('renameCase') ?? '',
  })

  const handleToggleStatus = useCallback(() => {
    showMessage({
      title: t(isClosed ? 'reopenCase' : 'closeCase') ?? '',
      message:
        t(isClosed ? 'reopenCaseConfirmationMessage' : 'closeCaseConfirmationMessage', {
          title: socialSupportCaseTitle(supportCase.title, supportCase.year),
        }) ?? '',
      buttons: [
        {
          text: t('common:cancel') ?? '',
          isCancel: true,
        },
        {
          text: (isClosed ? t('reopenCase') : t('closeCase')) ?? '',
          onClick: (dismiss) => {
            dismiss()
            mutate({
              data: {
                supportCase: {
                  ...supportCase,
                  closed: !isClosed,
                },
                change: {
                  type: 'updateProps',
                },
              },
            })
          },
        },
      ],
    })
  }, [isClosed, mutate, showMessage, supportCase, t])

  const deleteCase = useCallback(() => {
    mutate({
      data: {
        supportCase: {
          ...supportCase,
          isDeleted: true,
        },
        change: {
          type: 'delete',
        },
      },
    })
    navigator.pop()
  }, [mutate, navigator, supportCase])

  const handleDelete = useCallback(() => {
    showMessage({
      title: t('deleteDialogueTitle') ?? '',
      message: t('deleteDialogueText', { title: socialSupportCaseTitle(supportCase.title, supportCase.year) }) ?? '',
      buttons: [
        {
          text: t('deleteDialogueNo') ?? '',
          isCancel: true,
        },
        {
          text: t('deleteDialogueYes') ?? '',
          onClick: deleteCase,
        },
      ],
    })
  }, [deleteCase, showMessage, supportCase.title, supportCase.year, t])

  const { menu } = useMoreMenu((closeMenu: () => void) => {
    return [
      <MenuItem
        key="renameText"
        onClick={() => {
          closeMenu()
          handleRename()
        }}
      >
        {t('renameCase')}
      </MenuItem>,
      <MenuItem
        key="toggleStatus"
        onClick={() => {
          closeMenu()
          handleToggleStatus()
        }}
      >
        {isClosed ? t('reopenCase') : t('closeCase')}
      </MenuItem>,
      <MenuItem
        key="deleteGroup"
        onClick={() => {
          closeMenu()
          handleDelete()
        }}
      >
        {t('deleteCaseText')}
      </MenuItem>,
    ]
  })

  return { menu, messageDialog, renameDialog }
}
