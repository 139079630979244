import { cast, types } from 'mobx-state-tree'

const Onboarding = types
  .model({
    completedOnboardingIds: types.array(types.string),
    showOnboarding: types.optional(types.boolean, true),
  })
  .actions((self) => ({
    _setShowOnboarding(show: boolean) {
      self.showOnboarding = show
    },
  }))
  .actions((self) => ({
    addCompletedId(id: string) {
      self.completedOnboardingIds.push(id)
    },
    setShowOnboarding(show: boolean) {
      // Allow a slight delay if we're hiding the onboarding to allow the final card to slide out
      if (!show) {
        setTimeout(() => {
          self._setShowOnboarding(show)
        }, 300)
      } else {
        self._setShowOnboarding(show)
      }
    },
  }))

export default Onboarding
