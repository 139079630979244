import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import parseDate from '../utils/dates/parseDate'

const DAY_IN_MS = 3600 * 24 * 1000

export default function useRelativeDateFormatter() {
  const { t } = useTranslation('common')
  return useCallback(
    (date: Date | string | null | undefined) => {
      if (!date) return ''
      const parsedDate = parseDate(date)
      if (!parsedDate) return ''

      const now = Date.now()
      const diff = now - parsedDate.getTime()
      if (diff < DAY_IN_MS) return t('today')
      if (diff < DAY_IN_MS * 2) return t('yesterday')
      return t('relativeTime', { date: -Math.floor(diff / DAY_IN_MS) })
    },
    [t]
  )
}
