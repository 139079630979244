import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { myBetterWorldGroupQueryKey } from '../../../../api/constants'
import { myBetterWorldGroupEndpoint } from '../../../../api/endpoints'
import { IMyBetterWorldGroupDto } from '../../../../api/types'

// Props include groupId
interface IUseMyBetterWorldGroupProps {
  groupId: number | undefined
}

export async function getMyBetterWorldGroup(groupId: number): Promise<IMyBetterWorldGroupDto> {
  const res = await axios.get<{ data: IMyBetterWorldGroupDto }>(myBetterWorldGroupEndpoint(groupId))
  return res.data.data
}

const useGetMyBetterWorldGroup = (props: IUseMyBetterWorldGroupProps) => {
  const { groupId } = props

  return useQuery<IMyBetterWorldGroupDto, Error>({
    queryKey: myBetterWorldGroupQueryKey(groupId!),
    queryFn: () => getMyBetterWorldGroup(groupId!),
    enabled: !!groupId,
  })
}

export default useGetMyBetterWorldGroup
